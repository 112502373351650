import React, { createContext, useContext, useState, useEffect } from 'react';
import { globals, GlobalsResponse } from '@cerebral-inc/utils';

interface Regions {
  isTherapistRegion(region: string | undefined): boolean;
  therapyRegions: string[];
}

type GlobalData = GlobalsResponse & Regions;

export const defaultValue: GlobalData = {
  'chatting-available': false,
  'regions-available-for-insurance': [],
  'regions-available-for-therapists': [],
  api_version: '',
  max_coordinator_cap: '',
  promo_notification_variable: '',
  terms_and_conditions_version: '',
  isTherapistRegion: () => false,
  therapyRegions: []
};

export const GlobalsContext = createContext<GlobalData>(defaultValue);

export const GlobalsProvider: React.FC = ({ children }) => {
  const [globalData, setGlobalData] = useState<GlobalData>(defaultValue);

  useEffect(() => {
    globals.getGlobalData(process.env.REACT_APP_API_SERVER_URL).then(data => {
      if (data && 'api_version' in data) {
        const therapyRegions = data['regions-available-for-therapists'];
        const isTherapistRegion = (region: string | undefined) => (region ? therapyRegions.includes(region) : false);

        setGlobalData({
          ...data,
          isTherapistRegion,
          therapyRegions
        });
      }
    });
  }, []);

  return <GlobalsContext.Provider value={globalData}>{children}</GlobalsContext.Provider>;
};

export const useGlobals = (): GlobalData => useContext(GlobalsContext);

export const withGlobals = (
  Component: React.ComponentType<{ globals: GlobalData }>
): React.FC<{ [key: string]: unknown }> => {
  return props => {
    const globalData = useContext(GlobalsContext);

    return (
      <Component {...props} globals={globalData}>
        {props.children}
      </Component>
    );
  };
};
