import React, { useState, Fragment } from 'react';

import throttle from '../../utils/throttle';

interface ResizableTextareaProps {
  classNames?: string;
  name?: string;
  submitMessage: (text: string) => void;
  rows: number;
  userTyping: () => void;
}

export const ResizableTextarea: React.FC<ResizableTextareaProps> = ({
  submitMessage,
  rows: propRows,
  userTyping,
  classNames = 'chat_footer_text_placeholder',
  name = 'Care Counselor'
}) => {
  const [text, setText] = useState('');
  const [rows, setRows] = useState(propRows);
  const minRows = propRows;
  const maxRows = 4;
  const keyUpHandler = throttle(() => userTyping(), 1000);

  const onSubmit = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    if (text.length < 1) {
      return;
    }
    submitMessage(text);
    setText('');
    setRows(propRows);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textareaLineHeight = 18; // line-height

    const previousRows = event.target.rows;
    event.target.rows = minRows; // reset number of rows in textarea

    const currentRows = Math.floor(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }
    setRows(currentRows < maxRows ? currentRows : maxRows);
    setText(event.target.value);
  };

  return (
    <Fragment>
      <div className={classNames}>
        <textarea
          className="text-area"
          rows={rows}
          value={text}
          placeholder={`Message ${name} here...`}
          onChange={handleChange}
          onKeyUp={keyUpHandler}
        />
      </div>
      <img onClick={onSubmit} src={`${process.env.PUBLIC_URL}/img/send.svg`} alt="send message" />
    </Fragment>
  );
};

export default ResizableTextarea;
