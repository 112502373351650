import styled, { css } from 'styled-components/macro';

export const ProfileHolder = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  width: 100%;
  background-color: #fcfbfe;
  min-height: 100vh;

  @media (max-width: 991px) {
    padding-top: 50px;
    ${props =>
      props.showBanner &&
      css`
        padding-top: 90px;
      `}
  }

  ${props =>
    props.showBanner &&
    css`
      padding-top: 40px;
    `}
`;

export const ContentWrapper = styled.div`
  display: flex;

  @media (max-width: 991px) {
    overflow: visible;
    height: auto;

    ${props =>
      props.showNav &&
      css`
        overflow: hidden;
        height: 100vh;
        padding: 0;
      `}
  }
`;

export const NotificationBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6786ff;
  position: fixed;
  width: 100%;
  left: 0;
  height: 40px;
  top: 0;

  #refer_friend {
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    text-decoration: underline;
    color: white;
    cursor: pointer;
  }

  svg {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;

export const ProfileTopMenu = styled.div`
  position: fixed;
  display: flex;
  z-index: 10;
  top: ${props => (props.showBanner ? 40 : 0)}px;
  left: 0;
  width: 100%;
  padding: 9px 25px;
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  color: $purple;
  background: linear-gradient(90.1deg, #b9c5fc 3.79%, #c0d4f0 96.77%);
  justify-content: space-between;
  align-items: center;

  @media (min-width: 991px) {
    padding: 0 25px 0 75px;
    height: 80px;
  }
`;
