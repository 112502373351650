import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { USStates } from '@cerebral-inc/utils';
import InputWrapper from '../../../UI/InputWrapper';
import InputDefault from '../../../UI/InputDefault';
import Select from '../../../UI/Select';
import { StyledGridForm, StateZipWrapper } from '../../../question_components/styled_components';
import { stateToErrorReducer } from '../../../../utils/common';
import Error from '../../../Error';
import { VariationContext } from '../../../Variations/VariationContext';

export const validate = (address, setErrors, callback, country) => {
  const { address_1, city, region, postal_code } = address;
  let newErrors;

  if (country === 'uk') {
    newErrors = stateToErrorReducer({
      address_1,
      city,
      postal_code_uk: postal_code
    });
  } else {
    newErrors = stateToErrorReducer({
      address_1,
      city,
      region: region?.toLowerCase() || '',
      postal_code
    });
  }

  setErrors(newErrors);
  if (!Object.keys(newErrors).length) callback(address);
};

function localizedCity(country) {
  if (country === 'uk') {
    return 'Post Town';
  }
  return 'City';
}

function localizedZipCodeText(country) {
  if (country === 'uk') {
    return 'Postcode';
  }
  return 'Zip Code';
}

function localizedZipCodeMask(country) {
  if (country === 'uk') {
    return null;
  }
  return '99999';
}

function localizedZipCodeInputMode(country) {
  if (country === 'uk') {
    return 'text';
  }
  return 'numeric';
}

const StateInput = (initialValues, errors, region, updateHandler, country, addressLoaded) => {
  if (country === 'uk') {
    return (
      <InputWrapper inlineExpand={initialValues.region || initialValues.postal_code}>
        <InputDefault
          event_handler={e => updateHandler('region', e.target.value)}
          text="County"
          default_value={region}
          autoComplete="address-level2"
          name="region"
          disabled={!addressLoaded}
          error={errors.region}
          showErrorMessage={!!errors.region}
        />
      </InputWrapper>
    );
  }
  return (
    <InputWrapper inlineExpand={initialValues.region || initialValues.postal_code}>
      <label htmlFor="input-region">{initialValues.region ? 'State' : null}</label>
      <Select
        name="region"
        id="input-region"
        onChange={e => updateHandler('region', e.target.value)}
        value={region}
        padding="20px 12px"
      >
        <option value="">State</option>
        {USStates.abbreviations.map(key => (
          <option key={key} value={key}>
            {USStates.object[key]}
          </option>
        ))}
      </Select>
      {errors.region && <Error error={errors.region} className="red center pad-0 marg-0" />}
    </InputWrapper>
  );
};

const AddressForm = ({ addressState, addressLoaded, editable, errors, twoColumn }) => {
  const { country } = useContext(VariationContext);
  const [initialValues, setNewAddress] = addressState;
  const { address_1, address_2, address_3, region, city, postal_code } = initialValues;

  if (!addressLoaded) {
    return <p>Loading address...</p>;
  }

  if (!editable) {
    return (
      <>
        <p>{address_1}</p>
        {address_2 ? <p>{address_2}</p> : null}
        <p>{city ? `${city}, ${region}` : null}</p>
        <p>{postal_code}</p>
      </>
    );
  }

  const updateHandler = (key, value) => {
    setNewAddress(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  return (
    <StyledGridForm>
      <div className="small-card-item">
        <InputDefault
          event_handler={e => updateHandler('address_1', e.target.value)}
          text="Address Line 1"
          default_value={address_1}
          autoComplete="address-line1"
          name="address-line1"
          disabled={!addressLoaded}
          error={errors.address_1}
          showErrorMessage={!!errors.address_1}
        />
      </div>
      <div className="small-card-item">
        <InputDefault
          event_handler={e => updateHandler('address_2', e.target.value)}
          text="Address Line 2"
          default_value={address_2}
          autoComplete="address-line2"
          name="address-line2"
          disabled={!addressLoaded}
          showErrorMessage={!!errors.address_2}
        />
      </div>
      {country === 'uk' && (
        <div className="small-card-item">
          <InputDefault
            event_handler={e => updateHandler('address_3', e.target.value)}
            text="Address Line 3"
            default_value={address_3}
            autoComplete="address-line3"
            name="address-line3"
            disabled={!addressLoaded}
            showErrorMessage={!!errors.address_3}
          />
        </div>
      )}
      <div className="small-card-item">
        <InputDefault
          event_handler={e => updateHandler('city', e.target.value)}
          text={localizedCity(country)}
          default_value={city}
          autoComplete="address-level2"
          name="city"
          disabled={!addressLoaded}
          error={errors.city}
          showErrorMessage={!!errors.city}
        />
      </div>
      <StateZipWrapper twoColumn={twoColumn} className="small-card-item">
        {StateInput(initialValues, errors, region, updateHandler, country, addressLoaded)}
        <div className="gap" />
        <InputWrapper inlineExpand={initialValues.region || initialValues.postal_code}>
          <InputDefault
            event_handler={e => updateHandler('postal_code', e.target.value)}
            text={postal_code ? localizedZipCodeText(country) : ''}
            default_value={postal_code}
            autoComplete="postal-code"
            name="postal-code"
            mask={localizedZipCodeMask(country)}
            maskPlaceholder={localizedZipCodeText(country)}
            placeholder={localizedZipCodeText(country)}
            inputMode={localizedZipCodeInputMode(country)}
            disabled={!addressLoaded}
            error={errors.postal_code || errors.postal_code_uk}
            showErrorMessage={!!errors.postal_code || !!errors.postal_code_uk}
          />
        </InputWrapper>
      </StateZipWrapper>
    </StyledGridForm>
  );
};

AddressForm.propTypes = {
  addressState: PropTypes.array.isRequired,
  addressLoaded: PropTypes.bool,
  editable: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  twoColumn: PropTypes.bool
};

AddressForm.defaultProps = {
  twoColumn: false,
  addressLoaded: true,
  editable: true
};

export default AddressForm;
