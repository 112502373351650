import 'react-app-polyfill/ie11';
import 'core-js';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-smartbanner/dist/main.css';
import { PersistGate } from 'redux-persist/integration/react';
import GlobalErrorCatcher from 'components/GlobalErrorCatcher';
import { RolloutFlagProvider } from 'contexts/RolloutFlag';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configure_store from './store/configure_store';
import { VariationProvider } from './components/Variations/VariationContext';
import VersionChecker from './components/VersionChecker';
import { ApiProvider } from './utils/api';
import { RolloutProvider } from './contexts/rollout';
import { AcceptScriptProvider } from './utils/accept-script';
import { GlobalsProvider } from './contexts/globals';
import ReferContextProvider from './contexts/ReferContextProvider';
import { SmartAppBanner } from './SmartAppBanner';

const { store, persistor } = configure_store();

render(
  <>
    <SmartAppBanner />
    <GlobalErrorCatcher>
      <VersionChecker>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AcceptScriptProvider>
              <ApiProvider>
                <RolloutProvider>
                  <RolloutFlagProvider>
                    <GlobalsProvider>
                      <Router basename={process.env.PUBLIC_URL}>
                        <VariationProvider>
                          <ReferContextProvider>
                            <App />
                          </ReferContextProvider>
                        </VariationProvider>
                      </Router>
                    </GlobalsProvider>
                  </RolloutFlagProvider>
                </RolloutProvider>
              </ApiProvider>
            </AcceptScriptProvider>
          </PersistGate>
        </Provider>
      </VersionChecker>
    </GlobalErrorCatcher>
  </>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
