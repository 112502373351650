import styled from 'styled-components/macro';
import { Modal } from 'react-bootstrap';
import closeImage from '../../../img/close.svg';
import Unsubscribe from '../../Unsubscribe/UnsubscribeForm';

export const CancellationModal = styled(Modal)`
  .modal-content {
    width: 360px;
    margin: 0 auto;
  }
`;

export const CancellationModalClose = styled.button`
  background-image: url(${closeImage});
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  border: transparent;
  position: absolute;
  background-color: transparent;
  right: 0px;
  top: 2px;
  display: flex;
  background-position: center;
`;

export const UnsubscribeForm = styled(Unsubscribe)`
  margin-bottom: 18px;
  @media (min-width: 1303px) {
    &:nth-child(2n-1) {
      margin-right: 37px;
    }
  }

  @media (min-width: 1303px) {
    width: 450px;
    min-height: 390px;
  }
`;

export const Column = styled.div``;

export const Columns = styled.div`
  margin: 0 auto;
  max-width: 956px;
  @media (min-width: 991px) {
    display: flex;
  }

  ${Column} {
    margin: 0 15px;
    flex: 1;
    & > div {
      width: 100%;
    }
  }
`;

export const ViewPlanDetails = styled.div`
  color: #6786ff;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:nth-child(even) {
    margin-top: 30px;
  }
  &:hover {
    text-decoration: none;
  }
  img,
  svg {
    margin-right: 13px;
  }
`;

export const Card = styled.div`
  display: flex;
  background: #ffffff;
  box-shadow: 0 2px 2px #d3dbf8;
  border-radius: 4px;
  margin-bottom: 30px;
  font-size: 14px;
  color: #250044;
  min-height: 390px;

  @media (max-width: 991px) {
    min-height: 340px;
    margin-bottom: 18px;
  }
`;

export const CardInner = styled.div`
  min-height: 100%;
`;

export const CardHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #250044;
  line-height: 18px;
  letter-spacing: -0.4px;
  margin-bottom: 25px;
  word-break: break-word;
  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 991px) {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: -0.43px;
  }
`;

export const ButtonsHolder = styled.div`
  margin-top: ${props => props.marginTop && props.marginTop};
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.43px;
  text-decoration-line: underline;
  color: #6786ff;
`;

export const ReactivateButton = styled.div`
  text-transform: uppercase;
`;

export const SubscriptionSubtitle = styled.div`
  color: #6786ff;
  font-style: italic;
  font-size: 14px;
  line-height: 18px;
`;

export const NoActiveSubscriptions = styled(SubscriptionSubtitle)`
  margin-bottom: 20px;
`;

export const DeactivationReasons = styled.div`
  background: #eff1fa;
  border-radius: 4px;
  font-style: italic;
  font-size: 12px;
  line-height: 13px;
  padding: 20px;
  margin-top: 50px;
  h4 {
    font-weight: bold;
    font-size: 12px;
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
  }
  ul {
    padding: 0 20px;
    margin-top: 15px;
    li {
      margin-top: 20px;
    }
  }
  p:last-child {
    margin-bottom: 0;
  }
`;
