import styled from 'styled-components/macro';
import { Flexbox } from '@cerebral-inc/ui';

export const BubleHelpCenter = styled(Flexbox)`
  .header-text-buble {
    font-size: 12px;
    line-height: 25px;
    font-style: italic;
    letter-spacing: -0.375px;
    color: #333333;

    .check-out-buble {
      color: #6786ff;
      cursor: pointer;
    }
  }
  .content-buble {
    background: #ffffff;
    border-radius: 8px;
    padding: 10px;

    span.text-logo-buble {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.43px;
      color: #250044;
    }
    p {
      font-style: italic;
      font-size: 10px;
      line-height: 20px;
      letter-spacing: -0.4px;
      color: #444444;
    }
    button {
      margin: 15px 0;
      height: 40px;
      width: 180px;
    }
  }
`;

export const ReassignMessageWrapper = styled(Flexbox)`
  background: #e1e5f5;
  border-radius: 16px 16px 16px 0px;
  padding: 1em;
`;

export const TitleReassign = styled.p`
  font-size: 14px;
  line-height: 25px;
  font-style: italic;
  letter-spacing: -0.375px;

  margin-bottom: 10px;
  color: #333333;
`;

export const LinkChangeClinical = styled.p<{ disabled: boolean }>`
  color: #6786ff;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'unset')};
  margin-bottom: 0;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
`;

export const BoxChangeClinical = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 1em;
`;

export const TitleBoxChange = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.43px;

  color: #250044;
`;

export const ContentBoxChange = styled.p`
  font-style: italic;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.4px;

  color: #444444;
`;

export const ButtonReassign = styled.button`
  background: #6786ff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 28.5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  height: 40px;
  padding: 0 20px;
  border: none;
  outline: none;
  display: block;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
`;
