import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sendExerciseAnswers } from '../../../actions/exercise_actions';
import StepView from './StepView';
import { SectionTitle, QuestionText, NextBtn, LinkButton, CompleteActionWrapper } from './styled';

const FinalPreview = ({ exerciseData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const exercise_patient_id = useSelector(state => state.exercise_reducer.completed_exercise.id);

  const handleComplete = send => async () => {
    if (exercise_patient_id) {
      await dispatch(sendExerciseAnswers(exerciseData.id, exercise_patient_id, send));
      history.replace('/patient/dashboard/patient_exercises/exercise_history');
    }
  };

  return (
    <div>
      <SectionTitle>{exerciseData.title}</SectionTitle>
      <QuestionText>Send to your Care Team?</QuestionText>
      <CompleteActionWrapper>
        <NextBtn onClick={handleComplete(true)}>Send</NextBtn>
        <LinkButton onClick={handleComplete(false)}>Don&apos;t send</LinkButton>
      </CompleteActionWrapper>

      {exerciseData.sorted_steps.map((step, idx) => (
        <StepView stepData={step} key={idx} isPreview />
      ))}
    </div>
  );
};

export default FinalPreview;
