export default class {
  constructor(password) {
    this.password = password;
  }

  get isValid() {
    return this.isValidLength && this.isValidUpperAndLowerCase && this.isValidNumber && this.isValidSymbols;
  }

  get isValidLength() {
    return this.password.length >= 8;
  }

  get isValidUpperAndLowerCase() {
    return /[a-z]/.test(this.password) && /[A-Z]/.test(this.password);
  }

  get isValidNumber() {
    return /\d/.test(this.password);
  }

  get isValidSymbols() {
    return /[\s~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?()._]/g.test(this.password);
  }
}
