import React from 'react';
import { Spinner, InnerSpinner, OkSign, Processing, Redirecting } from '../PaymentProgressModal/styled';
import { Information, Error, Buttons, Modal, Header, Body, ContactSupportButton, TryAgainButton } from './styled';

const contactUs = () => {
  window.location.href = `${process.env.REACT_APP_MAIN_PAGE_URL}/contact/`;
};

const Image = ({ success }) => {
  if (success) {
    return <OkSign />;
  }

  return <Information />;
};

const Text = ({ loading, error, textOnSuccess }) => {
  if (loading) {
    return <Processing>Your payment is being processed.</Processing>;
  }

  return <>{!error ? <Processing>{textOnSuccess}</Processing> : <Error>{error}</Error>}</>;
};

const PaymentModal = ({ open, loading, payAgain, error, textOnSuccess, onClose }) => (
  <Modal className="loading-modal" show={open} onHide={onClose} centered>
    <Header closeButton onHide={onClose} />
    <Body>
      <Spinner loading={loading.toString()}>
        <InnerSpinner>{!loading && <Image success={!error} />}</InnerSpinner>
      </Spinner>
      <Text error={error} loading={loading} textOnSuccess={textOnSuccess} />
      {!loading &&
        (!error ? (
          <Redirecting>{loading ? '' : 'Redirecting...'}</Redirecting>
        ) : (
          <Buttons>
            <ContactSupportButton onClick={contactUs}>Contact support</ContactSupportButton>
            <TryAgainButton onClick={payAgain}>Try Again</TryAgainButton>
          </Buttons>
        ))}
    </Body>
  </Modal>
);

export default PaymentModal;
