export const UPDATE_PHOTOS = 'UPDATE_PHOTOS';
export const UPDATE_SECOND_PHOTOS = 'UPDATE_SECOND_PHOTOS';
export const UPDATE_PHOTOS_DIRECT = 'UPDATE_PHOTOS_DIRECT';
export const UPDATE_INSURANCE = 'UPDATE_INSURANCE';
export const UPDATE_MEDICATION = 'UPDATE_MEDICATION';
export const UPDATE_STEP = 'UPDATE_STEP';
export const CLOSE_MODALS = 'CLOSE_MODALS';
export const BEGIN_SUBMIT = 'BEGIN_SUBMIT';
export const SUBMISSION_COMPLETE = 'SUBMISSION_COMPLETE';
export const SET_INELIGIBLE = 'SET_INELIGIBLE';
export const SET_ERROR = 'SET_ERROR';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const INITIALIZE_ADDRESS = 'INITIALIZE_ADDRESS';
export const SET_CONFIRM = 'SET_CONFIRM';
export const UPDATE_MEDICARE_CHECKBOX = 'UPDATE_MEDICARE_CHECKBOX';
export const SET_INSURANCE_COVER_WARNING_MODAL = 'SET_INSURANCE_COVER_WARNING_MODAL';

export const updatePhotos = photos => ({
  type: UPDATE_PHOTOS,
  photos
});

export const updateMedicareCheckbox = data => ({
  type: UPDATE_MEDICARE_CHECKBOX,
  data
});

export const updateSecondPhotos = secondPhotos => ({
  type: UPDATE_SECOND_PHOTOS,
  secondPhotos
});

export const updateInsurance = data => ({
  type: UPDATE_INSURANCE,
  data
});

export const updateMedication = data => ({
  type: UPDATE_MEDICATION,
  data
});

export const updateAddress = address => ({
  type: UPDATE_ADDRESS,
  address
});

export const updateStep = step => ({
  type: UPDATE_STEP,
  step
});

export const closeModals = () => ({
  type: CLOSE_MODALS
});

export const beginSubmit = modalMessage => ({
  type: BEGIN_SUBMIT,
  modalMessage
});

export const submissionComplete = modalMessage => ({
  type: SUBMISSION_COMPLETE,
  modalMessage
});

export const uploadPhotoDirectComplete = modalMessage => ({
  type: UPDATE_PHOTOS_DIRECT,
  modalMessage
});

export const setError = error => ({
  type: SET_ERROR,
  error
});

export const setIneligible = insurance => ({
  type: SET_INELIGIBLE,
  insurance
});

export const initializeAddress = address => ({
  type: INITIALIZE_ADDRESS,
  address
});

export const setConfirm = ({ onSuccess, onReject }) => ({
  type: SET_CONFIRM,
  payload: {
    onSuccess,
    onReject
  }
});

export const setInsuranceCoverWarning = ({ onSuccess, onReject }) => ({
  type: SET_INSURANCE_COVER_WARNING_MODAL,
  payload: {
    onSuccess,
    onReject
  }
});
