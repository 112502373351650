import styled from 'styled-components/macro';

export const Title = styled.h1`
  max-width: 604px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #250044;
  margin-top: 90px;
  margin-right: auto;
  margin-left: auto;
`;

export const Info = styled.p`
  max-width: 604px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.43px;
  color: #250044;
  margin: 20px auto;
`;
