import { datadogRum } from '@datadog/browser-rum';

interface Data {
  message?: string;
  e?: unknown;
  info?: unknown;
  callback?: () => never;
}

const addError = (data: Data): void => datadogRum.addError(data.e, data);

const critical = addError;
const error = addError;
const warning = addError;
const info = addError;
const debug = addError;

export default {
  error,
  info,
  critical,
  warning,
  debug,
  addError
};
