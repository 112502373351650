import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Profile from '../../Profile';

const CareCoordinatorProfileHolder = styled.div`
  margin-top: 1em;

  @media (max-width: 991px) {
    margin-top: 0px;
  }
`;

const CareCoordinatorProfileFooter = styled.div`
  width: 300px;
  text-align: center;
  margin: 10px 25px;
  font-style: italic;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: -0.4px;
  color: #250044;

  @media (max-width: 991px) {
    width: 90%;
    margin: 1em auto;
    padding: 0 1.5em;
  }
`;

const CareCoordinatorProfile = ({ careCoordinator, carePersonType }) => {
  return (
    <CareCoordinatorProfileHolder>
      <Profile
        classHolder="d-flex flex-column align-items-center chat_body_profile"
        carePerson={careCoordinator}
        isOnline={false}
      />

      <CareCoordinatorProfileFooter>
        *Please note: When your {carePersonType} is not immediately available or if you have administrative issues, your
        coordinator willl take care of it.
      </CareCoordinatorProfileFooter>
    </CareCoordinatorProfileHolder>
  );
};

CareCoordinatorProfile.propTypes = {
  careCoordinator: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }).isRequired
};

export default CareCoordinatorProfile;
