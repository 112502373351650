import React from 'react';
import { ModalWrapper, ModalWrapperInner, ModalBody, OkButton } from './styled';

export const AppointmentModal = ({
  buttonText = 'OK',
  onRequestClose,
  text = "Good news! It seems like you've already scheduled this appointment. Simply head to your Appointments & Tasks page to reschedule your appointment date or time.",
  buttonPosition
}) => (
  <ModalWrapper>
    <ModalWrapperInner>
      <ModalBody>{text}</ModalBody>
      <OkButton center={buttonPosition} onClick={onRequestClose}>
        {buttonText}
      </OkButton>
    </ModalWrapperInner>
  </ModalWrapper>
);
