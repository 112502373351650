import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useReducer, lazy } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { isString } from 'lodash';
import {
  setPlan,
  createVisitsInsurance,
  removeMedicationsInsurance,
  removeVisitsInsurance,
  setInsurancePhotos,
  updateInsuranceStatus,
  updateMedicationsInsurance,
  setInsuranceVisitsPolicy,
  setInsuranceMedicationsPolicy
} from '../../actions/patient_action';
import { DescriptionText, Title } from '../../styles/styled';
import { useApi } from '../../utils/api';
import { sleep, formatDob, isNutritionOffering } from '../../utils/common';
import tracking from '../../utils/tracking';
import usePromise from '../../utils/usePromise';
import { descriptionText, flowSteps, formFlows, formSteps, getStepIndex, headerText, headerTextINI } from './formData';
import {
  beginSubmit,
  closeModals,
  initializeAddress,
  setConfirm,
  setInsuranceCoverWarning,
  setError,
  setIneligible,
  submissionComplete,
  updateInsurance,
  updateMedicareCheckbox,
  updateMedication,
  updatePhotos,
  updateSecondPhotos,
  updateStep,
  uploadPhotoDirectComplete
} from './store/actions';

import reducer, { initialState } from './store/reducer';
import PhoneSupport from '../PhoneSupport';

const ImportantInformation = lazy(() => import('../ImportantInformation'));
const Footnote = lazy(() => import('../ImportantInformation/Footnote'));
const FootNoteImportant = lazy(() => import('../ImportantInformation/FootNoteImportant'));
const Banner = lazy(() => import('./Banner'));
const FlowText = lazy(() => import('./FlowText'));
const InsuranceConfirmationForm = lazy(() => import('./Form/ConfirmationForm/InsuranceConfirmationForm'));
const MedicationConfirmationForm = lazy(() => import('./Form/ConfirmationForm/MedicationConfirmationForm'));
const NutritionConfirmationForm = lazy(() => import('./Form/ConfirmationForm/NutritionConfirmationForm'));
const submitPhotos = lazy(() => import('./Form/ConfirmationForm/submitPhotos'));
const InsuranceForm = lazy(() => import('./Form/InsuranceForm'));
const MedicationForm = lazy(() => import('./Form/MedicationForm'));
const NutritionForm = lazy(() => import('./Form/NutritionForm'));
const PhotosForm = lazy(() => import('./Form/PhotosForm'));
const ModalSwitch = lazy(() => import('./Modals/ModalSwitch'));

const MultiStepForm = ({
  initialStep,
  formFlow,
  onSuccess,
  onSkip,
  onPlanChange,
  outOfNetwork,
  inNetwork,
  isDashboard,
  showAllPayers = false,
  updateView = null,
  isSecondCardInSignUp = false,
  onSkipSecondPhotos = null,
  onAfterNextStep,
  onAfterSkip,
  defaultStepIndex,
  storeGlobal
}) => {
  const isMedFlowOrNutritionFlow = formFlow === formFlows.MEDICATION_FLOW || formFlow === formFlows.NUTRITION_FLOW;
  const globalDispatch = useDispatch();
  const prevUser = useSelector(
    ({
      global_reducer: {
        current_user: { attributes }
      }
    }) => attributes
  );

  const api = useApi();

  // If the form has been filled out before, start at the confirmation step (last step)
  const prevInsurance = useSelector(({ patient_reducer: { insurance_visits_policy } }) => insurance_visits_policy);
  const payer = useSelector(({ patient_reducer: { payerOnboarding } }) => payerOnboarding);
  const prevMedication = useSelector(
    ({ patient_reducer: { insurance_medications_policy } }) => insurance_medications_policy
  );
  const ab_testValue = useSelector(({ patient_reducer: { abTest } }) => abTest);
  const isMedicareSecondary = useSelector(({ patient_reducer: { is_medicare_secondary } }) => is_medicare_secondary);
  const serviceLine = useSelector(store => store.patient_reducer.visit_object?.service_lines);
  const offeringKey = useSelector(store => store.patient_reducer?.offering_key);
  const isNutritionServiceLine =
    (serviceLine?.length === 1 && serviceLine[0].name === 'nutrition') || isNutritionOffering(offeringKey);
  const prevDataID = isMedFlowOrNutritionFlow ? prevMedication.id : prevInsurance.id;

  let defaultStep =
    defaultStepIndex || (prevDataID && formFlow !== formFlows.INSURANCE_FLOW ? flowSteps[formFlow].length - 1 : 0);

  if (prevDataID && isDashboard && isNutritionServiceLine) {
    defaultStep = 0;
  }

  // BE API returns an array for photo URLS so we need to parse it to get the file properties
  const prevPhotos = useSelector(({ patient_reducer: { insurance_cards, pharmacy_cards } }) =>
    isMedFlowOrNutritionFlow ? pharmacy_cards : insurance_cards
  );
  const prevSecondPhotos = useSelector(({ patient_reducer: { second_insurance_cards, pharmacy_cards } }) =>
    isMedFlowOrNutritionFlow ? pharmacy_cards : second_insurance_cards
  );
  const getPrevPhotoData = () => {
    if (!prevPhotos?.every(photo => photo)) return [];

    return prevPhotos
      .map(fileToUpload => {
        if (isString(fileToUpload)) {
          const name = decodeURI(fileToUpload.split('/').splice(-1, 1)[0].split('?')[0]);
          const [, extension] = fileToUpload.split(/(\.(?:jpe?g|png|pdf))/);

          // This is to restrict only showing photos which have the same ID
          // as the policy being viewed.
          // The pharmacy flow allows for a patient to update their benefits without
          // updating the photos, but the insurance flow creates a new policy ID each time,
          // so this won't work for the insurance flow. If a patient updates their insurance
          // information without changing the photos, their photos would not return since
          // there's a new ID now.
          if (isMedFlowOrNutritionFlow && !isNutritionServiceLine && !name.includes(prevDataID + extension)) return;

          return {
            fileToUpload,
            name,
            type: extension.replace('.', 'application/')
          };
        }
        // Incase image object was pass to redux
        return fileToUpload;
      })
      .filter(photo => photo);
  };
  const getPrevSecondPhotoData = () => {
    if (!prevSecondPhotos?.every(photo => photo)) return [];

    return prevSecondPhotos
      .map(fileToUpload => {
        const name = decodeURI(fileToUpload.split('/').splice(-1, 1)[0].split('?')[0]);
        const [, extension] = fileToUpload.split(/(\.(?:jpe?g|png|pdf))/);
        if (isMedFlowOrNutritionFlow && !name.includes(prevDataID + extension)) return;

        return {
          fileToUpload,
          name,
          type: extension.replace('.', 'application/')
        };
      })
      .filter(photo => photo);
  };

  const prefillReducers = defaultState => ({
    ...defaultState,
    currentStepIndex: Math.max(getStepIndex(initialStep, formFlow), defaultStep),
    photos: getPrevPhotoData(),
    secondPhotos: getPrevSecondPhotoData(),
    insurance: {
      ...prevInsurance,
      payer_id: prevInsurance.payer_id,
      subscriber_dob: prevInsurance.subscriber_dob,
      subscriber_first_name: prevInsurance.subscriber_first_name,
      subscriber_last_name: prevInsurance.subscriber_last_name,
      plan_subscriber_type: prevInsurance.plan_subscriber_type
    },
    medication: prevMedication,
    user: {
      first_name: prevUser.first_name,
      last_name: prevUser.last_name,
      region: prevUser.patient.region,
      dob: prevUser.patient.dob
    }
  });
  const [state, dispatch] = useReducer(reducer, initialState, prefillReducers);
  const abTestValues = useSelector(({ patient_reducer: { abTest } }) => abTest);
  const {
    photos,
    secondPhotos,
    currentStepIndex,
    modal,
    modalActions,
    modalMessage,
    address,
    ineligibleInsurance,
    user,
    insurance,
    medication,
    hasNewPhotos,
    hasNewSecondPhotos,
    medicareCheckbox,
    photoComplete
  } = state;

  const currentStepValue = flowSteps[formFlow][isSecondCardInSignUp ? currentStepIndex - 1 : currentStepIndex];
  const isErrorModalAbtest =
    !outOfNetwork &&
    abTestValues?.ab_test_chc_error_modal === 'enable_new_chc_error_modal' &&
    currentStepValue === 'INSURANCE_CONFIRMATION';
  const [prevBilling, billingLoaded] = usePromise(
    `/api/patients/${prevUser.patient?.id}/addresses/?address_type=BillingAddress`
  );
  const [prevShipping, shippingLoaded] = usePromise(`/api/patients/${prevUser.patient?.id}/addresses/`);
  const addressesLoaded = billingLoaded && shippingLoaded;
  const nutritionUser = prevUser.patient?.birth_date ? { ...user, dob: prevUser.patient.birth_date } : user;

  // If there is no billing address, populate form with the current shipping address
  useEffect(() => {
    if (!billingLoaded) return;
    if (prevBilling?.data?.length === 0) {
      if (!shippingLoaded) return;
      dispatch(initializeAddress(prevShipping?.data?.[0] || {}));
    } else {
      dispatch(initializeAddress(prevBilling?.data?.[0] || {}));
    }
  }, [prevShipping, shippingLoaded, prevBilling, billingLoaded]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentStepIndex]);

  useEffect(() => {
    if (!isMedFlowOrNutritionFlow && prevUser.patient?.insurance_status !== 'active') {
      globalDispatch(updateInsuranceStatus('checking'));
    }
  }, []);

  const logError = (e, message) => {
    dispatch(setError(message));
    tracking.error({ e, message });
  };

  const startSubmission = message => {
    dispatch(beginSubmit(message));
  };
  const savingPhotos = message => {
    dispatch(uploadPhotoDirectComplete(message));
  };
  const completeSubmission = async successData => {
    dispatch(submissionComplete('Your insurance information is updated!'));
    await sleep(2000);
    if (storeGlobal) {
      const plan = { offering_key: successData.offering_key, is_insurance: true };
      const { insurance_medications_policy: insuranceMedicationsPolicy, ...policy } = successData.policy;
      globalDispatch(setPlan({ plan }));
      globalDispatch(setInsuranceVisitsPolicy({ policy }));
      globalDispatch(setInsuranceMedicationsPolicy({ policy: insuranceMedicationsPolicy || {} }));
    }
    onSuccess(successData, insurance);
  };

  const editStep = step => {
    switch (step) {
      case 'INSURANCE_PHOTOS':
        dispatch(updateStep(getStepIndex(step, 'INSURANCE_FLOW')));
        break;
      case 'SECONDARY_INSURANCE':
        dispatch(updateStep(getStepIndex(step, 'SECONDARY_FLOW')));
        break;
      case 'INSURANCE_FORM':
        dispatch(updateStep(getStepIndex(step, 'INSURANCE_FLOW')));
        break;
      default:
        dispatch(updateStep(getStepIndex(step, formFlow)));
        break;
    }
  };

  const saveAndContinue = () => {
    const { insurance_medications_policy } = ineligibleInsurance;
    if (inNetwork) {
      onPlanChange({
        medicationsInsurance: insurance_medications_policy,
        visitsInsurance: insurance,
        action: 'activate-new-insurance'
      });
    } else {
      batch(() => {
        globalDispatch(updateInsuranceStatus('prefers_cash_option'));
        globalDispatch(createVisitsInsurance(insurance, true));
        globalDispatch(
          updateMedicationsInsurance({ insurance: insurance_medications_policy, updates: { active: true } })
        );
      });

      onSkip();
    }
  };

  const continueWithoutInsurance = () => {
    if (inNetwork) {
      onPlanChange({
        action: 'deactivate-current-insurance'
      });
    } else {
      batch(() => {
        globalDispatch(updateInsuranceStatus('prefers_cash_option'));
        globalDispatch(removeMedicationsInsurance());
        globalDispatch(removeVisitsInsurance());
      });

      onSkip();
    }
  };

  const onsubmitPhotoDirecLink = async newPhotos => {
    try {
      startSubmission('Saving photos...');
      const {
        data: { insurance_policy }
      } = await api.post('/api/insurance_policies', {
        ...{ ...user, ...insurance },
        active: !isDashboard,
        out_of_network: outOfNetwork
      });
      const photoUrls = await submitPhotos(newPhotos, api, insurance_policy.id, 'insurance_card');
      globalDispatch(setInsurancePhotos(photoUrls));

      if (photoUrls) {
        savingPhotos('Photos saved!');
        await sleep(1000);
        onSuccess();
      }
    } catch (e) {
      onSkip();
    }
  };

  const nextInsuranceInfoStep = newData => {
    dispatch(updateInsurance(newData));

    if (storeGlobal) {
      globalDispatch(setInsuranceVisitsPolicy({ policy: newData }));
    }

    if (onAfterNextStep) {
      onAfterNextStep({ answer: newData });
    }
  };

  const nextInsurancePhotoStep = newPhotos => {
    dispatch(updatePhotos(newPhotos));

    if (storeGlobal) {
      globalDispatch(setInsurancePhotos(newPhotos));
    }

    if (onAfterNextStep) {
      onAfterNextStep({ answer: newPhotos });
    }
  };

  const CurrentDisplay = useCallback(() => {
    switch (currentStepValue) {
      case formSteps.INSURANCE_FORM:
        return (
          <InsuranceForm
            payerOnboarding={payer}
            abTest={ab_testValue}
            skipStep={continueWithoutInsurance}
            nextStep={nextInsuranceInfoStep}
            initialValues={{ ...user, ...insurance }}
            showPopover={isDashboard || outOfNetwork}
            showAllPayers={showAllPayers}
            isDashboard={isDashboard}
          />
        );
      case formSteps.NUTRITION_FORM:
        return (
          <NutritionForm
            abTest={ab_testValue}
            skipStep={onSkip}
            nextStep={newData => dispatch(updateMedication(newData))}
            initialValues={medication}
            showAllPayers={showAllPayers}
            payerOnboarding={payer}
            isDashboard={isDashboard}
            showPopover={isDashboard || outOfNetwork}
            isNutritionServiceLine={isNutritionServiceLine}
            insurance={insurance}
          />
        );
      case formSteps.MEDICATION_FORM:
        return (
          <MedicationForm
            abTest={ab_testValue}
            skipStep={onSkip}
            nextStep={newData => dispatch(updateMedication(newData))}
            initialValues={medication}
          />
        );
      case formSteps.NUTRITION_PHOTOS:
      case formSteps.MEDICATION_PHOTOS:
        return (
          <PhotosForm
            nextStep={newPhotos => dispatch(updatePhotos(newPhotos))}
            initialPhotos={photos}
            globalDispatch={globalDispatch}
            onSkip={() => {
              dispatch(
                setInsuranceCoverWarning({
                  onSuccess: () => dispatch(closeModals()),
                  onReject: () => {
                    dispatch(closeModals());
                    dispatch(updatePhotos());
                  }
                })
              );
            }}
            isNutritionServiceLine={isNutritionServiceLine}
          />
        );
      case formSteps.INSURANCE_PHOTOS:
        return (
          <PhotosForm
            nextStep={nextInsurancePhotoStep}
            initialPhotos={photos}
            globalDispatch={globalDispatch}
            onSkip={() =>
              dispatch(
                setConfirm({
                  onSuccess: () => dispatch(closeModals()),
                  onReject: () => {
                    dispatch(closeModals());
                    dispatch(updatePhotos());
                    globalDispatch(updateInsuranceStatus('prefers_cash_option'));
                    if (onAfterSkip) onAfterSkip();
                  }
                })
              )
            }
          />
        );
      case formSteps.SECONDARY_INSURANCE:
        return (
          <PhotosForm
            data={{
              insurance: { ...user, ...insurance }
            }}
            isMedicareSecondary={isMedicareSecondary}
            isSecondCardInSignUp={isSecondCardInSignUp}
            insuranceVisitsPolicy={insurance}
            isSecondPhotos
            onSkipSecondPhotos={onSkipSecondPhotos}
            nextStep={newPhotos => dispatch(updateSecondPhotos(newPhotos))}
            isDashboard={isDashboard}
            initialPhotos={secondPhotos}
            completeSubmission={completeSubmission}
            updateMedicareCheckbox={data => dispatch(updateMedicareCheckbox(data))}
            onSkip={() =>
              dispatch(
                setConfirm({
                  onSuccess: () => dispatch(closeModals()),
                  onReject: () => {
                    dispatch(closeModals());
                    dispatch(updateSecondPhotos());
                  }
                })
              )
            }
          />
        );
      case formSteps.INSURANCE_DIRECT_CONFIRMATION:
        return <PhotosForm nextStep={onsubmitPhotoDirecLink} initialPhotos={photos} type="direct" />;
      case formSteps.INSURANCE_CONFIRMATION:
        return (
          <InsuranceConfirmationForm
            data={{
              insurance: { ...user, ...insurance, dob: formatDob(insurance.dob, 'dashes') },
              address,
              photos,
              secondPhotos,
              hasNewPhotos,
              hasNewSecondPhotos,
              medicareCheckbox
            }}
            abTestValues={abTestValues?.add_second_medicare_question === 'enable_second_medicare_question'}
            logError={logError}
            startSubmission={startSubmission}
            completeSubmission={completeSubmission}
            setIneligible={policy => dispatch(setIneligible(policy))}
            onSkip={continueWithoutInsurance}
            editStep={editStep}
            addressLoaded={addressesLoaded}
            isOutOfNetwork={outOfNetwork}
            isDashboard={isDashboard}
            updateView={updateView}
            nextStep={() => {
              dispatch(updateStep());
            }}
            prevInsurance={prevInsurance}
          />
        );
      case formSteps.NUTRITION_CONFIRMATION:
        return (
          <NutritionConfirmationForm
            data={{ user: nutritionUser, medication, photos, hasNewPhotos, insurance }}
            logError={logError}
            startSubmission={startSubmission}
            completeSubmission={completeSubmission}
            onSkip={onSkip}
            editStep={editStep}
            isNutritionServiceLine={isNutritionServiceLine}
            prevInsurance={prevInsurance}
            isDashboard={isDashboard}
            updateView={updateView}
          />
        );
      case formSteps.MEDICATION_CONFIRMATION:
        return (
          <MedicationConfirmationForm
            data={{ user, medication, photos, hasNewPhotos }}
            logError={logError}
            startSubmission={startSubmission}
            completeSubmission={completeSubmission}
            onSkip={onSkip}
            editStep={editStep}
          />
        );
      default:
        return null;
    }
  }, [currentStepValue, address, addressesLoaded]);
  const DescriptionTextDisplay = useCallback(() => {
    switch (currentStepValue) {
      case formSteps.INSURANCE_DIRECT_CONFIRMATION:
        return <DescriptionText tight>{descriptionText[currentStepValue]}</DescriptionText>;
      default:
        return null;
    }
  }, [currentStepValue, address, addressesLoaded]);

  const banner = () => {
    return (
      <>
        <Title tight>{outOfNetwork ? headerText[currentStepValue] : headerTextINI[currentStepValue]}</Title>
        {currentStepValue !== formSteps.INSURANCE_DIRECT_CONFIRMATION ? (
          <Banner
            showOONBanner={outOfNetwork}
            currentFlow={formFlow}
            currentStep={currentStepValue}
            isNutritionServiceLine={isNutritionServiceLine}
          />
        ) : (
          ''
        )}
      </>
    );
  };

  const bannerFlowText = () => {
    return (
      <>
        {currentStepValue !== formSteps.INSURANCE_DIRECT_CONFIRMATION ? (
          <Banner
            showOONBanner={outOfNetwork}
            currentFlow={formFlow}
            currentStep={currentStepValue}
            isNutritionServiceLine={isNutritionServiceLine}
            isBannerFlowText
          />
        ) : (
          ''
        )}
      </>
    );
  };
  return (
    <>
      {isNutritionServiceLine && banner()}
      {currentStepValue !== formSteps.INSURANCE_DIRECT_CONFIRMATION ? (
        <FlowText currentFlow={formFlow} currentStep={currentStepValue} editStep={editStep} />
      ) : (
        ''
      )}
      {isNutritionServiceLine && bannerFlowText()}
      {!isNutritionServiceLine && banner()}

      <DescriptionTextDisplay />
      <div className="container" style={{ maxWidth: '700px' }}>
        <CurrentDisplay />
      </div>

      {(currentStepValue === formSteps.MEDICATION_CONFIRMATION ||
        currentStepValue === formSteps.NUTRITION_CONFIRMATION) && (
        <ImportantInformation removeTopFootnote>
          <FootNoteImportant>Important</FootNoteImportant>
          <Footnote>
            *Please note that in the case where your insurance rejects part or all of your claim and doesn&apos;t cover
            your medication costs in full, you will be responsible for the remainder of the charges.
          </Footnote>
          <Footnote>
            All remaining medication costs associated with your account will be charged to the card on file.
          </Footnote>
          <Footnote>
            If there is no insurance information saved to your account at the time the medication order is placed, your
            card on file will be charged for all medication costs.
          </Footnote>
        </ImportantInformation>
      )}

      <ModalSwitch
        isMedicare={ineligibleInsurance?.payer?.name.toLowerCase().includes('medicare')}
        pharmaOrOON={outOfNetwork || isMedFlowOrNutritionFlow}
        onReSubmit={() => {
          dispatch(closeModals());
          if (isErrorModalAbtest) editStep('INSURANCE_FORM', formFlows.INSURANCE_FLOW);
        }}
        onSkip={continueWithoutInsurance}
        onSave={saveAndContinue}
        modal={modal}
        modalActions={modalActions}
        message={modalMessage}
        rxBin={insurance.insurance_medications_policy?.rx_bin}
        complete={photoComplete}
        abTestValues={abTestValues}
        outOfNetwork={outOfNetwork}
        formFlow={formFlow}
        isErrorModalAbtest={isErrorModalAbtest}
      />
      <PhoneSupport isNutritionServiceLine={isNutritionServiceLine} />
    </>
  );
};

MultiStepForm.propTypes = {
  initialStep: (props, propName) => {
    if (props[propName] && !flowSteps[props.formFlow].includes(props[propName])) {
      return new Error(
        `The initial step ${props[propName]} must be defined as one of the available form steps for the ${props.formFlow} flow.`
      );
    }
  },
  formFlow: PropTypes.oneOf(Object.keys(formFlows)).isRequired,
  onSuccess: PropTypes.func,
  onSkip: PropTypes.func,
  outOfNetwork: PropTypes.bool,
  inNetwork: PropTypes.bool,
  isDashboard: PropTypes.bool,
  storeGlobal: PropTypes.bool,
  onPlanChange: (props, propName) => {
    if (
      !props[propName] &&
      (props.formFlow !== formFlows.MEDICATION_FLOW || props.formFlow !== formFlows.NUTRITION_FLOW)
    ) {
      return new Error(`${propName} must be defined for the ${props.formFlow} flow.`);
    }
  },
  defaultStepIndex: PropTypes.number,
  onAfterNextStep: PropTypes.func,
  onAfterSkip: PropTypes.func
};

MultiStepForm.defaultProps = {
  outOfNetwork: false,
  inNetwork: false,
  isDashboard: false,
  storeGlobal: false,
  onPlanChange: () => {},
  onSuccess: () => {},
  onSkip: () => {}
};

export default MultiStepForm;
