import styled from 'styled-components/macro';

const NoTasks = styled.div`
  padding: 20px 0;
  text-align: center;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #6786ff;
`;

export default NoTasks;
