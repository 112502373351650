import styled from 'styled-components/macro';

export const ListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const PageWrapper = styled.div`
  width: 950px;
  margin: 0 auto;
  position: relative;

  @media (max-width: 1024px) {
    width: 100%;

    ${ListWrapper} {
      justify-content: center;
    }
  }
`;

export const SectionTitle = styled.h3`
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 50px;
  letter-spacing: -0.43px;
`;

export const BackButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  border: none;

  &:focus {
    outline: none;
  }
`;
