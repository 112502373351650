import { useEffect } from 'react';
import axios from 'axios';
import useDocumentVisibility from '../../utils/useDocumentVisibility';
import tracking from '../../utils/tracking';

const currentVersion = process.env.REACT_APP_GIT_SHA;

export default function VersionChecker({ children }) {
  const visibility = useDocumentVisibility();

  useEffect(() => {
    if (visibility === 'visible') {
      (async () => {
        try {
          const {
            data: { version }
          } = await axios.get(`${process.env.REACT_APP_URL}/version.json?buster=${Date.now()}`);

          if (currentVersion !== version) {
            tracking.error({
              message: `Versions mismatch: local(${currentVersion}) - remote(${version})`
            });
          }
        } catch (e) {
          tracking.error({
            e,
            message: 'Error fetching app version.'
          });
        }
      })();
    }
  }, [visibility]);

  return children;
}
