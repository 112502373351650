import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get_prescriptions, consentToPrescriptions } from 'actions/patient_action';
import { formatCurrency, mapResponseToError } from 'utils/common';
import NoTasks from 'components/NoTasks';
import WhiteBox from 'components/WhiteBox';
import ProgressModal from 'components/ProgressModal';
import { Store } from 'types/store';
import tracking from 'utils/tracking';
import ErrorModal from 'components/ErrorModal';
import TextButton from 'components/UI/TextButton';
import { Flexbox } from '@cerebral-inc/ui';
import Prescription from './Prescription';
import { Holder, Title, ChangePlanButton, ConsentButton } from './styled';
import ConsentDenyModal from './ConsentDenyModal';
import ConsentApproveModal from './ConsentApproveModal';
import { StatusText } from './Prescription/styled';
import { useGlobals } from '../../contexts/globals';

type ModalState = 'closed' | 'consent' | 'deny' | string;

const PrescriptionInfoView: React.FC = () => {
  const { isTherapistRegion } = useGlobals();
  const dispatch = useDispatch();
  const history = useHistory();

  const patient = useSelector((store: Store) => store.global_reducer.current_user.attributes.patient);
  const current_user = useSelector((store: Store) => store.global_reducer.current_user.attributes);
  const { prescriptions, prescriptionsLoading } = useSelector((store: Store) => store.patient_reducer);

  useEffect(() => {
    patient?.offering_key !== 'therapy' && dispatch(get_prescriptions());
  }, []);

  const [modalState, setModal] = useState<ModalState>('closed');

  const parentPrescriptions = prescriptions.filter(({ parent_id }) => !parent_id);

  const activePrescriptions = parentPrescriptions.filter(({ status }) => !['expired', 'canceled'].includes(status));
  const expiredPrescriptions = parentPrescriptions.filter(({ status }) => ['expired', 'canceled'].includes(status));

  const prescriptionsWithConsent = activePrescriptions.filter(({ order_status }) =>
    ['consent_insurance', 'consent_cash'].includes(order_status)
  );

  const totalPrice = activePrescriptions.reduce((acc, pres) => Number(pres.billable_amount) + acc, 0);
  const isUS = () => current_user.country === 'us';

  const respondToConsent = async (answer: 'agreed' | 'disagree') => {
    try {
      const type = prescriptionsWithConsent[0]?.order_status === 'consent_cash' ? 'pay_in_cash' : 'copay';

      await dispatch(consentToPrescriptions(type, answer));
      await dispatch(get_prescriptions());
      setModal('closed');
    } catch (e) {
      const errorMessage = mapResponseToError(e);
      setModal(errorMessage);
      tracking.error({ e, message: errorMessage });
    }
  };

  return (
    <div className="d-flex flex-column profile-main-content">
      <h1 className="text-main-title">Prescription Information</h1>
      {prescriptionsLoading ? <ProgressModal open /> : null}
      {prescriptions.length > 0 ? (
        <>
          {activePrescriptions.length > 0 ? (
            <WhiteBox>
              <h2 className="text-main-title">Current Prescription(s)</h2>
              {activePrescriptions.map(prescription => (
                <Prescription prescription={prescription} key={prescription.id} isUS={isUS()} />
              ))}

              {!activePrescriptions.some(pres => pres.order_status === 'consent_insurance') && isUS() && (
                <StatusText spaced>
                  <div className="text-center">
                    <span>Total Amount: </span> {formatCurrency(totalPrice)}
                  </div>
                </StatusText>
              )}

              {prescriptionsWithConsent.length > 0 && (
                <Flexbox direction="column">
                  <ConsentButton onClick={() => setModal('consent')}>
                    Consent to
                    {prescriptionsWithConsent[0].order_status === 'consent_insurance' ? ' refill' : ' pay'}
                  </ConsentButton>
                  <TextButton onClick={() => setModal('deny')}>
                    {prescriptionsWithConsent[0].order_status === 'consent_cash'
                      ? 'Do not send my medication'
                      : 'Delay my medication for now'}
                  </TextButton>
                </Flexbox>
              )}
            </WhiteBox>
          ) : (
            <WhiteBox>
              <NoTasks>You have no active prescriptions.</NoTasks>
            </WhiteBox>
          )}
          {expiredPrescriptions.length > 0 && (
            <>
              <h2 className="text-main-title">Prescription History</h2>
              <WhiteBox opacity={0.5}>
                {expiredPrescriptions.map(prescription => (
                  <Prescription prescription={prescription} key={prescription.id} isUS={isUS()} />
                ))}
              </WhiteBox>
            </>
          )}
        </>
      ) : (
        <WhiteBox>
          {patient?.offering_key === 'therapy' && isTherapistRegion(patient?.region) ? (
            <Holder>
              <Title>Your plan does not include medication.</Title>
              <ChangePlanButton onClick={() => history.push('/patient/change-plan')}>
                Upgrade Cerebral plan
              </ChangePlanButton>
            </Holder>
          ) : (
            <NoTasks>You have no active prescriptions.</NoTasks>
          )}
        </WhiteBox>
      )}

      <ConsentDenyModal
        show={modalState === 'deny'}
        onHide={() => setModal('closed')}
        switchToApprove={() => setModal('consent')}
        onDeny={() => respondToConsent('disagree')}
      />

      {prescriptionsWithConsent.length > 0 && (
        <ConsentApproveModal
          show={modalState === 'consent'}
          onHide={() => setModal('closed')}
          onApprove={() => respondToConsent('agreed')}
          prescriptions={prescriptionsWithConsent}
          totalPrice={totalPrice}
        />
      )}

      <ErrorModal
        show={!['consent', 'closed', 'deny'].includes(modalState)}
        error={[modalState]}
        onSecondaryClick={() => setModal('closed')}
        onPrimaryClick={() => setModal('consent')}
        primaryButtonText="Try again"
        secondaryButtonText="Go back"
        title="Failed to process consent request."
      >
        We were unable to process your consent request. Please try to re-submit your request. If the problem persists,
        contact support at
        <br />
        <a href="mailto:support@getcerebral.com">support@getcerebral.com</a>
      </ErrorModal>
    </div>
  );
};

export default PrescriptionInfoView;
