import React from 'react';
import styled from 'styled-components/macro';
import plans from '../../../utils/plans';
import isoImage from '../../../img/iso.png';
import ABTest from '../../Variations/ABTest';
import { formatCurrency } from '../../../utils/currency.util';

const Header = styled.div`
  background: #6786ff;
  color: #fff;
  text-align: center;
  padding: 36px 0 26px;
`;

const Line = styled.div`
  display: flex;
  & > div {
    flex: 1;
  }
`;

const PromoLine = styled(Line)`
  margin-top: 20px;
`;

const TotalLine = styled(Line)`
  margin-top: 29px;
  border-top: 0.5px solid #250044;
  padding-top: 18px;
`;

const Name = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.43px;
  color: #6786ff;
`;

const Price = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: right;
  letter-spacing: -0.43px;
  color: #6786ff;
`;

const Promo = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.43px;
  color: #250044;
`;

const Discount = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  text-align: right;
  letter-spacing: -0.43px;
  color: #250044;
`;

const Content = styled.div`
  padding: 24px 20px 18px;
`;

const Title = styled.div`
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  margin-top: 5px;
`;

const TotalLabel = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  letter-spacing: -0.43px;
  color: #6786ff;
`;

const TotalPrice = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  text-align: right;
  letter-spacing: -0.43px;
  color: #6786ff;
`;

const Pd = styled.div`
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.43px;
  color: #6786ff;
  margin-top: 52px;
`;

const Isotype = styled.div`
  background-image: url(${isoImage});
  width: 32px;
  height: 34px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
`;

const WeekDescription = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: -0.43px;
  color: #6786ff;
  margin-top: 10px;
`;

const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #f1f4ff;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px 4px #f6f6f9;
  border-radius: 10px;
  overflow: hidden;

  @media (min-width: 991px) {
    ${Content} {
      padding: 24px 40px 18px;
    }
  }
`;

function PaymentInfo({ couponId, couponPrice, className }) {
  const plan = plans.cashPlans.legacy;
  const { price } = plan;
  const discount = parseInt(price, 10) - couponPrice;

  return (
    <Wrapper className={className}>
      <Header>
        <Isotype />
        <Title>Your Cerebral Membership</Title>
      </Header>

      <Content>
        <Line>
          <Name>Meds + Care</Name>

          <ABTest
            id="price"
            map={{
              no_value: <Price>${price}/mo</Price>,
              month: <Price>${price}/mo</Price>,
              week: <Price>{formatCurrency(24.75)}/week</Price>
            }}
          />
        </Line>

        {couponId && (
          <PromoLine>
            <Promo>{couponId}</Promo>
            <Discount>-${discount}</Discount>
          </PromoLine>
        )}

        <TotalLine>
          <TotalLabel>Today&apos;s total</TotalLabel>
          <TotalPrice>${couponPrice}</TotalPrice>
        </TotalLine>

        <ABTest
          id="price"
          map={{
            no_value: null,
            month: null,
            week: <WeekDescription>({formatCurrency(11.25)}/week for 4 weeks)</WeekDescription>
          }}
        />

        <ABTest
          id="price"
          map={{
            no_value: <Pd>*Upon renewal you will be charged {formatCurrency(99)}/month</Pd>,
            month: <Pd>*Upon renewal you will be charged {formatCurrency(99)}/month</Pd>,
            week: (
              <Pd>
                *Upon renewal you will be charged {formatCurrency(99)}/month <br />
                &middot; Billed Monthly &middot; Cancel Anytime.
              </Pd>
            )
          }}
        />
      </Content>
    </Wrapper>
  );
}

export default styled(PaymentInfo)``;
