import styled, { keyframes, css } from 'styled-components/macro';

const spin = keyframes`
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

interface SpinnerProps {
  loading?: string;
}

export const Spinner = styled.div<SpinnerProps>`
  margin-bottom: 28px;
  background: linear-gradient(306.37deg, #b4c0f2 18.87%, #5f43e9 86.22%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ loading }) =>
    loading === 'true' &&
    css`
      -webkit-animation: ${spin} 1.5s linear infinite;
      -moz-animation: ${spin} 1.5s linear infinite;
      animation: ${spin} 1.5s linear infinite;
      background: linear-gradient(306.37deg, #b4c0f2 18.87%, #5f43e9 86.22%);
    `}
`;

export const InnerSpinner = styled.div`
  background: #ffffff;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  position: relative;
`;

export const OkSign = styled.span`
  border-bottom: 4px solid #715eeb;
  border-left: 4px solid #715eeb;
  display: block;
  width: 33px;
  height: 20px;
  transform: rotate(-45deg);
  position: absolute;
  top: 18px;
  left: 16px;
`;

export const PaymentModalBody = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 2px #d3dbf8;
  border-radius: 4px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 50px 26px;
  box-sizing: border-box;
`;

export const Processing = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #6786ff;
  text-align: center;
  margin-bottom: 50px;
`;

export const Description = styled.div`
  margin: 10px auto;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -1px;
`;

export const Redirecting = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -1px;
  text-align: center;
  color: #250044;
`;

export const WarningText = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  letter-spacing: -1px;

  color: #250044;
`;

export const ButtonWrapper = styled.button`
  min-height: 50px;
  min-width: 200px;
  padding: 5px 20px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background: #6786ff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 35px;
  transition: all 0.3s;
  &:hover {
    background: #250044;
    text-decoration: none;
    color: #ffffff;
  }
`;
