import { differenceInYears, isValid, MmDdSwitch } from './date-fns';
import { email_validation, valid_states, dobValidation } from './common';

const isFilledIn = str => str?.length > 0;
const isFilledInPhoneNumber = str => isFilledIn(str) && !str.includes('_');

const error_checker = {
  email: email => email_validation(email),
  first_name: isFilledIn,
  last_name: isFilledIn,
  password: password => password?.length > 5,
  password_confirm: (current, password) => isFilledIn(current) && current === password,
  is_consent: value => value === true,
  phone_number: isFilledInPhoneNumber,
  state_selector: isFilledIn,
  birth_date: date => dobValidation(date),
  birth_date_uk: date => dobValidation(MmDdSwitch(date)),
  is_adult: date => isValid(date) && differenceInYears(date) >= 18,
  is_adult_uk: date => isValid(MmDdSwitch(date)) && differenceInYears(MmDdSwitch(date)) >= 18,
  weight: weight => /^\d+$/.test(weight) && Number(weight) > 0,
  height_inch: isFilledIn,
  height_ft: isFilledIn,
  region: state => valid_states.some(valid_state => valid_state.includes(state.toLowerCase())),
  postal_code: zip => /^\d{5}$/.test(zip),
  postal_code_uk: zip =>
    /([Gg][Ii][Rr]\s0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/.test(
      zip
    ),
  city: isFilledIn,
  address_1: isFilledIn,
  name_on_card: isFilledIn,
  selected_checkbox: boxes => boxes.some(isChecked => isChecked === true),
  sms_opted_in: optIn => optIn
};

export default error_checker;
