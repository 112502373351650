import createPricing from './createPricing';
import { IStrings } from './types';
import { UKTerm } from '../components/ImportantInformation/ImportantInformation';

const discountedTherapyPrice = 129;

export default {
  nationalSuicideHotlineName: 'Samaritans',
  nationalSuicideHotlineNumber: '116 123',
  nationalEmergencyNumber: '999',
  currencySymbol: '£',
  assessmentFee: 15,
  nutritionManagementUpsellPriceDefault: 69,
  nutritionManagementPriceDefault: 69,
  nutritionMedicationUpsellPriceDefault: 85,
  nutritionMedicationPriceDefault: 85,
  nutritionMedicationGlp1UpsellPriceDefault: 120,
  nutritionMedicationGlp1PriceDefault: 120,
  conselingUpgradePrice: 30,
  nutritionTherapyUpgradePrice: 99,
  smokingAssessmentUpgradePrice: 40,
  medsTherapyCta: 'Start GP + Therapy Today',
  medicationTherapyPrices: createPricing(349, 149),
  medicationNicotinePrices: createPricing(40, 28),
  medicationBupropionPrices: createPricing(40, 17),
  medicationBupropionNicotinePrices: createPricing(80, 40),
  therapyPrices: createPricing(299, discountedTherapyPrice),
  discountedTherapyPrice,
  medicationPrices: createPricing(89, 49),
  nutritionPrices: createPricing(69, 25),
  nutritionMedicationPrices: createPricing(85, 30),
  nutritionMedicationGlp1Prices: createPricing(120, 45),
  nutritionMedicationGlp1TherapyPrices: createPricing(379, 235),
  medsPlanTitle: {
    title: 'GP &\nCounseling',
    name: 'GP & Counseling'
  },
  medsTherapyPlanTitle: {
    title: 'GP &\nTherapy',
    name: 'GP + Therapy'
  },
  prescriberText: 'GP',
  defaultSubpriceMessage: 'Billed monthly <br /> Cancel anytime',
  weeklyVideoPhone: 'Weekly video/phone session with a UK registered therapist',
  regularVideo: 'Regular video session with an assigned care counselor, who is a UK registered therapist',
  defaultPromoCode: 'START49',
  defaultSMCampaignCode: 'SIMONE',
  defaultTherapyCode: 'CARE129',
  defaultMedsTherapyCode: 'CARE149',
  defaultTherapyCampaignCode: 'CARE79',
  defaultMedsTherapyCampaignCode: 'CARE99',
  defaultNicotineCode: '12OFF',
  defaultBupropionCode: '23OFF',
  defaultBupropionNicotineCode: '40OFF',
  defaultNicotineCounselingCode: '52OFF',
  defaultBupropionCounselingCode: '63OFF',
  defaultBupropionNicotineCounselingCode: '80OFF',
  defaultNicotineCodePrice: 12,
  defaultBupropionCodePrice: 23,
  defaultBupropionNicotineCodePrice: 40,
  defaultNicotineCounselingCodePrice: 52,
  defaultBupropionCounselingCodePrice: 63,
  defaultBupropionNicotineCounselingCodePrice: 80,
  defaultNutritionCodePrice: 44,
  defaultNutritionMedicationCodePrice: 55,
  defaultTherapyCampaignPrice: 79,
  defaultMedsTherapyCampaignPrice: 99,
  defaultNutritionCode: '44OFF',
  defaultNutritionMedicationCode: '55OFF',
  defaultNutritionMedicationGlp1Code: '75OFF',
  defaultNutritionMedicationGlp1CodePrice: 75,
  countryCode: 'GB',
  defaultAssessmentFeeAddonCode: 'assessmentfee-15',
  infoScreeningMessage:
    'The following questions serve as a preliminary screen to establish the right course of treatment for you.',
  zipCodeLength: 8,
  zipCodeParser: /[^a-zA-Z0-9 ]+/g,
  subscriptionApi: '/api/stripe_payments/subscription_info',
  phoneRegion: 'GB',
  PaymentTerms: UKTerm,
  SignupTerms: UKTerm,
  checkoutTitle: 'Your Cerebral Membership'
} as IStrings;
