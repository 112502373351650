import React, { useContext, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { isNutritionOffering } from 'utils/common';
import { RolloutContext } from 'contexts/rollout';
import {
  CardHeader,
  Text,
  Card,
  CardInner,
  NoActiveSubscriptions,
  ButtonsHolder,
  ViewPlanDetails
} from '../ProfileInfo/styled';
import { ReactComponent as ViewSubscriptionImage } from '../assets/view_subscription.svg';
import { ReactComponent as SelfPayImage } from '../assets/self_pay.svg';
import { ReactComponent as ChangePlanImage } from '../assets/change_plan.svg';

const InsuranceVisit = ({
  insurance,
  updateView,
  onPlanChange,

  abTestValues,
  payerNotInListed,
  isNotSupportInsurancePayment = false
}) => {
  const offeringKey = useSelector(store => store.patient_reducer?.offering_key);
  const { flags, loadFlags } = useContext(RolloutContext);
  const isInvalidInsurance = isEmpty(insurance);
  const updateInsurance = () => {
    if (isNutritionOffering(offeringKey)) {
      updateView('nutrition_insurance');
    } else if (abTestValues?.ab_test_convert_ooni_to_ini === 'enable_new_flow_convert_ooni_to_ini') {
      updateView('update_insurance_cards');
    } else {
      updateView('insurance');
    }
  };

  useEffect(() => {
    const shouldUpdateView = sessionStorage.getItem('PATIENT_INFO_VIEW') === 'InsuranceVisit';
    if (shouldUpdateView) {
      updateInsurance('');
      sessionStorage.removeItem('PATIENT_INFO_VIEW');
    }
  }, []);

  useEffect(() => {
    if (flags && !Object.keys(flags).length) {
      loadFlags();
    }
  }, []);

  const nutritionInsuranceStatus = isNutritionOffering(offeringKey) && 'None';

  return (
    <Card>
      <CardInner className="d-flex flex-column card-items-container justify-content-between">
        <div className="flex-shrink-1 flex-grow-1">
          <div className="small-card-title">Insurance information</div>
          {isInvalidInsurance ? (
            <>
              {payerNotInListed ? (
                <>
                  <NoActiveSubscriptions>Status: {nutritionInsuranceStatus || 'Out-of-network'}</NoActiveSubscriptions>
                  <CardHeader>Insurance Plan</CardHeader>
                  <CardHeader>{`${payerNotInListed}`}</CardHeader>
                </>
              ) : (
                <NoActiveSubscriptions>No insurance information saved.</NoActiveSubscriptions>
              )}
            </>
          ) : (
            <>
              {insurance.status && (
                <NoActiveSubscriptions>
                  Status:{' '}
                  {nutritionInsuranceStatus ||
                    `${insurance.status === 'in_network' ? 'In-Network' : 'Covered out-of-network'}`}
                </NoActiveSubscriptions>
              )}
              <CardHeader>Insurance Plan</CardHeader>
              {insurance.payer && insurance.payer.name && <CardHeader>{`${insurance.payer.name}`}</CardHeader>}
              {insurance.policy_id && <CardHeader>{`Membership ID: ${insurance.policy_id}`}</CardHeader>}
              {insurance.rx_bin && <CardHeader>{`Rx BIN: ${insurance.rx_bin}`}</CardHeader>}
              {insurance.group_number && <CardHeader>{`Group/Plan ID: ${insurance.group_number}`}</CardHeader>}
            </>
          )}
        </div>
        {(!isNotSupportInsurancePayment ||
          (isNutritionOffering(offeringKey) && flags?.nutrition_glp1_insurance_toggle)) && (
          <ButtonsHolder marginTop="50px">
            {(isInvalidInsurance && !isNutritionOffering(offeringKey)) ||
            (insurance.status !== 'in_network' && !isNutritionOffering(offeringKey)) ? (
              <ViewPlanDetails onClick={updateInsurance}>
                <ViewSubscriptionImage />
                <Text>Check my insurance coverage</Text>
              </ViewPlanDetails>
            ) : (
              <>
                <ViewPlanDetails onClick={updateInsurance}>
                  <ChangePlanImage />
                  <Text>Update my insurance coverage</Text>
                </ViewPlanDetails>
              </>
            )}

            {insurance.status === 'in_network' && (
              <ViewPlanDetails onClick={() => onPlanChange()}>
                <SelfPayImage />
                <Text>Switch to self-pay</Text>
              </ViewPlanDetails>
            )}
          </ButtonsHolder>
        )}
      </CardInner>
    </Card>
  );
};

export default InsuranceVisit;
