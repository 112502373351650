import styled from 'styled-components/macro';
import eligibleImage from './img/eligible.png';
import CaptureImage from './img/CareteamIcon.svg';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: ${props => props.zIndex || '10'};
`;

export const Divider = styled.div`
  width: 40px;
  height: 10px;
`;

export const Holder = styled.div`
  display: flex;
  flex-direction: ${props => (props.row ? 'row' : 'column')};
  justify-content: center;
  align-items: center;
`;

export const ModalWrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 11;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  max-width: 360px;
  background: #ffffff;
  box-shadow: 0px 2px 2px #d3dbf8;
  border-radius: 4px;
  padding: 30px 0;
  @media (max-width: 991px) {
    left: 10px;
    right: 10px;
    width: auto;
    transform: translateY(-50%);
    max-width: inherit;
  }
`;

export const ModalHeader = styled.div`
  color: #6786ff;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -1px;
  padding: 0 42px;
  text-align: center;
`;

export const ModalBody = styled.div`
  color: #6786ff;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  padding: 0 30px;
  text-align: center;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.314286px;
  color: #6786ff;
  appearance: none;
  padding: 0;
  &:focus {
    outline: none;
    appearance: none;
  }
`;

export const OkButton = styled.button`
  align-self: ${props => (props.center ? 'center' : 'flex-end')};
  margin-right: ${props => (props.center ? '0px' : '30px')};
  padding: 5px 20px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background: #6786ff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 15px;
  transition: all 0.3s;
  &:hover {
    background: #250044;
    text-decoration: none;
    color: #ffffff;
  }
`;

export const Message = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -1px;
  color: #6786ff;
`;

export const ModalMain = styled.div`
  font-family: 'Montserrat';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  z-index: ${props => props.zIndex || '10'};
`;

export const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 11;
  top: 45%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  max-width: 360px;
  background: #ffffff;
  box-shadow: 0px 2px 2px #d3dbf8;
  border-radius: 10px;
  padding: 0px 0;
  @media (max-width: 991px) {
    left: 10px;
    right: 10px;
    width: auto;
    transform: translateY(-50%);
    max-width: inherit;
  }
`;

export const WhiteTitle = styled.div`
  font-style: italic;
  font-weight: 600;
  font-size: ${props => (props.small ? '14px;' : '20px;')};
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  margin-top: 5px;
  padding: 30px;
`;

export const HeadersPopup = styled.div`
  background: #6786ff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;
export const ContentPopup = styled.div`
  padding: 30px 24px;
  font-size: 14px;
  font-family: Montserrat;
`;
export const ChargeOption = styled.div`
  font-size: 14px;
  font-family: Montserrat;
  background: #dafff1;
  padding: 12px 8px 20px 8px;
  border-radius: 15px;
  color: #6786ff;
  text-align: center;
  font-weight: 600;
  letter-spacing: -0.4px;
  line-height: 28px;
`;
export const MessagePopup = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.4px;
  margin-bottom: 20px;
`;
export const ActionPopup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const LinkStyle = styled.a`
  padding: 25px 0px 35px;
  font-size: 14px;
  text-decoration-line: underline;
  font-weight: 600;
  letter-spacing: -0.314286px;
  cursor: pointer;
`;
export const UpdateButton = styled.button`
  align-self: center;
  padding: 10px 24px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background: #6786ff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 30px;
  transition: all 0.3s;
  &:hover {
    background: #250044;
    text-decoration: none;
    color: #ffffff;
  }
`;

export const ClosePopup = styled.button`
  width: 43px;
  height: 43px;
  background-repeat: no-repeat;
  border: transparent;
  border-radius: 50%;
  position: absolute;
  background-color: white;
  right: -16px;
  top: -16px;
  display: flex;
  background-position: center;
`;

export const MessageContent = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.4px;
  margin-bottom: 20px;
  color: #6786ff;
`;

export const Messagetitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.4px;
  margin-top: 20px;
  color: #6786ff;
`;

export const MessageSteps = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: left;
  letter-spacing: -0.4px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #6786ff;
  padding: 0 20px;
`;
export const ContentSteps = styled.div`
  padding: 0px 30px 24px;
  font-size: 14px;
  font-family: Montserrat;
`;

export const MessageCareteam = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.4px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #6786ff;
  padding: 0 0px;
  &.bold {
    font-weight: 600;
  }
`;

export const BlueTitle = styled.div`
  font-style: italic;
  font-weight: 600;
  font-size: ${props => (props.small ? '14px;' : '20px;')};
  line-height: 25px;
  text-align: center;
  color: #6786ff;
  margin-top: 5px;
  padding: 10px 30px;
`;

export const EligibleImage = styled.div`
  background-image: url(${eligibleImage});
  width: 209px;
  height: 200px;
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ModalMainWrapper = styled(ModalMain)`
  top: 100px;
`;

export const CareteamImage = styled.div`
  background-image: url(${CaptureImage});
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0px auto;
  width: 100px;
  height: 103px;
`;
