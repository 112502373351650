import styled from 'styled-components/macro';
import chevronImage from '../../../img/chevron-select.png';

interface Props {
  noWidth?: boolean;
  borderColor?: string;
  padding?: string;
}

export default styled.select`
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px 4px #f6f6f9;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.43px;
  color: rgba(37, 0, 68);
  padding: ${(props: Props) => (props.padding ? props.padding : '20px')};
  outline: none;
  width: ${(props: Props) => (props.noWidth ? '' : '100%;')}
  position: relative;
  ${props => (!props.disabled ? `background-image: url(${chevronImage});` : '')}
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 9px 5px;
  appearance: none;
  border: 1px solid ${(props: Props) => props.borderColor || '#D3DBF8'};
`;
