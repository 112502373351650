import styled from 'styled-components/macro';
import TextButton from '../components/UI/TextButton';

export interface TitleProps {
  alignButton: boolean;
  tight?: boolean;
}

export const Title = styled.h1<TitleProps>`
  font: 600 30px/45px Montserrat, sans-serif;
  text-align: center;
  letter-spacing: -0.4px;
  margin: 0 auto 30px;
  padding: 0 15px;
  max-width: ${({ tight }) => (tight ? '500px' : 'none')};
  align-items: center;
  justify-content: center;
  display: ${({ alignButton }) => (alignButton ? 'flex' : 'block')};

  ${TextButton} {
    color: inherit;
    padding: 0;
    margin-left: 1rem;
  }

  span {
    display: inline-block;
    margin-left: 0.5rem;
  }

  @media (max-width: 991px) {
    margin-top: 30px;
    font-size: 18px;
    line-height: 22px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
export const DescriptionText = styled.h2<TitleProps>`
  font: 400 16px/18px Montserrat, sans-serif;
  text-align: center;
  letter-spacing: -0.43px;
  margin: 0 auto 50px;
  padding: 0 15px;
  max-width: ${({ tight }) => (tight ? '580px' : 'none')};
  align-items: center;
  justify-content: center;
  display: ${({ alignButton }) => (alignButton ? 'flex' : 'block')};

  ${TextButton} {
    color: inherit;
    padding: 0;
    margin-left: 1rem;
  }

  span {
    display: inline-block;
    margin-left: 0.5rem;
  }

  @media (max-width: 991px) {
    margin-top: 30px;
    font-size: 18px;
    line-height: 25px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
