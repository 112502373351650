import styled from 'styled-components/macro';

export default styled.button`
  background: transparent;
  padding: 13px 40px;
  border: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.375px;
  text-decoration: underline;
  color: #6786ff;
`;
