import React from 'react';
import styled from 'styled-components/macro';

const SVG = styled.svg`
  display: block;
  margin: auto;

  span & {
    display: inline-block;
    cursor: pointer;
    margin: 0 10px;
  }
`;

const InformationIcon = ({ iconStyle = 'blue' }) => {
  const textColor = iconStyle === 'white' ? '#6786FF' : '#FFFFFF';
  let iconColor;
  switch (iconStyle) {
    case 'white':
      iconColor = '#FFFFFF';
      break;
    case 'gray':
      iconColor = '#B2B2B2';
      break;
    default:
      iconColor = '#6786FF';
  }

  return (
    <SVG width="18" height="18" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30 15.0002C30 6.7152 23.2843 0 15.0002 0C6.7158 0 0 6.7152 0 15.0002C0 23.2839 6.7158 29.9996 15.0002 29.9996C23.2843 29.9996 30 23.2839 30 15.0002Z"
        fill={iconColor}
      />
      <path
        d="M19.0604 21.7406C18.9766 21.6178 18.8157 21.5741 18.6813 21.6377C17.195 22.3414 15.508 23.0853 15.1769 23.1503C15.1686 23.1428 15.1553 23.1292 15.138 23.1057C15.0931 23.0449 15.0713 22.9655 15.0713 22.8629C15.0713 22.0222 15.7058 19.4645 16.9573 15.2614C18.0124 11.7299 18.1345 10.9987 18.1345 10.7559C18.1345 10.3812 17.991 10.0645 17.7194 9.84011C17.4614 9.62734 17.1009 9.51953 16.648 9.51953C15.8947 9.51953 15.0216 9.80553 13.9787 10.3939C12.9682 10.9643 11.8412 11.9303 10.6292 13.2652C10.5344 13.3697 10.5245 13.526 10.6054 13.6418C10.6864 13.7576 10.8366 13.8016 10.9673 13.7484C11.3928 13.5748 13.5311 12.6988 13.8428 12.503C14.098 12.343 14.319 12.2618 14.4995 12.2618C14.506 12.2618 14.5115 12.2621 14.5162 12.2624C14.5184 12.2744 14.5204 12.2927 14.5204 12.3189C14.5204 12.5031 14.4825 12.7212 14.4082 12.966C12.5861 18.8698 11.7003 22.4891 11.7003 24.0307C11.7003 24.5747 11.8515 25.0127 12.1497 25.3324C12.4535 25.6584 12.8633 25.8238 13.3678 25.8238C13.9054 25.8238 14.5597 25.5992 15.368 25.1372C16.1501 24.6903 17.344 23.7071 19.0178 22.1311C19.1262 22.0291 19.1443 21.8635 19.0604 21.7406Z"
        fill={textColor}
      />
      <path
        d="M19.0018 4.63611C18.704 4.33131 18.3229 4.17676 17.8693 4.17676C17.3046 4.17676 16.8127 4.39993 16.4075 4.84012C16.0088 5.27304 15.8066 5.80428 15.8066 6.41903C15.8066 6.90474 15.9556 7.30725 16.2492 7.61532C16.5468 7.92804 16.9228 8.08658 17.3664 8.08658C17.908 8.08658 18.3972 7.85089 18.8204 7.38604C19.2361 6.92935 19.447 6.39242 19.447 5.7902C19.447 5.3274 19.2973 4.93916 19.0018 4.63611Z"
        fill={textColor}
      />
    </SVG>
  );
};

export default InformationIcon;
