/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  PageWrapper,
  SectionTitle,
  ImageWrapper,
  Content,
  MediaWrapper,
  InputPromptText,
  TextArea,
  NextBtn
} from './styled';
import { BackButton } from '../CategoryList/styled';

const baseUrl = process.env.REACT_APP_API_SERVER_URL;

const StepView = ({ stepData, goBack, onNext, isPreview = false, title, isLast = false }) => {
  const [answer, setAnswer] = useState('');
  const activeStepAnswers = useSelector(state => state.exercise_reducer.active_exercise.answers);

  const step = useMemo(() => {
    return activeStepAnswers[stepData.id];
  }, [activeStepAnswers, stepData]);

  useEffect(() => {
    setAnswer(activeStepAnswers[stepData.id] || '');
  }, [stepData]);

  const handleNext = () => {
    onNext(answer);
  };

  const handleTextArea = evt => {
    setAnswer(evt.target.value);
  };

  return (
    <PageWrapper>
      {!isPreview && (
        <BackButton onClick={goBack}>
          <img src={`${process.env.PUBLIC_URL}/img/exercises/arrow-left.svg`} alt="Go Back" />
        </BackButton>
      )}
      {!isPreview && <SectionTitle>{title}</SectionTitle>}
      {stepData.image_url && (
        <ImageWrapper>
          <img src={`${baseUrl}${stepData.image_url}`} alt={stepData.title} />
        </ImageWrapper>
      )}

      {stepData.html && <Content dangerouslySetInnerHTML={{ __html: stepData.html }} />}

      {stepData.audio_url && (
        <MediaWrapper>
          <audio controls>
            <source src={`${baseUrl}${stepData.audio_url}`} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </MediaWrapper>
      )}

      {stepData.input_prompt && (
        <>
          <InputPromptText>{stepData.input_prompt}</InputPromptText>
          {!isPreview ? <TextArea value={answer} onChange={handleTextArea} /> : step && <p>{step}</p>}
        </>
      )}

      {!isPreview && (
        <MediaWrapper>
          <NextBtn onClick={handleNext}>{isLast ? 'Complete' : 'Next'}</NextBtn>
        </MediaWrapper>
      )}
    </PageWrapper>
  );
};

export default StepView;
