import Spinner from 'components/Spinner';
import React from 'react';
import { WhiteIsotype } from '../UI/Modal';
import {
  ActionPopup,
  BlueTitle,
  CareteamImage,
  ContentSteps,
  LinkStyle,
  MessageCareteam,
  ModalInner,
  ModalMain,
  UpdateButton
} from './styled';

interface Props {
  onClose: () => void;
  onUpdate: () => void;
  loading: boolean;
}

const SwitchCareTeamModal = (props: Props) => {
  const { onClose, onUpdate, loading } = props;
  return (
    <ModalMain>
      <ModalInner>
        <WhiteIsotype className="careteamIcon" />
        <BlueTitle>Switch your Care Team</BlueTitle>
        <ContentSteps>
          <CareteamImage />
          <MessageCareteam>
            It looks like your current team is not <br />
            covered by your insurance provider.
          </MessageCareteam>

          <MessageCareteam>
            We understand how important it is to <br />
            have a meaninful connection with your <br />
            team. However, we are committed to <br />
            providing you with the highest quality <br />
            care through our network of
            <br />
            licensed clinicians.
          </MessageCareteam>
          <MessageCareteam className="bold">
            By continuing to switch to an insurance <br />
            plan, you consent to losing your care <br />
            counselor and having to select a new
            <br />
            prescriber if they&apos;re not in-network
            <br />
            with your insurance.
          </MessageCareteam>
        </ContentSteps>
        <ActionPopup>
          <UpdateButton
            onClick={() => {
              onUpdate();
            }}
          >
            {loading ? <Spinner size="1.6em" /> : ` Continue to switch`}
          </UpdateButton>
          <LinkStyle
            onClick={() => {
              onClose();
            }}
          >
            Keep my current plan
          </LinkStyle>
        </ActionPopup>
      </ModalInner>
    </ModalMain>
  );
};

export default SwitchCareTeamModal;
