import React, { useState, useEffect } from 'react';
import { AssessmentType, Scores } from 'types/assessments';
import type {
  Area as AreaType,
  AreaChart as AreaChartType,
  CartesianGrid as CartesianGridType,
  Label as LabelType,
  ReferenceLine as ReferenceLineType,
  ResponsiveContainer as ResponsiveContainerType,
  XAxis as XAxisType,
  YAxis as YAxisType
} from 'recharts';
import { getYAxisRange, getDotPoint, getGraphLabel } from '../utils';

export interface ChartAreaProps {
  width: string | number;
  height: string | number;
  type: AssessmentType;
  data: Scores[AssessmentType];
}

interface Recharts {
  Area: typeof AreaType;
  AreaChart: typeof AreaChartType;
  CartesianGrid: typeof CartesianGridType;
  Label: typeof LabelType;
  ReferenceLine: typeof ReferenceLineType;
  ResponsiveContainer: typeof ResponsiveContainerType;
  XAxis: typeof XAxisType;
  YAxis: typeof YAxisType;
}

const ChartArea: React.FC<ChartAreaProps> = ({ type, data, width, height }) => {
  const [rechartsComponents, setRechartsComponents] = useState<Recharts | null>(null);

  useEffect(() => {
    import('recharts').then(recharts => {
      setRechartsComponents({
        Area: recharts.Area,
        AreaChart: recharts.AreaChart,
        CartesianGrid: recharts.CartesianGrid,
        Label: recharts.Label,
        ReferenceLine: recharts.ReferenceLine,
        ResponsiveContainer: recharts.ResponsiveContainer,
        XAxis: recharts.XAxis,
        YAxis: recharts.YAxis
      });
    });
  }, []);

  if (!rechartsComponents) return null;

  const { Area, AreaChart, CartesianGrid, Label, ReferenceLine, ResponsiveContainer, XAxis, YAxis } =
    rechartsComponents;

  return (
    <ResponsiveContainer width={width} height={height}>
      <AreaChart
        margin={{ top: 20, right: 0, left: 5, bottom: 0 }}
        data={data.length === 1 ? [{ name: null, uv: data[0].uv }, data[0]] : data}
      >
        <XAxis dataKey="name" orientation="bottom" dy={10} fontSize={14} />
        <YAxis type="number" domain={[0, getYAxisRange(type)]} tickCount={getDotPoint(type)}>
          <Label
            angle={-90}
            value={getGraphLabel(type)}
            position="insideLeft"
            style={{
              fontWeight: 600,
              textAnchor: 'middle',
              fontSize: '16px',
              fill: '#250044'
            }}
          />
        </YAxis>
        <CartesianGrid stroke="#E9E7E9" />
        <Area type="monotone" dataKey="uv" stroke="#e1e5f5" fill="#e1e5f5" />
        <ReferenceLine
          y={data[0].uv}
          stroke="#250044"
          strokeDasharray="3 3"
          label={{
            fontWeight: 600,
            fontSize: '16px',
            position: 'right',
            value: data[0].uv,
            fill: '#250044'
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default ChartArea;
