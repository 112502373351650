import React, { useState, useEffect, lazy } from 'react';
import styled from 'styled-components/macro';
import xor from 'lodash/xor';

const Checkbox = lazy(() => import('../CustomCheckBox'));

const descriptions = [
  {
    id: 'receive_content_notifications',
    title: 'Marketing Communications',
    description:
      'Receive emails with informational content regarding best practices for medication management and psychosocial techniques.'
  },
  {
    id: 'receive_reminder_notifications',
    title: 'Sessions and Account Updates',
    description:
      'Receive email reminders for your Cerebral appointments and updates regarding your account and payment methods.'
  },
  {
    id: 'sms_opted_in',
    title: 'SMS Notifications',
    description: 'Receive important text reminders and updates about your treatment plan and services.'
  }
];

const FormTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.43px;
  text-transform: uppercase;
`;
const Item = styled.div`
  display: flex;
  margin: 15px auto 0;
  &:first-child {
    margin-top: 0;
  }
`;
const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #250044;
`;
const Description = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #828282;
  margin: 4px auto 0;
`;
const Content = styled.div`
  flex: 1;
  margin-right: 54px;
`;
const Options = styled.div`
  margin: 24px auto 0;
  opacity: ${({ saving }) => (saving ? '.5' : '1')};
  pointer-events: ${({ saving }) => (saving ? 'none' : 'auto')};
`;
const Controls = styled.div`
  margin: 15px 0 0;
`;
const Button = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #6786ff;
  border: none;
  background: transparent;
  margin: 0 10px;
  cursor: pointer;

  &:disabled {
    cursor: default;
    color: #bdbdbd;
  }

  &:first-child {
    margin-left: 0;
  }
`;
const Saving = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #6786ff;
  margin: 0 10px;
`;
const Loading = styled.div`
  margin: 24px 0;
`;
const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 2px #d3dbf8;
  border-radius: 4px;
  padding: 36px 19px 49px;
  width: 100%;

  @media (min-width: 834px) {
    padding: 68px 69px 49px;

    ${Options} {
      margin: 42px auto 0;
    }

    ${Item} {
      margin: 24px auto 0;
    }

    ${Controls} {
      margin: 38px 0 0;
    }
  }
`;

function UnsubscribeForm({ className, load, save, unChecked = [] }) {
  const [loading, setLoading] = useState(true);
  const [initialOptions, setInitialOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const onOptionClick = id => {
    setSelectedOptions(options => (options.includes(id) ? options.filter(oid => oid !== id) : [...options, id]));
  };

  const onSaveClick = async () => {
    setIsSaving(true);

    const options = descriptions.reduce((acc, value) => {
      acc[value.id] = selectedOptions.includes(value.id);
      return acc;
    }, {});

    await save({ options });

    setIsSaving(false);
    setInitialOptions(selectedOptions);
  };

  const onCancelClick = () => {
    setSelectedOptions(initialOptions);
  };

  useEffect(() => {
    if (xor(initialOptions, selectedOptions).length > 0) {
      setIsEdited(true);
    } else {
      setIsEdited(false);
    }
  }, [selectedOptions, initialOptions]);

  useEffect(() => {
    let allowUpdate = true;

    async function loadOptions() {
      const options = await load();
      const ids = Object.keys(options)
        .filter(key => options[key])
        .map(key => key);
      const edited = ids.filter(id => !unChecked.includes(id));

      allowUpdate && setInitialOptions(ids);
      allowUpdate && setSelectedOptions(edited);
      allowUpdate && setLoading(false);
    }

    loadOptions();

    return () => {
      allowUpdate = false;
    };
  }, []);

  return (
    <Wrapper className={className}>
      <FormTitle>Notification Preferences</FormTitle>

      {loading && <Loading>Loading...</Loading>}

      {!loading && (
        <>
          <Options saving={isSaving}>
            {descriptions.map(option => (
              <Item key={option.id}>
                <Content>
                  <Title>{option.title}</Title>
                  <Description>{option.description}</Description>
                </Content>
                <Checkbox
                  isChecked={selectedOptions.includes(option.id)}
                  event_handler={() => onOptionClick(option.id)}
                  name={`unsubscribe_${option.id}`}
                />
              </Item>
            ))}
          </Options>
          <Controls>
            {!isSaving && (
              <>
                <Button disabled={!isEdited} onClick={onSaveClick}>
                  Save
                </Button>
                <Button disabled={!isEdited} onClick={onCancelClick}>
                  Cancel
                </Button>
              </>
            )}
            {isSaving && <Saving>Saving ...</Saving>}
          </Controls>
        </>
      )}
    </Wrapper>
  );
}

export default styled(UnsubscribeForm)``;
