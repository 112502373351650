import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import creditCardType from 'credit-card-type';
import { verifyLuhn, getCardTypeAndMaxLength, cvvLengthByType } from 'utils/common';
import { Flexbox } from '@cerebral-inc/ui';
import { CardNumberInput, MonthInput, YearInput, CvcInput, ZipInput } from './styled';

type CreditCardFields = 'cardNumber' | 'month' | 'year' | 'cardCode' | 'postal_code';

interface PaymentFormProps {
  values: Record<CreditCardFields, string>;
  onUpdateField(e: ChangeEvent<HTMLInputElement>, field: CreditCardFields): void;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ values, onUpdateField }) => {
  const [showZip, setShowZip] = useState(false);

  const cardNumberInput = useRef<HTMLInputElement>(null);
  const monthInput = useRef<HTMLInputElement>(null);
  const yearInput = useRef<HTMLInputElement>(null);
  const cardCodeInput = useRef<HTMLInputElement>(null);
  const zipCodeInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const onlyDigits = values.cardNumber.replace(/\D/g, '');
    const cardType = creditCardType(onlyDigits)[0];

    if (cardType) {
      if (cardType.lengths.some(item => item === onlyDigits.length) && verifyLuhn(onlyDigits)) {
        return monthInput.current?.focus();
      }
    } else if (verifyLuhn(onlyDigits)) {
      return monthInput.current?.focus();
    }
  }, [values.cardNumber]);

  const dateInputCheck = (date: string, focusRef: React.RefObject<HTMLInputElement>) => () =>
    date.length === 2 ? focusRef.current?.focus() : undefined;

  useEffect(dateInputCheck(values.month, yearInput), [values.month]);
  useEffect(dateInputCheck(values.year, cardCodeInput), [values.year]);

  useEffect(() => {
    const [type] = getCardTypeAndMaxLength(values.cardNumber);
    const cvvLength = cvvLengthByType(type);
    if (values.cardCode.length >= cvvLength) {
      setShowZip(true);
    }
  }, [values.cardNumber, values.cardCode]);

  useEffect(() => (showZip ? zipCodeInput.current?.focus() : undefined), [showZip]);

  return (
    <>
      <CardNumberInput
        type="text"
        name="cardnumber"
        value={values.cardNumber}
        ref={cardNumberInput}
        onChange={e => onUpdateField(e, 'cardNumber')}
        placeholder="Card Number"
        autoComplete="cc-number"
      />
      <Flexbox justifyContent="flex-start">
        <MonthInput
          type="text"
          name="ccmonth"
          inputMode="numeric"
          pattern="[0-9]*"
          value={values.month}
          ref={monthInput}
          onChange={e => onUpdateField(e, 'month')}
          placeholder="MM"
          autoComplete="cc-exp-month"
        />
        <span> / </span>
        <YearInput
          type="text"
          name="ccyear"
          inputMode="numeric"
          pattern="[0-9]*"
          value={values.year}
          ref={yearInput}
          onChange={e => onUpdateField(e, 'year')}
          placeholder="YY"
          autoComplete="cc-exp-year"
        />
      </Flexbox>
      <CvcInput
        type="text"
        name="cvc"
        inputMode="numeric"
        pattern="[0-9]*"
        value={values.cardCode}
        ref={cardCodeInput}
        onChange={e => onUpdateField(e, 'cardCode')}
        placeholder="CVC"
        autoComplete="cc-csc"
      />
      {showZip && (
        <ZipInput
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          value={values.postal_code}
          ref={zipCodeInput}
          onChange={e => onUpdateField(e, 'postal_code')}
          placeholder="ZIP"
        />
      )}
    </>
  );
};

export default PaymentForm;
