const lowBmiText =
  'Body mass index (BMI) is a screening method for weight categories, but it does not diagnose the health of an individual. A “Low” BMI can contribute to a weakened immune system, fragile bones, and feeling tired. If your diet is the cause of your low weight, changing to a personalized healthy and balanced diet can help you achieve a healthy weight and lifestyle, physically and emotionally.';
const normalBmiText =
  'Body mass index (BMI) is a screening method for weight categories, but it does not diagnose the health of an individual. While a “Normal” BMI indicates a healthy ratio of muscle and fat, there are several factors that can expose you to similar health risks as high fat. These include smoking, drinking, consuming a large amount of sugar and cholesterol, and not getting enough fitness.';
const overweightBmiText =
  'Body mass index (BMI) is a screening method for weight categories, but it does not diagnose the health of an individual. An “Overweight” BMI indicates a higher ratio of fat to muscle. A few risks of being overweight include: heart disease, high blood pressure, osteoarthritis, and Type 2 diabetes. If your diet is the cause of your BMI category, creating a personalized diet and fitness regime that can help you achieve a healthier lifestyle, physically and emotionally. ';
const obeseBmiText =
  'Body mass index (BMI) is a screening method for weight categories, but it does not diagnose the health of an individual. An “Obese” BMI indicates a high ratio of fat to muscle. A few risks of being obese include: gallbladder disease, heart disease, high blood pressure, osteoarthritis, and Type 2 diabetes.  If your diet is the cause of your BMI category, creating a personalized diet and fitness regime that can help you achieve a healthier lifestyle, physically and emotionally. ';

const nutritionManagementText = 'Nutrition Management';
const nutritionMedManagementText = 'Nutrition & Medication Management';

export type BmiStatus = 'Low' | 'Normal' | 'Overweight' | 'Obese';

export const getBmiStatus = (bmiScore: number): BmiStatus => {
  if (bmiScore < 18.5) {
    return 'Low';
  }
  if (bmiScore <= 24.9) {
    return 'Normal';
  }
  if (bmiScore <= 29.9) {
    return 'Overweight';
  }
  return 'Obese';
};

export const getBmiText = (bmiScore: number): string => {
  return (
    {
      Low: lowBmiText,
      Normal: normalBmiText,
      Overweight: overweightBmiText,
      Obese: obeseBmiText
    }[getBmiStatus(bmiScore)] || obeseBmiText
  );
};

export const getBmiPlanText = (bmiScore: number): string => {
  return (
    {
      Low: nutritionManagementText,
      Normal: nutritionManagementText,
      Overweight: nutritionMedManagementText,
      Obese: nutritionMedManagementText
    }[getBmiStatus(bmiScore)] || nutritionMedManagementText
  );
};

export const formatNumber = (bmiScore: number) => {
  const instance = new Intl.NumberFormat('en-us', { maximumFractionDigits: 1 });
  return instance.format(bmiScore);
};

export const getButtonLabel = (bmiScore: number) => {
  if (bmiScore < 18.5) {
    return 'Review Your Plan';
  }
  return 'Choose Your Plan';
};
