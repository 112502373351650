import React from 'react';
import styled from 'styled-components/macro';
import { common_errors } from '../../utils/common';
import SuccessBullet from '../../img/bullet-success.svg';
import ErrorBullet from '../../img/bullet-error.svg';

const Container = styled.div`
  padding-top: 0.5em;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.43px;
  width: 100%;
`;

const Title = styled.div`
  color: red;
  margin-bottom: 0.5rem;
`;

const List = styled.ul`
  margin-left: -20px;
  margin-bottom: 0.5rem;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
  color: ${props => (props.valid ? '#59E3B3' : 'red')};
  list-style-image: url(${props => (props.valid ? SuccessBullet : ErrorBullet)});
`;

export default ({ password_validator }) => {
  return (
    <Container>
      <Title>Your password must:</Title>
      <List>
        <ListItem valid={password_validator.isValidLength}>{common_errors.password_length}</ListItem>
        <ListItem valid={password_validator.isValidUpperAndLowerCase}>
          {common_errors.password_upper_and_lower_case}
        </ListItem>
        <ListItem valid={password_validator.isValidNumber}>{common_errors.password_at_least_one_number}</ListItem>
        <ListItem valid={password_validator.isValidSymbols}>{common_errors.password_at_least_one_symbol}</ListItem>
      </List>
    </Container>
  );
};
