import React, { useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CategoryCard from '../CategoryCard';
import { PageWrapper, SectionTitle, ListWrapper, BackButton } from './styled';

const baseUrl = process.env.REACT_APP_API_SERVER_URL;

const CategoryList = ({ showAll }) => {
  const { categoryId } = useParams();
  const history = useHistory();
  const categories = useSelector(state => state.exercise_reducer.categories);
  const allExercises = useSelector(state => state.exercise_reducer.all_exercises);
  const listItems = useMemo(() => {
    if (showAll) return allExercises;

    return categoryId ? allExercises.filter(ex => ex.category_id === Number(categoryId)) : categories;
  }, [categoryId, showAll, categories]);

  const title = useMemo(() => {
    if (showAll) return 'All Exercises';

    const category = categories.find(c => c.id === Number(categoryId));
    return category ? category.name : 'Discover';
  });

  const handleGoBack = () => {
    history.replace('/patient/dashboard/patient_exercises');
  };

  const isExercise = categoryId || showAll;

  return (
    <PageWrapper>
      {isExercise && (
        <BackButton onClick={handleGoBack} data-testid="back-button">
          <img src={`${process.env.PUBLIC_URL}/img/exercises/arrow-left.svg`} alt="Go Back" />
        </BackButton>
      )}
      <SectionTitle>{title}</SectionTitle>
      <ListWrapper>
        {listItems.map((ex, idx) => (
          <CategoryCard
            topic={ex.topic}
            name={isExercise ? ex.title : ex.name}
            type={isExercise ? 'exercise' : 'category'}
            ctaLink={
              isExercise
                ? `/patient/dashboard/patient_exercises/exercise_flow/${ex.id}`
                : `/patient/dashboard/patient_exercises/${ex.id}`
            }
            imageUrl={`${baseUrl}${ex.image_url}`}
            key={idx}
            summary={ex.summary}
          />
        ))}

        {!isExercise && (
          <CategoryCard
            title="All Exercises"
            imageUrl={`${process.env.PUBLIC_URL}/img/exercises/all-exercises.svg`}
            ctaLink="/patient/dashboard/patient_exercises/all"
          />
        )}
      </ListWrapper>
    </PageWrapper>
  );
};

export default CategoryList;
