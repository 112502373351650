import styled, { css } from 'styled-components/macro';
import { Button, TextArea, inputType } from './components';

const TextInput = inputType('text');

export interface StyledGridFormProps {
  rowGap: boolean;
}

export interface StateZipWrapperProps {
  twoColumn: boolean;
}

export interface ProfileImageProps {
  absolute: boolean;
}

export const StyledTextArea = styled(TextArea)`
  padding: 20px 20px;
  background: #ffffff;
  border: 1px solid #d3dbf8;
  box-shadow: 0 0 12px 4px #f6f6f9;
  border-radius: 4px;
  width: 100%;
  margin: 20px 0;
  font-family: Montserrat, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #250044;
  resize: none;

  &::placeholder {
    font-size: 1em;
    color: #250044;
    font-style: italic;
  }

  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const StyledSelect = styled.select`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: 18px;
  letter-spacing: -0.43px;
  color: #250044;
  margin-bottom: 20px;
  padding: 20px 20px;
  background: #ffffff;
  border: 1px solid #250044;
  border-radius: 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.14759 6L0.498037 0.75L5.79714 0.75L3.14759 6Z' fill='%23250044'/%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 20px top 50%, 0 0;
`;

export const LinkButton = styled(Button)`
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 37px;
  font-weight: 600;
  letter-spacing: -0.43px;
  color: #250044;
  &:hover {
    color: #3e0370;
  }
`;

export const NextButton = styled(Button)`
  width: 155px;
  background: #ffffff;
  border: 1px solid #6786ff;
  box-sizing: border-box;
  border-radius: 42px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.375px;
  color: #6786ff;
  padding: 7px 0;
  :focus {
    outline: none;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SubmitButton = styled(Button)`
  width: 250px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.314286px;
  background-color: rgba(37, 0, 68, 1);
  height: 60px;
  color: white;
  border-radius: 30px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(37, 0, 68, 1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  &:hover {
    background: #3e0370;
    border-radius: 28.5px;
  }
  &:disabled {
    opacity: 0.5;
  }
  @media (max-width: 991px) {
    min-width: auto;
  }
`;

export const StyledTextInput = styled(TextInput)`
  text-indent: 20px;
  width: 100%;
  font-size: 14px;
  background: #ffffff;
  border: 1px solid ${({ error }) => (error ? 'red;' : '#D3DBF8;')};
  box-shadow: 0 0 12px 4px #f6f6f9;
  box-sizing: border-box;
  border-radius: 4px;
  height: 60px;
  color: #250044;
  ::placeholder {
    font-size: 14px;
    letter-spacing: -0.4px;
    color: #250044;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 30px;
`;

export const StyledForm = styled.form`
  text-align: left;
`;

export const StyledGridForm = styled.form<StyledGridFormProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  ${({ rowGap }) =>
    rowGap &&
    `
      grid-row-gap: 1rem;
  `}

  > * {
    grid-column: 1/-1;
  }
`;

export const StateZipWrapper = styled.div<StateZipWrapperProps>`
  display: flex;
  align-items: flex-start;
  flex-direction: ${({ twoColumn }) => (twoColumn ? 'row' : 'column')};

  .gap {
    width: 8px;
  }

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

export const ProfileImage = styled.img<ProfileImageProps>`
  width: ${({ width }) => width || '105px'};
  border-radius: 50%;
  border: 2px solid #85abee;
  background-color: #85abee;
  z-index: 100;
  ${props =>
    props.absolute &&
    css`
      position: absolute;
      top: 5px;
    `}
`;
