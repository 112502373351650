import React, { useContext, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { isNutritionOffering } from 'utils/common';
import { RolloutContext } from 'contexts/rollout';
import { CardHeader, Card, CardInner, NoActiveSubscriptions, ButtonsHolder } from '../ProfileInfo/styled';

const InsuranceMedication = ({ insurance, updateView, isNotSupportInsurancePayment = false }) => {
  const isInvalidInsurance = isEmpty(insurance);
  const updateInsurance = () => updateView('insurance_medication');
  const offeringKey = useSelector(store => store.patient_reducer?.offering_key);
  const { flags, loadFlags } = useContext(RolloutContext);

  useEffect(() => {
    if (flags && !Object.keys(flags).length) {
      loadFlags();
    }
  }, []);
  return (
    <Card>
      <CardInner className="d-flex flex-column card-items-container justify-content-between">
        <div className="flex-shrink-1 flex-grow-1">
          <div className="small-card-title">Pharmacy benefits / Medication Billing</div>
          {isInvalidInsurance ? (
            <>
              <NoActiveSubscriptions>
                No pharmacy benefits/insurance information saved. Defaulting to out-of-pocket.
              </NoActiveSubscriptions>
            </>
          ) : (
            <>
              {insurance.policy_id && <CardHeader>{`Membership ID: ${insurance.policy_id}`}</CardHeader>}
              {insurance.rx_bin && <CardHeader>{`Rx BIN: ${insurance.rx_bin}`}</CardHeader>}
              {insurance.group_number && <CardHeader>{`Group/Plan ID: ${insurance.group_number}`}</CardHeader>}
            </>
          )}
        </div>
        {(!isNotSupportInsurancePayment ||
          (isNutritionOffering(offeringKey) && flags?.nutrition_glp1_insurance_toggle)) && (
          <ButtonsHolder marginTop="50px">
            <div className="small-card-btn" onClick={updateInsurance}>
              EDIT
            </div>
          </ButtonsHolder>
        )}
      </CardInner>
    </Card>
  );
};

export default InsuranceMedication;
