import {
  UPDATE_PHOTOS_DIRECT,
  UPDATE_PHOTOS,
  UPDATE_STEP,
  CLOSE_MODALS,
  BEGIN_SUBMIT,
  SUBMISSION_COMPLETE,
  SET_ERROR,
  SET_INELIGIBLE,
  UPDATE_ADDRESS,
  INITIALIZE_ADDRESS,
  UPDATE_INSURANCE,
  UPDATE_MEDICATION,
  SET_CONFIRM,
  UPDATE_SECOND_PHOTOS,
  UPDATE_MEDICARE_CHECKBOX,
  SET_INSURANCE_COVER_WARNING_MODAL
} from './actions';
import { formatDob } from '../../../utils/common';

export const modals = {
  ERROR_MODAL: 'ERROR_MODAl',
  NOT_ELIGIBLE_MODAL: 'NOT_ELIGIBLE_MODAL',
  ELIGIBLE_MODAL: 'ELIGIBLE_MODAL',
  LOADING_MODAL: 'LOADING_MODAL',
  CONFIRM_MODAL: 'CONFIRM_MODAL',
  CONFIRM_SAVE_PHOTO: 'CONFIRM_SAVE_PHOTO',
  SET_INSURANCE_COVER_WARNING_MODAL: 'SET_INSURANCE_COVER_WARNING_MODAL'
};

export const initialState = {
  currentStepIndex: 0,
  photos: [],
  hasNewPhotos: false,
  secondPhotos: [],
  hasNewSecondPhotos: false,
  medicareCheckbox: false,
  data: {},
  modalMessage: '',
  modal: null,
  address: {},
  insurance: {},
  medication: {},
  user: {},
  ineligibleInsurance: {},
  photoComplete: true
};

export default (state, action) => {
  switch (action.type) {
    case UPDATE_PHOTOS:
      return {
        ...state,
        photos: action.photos || [],
        hasNewPhotos: Boolean(action.photos) && JSON.stringify(state.photos) !== JSON.stringify(action.photos),
        currentStepIndex: state.currentStepIndex + 1
      };
    case UPDATE_SECOND_PHOTOS:
      return {
        ...state,
        secondPhotos: action.secondPhotos || [],
        hasNewSecondPhotos:
          Boolean(action.secondPhotos) && JSON.stringify(state.secondPhotos) !== JSON.stringify(action.secondPhotos),
        currentStepIndex: state.currentStepIndex + 1
      };
    case UPDATE_INSURANCE:
      return {
        ...state,
        insurance: {
          ...action.data,
          dob: formatDob(action.data.dob, 'dashes')
        },
        currentStepIndex: state.currentStepIndex + 1
      };
    case UPDATE_MEDICARE_CHECKBOX:
      return {
        ...state,
        medicareCheckbox: action.data
      };
    case UPDATE_MEDICATION:
      return {
        ...state,
        medication: action.data,
        currentStepIndex: state.currentStepIndex + 1
      };
    case UPDATE_ADDRESS:
      return {
        ...state,
        address: action.address,
        currentStepIndex: state.currentStepIndex + 1
      };
    case INITIALIZE_ADDRESS:
      return {
        ...state,
        address: action.address
      };
    case UPDATE_STEP:
      return {
        ...state,
        currentStepIndex: action.step
      };
    case BEGIN_SUBMIT:
      return {
        ...state,
        modal: modals.LOADING_MODAL,
        modalMessage: action.modalMessage
      };

    case UPDATE_PHOTOS_DIRECT:
      return {
        ...state,
        modal: modals.CONFIRM_SAVE_PHOTO,
        photoComplete: false,
        modalMessage: action.modalMessage
      };

    case SUBMISSION_COMPLETE:
      return {
        ...state,
        modalMessage: action.modalMessage,
        modal: modals.ELIGIBLE_MODAL
      };
    case SET_CONFIRM:
      return {
        ...state,
        modal: modals.CONFIRM_MODAL,
        modalActions: {
          ...action.payload
        }
      };
    case SET_INSURANCE_COVER_WARNING_MODAL:
      return {
        ...state,
        modal: modals.SET_INSURANCE_COVER_WARNING_MODAL,
        modalActions: {
          ...action.payload
        }
      };
    case SET_INELIGIBLE:
      return {
        ...state,
        modal: modals.NOT_ELIGIBLE_MODAL,
        ineligibleInsurance: action.insurance
      };
    case SET_ERROR:
      return {
        ...state,
        modalMessage: action.error,
        modal: modals.ERROR_MODAL
      };
    case CLOSE_MODALS:
      return {
        ...state,
        modalMessage: '',
        modal: null
      };
    default:
      return state;
  }
};
