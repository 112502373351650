import { getVariantValue } from '../utils';

const testPriceSensitivity = () => {
  let valueKameleoon: string | undefined = 'Reference';
  const experimentName = 'Test Price Sensitivity Coupon Price';

  valueKameleoon = getVariantValue(experimentName);
  return ['Excluded', 'Disabled'].includes(valueKameleoon) ? 'Reference' : valueKameleoon;
};

export { testPriceSensitivity };
