import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import * as app_types from '../actions';
import * as user_auth_types from '../actions/user_auth_action';
import * as patient_types from '../actions/patient_action';
import { patient_reducer } from './patient_reducer';
import { messenger_reducer } from './messenger_reducer';
import { therapist_reducer } from './therapist_reducer';
import { exercise_reducer } from './exercise_reducer';
import { api_middleware } from '../middle/api';
import questions_reducer from './questions_reducer';
import { account_reducer } from './account_reducer';

// https://redux.js.org/basics/reducers#designing-the-state-shape
const init_global_state = {
  current_user: {
    user_type: null,
    saved_url: null,
    is_loading: false,
    is_signedIn: false,
    attributes: {
      id: null,
      uid: null,
      email: null,
      first_name: null,
      last_name: null,
      patient: null,
      therapist: null,
      nutritionist: null,
      'access-token': null,
      client: null,
      'jwt-token': null
    }
  },
  app_state: '',
  env: ''
};

// global state storage, it will have user account information and current global state
// https://redux.js.org/basics/reducers#handling-actions
export const global_reducer = handleActions(
  {
    [user_auth_types.SAVE_URL]: (state, action) => {
      return {
        ...state,
        saved_url: action.url
      };
    },
    [user_auth_types.UPDATE_USER_INFO]: (state, action) => {
      return {
        ...state,
        current_user: {
          ...state.current_user,
          attributes: {
            ...state.current_user.attributes,
            ...action.user_info
          }
        }
      };
    },
    [user_auth_types.SET_USER]: (state, action) => {
      return {
        // https://redux.js.org/recipes/using-object-spread-operator
        ...state,
        // app_state:'',
        current_user: {
          ...state.current_user,
          // user_type: '',
          is_loading: true,
          is_signedIn: true,
          attributes: action.user_attr
        }
      };
    },
    [app_types.SET_APP_STATE]: (state, action) => {
      return {
        ...state,
        app_state: action.new_app_state
      };
    },
    [app_types.SET_ENV]: (state, action) => {
      return {
        ...state,
        env: action.env
      };
    },
    [user_auth_types.REMOVE_TOKEN]: state => {
      return {
        ...state,
        current_user: {
          ...state.current_user,
          is_signedIn: false,
          attributes: {
            ...state.current_user.attributes,
            'access-token': null,
            client: null,
            'jwt-token': null
          }
        }
      };
    },
    [user_auth_types.SET_PATIENT]: (state, action) => {
      return {
        ...state,
        current_user: {
          ...state.current_user,
          attributes: {
            ...state.current_user.attributes,
            patient: action.patient
          }
        }
      };
    },
    [patient_types.SET_COMMUNICATION_PREFERENCES]: (state, action) => {
      return {
        ...state,
        current_user: {
          ...state.current_user,
          attributes: {
            ...state.current_user.attributes,
            patient: {
              ...state.current_user.attributes.patient,
              communication_preference: action.preference
            }
          }
        }
      };
    },
    [patient_types.SET_PRESCRIPTION_DELIVERY_METHOD]: (state, action) => {
      return {
        ...state,
        current_user: {
          ...state.current_user,
          attributes: {
            ...state.current_user.attributes,
            patient: {
              ...state.current_user.attributes.patient,
              prescription_delivery_method: action.prescriptionDeliveryMethod
            }
          }
        }
      };
    },
    [patient_types.SET_CONTROLLED_SUBSTANCE_REQUESTED]: (state, action) => {
      return {
        ...state,
        current_user: {
          ...state.current_user,
          attributes: {
            ...state.current_user.attributes,
            patient: {
              ...state.current_user.attributes.patient,
              controlled_substance_requested: action.value
            }
          }
        }
      };
    },
    [patient_types.SET_PATIENT_STATE]: (state, action) => {
      const { patient_state } = action;
      return {
        ...state,
        current_user: {
          ...state.current_user,
          attributes: {
            ...state.current_user.attributes,
            ...patient_state,
            patient: { ...state.current_user.attributes.patient, ...patient_state }
          }
        }
      };
    },
    [patient_types.CANCEL_SUBSCRIPTION_FINISHED]: state => {
      return {
        ...state,
        current_user: {
          ...state.current_user,
          attributes: {
            ...state.current_user.attributes,
            patient: {
              ...state.current_user.attributes.patient,
              status: 'canceled',
              external_id: null,
              last_notified_from_health_coach_at: null,
              care_manager_has_unseen_messages: false,
              notification_flags: null,
              completed_payment_at: null,
              initial_assessment_completed_at: null,
              provider_appointment_scheduled_at: null,
              truepill_order_last_submitted_at: null,
              first_cm_visit_scheduled_at: null,
              first_truepill_order_delivered_at: null,
              subscription_end_date: null
            }
          }
        }
      };
    },
    [patient_types.SET_IS_PAYMENT_SUBMITTED]: (state, action) => {
      const now = new Date().toISOString();
      const { is_insurance } = action;
      const communication_preference = is_insurance ? 'video' : null;
      const insurance_status = is_insurance ? 'active' : 'prefers_cash_option';

      return {
        ...state,
        current_user: {
          ...state.current_user,
          attributes: {
            ...state.current_user.attributes,
            patient: {
              ...state.current_user.attributes.patient,
              completed_payment_at: now,
              status: 'paid_but_incomplete',
              insurance_status,
              communication_preference
            }
          }
        }
      };
    },
    [patient_types.SET_VISIT_IS_COMPLETE]: (state, action) => {
      const { initial_assessment_completed_at } = state.current_user.attributes.patient;
      const { visitType } = action;

      if (initial_assessment_completed_at) {
        return {
          ...state
        };
      }

      let status = 'active_client';
      if (['phone', 'video'].includes(visitType)) {
        status = 'active_client_without_provider';
      }

      const now = new Date().toISOString();
      return {
        ...state,
        current_user: {
          ...state.current_user,
          attributes: {
            ...state.current_user.attributes,
            patient: {
              ...state.current_user.attributes.patient,
              initial_assessment_completed_at: now,
              status
            }
          }
        }
      };
    },
    [patient_types.SET_PATIENT_STATUS]: (state, action) => {
      return {
        ...state,
        current_user: {
          ...state.current_user,
          attributes: {
            ...state.current_user.attributes,
            patient: {
              ...state.current_user.attributes.patient,
              status: action.status
            }
          }
        }
      };
    },
    [patient_types.SET_PLAN]: (state, action) => {
      const {
        plan: { offering_key }
      } = action;

      return {
        ...state,
        current_user: {
          ...state.current_user,
          attributes: {
            ...state.current_user.attributes,
            patient: {
              ...state.current_user.attributes.patient,
              offering_key
            }
          }
        }
      };
    },
    [user_auth_types.SET_THERAPIST]: (state, action) => {
      return {
        ...state,
        current_user: {
          ...state.current_user,
          attributes: {
            ...state.current_user.attributes,
            therapist: action.therapist
          }
        }
      };
    },
    [user_auth_types.SET_NUTRITIONIST]: (state, action) => {
      return {
        ...state,
        current_user: {
          ...state.current_user,
          attributes: {
            ...state.current_user.attributes,
            nutritionist: action.nutritionist
          }
        }
      };
    },
    [user_auth_types.SET_PHONE]: (state, { phone }) => {
      return {
        ...state,
        current_user: {
          ...state.current_user,
          attributes: {
            ...state.current_user.attributes,
            phone
          }
        }
      };
    },
    [patient_types.SET_INSURANCE_STATUS]: (state, { status }) => {
      return {
        ...state,
        current_user: {
          ...state.current_user,
          attributes: {
            ...state.current_user.attributes,
            patient: {
              ...state.current_user.attributes.patient,
              insurance_status: status
            }
          }
        }
      };
    },

    [patient_types.SET_TERMS_VERSION_UPDATED]: (state, { status }) => {
      return {
        ...state,
        current_user: {
          ...state.current_user,
          attributes: {
            ...state.current_user.attributes,
            patient: {
              ...state.current_user.attributes.patient,
              tac_update_needed: status
            }
          }
        }
      };
    }
  },
  init_global_state
);

const appReducer = combineReducers({
  patient_reducer,
  global_reducer,
  therapist_reducer,
  api_middleware,
  messenger_reducer,
  exercise_reducer,
  questions_reducer,
  account_reducer
});

const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === 'RESET') {
    storage.removeItem('persist:root');
    newState = undefined;
  }

  return appReducer(newState, action);
};

export default rootReducer;
