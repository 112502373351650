import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

const ProgressModal = ({ open }) => (
  <Modal className="loading-modal" show={open} onHide={() => {}}>
    <Modal.Body className="loading-modal-body">
      <div className="spinner-border loading-icon-color" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </Modal.Body>
  </Modal>
);

ProgressModal.propTypes = {
  open: PropTypes.bool.isRequired
};

export default ProgressModal;
