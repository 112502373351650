const EXCHANGE_RATE = 1.4;

const valueFromStorage = (key: string) => localStorage.getItem(key) || null;

const countryFromUrl = (host: string): string => {
  const regex = /[^.]+$/gm;
  const domainExtensionArray = host.match(regex);
  let domainExtension = '';

  if (domainExtensionArray != null) {
    [domainExtension] = domainExtensionArray;
  }

  if (domainExtension === 'uk') {
    localStorage.setItem('country', 'uk');
    return 'uk';
  }
  localStorage.setItem('country', 'us');
  return 'us';
};

const countryFromWindowLocation = (): string => countryFromUrl(window.location.host);

const countryFromStorage = () => valueFromStorage('country');

const defaultCountry = () => {
  return countryFromStorage() || countryFromWindowLocation();
};

const convertAmountFromDollartoPoundSterling = (amount: number): number => {
  return Math.round((amount / EXCHANGE_RATE) * 100) / 100;
};

export const formatCurrency = (amount: number): string => {
  const currentCountry = defaultCountry();

  if (currentCountry === 'uk') {
    return `£${convertAmountFromDollartoPoundSterling(amount)}`;
  }

  return `$${amount}`;
};

export const currentCurrency = (): string => {
  const currentCountry = defaultCountry();

  if (currentCountry === 'uk') {
    return 'GBP';
  }

  return 'USD';
};

export { countryFromWindowLocation, convertAmountFromDollartoPoundSterling, countryFromUrl };
