import axios from 'axios';
import { batch } from 'react-redux';
import { triggerEventKameleoon } from 'utils/kameleoon/utils';
import { get_current_patient, set_question_banks, set_visit } from '../patient_action';
import * as global_actions from '../user_auth_action';
import { isUserCanceled } from '../../utils/common';

interface Patient {
  id: number;
  status: string;
}
interface Treatment {
  [key: string]: string | number;
}

const { get_user_attr, make_headers } = global_actions;

type Props = {
  service_line_ids: string;
  other_service_line_response?: string;
};

const create_visit =
  ({ service_line_ids, other_service_line_response = undefined }: Props) =>
  (dispatch: <T>(action: unknown) => T, getState: () => void) => {
    const user_attr = get_user_attr(getState());

    const patient = dispatch<Patient>(get_current_patient());
    const body = {
      patient_id: patient.id,
      visit: {
        patient_id: patient.id,
        service_line_ids,
        other_service_line_response,
        is_reactivation: false
      }
    };

    if (isUserCanceled(patient.status)) {
      body.visit.is_reactivation = true;
    }

    return axios.post(`/api/patients/${patient.id}/visits`, body, { headers: make_headers(user_attr) }).then(resp => {
      batch(() => {
        dispatch(set_visit(resp.data));
        dispatch(set_question_banks({}, [], 0));
      });

      if (
        resp.data &&
        resp.data.service_lines &&
        resp.data.service_lines.find((treatment: Treatment) => treatment.name === 'adhd')
      ) {
        window.localStorage.setItem('isADHD', 'true');
      } else {
        window.localStorage.setItem('isADHD', 'false');
      }

      // Trigger event state for Kameleoon
      triggerEventKameleoon(`State-${resp?.data?.data?.patient?.region}`);

      return Promise.resolve(resp.data);
    });
  };

export default create_visit;
