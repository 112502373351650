import React from 'react';
import creditCardType from 'credit-card-type';
import styled from 'styled-components/macro';
import InputMask from 'react-input-mask';
import { verifyLuhn, getCardTypeAndMaxLength, cvvLengthByType } from '../../../utils/common';
import { CardNumberInput, CardNameInput, CvcInput, ZipInput, DateInput, CardExtra, Wrapper } from './styled';

type PaymentFormProps = {
  values: AcceptData['cardData'] & { date: string; postal_code: string };
  updateField: (event: React.ChangeEvent<HTMLInputElement>, text: string) => void;
  className: string;
  isReactivate: boolean;
};

class PaymentForm extends React.Component<PaymentFormProps> {
  cardNumberInput: React.RefObject<HTMLInputElement>;

  dateInput: React.RefObject<HTMLInputElement>;

  cardCodeInput: React.RefObject<HTMLInputElement>;

  zipCodeInput: React.RefObject<HTMLInputElement>;

  constructor(props: PaymentFormProps) {
    super(props);
    this.cardNumberInput = React.createRef();
    this.dateInput = React.createRef();
    this.cardCodeInput = React.createRef();
    this.zipCodeInput = React.createRef();
  }

  componentDidUpdate(prevProps: PaymentFormProps) {
    const { values } = this.props;
    if (prevProps.values.cardNumber.length !== values.cardNumber.length) {
      const onlyDigits = values.cardNumber.replace(/\D/g, '');
      if (creditCardType(onlyDigits)[0]) {
        if (creditCardType(onlyDigits)[0].lengths.some(item => item === onlyDigits.length) && verifyLuhn(onlyDigits)) {
          this.dateInput?.current?.focus();
        }
      } else if (verifyLuhn(onlyDigits)) {
        this.dateInput?.current?.focus();
      }
    }

    if (prevProps.values.date !== values.date && !Number.isNaN(+values.date[4])) {
      this.cardCodeInput?.current?.focus();
    }

    const [type] = getCardTypeAndMaxLength(values.cardNumber);
    const cvvLength = cvvLengthByType(type);
    if (prevProps.values.cardCode.length !== values.cardCode.length && values.cardCode.length >= cvvLength) {
      this.zipCodeInput?.current?.focus();
    }
  }

  render() {
    const { updateField, values, className, isReactivate } = this.props;
    return (
      <Wrapper className={className}>
        <CardNameInput
          type="text"
          placeholder="Name on card"
          name="fullName"
          id="fullName"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateField(e, 'fullName')}
          autoComplete="cc-name"
          required
          isReactivate={isReactivate}
        />
        <CardNumberInput
          type="text"
          name="cardnumber"
          inputMode="numeric"
          pattern="[0-9]*"
          value={values.cardNumber}
          ref={this.cardNumberInput}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateField(e, 'cardNumber')}
          placeholder="Credit card number"
          autoComplete="cc-number"
          isReactivate={isReactivate}
        />
        <CardExtra>
          <InputMask mask="99/99" onChange={e => updateField(e, 'date')} value={values.date} placeholder="MM/YY">
            <DateInput
              inputMode="numeric"
              pattern="[0-9]*"
              autoComplete="cc-exp-date"
              ref={this.dateInput}
              isReactivate={isReactivate}
            />
          </InputMask>
          <CvcInput
            type="text"
            name="cvc"
            inputMode="numeric"
            pattern="[0-9]*"
            value={values.cardCode}
            ref={this.cardCodeInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateField(e, 'cardCode')}
            placeholder="CVC"
            autoComplete="cc-csc"
            isReactivate={isReactivate}
          />
          <ZipInput
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            value={values.postal_code}
            ref={this.zipCodeInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateField(e, 'postal_code')}
            placeholder="ZIP"
            isReactivate={isReactivate}
          />
        </CardExtra>
      </Wrapper>
    );
  }
}

export default styled(PaymentForm)``;
