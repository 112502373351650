import { PriceModel } from './types';

const createPricing = (priceMonth: number, priceMonthDiscount: number): PriceModel => ({
  priceMonth,
  priceMonthDiscount,
  priceWeek: Math.ceil(priceMonth / 4.33),
  priceWeekDiscount: Math.ceil(priceMonthDiscount / 4.33)
});

export default createPricing;
