import styled from 'styled-components/macro';

export const Separator = styled.li`
  position: relative;
  height: 30px;
  z-index: 2;
  background: #fcfbfe;
  text-align: center;
  margin-bottom: 10px;
  @media (max-width: 991px) {
    margin-bottom: 0;
  }
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    border-top: 1px solid #e5e7ef;
    top: 13px;
  }
  &:before {
    left: 0;
    right: 50%;
  }
  &:after {
    right: 0;
    left: 50%;
  }
`;

export const SeparatorValue = styled.span`
  display: inline-block;
  background: #fcfbfe;
  font-size: 11px;
  line-height: 12px;
  padding: 10px 15px;
  color: #a3a3a3;
  position: relative;
  z-index: 3;
`;

export const StickySeparator = styled.li`
  text-align: center;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  margin-bottom: -30px;
  height: 30px;
  border-radius: 15px;
  @media (max-width: 991px) {
    width: auto;
    transform: none;
    border-radius: 0;
  }
`;

export const StickySeparatorValue = styled(SeparatorValue)`
  width: 90px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #e5e7ef;
  border-radius: 20px;
  z-index: 1;
  padding: 4px 15px;
`;
