import React from 'react';
import styled from 'styled-components/macro';

interface LabelProps {
  disabled?: boolean;
}
export const LabelWrapper = styled.label<LabelProps>`
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  cursor: ${props => (props.disabled ? 'auto !important' : 'pointer')};
`;

interface CustomCheckBoxProps {
  event_handler(): void;
  isChecked: boolean;
  name: string;
  disabled: boolean;
}

const CustomCheckBox: React.FC<CustomCheckBoxProps> = ({ event_handler, isChecked, name, disabled }) => (
  <LabelWrapper className="checkbox-container" htmlFor={name} disabled={disabled}>
    <input type="checkbox" onChange={event_handler} id={name} name={name} checked={isChecked} disabled={disabled} />
    <span className="checkbox-checkmark">
      <img src={`${process.env.PUBLIC_URL}/img/check-dark.svg`} alt="" />
    </span>
  </LabelWrapper>
);

export default CustomCheckBox;
