const getVariantValue = (experimentName: string) => {
  let variationIdResult = 'Reference';

  if (window.Kameleoon && experimentName) {
    // Make sure Kameleoon is loaded and active at this point
    const experiment = window.Kameleoon.API.Experiments.getByName(experimentName);
    if (!experiment) {
      variationIdResult = 'Disabled';
    } else if (experiment.associatedVariation.name === 'Excluded') {
      // The experiment is not currently live (not launched yet, or paused). The default behavior should take place
      variationIdResult = 'Excluded';
    } else {
      variationIdResult = experiment.associatedVariation.name;
    }
  }
  return variationIdResult || 'Reference';
};

const triggerEventKameleoon = (eventName: string) => {
  window && window.Kameleoon && window.Kameleoon.API.Events.trigger(eventName);
};

const getKameleoonByExperimentName = (experimentName: string) => {
  let valueKameleoon: string | undefined = 'Reference';

  if (experimentName) {
    valueKameleoon = getVariantValue(experimentName);
  }
  return valueKameleoon;
};

export { getVariantValue, triggerEventKameleoon, getKameleoonByExperimentName };
