import { prescriberVariant } from 'utils/care-person-helpers';
import strings from '../localization';

/**
BE FLOW UPDATE: remove this file
*/
const visitWithProvider = {
  id: 123, // need to be changed if moved to BE
  name: 'visit_with_provider',
  title: `${prescriberVariant.singularCapitalize} Visit`,
  last_answered_question: null,
  num_questions: 4
};

const survey = {
  name: 'survey',
  last_answered_question: null,
  num_questions: 1
};

const chooseTherapist = {
  id: 234,
  name: 'choose_therapist',
  title: 'Choose your Therapist',
  last_answered_question: null,
  num_questions: 1
};

const chooseCounselor = {
  id: 23411,
  name: 'choose_counselor',
  title: 'Choose your Care Counselor',
  last_answered_question: null,
  num_questions: 1
};

const progressEmotionalAssessment = {
  id: 456,
  name: 'progress_emotional',
  title: 'Progress',
  last_answered_question: null,
  num_questions: 1
};

const progressCheckout = {
  id: 456,
  name: 'progress_checkout',
  title: 'Progress',
  last_answered_question: null,
  num_questions: 1
};

const progressHealthAssessment = {
  id: 456,
  name: 'progress_assessment',
  title: 'Progress',
  last_answered_question: null,
  num_questions: 1
};

const progressIdenttification = {
  id: 457,
  name: 'progress_identity',
  title: 'Progress',
  last_answered_question: null,
  num_questions: 1
};

const progressProviderVisit = {
  id: 45711,
  name: 'progress_provider_visit',
  title: 'Progress',
  last_answered_question: null,
  num_questions: 1
};

const informationScreening = {
  id: 566,
  name: 'information_screening',
  title: 'Information',
  last_answered_question: null,
  num_questions: 3
};

const informationTherapistPreferences = {
  id: 567,
  name: 'information_therapist_preferences',
  title: 'Information',
  last_answered_question: null,
  num_questions: 1
};

const informationCounselorPreferences = {
  id: 56711,
  name: 'information_counselor_preferences',
  title: 'Information',
  last_answered_question: null,
  num_questions: 1
};

const informationTherapists = {
  id: 568,
  name: 'information_therapists',
  title: 'Information',
  last_answered_question: null,
  num_questions: 1
};

const informationCounselor = {
  id: 56811,
  name: 'information_counselor',
  title: 'Information',
  last_answered_question: null,
  num_questions: 1
};

const informationMedicalAssessment = {
  id: 569,
  name: 'information_assessment',
  title: 'Information',
  last_answered_question: null,
  num_questions: 2
};

const informationIdentification = {
  id: 570,
  name: 'information_identity',
  title: 'Information',
  last_answered_question: null,
  num_questions: 1
};

const informationFollowUp = {
  id: 572,
  name: 'information_follow-up',
  title: 'Information',
  last_answered_question: null,
  num_questions: 2
};

const visitType = {
  id: 571,
  name: 'visit_type',
  title: 'Visit Type',
  last_answered_question: null,
  num_questions: 2
};

const surveyQuestions = [
  {
    id: 80987,
    name: 'select_source',
    title: 'How did you hear about us?',
    question_type: 'source_selector'
  }
];

const visitTypeQuestions = [
  {
    id: 5677,
    name: 'immediate',
    title: `Do you want to meet with a Cerebral ${prescriberVariant.singularNormal} now?`,
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: `If you want to meet now, we will pair you with the next available ${prescriberVariant.singularNormal}. This shouldn&apos;t take longer than 20 minutes.`,
    question_type: 'visit_information',
    options: [],
    flag_title: null,
    optional: null,
    save_answer: true
  },
  {
    id: 5667,
    name: 'schedule',
    title: '',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'information',
    options: [{ title: `Please schedule your initial visit with a Cerebral ${prescriberVariant.singularNormal}.` }],
    flag_title: null,
    optional: null,
    save_answer: true
  }
];

const informationFollowUpQuestions = [
  {
    id: 5662,
    name: 'follow-up1',
    title: '',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'information',
    options: [
      {
        title:
          'Your follow-up visit will consist of questions designed to track your progress since starting treatment.'
      }
    ],
    flag_title: null,
    optional: null,
    save_answer: true
  },
  {
    id: 5663,
    name: 'follow-up2',
    title: '',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'information',
    options: [{ title: 'This will only take a minute. Please answer as honestly as possible.' }],
    flag_title: null,
    optional: null,
    save_answer: true
  }
];

const informationScreeningQuestions = [
  {
    id: 5661,
    name: 'screening1',
    title: '',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'information',
    options: [
      {
        title: strings.infoScreeningMessage
      }
    ],
    flag_title: null,
    optional: null,
    save_answer: true
  },
  {
    id: 5660,
    name: 'screening2',
    title: '',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'information',
    options: [{ title: 'This will only take a minute. Please answer as honestly as possible.' }],
    flag_title: null,
    optional: null,
    save_answer: true
  },
  {
    id: 5659,
    name: 'screening3',
    title: '',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'information',
    options: [{ title: 'Let’s take your first step to recovery together.' }],
    flag_title: null,
    optional: null,
    save_answer: true
  }
];

const progressIdenttificationQuestions = [
  {
    id: 5676,
    name: 'identity',
    title: 'Next steps:',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'progress',
    options: [],
    flag_title: null,
    optional: null,
    save_answer: true,
    question_bank: {
      id: 457,
      name: 'progress_identity',
      title: 'Progress'
    }
  }
];

const informationIdentificationQuestions = [
  {
    id: 5675,
    name: 'identity1',
    title: '',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'information',
    options: [
      { title: 'We’re almost there! We just need to verify your identity using your ID.\n\n*Required by law.' }
    ],
    flag_title: null,
    optional: null,
    save_answer: true
  }
];

const progressHealthAssessmentQuestions = [
  {
    id: 5674,
    name: 'assessment',
    title: 'Next steps:',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'progress',
    options: [],
    flag_title: null,
    optional: null,
    save_answer: true,
    question_bank: {
      id: 456,
      name: 'progress_assessment',
      title: 'Progress'
    }
  }
];

const informationMedicalAssessmentQuestions = [
  {
    id: 5673,
    name: 'assessment',
    title: '',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'information',
    options: [
      {
        title: `Your answers to the following questions will give your ${prescriberVariant.singularNormal} a better understanding of the treatment plan that’ll best suit your needs. Please answer as accurately as possible.`
      }
    ],
    flag_title: null,
    optional: null,
    save_answer: true
  }
];

const informationTherapistsQuestions = [
  {
    id: 5672,
    name: 'therapists',
    title: '',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'information',
    options: [
      {
        title:
          'We have identified Therapists that closely match your needs. Please schedule your initial visit with the Therapist of your choice.'
      }
    ],
    flag_title: null,
    optional: null,
    save_answer: true
  }
];

const informationCounselorQuestions = [
  {
    id: 5672,
    name: 'counselor',
    title: '',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'information',
    options: [
      {
        title:
          'We have identified Care Counselors that closely match your needs. Please schedule your initial visit with the Care Counselor of your choice.'
      }
    ],
    flag_title: null,
    optional: null,
    save_answer: true
  }
];

const informationTherapistPreferencesQuestions = [
  {
    id: 5671,
    name: 'preferences',
    title: '',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'information',
    options: [
      {
        title:
          'Based on your answers to the following questions, we’ll provide a selection of recommended Therapists for you to choose from.'
      }
    ],
    flag_title: null,
    optional: null,
    save_answer: true
  }
];

const progressCheckoutQuestions = [
  {
    id: 5670,
    name: 'checkout',
    title: 'Next steps:',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'progress',
    options: [],
    flag_title: null,
    optional: null,
    save_answer: true,
    question_bank: {
      id: 456,
      name: 'progress_checkout',
      title: 'Progress'
    }
  }
];

const chooseTherapistQuestions = [
  {
    id: 5678,
    name: 'therapist_selector',
    title: '',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'schedule',
    options: [],
    flag_title: null,
    optional: null,
    save_answer: true
  }
];

// CARE CAOUNSELOR PICKER START
const progressProviderVisitQuestions = [
  {
    id: 56761,
    name: 'provider_visit',
    title: 'Next steps:',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'progress',
    options: [],
    flag_title: null,
    optional: null,
    save_answer: true,
    question_bank: {
      id: 45711,
      name: 'progress_provider_visit',
      title: 'Progress'
    }
  }
];

const informationCounselorPreferencesQuestions = [
  {
    id: 56711,
    name: 'preferences',
    title: '',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'information',
    options: [
      {
        title:
          'Based on your answers to the following questions, we’ll provide a selection of recommended Care Counselors for you to choose from.'
      }
    ],
    flag_title: null,
    optional: null,
    save_answer: true
  }
];

const chooseCounselorQuestions = [
  {
    id: 56781,
    name: 'counselor_selector',
    title: '',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'schedule',
    options: [],
    flag_title: null,
    optional: null,
    save_answer: true
  }
];
// CARE COUNSELOR PICKER FINISH

const choosingProvidersQuestions = [
  {
    id: 3456, // need to update when push to BE
    name: 'immediate_video',
    title: '',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'immediate_video',
    options: [],
    flag_title: null,
    optional: null,
    save_answer: true
  },
  {
    id: 3456, // need to update when push to BE
    name: 'immediate_phone',
    title: '',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'immediate_phone',
    options: [],
    flag_title: null,
    optional: null,
    save_answer: true
  },
  {
    id: 1234, // need to update when push to BE
    name: 'provider_selector',
    title: '',
    description: null,
    assessment_type: null,
    skip_option: null,
    subscript: null,
    question_type: 'schedule',
    options: [],
    flag_title: null,
    optional: null,
    save_answer: true
  }
];

export const getInternalQuestionBanks = () => {
  return [
    visitWithProvider,
    chooseTherapist,
    chooseCounselor,
    progressEmotionalAssessment,
    progressCheckout,
    progressHealthAssessment,
    progressIdenttification,
    progressProviderVisit,
    informationScreening,
    informationTherapistPreferences,
    informationCounselorPreferences,
    informationTherapists,
    informationCounselor,
    informationMedicalAssessment,
    informationIdentification,
    informationFollowUp,
    visitType,
    survey
  ];
};

export const getQuestionsForInternalBanks = name => {
  switch (name) {
    case 'survey':
      return surveyQuestions;
    case 'visit_with_provider':
      return choosingProvidersQuestions;
    case 'progress_checkout':
      return progressCheckoutQuestions;
    case 'choose_therapist':
      return chooseTherapistQuestions;
    case 'information_therapist_preferences':
      return informationTherapistPreferencesQuestions;
    case 'information_therapists':
      return informationTherapistsQuestions;
    case 'information_counselor':
      return informationCounselorQuestions;
    case 'information_assessment':
      return informationMedicalAssessmentQuestions;
    case 'progress_assessment':
      return progressHealthAssessmentQuestions;
    case 'information_identity':
      return informationIdentificationQuestions;
    case 'information_screening':
      return informationScreeningQuestions;
    case 'progress_identity':
      return progressIdenttificationQuestions;
    case 'visit_type':
      return visitTypeQuestions;
    case 'information_follow-up':
      return informationFollowUpQuestions;
    case 'choose_counselor':
      return chooseCounselorQuestions;
    case 'progress_provider_visit':
      return progressProviderVisitQuestions;
    case 'information_counselor_preferences':
      return informationCounselorPreferencesQuestions;
    default:
      return [];
  }
};
