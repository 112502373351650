import React, { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CategoryCard from '../../components/exercises/CategoryCard';
import CategoryList from '../../components/exercises/CategoryList';
import ExerciseFlow from '../../components/exercises/ExerciseFlow';
import ExerciseHistory from '../../components/exercises/ExerciseHistory';
import Spinner, { SpinnerHolder } from '../../components/Spinner';
import { fetchExerciseData, dismissExercise } from '../../actions/exercise_actions';

import { SectionTitle, AssignmentWrapper, NoAssignmentWrapper, HistoryLink, MainContent } from './styled';

const baseUrl = process.env.REACT_APP_API_SERVER_URL;

const ExerciseWrapper = () => {
  const match = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const assignments = useSelector(state => state.exercise_reducer.assignments);
  const completed = useSelector(state => state.exercise_reducer.exercise_history);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      await dispatch(fetchExerciseData());
      setLoading(false);
    };

    loadData();
  }, []);

  const handleDismiss = exercise_patient_id => () => {
    dispatch(dismissExercise(exercise_patient_id));
  };

  const paths = location.pathname.split('/');
  const isRoot = paths[paths.length - 1] === 'patient_exercises';

  return (
    <MainContent>
      {loading && (
        <SpinnerHolder>
          <Spinner size="2.5rem" color="#6786FF" borderWidth="4px" />
        </SpinnerHolder>
      )}
      {!loading && (
        <>
          {isRoot && (
            <>
              <div className="text-main-title">Exercises</div>
              <SectionTitle>Assignments</SectionTitle>
              {assignments.length > 0 ? (
                <AssignmentWrapper>
                  {assignments.map((ass, idx) => (
                    <CategoryCard
                      name={ass.exercise.title}
                      imageUrl={`${baseUrl}${ass.exercise.image_url}`}
                      key={idx}
                      type="assignment"
                      ctaLink={`/patient/dashboard/patient_exercises/exercise_flow/${ass.exercise.id}`}
                      onDismiss={handleDismiss(ass.id)}
                    />
                  ))}
                </AssignmentWrapper>
              ) : (
                <NoAssignmentWrapper>You have no assignments.</NoAssignmentWrapper>
              )}
            </>
          )}

          <Switch>
            <Route path={`${match.url}/all`}>
              <CategoryList showAll />
            </Route>
            <Route path={`${match.url}/exercise_history`}>
              <ExerciseHistory historyData={completed} />
            </Route>
            <Route path={`${match.url}/exercise_flow/:exerciseId`}>
              <ExerciseFlow />
            </Route>
            <Route path={[`${match.url}/:categoryId`, `${match.url}`]}>
              <CategoryList />
            </Route>
          </Switch>

          {isRoot && (
            <HistoryLink to="/patient/dashboard/patient_exercises/exercise_history">Exercise History</HistoryLink>
          )}
        </>
      )}
    </MainContent>
  );
};

export default ExerciseWrapper;
