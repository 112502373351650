import styled from 'styled-components/macro';

export const PharmacyHolder = styled.div`
  margin-bottom: 30px;
`;

export const Holder = styled.div`
  display: flex;
`;

export const Name = styled.div``;

export const Street = styled.div``;
