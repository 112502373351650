import React from 'react';
import noop from 'lodash/noop';
import {
  CardWrapper,
  Img,
  RightWrapper,
  TopicText,
  NameText,
  TitleText,
  DescriptionText,
  ActionWrapper,
  Button,
  LinkButton,
  PDFLink
} from './styled';

const CategoryCard = ({
  name,
  subTitle,
  summary,
  imageUrl,
  ctaLink,
  title,
  type = 'category',
  pdfLink,
  onDismiss = noop,
  isAdhocCard = false
}) => {
  return (
    <CardWrapper className="category-card" isAdhocCard={isAdhocCard}>
      <Img width="150" height="144" src={imageUrl} alt={name} />
      <RightWrapper>
        <div>
          <TopicText>{subTitle}</TopicText>
          <NameText>{name}</NameText>
        </div>
        {title && <TitleText>{title}</TitleText>}
        {summary && <DescriptionText>{summary}</DescriptionText>}
        <ActionWrapper type={type}>
          {type === 'category' && <Button to={ctaLink}>View</Button>}
          {type === 'exercise' && <Button to={ctaLink}>Begin</Button>}
          {type === 'assignment' && (
            <>
              <Button to={ctaLink}>Begin</Button>
              <LinkButton onClick={onDismiss}>Dismiss</LinkButton>
            </>
          )}

          {type === 'completed' && (
            <>
              <Button to={ctaLink}>Repeat</Button>
              <PDFLink href={pdfLink} target="_blank" disabled={!pdfLink}>
                View PDF
              </PDFLink>
            </>
          )}
        </ActionWrapper>
      </RightWrapper>
    </CardWrapper>
  );
};

export default CategoryCard;
