import { formatCurrency } from '../../utils/currency.util';

export const formFlows = {
  INSURANCE_FLOW: 'INSURANCE_FLOW',
  INSURANCE_DIRECT_FLOW: 'INSURANCE_DIRECT_FLOW',
  MEDICATION_FLOW: 'MEDICATION_FLOW',
  SECONDARY_FLOW: 'SECONDARY_FLOW',
  TEST_FLOW: 'TEST_FLOW',
  NUTRITION_FLOW: 'NUTRITION_FLOW'
};

export const formSteps = {
  INSURANCE_PHOTOS: 'INSURANCE_PHOTOS',
  MEDICATION_PHOTOS: 'MEDICATION_PHOTOS',
  NUTRITION_PHOTOS: 'NUTRITION_PHOTOS',
  MEDICATION_FORM: 'MEDICATION_FORM',
  NUTRITION_FORM: 'NUTRITION_FORM',
  INSURANCE_FORM: 'INSURANCE_FORM',
  SECONDARY_INSURANCE: 'SECONDARY_INSURANCE',
  INSURANCE_CONFIRMATION: 'INSURANCE_CONFIRMATION',
  OON_INSURANCE_CONFIRMATION: 'OON_INSURANCE_CONFIRMATION',
  MEDICATION_CONFIRMATION: 'MEDICATION_CONFIRMATION',
  NUTRITION_CONFIRMATION: 'NUTRITION_CONFIRMATION',
  INSURANCE_DIRECT_CONFIRMATION: 'INSURANCE_DIRECT_CONFIRMATION'
};

export const flowSteps = {
  [formFlows.INSURANCE_FLOW]: [formSteps.INSURANCE_FORM, formSteps.INSURANCE_PHOTOS, formSteps.INSURANCE_CONFIRMATION],
  [formFlows.SECONDARY_FLOW]: [formSteps.SECONDARY_INSURANCE, formSteps.INSURANCE_CONFIRMATION],
  [formFlows.MEDICATION_FLOW]: [
    formSteps.MEDICATION_PHOTOS,
    formSteps.MEDICATION_FORM,
    formSteps.MEDICATION_CONFIRMATION
  ],
  [formFlows.INSURANCE_DIRECT_FLOW]: [formSteps.INSURANCE_DIRECT_CONFIRMATION],
  [formFlows.TEST_FLOW]: [
    formSteps.MEDICATION_PHOTOS,
    formSteps.MEDICATION_FORM,
    formSteps.MEDICATION_CONFIRMATION,
    formSteps.INSURANCE_FORM,
    formSteps.INSURANCE_PHOTOS,
    formSteps.INSURANCE_CONFIRMATION
  ],
  [formFlows.NUTRITION_FLOW]: [formSteps.NUTRITION_PHOTOS, formSteps.NUTRITION_FORM, formSteps.NUTRITION_CONFIRMATION]
};

export const getStepIndex = (step: string, flow: string) => flowSteps[flow].indexOf(step);

export const bannerText = {
  [formSteps.SECONDARY_INSURANCE]:
    'Add photos of your insurance card. This will help us verify your information and avoid delays.',
  [formSteps.INSURANCE_PHOTOS]:
    'We recommend uploading photos of your card to ensure proper billing to your insurance, and to avoid any delays in the future.',
  [formSteps.MEDICATION_PHOTOS]:
    'Add photos of your insurance card. This will help us verify your information and avoid delays.',
  [formSteps.NUTRITION_PHOTOS]:
    'This will help us verfiy your information and avoid delays.\nPlease ensure the card you upload includes an RxBIN number.',
  [formSteps.INSURANCE_FORM]: 'We’ll use this information to verify your coverage',
  [formSteps.MEDICATION_FORM]: `We’ll try to bill your insurance for medication.\nRegardless of whether you have insurance or not,  90% of clients pay less than ${formatCurrency(
    15
  )} per prescription per month.`,
  [formSteps.NUTRITION_FORM]: ` We’ll try to bill your insurance for medication.\nRegardless of whether you have insurance or not,  90% of clients pay less than ${formatCurrency(
    15
  )} per prescription per month.`,
  [formSteps.INSURANCE_CONFIRMATION]:
    'Look over your information carefully! We’ll check to see what coverage is available for you',
  [formSteps.OON_INSURANCE_CONFIRMATION]:
    'Most insured members save 30-60% of their subscription cost through out-of-network reimbursement. We’ll submit your claim, and if it qualifies, your insurer will mail you a check each month.',
  [formSteps.MEDICATION_CONFIRMATION]:
    'We’ll try to bill your insurance for medication after you confirm your information.',
  [formSteps.NUTRITION_CONFIRMATION]:
    'We’ll try to bill your insurance for medication after you confirm your information.'
};

export const bannerTextINI = {
  ...bannerText,
  [formSteps.INSURANCE_PHOTOS]: `Please upload images of the front and back of your card.
    Just like when you visit a new doctor, we need to get your insurance information on file. This will ensure accurate billing and help to avoid any delays in treatment!`
};

export const bannerFlowText = {
  [formSteps.NUTRITION_PHOTOS]: 'Please ensure that the card photo uploaded below contains your RxBIN number.',
  [formSteps.NUTRITION_FORM]: `Please ensure that the information below is populated from the card containing your RxBIN number.`,
  [formSteps.NUTRITION_CONFIRMATION]: ''
};

export const headerText = {
  [formSteps.INSURANCE_PHOTOS]: 'Add photos of your insurance card',
  [formSteps.MEDICATION_PHOTOS]: 'Add photos of your pharmacy benefits card',
  [formSteps.NUTRITION_PHOTOS]: 'Add photos of your insurance or pharmacy benefits card',
  [formSteps.SECONDARY_INSURANCE]: 'Add photos of your secondary insurance card',
  [formSteps.INSURANCE_FORM]: 'Enter your insurance details',
  [formSteps.MEDICATION_FORM]: 'Enter your pharmacy benefits card details',
  [formSteps.NUTRITION_FORM]: 'Enter your insurance or pharmacy benefits card details',
  [formSteps.INSURANCE_CONFIRMATION]: 'How does everything look?',
  [formSteps.MEDICATION_CONFIRMATION]: 'How does everything look?',
  [formSteps.NUTRITION_CONFIRMATION]: 'How does everything look?',
  [formSteps.INSURANCE_DIRECT_CONFIRMATION]: 'Add photos of your insurance card to avoid delays.'
};

export const headerTextINI = {
  ...headerText,
  [formSteps.INSURANCE_PHOTOS]: 'Do you have your insurance card with you?'
};

export const flowText = {
  [formSteps.INSURANCE_PHOTOS]: 'Take photos',
  [formSteps.SECONDARY_INSURANCE]: 'Take photos',
  [formSteps.MEDICATION_PHOTOS]: 'Take photos',
  [formSteps.NUTRITION_PHOTOS]: 'Take photos',
  [formSteps.INSURANCE_FORM]: 'Insurance details',
  [formSteps.MEDICATION_FORM]: 'Pharmacy benefits details',
  [formSteps.NUTRITION_FORM]: 'Card details',
  [formSteps.INSURANCE_CONFIRMATION]: 'Confirm',
  [formSteps.INSURANCE_CONFIRMATION]: 'Confirm',
  [formSteps.MEDICATION_CONFIRMATION]: 'Confirm',
  [formSteps.NUTRITION_CONFIRMATION]: 'Confirm'
};

export const descriptionText = {
  [formSteps.INSURANCE_DIRECT_CONFIRMATION]:
    'We recommend uploading photos of your card to ensure proper billing to your insurance, and to avoid any delays in the future.'
};
