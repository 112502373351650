const isProduction = () => {
  return (
    process.env.NODE_ENV === 'production' &&
    !window.location.href.includes('staging') &&
    !window.location.href.includes('dev') &&
    !window.location.href.includes('insurance')
  );
};

/**
 *
 * @param {string} eventName tracking event name
 * @param {string} payload.email email of referred user
 * @param {string} payload.id id of referred user
 * @param {string} payload.name full name of
 */
const friendbuyTracking = (eventName, payload) => {
  if (window.friendbuyAPI && isProduction()) {
    window.friendbuyAPI.push(['track', eventName, payload]);
  }
};

export default friendbuyTracking;
