export const SET_PAID_INVOICE = 'SET_PAID_INVOICE';
export const SET_SELECTED_INVOICE = 'SET_SELECTED_INVOICE';
export const SET_CHECKOUT_RESULT = 'SET_CHECKOUT_RESULT';
export const SET_CHECKOUT_ERROR = 'SET_CHECKOUT_ERROR';

export const setPaidInvoice = invoice => ({
  type: SET_PAID_INVOICE,
  payload: invoice
});

export const setSelectedInvoice = invoice => ({
  type: SET_SELECTED_INVOICE,
  payload: invoice
});

export const setCheckoutResult = result => ({
  type: SET_CHECKOUT_RESULT,
  payload: result
});

export const setCheckoutError = () => ({
  type: SET_CHECKOUT_ERROR
});
