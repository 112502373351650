import React from 'react';
import styled from 'styled-components/macro';
import Footnote from './Footnote';
import strings from '../../localization';

const Holder = styled.div`
  max-width: 400px;
  padding: 20px 14px;
  margin: 0 auto;
`;

interface PaymentText {
  buttonText: string;
}

export const USPaymentTerm: React.FC<PaymentText> = ({ buttonText }) => (
  <>
    By clicking “{buttonText}” you consent to Cerebral’s{' '}
    <a href="https://getcerebral.com/payment-terms" target="_blank" rel="noopener noreferrer">
      payment terms
    </a>{' '}
    &{' '}
    <a href="https://www.getcerebral.com/recurring-billing-policy" target="_blank" rel="noopener noreferrer">
      recurring billing policy
    </a>
    , and you acknowledge the{' '}
    <a href="https://getcerebral.com/cancellation-policy/" target="_blank" rel="noopener noreferrer">
      cancellation policy
    </a>
    .
  </>
);

export const USSignupTerm: React.FC<PaymentText> = ({ buttonText }) => (
  <>
    By clicking “{buttonText}”, you agree to Cerebral’s{' '}
    <a href="https://getcerebral.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
      Terms & Conditions
    </a>{' '}
    , including the mandatory arbitration provision,{' '}
    <a href="https://getcerebral.com/privacy-policy" target="_blank" rel="noopener noreferrer">
      Privacy Policy
    </a>{' '}
    and{' '}
    <a href="https://getcerebral.com/telehealth-informed-consent" target="_blank" rel="noopener noreferrer">
      Telehealth Consent.
    </a>
    .
  </>
);

export const UKTerm: React.FC<PaymentText> = ({ buttonText }) => (
  <>
    By clicking “{buttonText}” you consent to Cerebral’s{' '}
    <a href="https://getcerebral.co.uk/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
      Terms and Conditions of Service
    </a>{' '}
    which includes policies on cancellation, recurring billing and payment terms.
    <br />
    If your GP does decide that medication is appropriate for your condition, this is included in your monthly
    subscription at no extra charge.
  </>
);

interface ImportantInformation {
  removeTopFootnote?: boolean;
  buttonText?: string;
  isPaymentTerms?: boolean;
}

const ImportantInformation: React.FC<ImportantInformation> = ({
  removeTopFootnote,
  buttonText = 'Submit',
  children,
  isPaymentTerms = true
}) => {
  const { PaymentTerms = USPaymentTerm, SignupTerms = USSignupTerm } = strings;

  return (
    <Holder>
      {!removeTopFootnote && (
        <Footnote isNormal>
          {isPaymentTerms ? <PaymentTerms buttonText={buttonText} /> : <SignupTerms buttonText={buttonText} />}
        </Footnote>
      )}
      {children}
    </Holder>
  );
};

export default ImportantInformation;
