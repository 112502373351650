import styled from 'styled-components/macro';

export const ViewPlanDetails = styled.div`
  color: #6786ff;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:nth-child(n + 1) {
    margin-top: 30px;
  }
  &:hover {
    text-decoration: none;
  }
  img,
  svg {
    margin-right: 13px;
  }
`;

export const CardInner = styled.div`
  min-height: 100%;
  .small-card-title {
    padding-left: 18px;
  }
`;

export const ButtonsHolder = styled.div``;

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.43px;
  text-decoration-line: underline;
  color: #6786ff;
`;

export const ReactivateButton = styled.div`
  cursor: pointer;
  width: 240px;
  margin-top: 26px;
  padding: 20px;
  color: #ffffff;
  background-color: #6786ff;
  text-align: center;
  border-radius: 28.5px;
`;

export const NoActiveSubscriptions = styled.div`
  padding-left: 18px;
  color: #6786ff;
  font-style: italic;
  font-size: 14px;
  line-height: 18px;
`;

export const DeactivationReasons = styled.div`
  background: #eff1fa;
  border-radius: 4px;
  font-style: italic;
  font-size: 12px;
  line-height: 13px;
  padding: 20px;
  margin-top: 37px;
  h4 {
    font-weight: bold;
    font-size: 12px;
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
  }
  ul {
    padding: 0 20px;
    margin-top: 15px;
    li {
      margin-top: 20px;
    }
  }
  p:last-child {
    margin-bottom: 0;
  }
`;
