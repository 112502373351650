import React from 'react';
import { NutritionHolder, ResultText } from './styled';
import { getBmiStatus } from '../../BmiResult/bmiHelpers';

export interface NutritionResultProps {
  bmiScore: number;
}

const AssessmentResult: React.FC<NutritionResultProps> = ({ bmiScore }) => {
  const bmiStatus = getBmiStatus(bmiScore);

  return (
    <NutritionHolder>
      <ResultText>
        Your BMI score: {bmiScore} ({bmiStatus}).
      </ResultText>
    </NutritionHolder>
  );
};

export default AssessmentResult;
