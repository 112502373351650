import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import intersaction from 'lodash/intersection';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import styled from 'styled-components/macro';
import Tooltip from 'react-bootstrap/Tooltip';
import { prescriberVariant } from 'utils/care-person-helpers';
import plans, { planPriceModeDetail } from '../../../utils/plans';
import InformationIcon from '../../InformationIcon/InformationIcon';
import { selectOffering } from '../../../utils/selectors';
import { is_touch_device } from '../../../utils/common';
import isUK from '../../../utils/uk.util';
import isoImage from '../../../img/iso.png';
import bulletImage from '../../../img/bullet.svg';
import { mapPlanToCoupon } from './PatientTopText';
import strings from '../../../localization';
import { InitAssessment, InitAssessmentFee } from './styled';
import { testPriceSensitivity } from '../../../utils/kameleoon';
import createPricing from '../../../localization/createPricing';

const CURRENCY = strings.currencySymbol;

export const Header = styled.div`
  background: #6786ff;
  color: #fff;
  text-align: center;
  padding: 36px 0 26px;
`;

export const TotalTooltip = styled(Tooltip)`
  &.bs-tooltip-bottom > .arrow:before {
    bottom: 0;
    left: 50%;
    border: 6px solid transparent;
    border-left-width: 20px;
    border-right-width: 20px;
    border-bottom-color: #eff1fa;
    display: block;
    transform: translateX(-50%);
    pointer-events: none;
  }
  &.bs-tooltip-bottom > .tooltip-inner {
    font-size: 10px;
    line-height: 12px;
    background: #eff1fa;
    text-align: left;
    width: 270px;
    max-width: none;
    border-radius: 0;
  }
`;

export const Line = styled.div`
  text-align: ${({ b2bClient }) => (b2bClient ? 'center' : 'unset')};
  display: ${({ b2bClient }) => (b2bClient ? 'block' : 'flex')};
  & > div {
    flex: 1;
  }
`;

export const Footer = styled.div`
  padding: 30px 20px;
  background: #6786ff;
  color: #fff;
`;

export const Name = styled.div`
  font-style: italic;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.43px;
  color: #6786ff;
`;

export const Price = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  text-align: right;
  letter-spacing: -0.43px;
  color: #6786ff;
`;

export const Amount = styled.span``;

export const FirstMonth = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: -0.43px;
  color: #6786ff;
`;

export const AfterFirstMonth = styled(FirstMonth)`
  font-weight: 500;
`;

export const Content = styled.div`
  padding: 24px 20px 0;
`;

export const Title = styled.div`
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  margin-top: 5px;
`;

export const BreakDown = styled.div`
  padding-top: 30px;
  margin-bottom: 30px;
  border-top: 0.5px solid #250044;
`;

export const Total = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Coupon = styled(Total)``;
export const Subscription = styled(Total)``;
export const Medication = styled(Total)``;
export const Shipping = styled(Total)``;
export const Consultation = styled(Total)``;

export const TotalLabel = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.43px;
  color: #ffffff;
`;

export const SubTotalLabel = styled.div`
  padding-top: 10px;
  font-weight: 500;
  font-size: 10px;
  width: 240px;
  color: #ffffff;
`;

export const Crossed = styled.span`
  text-decoration: line-through;
  font-size: ${props => props.size && props.size};
  font-weight: ${props => props.weight && props.weight};
`;

export const TotalPrice = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;
  text-align: right;
  letter-spacing: -0.43px;
  color: #ffffff;
  display: flex;
  align-items: center;
  ${Crossed} {
    margin-right: 13px;
  }
`;

export const Isotype = styled.div`
  background-image: url(${isoImage});
  width: 32px;
  height: 34px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
`;

export const Bullets = styled.ul`
  margin: 30px 0 0;
  padding: 30px 0;
  flex: 1;
  list-style: none;
  border-top: 0.5px solid #250044;
`;

export const Text = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.43px;
  color: ${props => (props.color ? props.color : '#444444')};
`;

export const Bullet = styled.li`
  padding: 0 0 0 31px;
  position: relative;
  margin-top: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.43px;
  color: #444444;
  &:first-child {
    margin-top: 0;
  }
  &:before {
    content: ' ';
    height: 20px;
    width: 20px;
    position: absolute;
    background-image: url(${bulletImage});
    top: 0;
    left: -4px;
    background-size: 49%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 100%;
    background-color: #85abee;
  }
`;

const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #f1f4ff;
  box-sizing: border-box;
  box-shadow: 0 0 12px 4px #f6f6f9;
  border-radius: 10px;
  overflow: hidden;
`;

export const InsuranceDisclaimer = styled.div`
  background: #b172e6;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding: 10px;
  border-radius: 2px;
  color: white;
  box-shadow: 0 5px 14px rgba(34, 34, 51, 0.14);
  margin-bottom: 15px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 20px;
    border: 6px solid transparent;
    border-left-width: 20px;
    border-right-width: 20px;
    border-bottom-color: #b172e6;
    display: block;
  }
`;

const toWeek = x => (x ? Math.ceil(x / 4.33) : '0');
const toMonth = price => price;
const supportInsurancePayment = serviceLineNames => {
  const names = intersaction(serviceLineNames, ['smoking', 'alcohol', 'nutrition']);

  return !names.length;
};

const getPrice = period => {
  switch (period) {
    case 'week':
      return toWeek;
    default:
      return toMonth;
  }
};

function PaymentInfo({ couponId, couponPrice, className, isInsurance, period = 'month' }) {
  const offering = useSelector(selectOffering);
  const isPilot = useSelector(({ patient_reducer }) => patient_reducer.direct_messaging_pilot);
  const serviceLineNames = useSelector(
    ({ patient_reducer }) => patient_reducer.visit_object?.service_lines?.map(line => line.name) || []
  );
  const type = isInsurance ? 'insurancePlans' : 'cashPlans';
  let plan = plans[type][isPilot && !isInsurance && offering === 'medication' ? 'medication-only' : offering] || {};
  const { price, paymentBullets, bullets: defaultBullets, title } = plan;
  const bullets = paymentBullets || defaultBullets;
  const hasMeds = (isPilot && !isInsurance) || offering.includes('medication');
  const toPrice = getPrice(period);
  const [getCouponId, setCouponId] = useState(couponId);
  const [getCouponPrice, setCouponPrice] = useState(couponPrice);
  const couponPriceStr = priceCalc => {
    if (!priceCalc || Math.floor(priceCalc) === priceCalc) return priceCalc;
    const [dollars, cents] = priceCalc.toString().split('.');

    return `${dollars}.${cents.padEnd(2, '0')}`;
  };

  const isOnlySmokingServiceLine = serviceLineNames?.length === 1 && serviceLineNames[0] === 'smoking';
  const isSupportInsurancePayment = supportInsurancePayment(serviceLineNames);
  const b2bClient = useSelector(({ patient_reducer }) => patient_reducer.accountId);
  const priceSmokingStr = () => {
    return strings.assessmentFee;
  };

  useEffect(() => {
    const valueKameleoon = testPriceSensitivity();

    if (valueKameleoon === 'Group 5') {
      switch (plan.id) {
        case 'medication':
          plan = { ...plan, ...planPriceModeDetail(createPricing(85, 85)) };
          break;
        case 'medication-therapy':
          plan = { ...plan, ...planPriceModeDetail(createPricing(325, 325)) };
          break;
        case 'therapy':
          plan = { ...plan, ...planPriceModeDetail(createPricing(259, 259)) };
          break;
        default:
          break;
      }
    }

    if (couponId) setCouponId(couponId);
    else if (isInsurance) setCouponId('50OFF');
    else setCouponId(mapPlanToCoupon(offering, valueKameleoon));

    if (typeof couponPrice !== 'number') setCouponPrice(plan.discountedPrice);
    else setCouponPrice(couponPrice);
  }, [couponId, couponPrice, isInsurance, mapPlanToCoupon]);

  const renderPlanPrice = () => {
    if (b2bClient) {
      return (
        <Price>
          <Amount />
          <FirstMonth />
          <AfterFirstMonth />
        </Price>
      );
    }

    if (!isOnlySmokingServiceLine) {
      return (
        <Price>
          <Amount>
            {CURRENCY}
            {couponPriceStr(Number(toPrice(getCouponPrice)))}/{period}
          </Amount>
          <FirstMonth>for your first month</FirstMonth>
          <AfterFirstMonth>
            {CURRENCY}
            {toPrice(price)}/{period} after
          </AfterFirstMonth>
        </Price>
      );
    }

    return (
      <Price>
        <Amount>
          {CURRENCY}
          {priceSmokingStr()}
        </Amount>
        <InitAssessment>Initial intake assessment</InitAssessment>
        <InitAssessmentFee>*Medication &amp; care coordination fee billed separately every month</InitAssessmentFee>
      </Price>
    );
  };

  return (
    <Wrapper className={className}>
      <Header>
        <Isotype />
        <Title>{strings.checkoutTitle}</Title>
      </Header>

      <Content>
        <Line b2bClient={b2bClient}>
          <Name>{title}</Name>

          {renderPlanPrice()}
        </Line>

        <Bullets>
          {bullets.map(b => (
            <Bullet key={b}>{b}</Bullet>
          ))}
        </Bullets>
        {b2bClient && (
          <BreakDown>
            <Subscription>
              <Text>
                {hasMeds ? '' : '*'}
                {isInsurance ? 'Monthly Membership fee' : 'Monthly Subscription'}
              </Text>
            </Subscription>
            <InsuranceDisclaimer>
              We collect billing information in case you need to pay any copays or for any medication. You will not be
              charged at this time, and we will always ask you to confirm charges before they are applied. You can
              change your payment method at any time in your customer portal.
            </InsuranceDisclaimer>
            <Shipping>
              <Text>Shipping</Text>
              <Text color="#6786FF">FREE</Text>
            </Shipping>
          </BreakDown>
        )}

        {!isOnlySmokingServiceLine && !b2bClient && (
          <BreakDown>
            <Subscription>
              <Text>
                {hasMeds ? '' : '*'}
                {isInsurance ? 'Monthly Membership fee' : 'Monthly Subscription'}
              </Text>
              {!b2bClient && (
                <Text color="#6786FF">
                  {price !== couponPriceStr(getCouponPrice) && (
                    <Crossed>
                      {CURRENCY}
                      {price}
                      {hasMeds ? '*' : null}
                    </Crossed>
                  )}{' '}
                  First month {CURRENCY}
                  {couponPriceStr(getCouponPrice)}
                </Text>
              )}
            </Subscription>

            {isSupportInsurancePayment && !isInsurance && !isUK() && !b2bClient && (
              <InsuranceDisclaimer>
                Have insurance? Most insured members get 30-60% of their monthly subscription cost reimbursed. Enter
                your insurance information after payment and you&apos;ll likely qualify.
              </InsuranceDisclaimer>
            )}
            {b2bClient && (
              <InsuranceDisclaimer>
                We collect billing information in case you need to pay any copays or for any medication. You will not be
                charged at this time, and we will always ask you to confirm charges before they are applied. You can
                change your payment method at any time in your customer portal.
              </InsuranceDisclaimer>
            )}
            <Shipping>
              <Text>Shipping</Text>
              <Text color="#6786FF">FREE</Text>
            </Shipping>
          </BreakDown>
        )}
      </Content>

      {b2bClient ? (
        <Footer>
          <Total>
            <div>
              <TotalLabel>
                Today&apos;s total
                <OverlayTrigger
                  rootClose
                  key="bottom"
                  placement="bottom"
                  trigger={is_touch_device() ? 'click' : ['hover', 'focus']}
                  overlay={
                    <TotalTooltip id="tooltip-bottom">
                      {isInsurance
                        ? `Medication (if prescribed) & visits billed after the ${prescriberVariant.singularNormal} visit. Cost will depend on your insurance plan and copay.`
                        : `Medication (if prescribed) billed after the ${prescriberVariant.singularNormal} visit`}
                    </TotalTooltip>
                  }
                >
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                  <span tabIndex={0}>
                    <InformationIcon iconStyle="white" />
                  </span>
                </OverlayTrigger>
              </TotalLabel>
            </div>
            <TotalPrice>
              {CURRENCY}
              {couponPriceStr('0.0')}
            </TotalPrice>
          </Total>
        </Footer>
      ) : (
        <Footer>
          <Total>
            <div>
              <TotalLabel>
                Today&apos;s total
                {hasMeds ? (
                  <OverlayTrigger
                    rootClose
                    key="bottom"
                    placement="bottom"
                    trigger={is_touch_device() ? 'click' : ['hover', 'focus']}
                    overlay={
                      <TotalTooltip id="tooltip-bottom">
                        {isInsurance
                          ? `Medication (if prescribed) & visits billed after the ${prescriberVariant.singularNormal} visit. Cost will depend on your insurance plan and copay.`
                          : `Medication (if prescribed) billed after the ${prescriberVariant.singularNormal} visit`}
                      </TotalTooltip>
                    }
                  >
                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                    <span tabIndex={0}>
                      <InformationIcon iconStyle="white" />
                    </span>
                  </OverlayTrigger>
                ) : null}
              </TotalLabel>
              {isOnlySmokingServiceLine && (
                <SubTotalLabel>
                  Estimated monthly subscription after medication is prescribed {plan.pricePerWeek} (billed monthly)
                </SubTotalLabel>
              )}
            </div>
            <TotalPrice>
              {!isOnlySmokingServiceLine && couponPriceStr(getCouponPrice) !== price && (
                <Crossed size="14px" weight="normal">
                  {CURRENCY}
                  {price}
                </Crossed>
              )}{' '}
              {CURRENCY}
              {isOnlySmokingServiceLine ? couponPriceStr(priceSmokingStr()) : couponPriceStr(getCouponPrice)}
            </TotalPrice>
          </Total>
          {getCouponId && !isOnlySmokingServiceLine && (
            <Coupon>
              <Text color="#fff">Promo code applied</Text>
              <Text color="#fff">{getCouponId}</Text>
            </Coupon>
          )}
        </Footer>
      )}
    </Wrapper>
  );
}

export default styled(PaymentInfo)``;
