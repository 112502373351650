import styled from 'styled-components/macro';

const Button = styled.button`
  background: linear-gradient(90deg, #6786ff 6.2%, #85abee 100%);
  box-shadow: 0px 7px 14px rgba(34, 34, 52, 0.13);
  border-radius: 30px;
  border: none;
  color: #fff;
  padding: 13px 40px;
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  opacity: ${({ disabled }) => (disabled ? '.2' : '1')};
`;

export default Button;
