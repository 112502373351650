import React, { useState } from 'react';
import styled from 'styled-components/macro';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { is_touch_device } from '../../../utils/common';
import { SectionTitle, QuestionText, RadioGroup, RadioItem, RadioBox, MediaWrapper, NextBtn } from './styled';

const radio_colors = [
  '#55B34D',
  '#57D364',
  '#72EA48',
  '#C0EA48',
  '#E1E440',
  '#FCC36D',
  '#FCA06D',
  '#FF9A7A',
  '#FF6838',
  '#F77147',
  '#F93939'
];

const ButtonTooltip = styled(Tooltip)`
  .tooltip-inner {
    background: #d4dcf7;
    color: #250044;
  }

  &.bs-tooltip-right .arrow:before {
    border-right-color: #d4dcf7;
  }

  &.bs-tooltip-top .arrow:before {
    border-top-color: #d4dcf7;
  }
`;

const RatingForm = ({ isBegin, onSubmit, title }) => {
  const [rate, setRate] = useState(null);

  const handleRadioClick = value => () => {
    setRate(value);
  };

  const handleSubmit = () => {
    if (rate === null) return;
    onSubmit(rate, isBegin);
  };

  const question = isBegin
    ? 'Before we get started, how are you feeling in this moment?'
    : 'How do you feel after completing this exercise?';

  return (
    <div>
      <SectionTitle>{title}</SectionTitle>
      <QuestionText>{question}</QuestionText>

      <RadioGroup>
        {radio_colors.map((color, idx) => (
          <RadioItem key={`radio-${idx}`}>
            <span className="rate-score">{10 - idx}</span>
            <RadioBox color={color} onClick={handleRadioClick(10 - idx)} checked={rate === 10 - idx} />
            {idx === 0 && <span className="rate-text">Great</span>}
            {idx === 5 && <span className="rate-text">Okay</span>}
            {idx === 10 && <span className="rate-text">Terrible</span>}
          </RadioItem>
        ))}
      </RadioGroup>

      <MediaWrapper>
        <OverlayTrigger
          rootClose
          key={is_touch_device() ? 'top' : 'right'}
          placement={is_touch_device() ? 'top' : 'right'}
          // show={rate === null}
          trigger={rate === null && 'click'}
          overlay={
            <ButtonTooltip id={`tooltip-${is_touch_device() ? 'top' : 'right'}`}>
              Please provide a rating before moving on to the next part of the exercise.
            </ButtonTooltip>
          }
        >
          <NextBtn disable={rate === null} onClick={handleSubmit}>
            {isBegin ? 'Next' : 'Complete'}
          </NextBtn>
        </OverlayTrigger>
      </MediaWrapper>
    </div>
  );
};

export default RatingForm;
