import React, { createContext, useContext, useState } from 'react';
import { useApi } from 'utils/api';

interface FlagData {
  [key: string]: boolean;
}

interface RolloutFlagProps {
  loadFlag(flag: string): Promise<void>;
  flag: FlagData;
}

export const RolloutFlagContext = createContext<RolloutFlagProps>({
  loadFlag: () => new Promise<void>(resolve => resolve()),
  flag: {}
});

export const RolloutFlagProvider: React.FC = ({ children }) => {
  const api = useApi();
  const [flag, setFlag] = useState<FlagData>({});

  const loadFlag = (name: string) =>
    api
      .get<FlagData>('/api/v1/feature_flags/find_by', {
        params: {
          flag: name
        }
      })
      .then(res => setFlag(res.data))
      .catch(() => setFlag({ [name]: false }));

  return (
    <RolloutFlagContext.Provider
      value={{
        loadFlag,
        flag
      }}
    >
      {children}
    </RolloutFlagContext.Provider>
  );
};

export const useRolloutFlag = (): RolloutFlagProps => useContext(RolloutFlagContext);
