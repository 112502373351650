import React, { Component, useEffect } from 'react';
import { connect, batch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components/macro';
import Cookies from 'js-cookie';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  ElementsConsumer
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PromoForm from 'components/PromoForm';
import { ReactComponent as Lock } from 'img/lock.svg';
import { withRouter } from 'react-router-dom';
import strings from '../../../localization';
import { SubmitButton } from '../../question_components/styled_components';
import Error from '../../Error';
import PaymentForm from './PaymentForm';
import PromoHeading from './PromoHeading';
import {
  submit_payment,
  set_is_payment_submitted,
  setCurrentPatientPath,
  setCoupon,
  submit_stripe_payment,
  submit_stripe_payment_us
} from '../../../actions/patient_action';
import {
  formatCardNumber,
  getCardTypeAndMaxLength,
  testCards,
  cvvLengthByType,
  mapPlanToCouponSelingUpgrade,
  upgradePlanOrderedList,
  getSmokingAddonCode,
  nutritionPlanOrderedList,
  conselingUpgradePlanOrderedList,
  countryFromUrl
} from '../../../utils/common';
import { CardNameInput, ZipInput, Title, Info, NoteText, PaymentInformation } from './styled';
import PaymentProgressModal from '../../PaymentProgressModal';
import fireGtagConversion from '../../../utils/gtagConversions';
import tracking from '../../../utils/tracking';
import segment from '../../../utils/segment';
import gtagSession from '../../../utils/gtagSession';
import PaymentInfo from './PaymentInfo';
import PaymentTopText from './PatientTopText';
import PaymentInfoInsurance from './PaymentInfoInsurance';
import ImportantInformationContent from './ImportantInformationContent';
import TermAndConditionFooter from './TermAndConditionFooter';
import ABtest from '../../Variations/ABTest';
import plans from '../../../utils/plans';
import friendbuyTracking from '../../../utils/friendbuy';
import './index.scss';
import { VariationContext } from '../../Variations/VariationContext';
import { withAcceptScript } from '../../../utils/accept-script';
import { currentCurrency } from '../../../utils/currency.util';
import { withGlobals } from '../../../contexts/globals';
import { useRollout } from '../../../contexts/rollout';
import { testPriceSensitivity } from '../../../utils/kameleoon';

const PaymentNote = styled.p`
  margin-top: 10px;
  height: 25px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.43px;
  color: #9b9b9b;
  text-align: center;
`;

const ImportantInformationHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageHolder = styled.div`
  ${({ isReactivate }) =>
    isReactivate &&
    `
      text-align: center;
    `}

  ${({ isReactivate }) =>
    !isReactivate &&
    `
      display: flex;
      justify-content: space-between;
  `}
`;

const Image = styled.img`
  height: 27px;
  width: 172px;
`;

const Heading = styled.div`
  margin-top: ${props => props.marginTop && props.marginTop};
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.43px;
  color: #6786ff;
`;

const PayHeader = styled.h3`
  margin: 50px 0 0;
  font-size: 20px;
  line-height: 45px;
  letter-spacing: -0.4px;
  color: #6786ff;
`;

const SubTitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #250044;
  white-space: pre;
  margin: 0 0 30px;
`;

const Forms = styled.div`
  max-width: 300px;
  ${({ isReactivate }) =>
    isReactivate &&
    `
      max-width: 400px;
    `}
  ${({ b2bClient }) =>
    b2bClient &&
    `
    max-width: 400px;
  `}
  margin: 10px auto 0;
  padding: 20px;
`;

const Wrapper = styled.div`
  &.patient-payment {
    max-width: unset;
    margin: unset;
  }

  ${PaymentInfo}, ${PaymentInfoInsurance} {
    max-width: 390px;
    margin: 0 auto 0;
  }

  ${PromoForm} {
    margin-top: 10px;
  }

  .agree-holder {
    margin-top: 50px;
  }

  ${SubmitButton} {
    margin: 50px auto 0;
    display: block;
  }

  ${PaymentForm} {
    margin-top: 10px;
  }

  ${ImageHolder} {
    margin-top: 5px;
    ${({ isReactivate }) =>
      isReactivate &&
      `
        margin-top: 25px;
    `}
  }

  .errors-holder {
    margin-top: 10px;
  }

  .credit-cards-logos {
    text-align: center;
  }
`;

const CardExtraUk = styled.div`
  width: 100%;
  //display: flex;
`;

const ELEMENT_OPTIONS = {
  style: {
    base: {
      border: '1px solid black',
      fontSize: '14px',
      letterSpacing: '0.025em',
      fontFamily: `"Montserrat Regular", sans-serif`,
      fontSmoothing: 'antialiased',
      '::placeholder': {
        fontFamily: `"Montserrat Regular", sans-serif`,
        fontSize: '14px',
        color: '#250044',
        fontWeight: 400
      },
      padding: '10px'
    },
    invalid: {
      color: '#9e2146'
    }
  }
};

const handleCVV = (cvv, card) => {
  const value = cvv.replace(/\D/g, '');
  const [cardType] = getCardTypeAndMaxLength(card);
  const cvvLength = cvvLengthByType(cardType);
  return value.length > cvvLength ? value.substring(0, cvvLength) : value;
};

const copayRange = ({ medication_copay_amounts, therapy_copay_amounts }) => {
  const copays = medication_copay_amounts.concat(therapy_copay_amounts).map(Number);
  const min = Math.min(...copays);
  const max = Math.max(...copays);

  return min === max ? `$${min}` : `$${min}-$${max}`;
};

const envStripe =
  countryFromUrl() === 'uk' ? process.env.REACT_APP_STRIPEJS_KEY : process.env.REACT_APP_STRIPE_API_KEY_US;
const stripePromise = loadStripe(envStripe);

class PatientPaymentInsurance extends Component {
  static contextType = VariationContext;

  constructor(props) {
    super(props);
    this.state = {
      is_disabled: false,
      is_loading: false,
      is_modal_opened: false,
      cardNumber: '',
      date: '',
      cardCode: '',
      fullName: '',
      postal_code: '',
      showZip: false,
      errors: [],
      couponId: '',
      couponPrice: null,
      campaignId: Cookies.get('iterableEmailCampaignId') || '',
      agreeTermOpt: false,
      agreeConsentOpt: false
    };
  }

  componentDidMount() {
    this.maybeFireGtag();
    window.scrollTo(0, 0);

    const { acceptScript } = this.props;
    const listVariantAbtest = ['Group 2', 'Group 3', 'Group 4', 'Group 5'];
    const valueKameleoon = testPriceSensitivity();
    acceptScript.injectScript();

    if (this.context && this.context.campaignId) {
      this.setState({ campaignId: this.context.campaignId });
    }

    if (
      this.props.offeringKey === 'therapy' &&
      !this.props.isInsurance &&
      !listVariantAbtest.includes(valueKameleoon)
    ) {
      this.onApplyCode({
        id: strings.defaultTherapyCode,
        price: strings.discountedTherapyPrice
      });
    } else if (upgradePlanOrderedList.includes(this.props.offeringKey)) {
      this.onApplyCode({
        id: mapPlanToCouponSelingUpgrade(this.props.offeringKey).coupon,
        price: mapPlanToCouponSelingUpgrade(this.props.offeringKey).price
      });
    }

    segment.track('checkout', { question_answer: '' });
  }

  maybeFireGtag() {
    gtagSession();
    if (!gtagSession.contains('initiate_checkout')) {
      gtagSession.set('initiate_checkout');
      fireGtagConversion('initiate_checkout');
    }
  }

  createPaymentAsync = data => {
    return new Promise(resolve => {
      window.Accept.dispatchData(data, resolve);
    });
  };

  parseErrors = response => {
    let i = 0;
    const result = [];

    while (i < response.messages.message.length) {
      result.push(response.messages.message[i].text);
      i += 1;
    }
    return this.setState({
      errors: result,
      is_disabled: false
    });
  };

  refinedData = () => {
    const authData = {
      clientKey: process.env.REACT_APP_AUTHORIZE_NET_CLIENT_KEY,
      apiLoginID: process.env.REACT_APP_AUTHORIZE_NET_API_LOGIN_ID
    };

    const { cardNumber, date, cardCode, fullName, postal_code } = this.state;

    const [month, year] = date.replace('_', '').split('/');
    const numbers = cardNumber.split(' ').join('');

    return {
      authData,
      cardData: {
        cardNumber: numbers,
        month,
        year,
        cardCode,
        fullName,
        zip: postal_code
      }
    };
  };

  mapCodeToError = err => {
    if (isEmpty(err)) {
      return ['It seems something went wrong and your payment could not be submitted. Please try again.'];
    }
    if (err.error_code && err.error_code === '27') {
      return ['Please confirm that your ZIP code is entered correctly.'];
    }

    if (err.error_code && err.error_code === '65') {
      return ['Please confirm that your CVV/CVC code is entered correctly.'];
    }

    return [err.errors];
  };

  createPayment = () => {
    this.setState({ is_disabled: true });
    const { submitPayment, visitId, offeringKey, isInsurance, patientId, insurance, isReactivate } = this.props;
    const { cardNumber, fullName, postal_code, cardCode, couponId, campaignId } = this.state;

    if (fullName.split(' ').length < 2) {
      return this.setState({
        is_disabled: false,
        errors: ["Please input the cardholder's full name."]
      });
    }

    if (cardCode.length < 3) {
      return this.setState({
        is_disabled: false,
        errors: ['Please confirm that your CVV/CVC code is entered correctly.']
      });
    }

    if (postal_code.length < 5) {
      return this.setState({
        is_disabled: false,
        errors: ['Please confirm that your ZIP code is entered correctly.']
      });
    }

    if (isReactivate && (!this.state.agreeTermOpt || !this.state.agreeConsentOpt)) {
      return this.setState({
        is_disabled: false,
        errors: ['Please accept Terms & Conditions.']
      });
    }

    if (process.env.REACT_APP_ALLOW_TESTCARDS === 'false' && testCards.includes(cardNumber.replace(/\s/g, ''))) {
      return this.setState({
        is_disabled: false,
        errors: ['Please provide valid credit card number.']
      });
    }

    return this.createPaymentAsync(this.refinedData()).then(response => {
      if (response.messages.resultCode === 'Error') {
        return this.parseErrors(response);
      }
      this.setState({
        errors: []
      });

      const body = {
        opaqueData: response.opaqueData,
        patient_id: patientId,
        visit_id: visitId,
        cardholder_name: fullName,
        zip: postal_code,
        coupon_id: couponId,
        offering: offeringKey,
        is_insurance: isInsurance,
        campaignId,
        insurance
      };

      const addonCode = getSmokingAddonCode(offeringKey);
      if (addonCode !== '') {
        body.addon_id = addonCode;
      }

      this.setState({ is_loading: true, is_modal_opened: true, errors: [] });
      return submitPayment(body)
        .then(res => this.onSubmitPaymentSuccess(res))
        .catch(err => this.onSubmitPaymentFailed(err));
    });
  };

  createStripePayment = async () => {
    const {
      stripe,
      elements,
      visitId,
      offeringKey,
      isInsurance,
      patientId,
      insurance,
      submitStripePayment,
      submitStripePaymentUs,
      abTest
    } = this.props;
    const { fullName, postal_code, couponId, campaignId } = this.state;
    const isStripeUS = abTest?.stripe_gateway_checkout === 'stripe' && countryFromUrl() === 'us';
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    const card = elements.getElement(CardNumberElement);
    if (card == null) {
      return;
    }
    try {
      this.showLoadingModal();
      const { token, error } = await stripe.createToken(card);
      if (error) {
        this.setState({ is_loading: false, is_modal_opened: false, errors: this.mapCodeToError() });
        return;
      }

      if (token) {
        const body = {
          token: token.id,
          patient_id: patientId,
          visit_id: visitId,
          cardholder_name: fullName,
          zip: postal_code,
          coupon_id: couponId,
          offering: offeringKey,
          is_insurance: isInsurance,
          campaignId,
          insurance
        };
        const addonCode = getSmokingAddonCode(offeringKey);
        if (addonCode !== '') {
          body.addon_id = addonCode;
        }

        if (isStripeUS) {
          submitStripePaymentUs(body)
            .then(() => this.onSubmitPaymentSuccess())
            .catch(err => this.onSubmitPaymentFailed(err));
        } else {
          submitStripePayment(body)
            .then(() => this.onSubmitPaymentSuccess())
            .catch(err => this.onSubmitPaymentFailed(err));
        }
      }
    } catch (err) {
      this.setState({ is_loading: false, is_modal_opened: false, errors: this.mapCodeToError() });
    }
  };

  showLoadingModal = () => {
    this.setState({ is_loading: true, is_modal_opened: true, errors: [] });
  };

  onSubmitPaymentSuccess = res => {
    const {
      email,
      offeringKey,
      isInsurance,
      patientId,
      setIsPaymentSubmitted,
      setPatientPath,
      price,
      submit_action,
      flowKind = 'FE',
      insurance,
      globals,
      region,
      history,
      isReactivate
    } = this.props;
    const { fullName, couponId, couponPrice, campaignId } = this.state;
    const eventID = res?.data?.event_id;
    // fire gta
    fireGtagConversion(`subscription-signup-${offeringKey}`);
    const segmentOrderId = `${patientId}-${new Date().getTime()}`;
    try {
      window.dataLayer.push({ segmentOrderId });
      window.dataLayer.push({ price });
      window.dataLayer.push({ currency: currentCurrency() });
      window.dataLayer.push({ plan: offeringKey });
      window.dataLayer.push({ transactionId: eventID });
    } catch (e) {
      tracking.error({ e, message: 'Error pushing dataLayer segmentOrderId' });
    }
    segment.trackSubscription(offeringKey, price, campaignId, insurance, segmentOrderId, eventID, email);
    const fbPayload = {
      id: `${patientId}`,
      amount: couponPrice,
      currency: 'USD',
      isNewCustomer: true,
      couponCode: couponId,
      customer: {
        email,
        name: fullName,
        id: `${patientId}`
      }
    };
    friendbuyTracking('purchase', fbPayload);
    // we can handle a response somehow
    this.setState(
      {
        is_loading: false
      },
      () => {
        setTimeout(() => {
          batch(() => {
            setIsPaymentSubmitted(true, isInsurance);
            if (!isReactivate) {
              if (flowKind === 'BE') {
                submit_action();
              } else {
                setPatientPath({
                  isPaymentSubmitted: true,
                  isMedsTherapy: ['medication-therapy', '345_meds_therapy', '365_meds_therapy'].includes(offeringKey),
                  therapistRegions: globals.isTherapistRegion(region)
                });
              }
            } else {
              history.push('/patient/dashboard/reactivation_info');
            }
          });
        }, 3000);
      }
    );
  };

  onSubmitPaymentFailed = (err = {}) => {
    this.setState({
      errors: this.mapCodeToError(err.data),
      is_loading: false,
      is_modal_opened: false,
      is_disabled: false
    });
  };

  validateCC = (value, type) => {
    let val = value;

    switch (type) {
      case 'cardCode':
        return handleCVV(value, this.state.cardNumber);
      case 'postal_code':
        val = val.replace(val.zipCodeParser, '');
        return val.length > strings.zipCodeLength ? val.substring(0, strings.zipCodeLength) : val;
      case 'cardNumber':
        return val.length < 2 || val.length < this.state[type].length ? val : formatCardNumber(val);
      case 'fullName':
        return val.trim().split(' ').filter(Boolean).join(' ');
      default:
        return val;
    }
  };

  // remove callback because some card numbers can have 13, 14 and 15 digits.
  updateField = (e, type) => {
    this.setState({ [type]: this.validateCC(e.target.value, type) });
  };

  onApplyCode = ({ id, price }) => {
    this.setState({ couponId: id, couponPrice: price });
    this.props.setCoupon(id, price, this.props.offeringKey);
  };

  setAgreeTerm = () => {
    this.setState(prevState => ({ agreeTermOpt: !prevState.agreeTermOpt }));
  };

  setAgreeConsent = () => {
    this.setState(prevState => ({ agreeConsentOpt: !prevState.agreeConsentOpt }));
  };

  render() {
    const {
      is_disabled,
      postal_code,
      errors,
      is_loading,
      is_modal_opened,
      cardNumber,
      cardCode,
      date,
      couponId,
      couponPrice,
      agreeTermOpt,
      agreeConsentOpt
    } = this.state;

    const { insurance, acceptScript, globals, b2bClient, abTest } = this.props;

    const { isInsurance, region, offeringKey, serviceLines, isReactivate } = this.props;
    const isStripeUS = abTest?.stripe_gateway_checkout === 'stripe' && countryFromUrl() === 'us';

    const defaultPeriod =
      globals.isTherapistRegion(region) && !nutritionPlanOrderedList.includes(offeringKey) ? 'week' : 'month';
    const planType = isInsurance ? 'insurancePlans' : 'cashPlans';
    const plan = plans[planType][offeringKey];
    const consultationFee = plan.price - plans.membershipFee;
    const isOnlySmoking = conselingUpgradePlanOrderedList.includes(offeringKey);
    const isNutritionServiceLine = serviceLines?.length === 1 && serviceLines[0].name === 'nutrition';

    return (
      <Wrapper className="patient-payment" isReactivate={isReactivate}>
        {!isReactivate && !b2bClient && (
          <>
            <PaymentTopText couponId={couponId} isInsurance={isInsurance} />

            <SubTitle>{'Today’s purchase\n summary'}</SubTitle>
          </>
        )}

        {!isReactivate && (
          <ABtest
            id="price"
            map={{
              no_value: (
                <PaymentInfoInsurance
                  period={defaultPeriod}
                  couponId={couponId}
                  couponPrice={couponPrice}
                  isInsurance={isInsurance}
                />
              ),
              month: (
                <PaymentInfoInsurance
                  period="month"
                  couponId={couponId}
                  couponPrice={couponPrice}
                  isInsurance={isInsurance}
                />
              ),
              week: (
                <PaymentInfoInsurance
                  period="week"
                  couponId={couponId}
                  couponPrice={couponPrice}
                  isInsurance={isInsurance}
                />
              )
            }}
          />
        )}

        {isReactivate && (
          <>
            <Title>Thank you for choosing to reactivate your account!</Title>
            <Info>
              <p>
                We&apos;ll get you restarted on the {plan.name} plan for ${plan.price} per month.
              </p>
              <p>Please enter your payment information below to activate your account and begin your care again.</p>
              <p>
                And, since things may have changed since the last time we checked, we&apos;ll ask you to answer a few
                short questions about your health and symptoms.
              </p>
            </Info>
            <NoteText>*Your Cerebral subscription is billed on a recurring monthly basis. Cancel anytime.</NoteText>
            <PaymentInformation>PAYMENT INFORMATION</PaymentInformation>
          </>
        )}

        <Forms isReactivate={isReactivate} b2bClient={b2bClient}>
          {(!isInsurance || nutritionPlanOrderedList.includes(offeringKey)) && !isReactivate && !b2bClient && (
            <>
              <Heading marginTop="25px">
                <PromoHeading isOnlySmoking={isOnlySmoking} />
              </Heading>
              <PromoForm onApplyCode={this.onApplyCode} title="Promo Code?" offering={plan.id} />
            </>
          )}

          {!isReactivate && (
            <>
              <PayHeader>Pay with:</PayHeader>
              <ImageHolder>
                <Heading>Credit or Debit</Heading>

                <Image
                  alt="Credit Card Logos"
                  title="Credit Card Logos"
                  src={`${process.env.PUBLIC_URL}/img/credit-cards-logo.svg`}
                />
              </ImageHolder>
            </>
          )}

          {strings.getLanguage() === 'uk' || isStripeUS ? (
            <>
              <CardNameInput
                type="text"
                placeholder="Name on card"
                name="fullName"
                id="fullName"
                autoComplete="cc-name"
                onChange={e => this.updateField(e, 'fullName')}
                required
              />

              <CardNumberElement
                options={{ ...ELEMENT_OPTIONS, placeholder: 'Credit Card Number' }}
                className="stripeElement"
              />
              <CardExtraUk className="CardExtraUk">
                <CardExpiryElement options={ELEMENT_OPTIONS} className="stripeElement" />
                <CardCvcElement options={ELEMENT_OPTIONS} className="stripeElement" />
                <ZipInput
                  type="text"
                  placeholder={isStripeUS ? `ZIP` : `Post Code`}
                  value={postal_code}
                  onChange={e => this.updateField(e, 'postal_code')}
                />
              </CardExtraUk>
            </>
          ) : (
            <PaymentForm
              values={{
                cardCode,
                cardNumber,
                date,
                postal_code
              }}
              updateField={this.updateField}
              isReactivate={isReactivate}
            />
          )}
          {errors.length > 0 && (
            <div className="errors-holder">
              {errors.map(error => (
                <Error key={error} error={error} className="red pad-0 marg-0 weight_normal" />
              ))}
            </div>
          )}

          {isReactivate && (
            <TermAndConditionFooter
              setAgreeTerm={this.setAgreeTerm}
              agreeTermOpt={agreeTermOpt}
              setAgreeConsent={this.setAgreeConsent}
              agreeConsentOpt={agreeConsentOpt}
            />
          )}

          {strings.getLanguage() === 'uk' || isStripeUS ? (
            <SubmitButton
              onClick={this.createStripePayment}
              value={isReactivate ? 'Confirm' : 'Submit'}
              is_disabled={is_disabled}
            />
          ) : (
            <SubmitButton
              onClick={this.createPayment}
              value={isReactivate ? 'Confirm' : 'Submit'}
              is_disabled={is_disabled || !acceptScript.loaded}
            />
          )}
        </Forms>

        {isReactivate && (
          <>
            <ImageHolder isReactivate={isReactivate}>
              <Image
                alt="Credit Card Logos"
                title="Credit Card Logos"
                src={`${process.env.PUBLIC_URL}/img/credit-cards-logo.svg`}
              />
            </ImageHolder>
            <PaymentNote>
              <Lock />
              &nbsp;Anonymous. Safe & Secure. Cancel Anytime.
            </PaymentNote>
          </>
        )}

        <ImportantInformationHolder>
          <ImportantInformationContent
            offeringKey={offeringKey}
            isInsurance={isInsurance}
            isNutritionServiceLine={isNutritionServiceLine}
            isOnlySmoking={isOnlySmoking}
            copayRange={copayRange}
            insurance={insurance}
            plan={plan}
            consultationFee={consultationFee}
            plans={plans}
          />
        </ImportantInformationHolder>

        <PaymentProgressModal
          open={is_modal_opened}
          loading={is_loading}
          textOnLoading="Your payment is being processed."
          textOnLoaded="Your payment was processed successfully."
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  visitId: state.patient_reducer.visit_object.id,
  isInsurance: state.patient_reducer.plan.is_insurance,
  insurance: state.patient_reducer.insurance_visits_policy,
  offeringKey: state.patient_reducer.plan.offering_key,
  patientId: state.global_reducer.current_user.attributes.patient.id,
  region: state.global_reducer.current_user.attributes.patient.region,
  price: state.patient_reducer.plan.price,
  email: state.global_reducer.current_user.attributes.email,
  serviceLines: state.patient_reducer.service_line,
  b2bClient: state.patient_reducer.accountId,
  abTest: state.patient_reducer.abTest
});

const mapDispatchToProps = {
  submitPayment: submit_payment,
  submitStripePayment: submit_stripe_payment,
  submitStripePaymentUs: submit_stripe_payment_us,
  setIsPaymentSubmitted: set_is_payment_submitted,
  setPatientPath: setCurrentPatientPath,
  setCoupon
};

const InjectedPatientPaymentInsurance = props => {
  const rollout = useRollout();

  useEffect(() => {
    rollout.loadFlags();
  }, []);

  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            family: 'Montserrat Regular',
            weight: 400,
            src: `local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2')`
          }
        ]
      }}
    >
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <PatientPaymentInsurance stripe={stripe} elements={elements} rolloutContext={rollout.flags} {...props} />
        )}
      </ElementsConsumer>
    </Elements>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withGlobals(withAcceptScript(withRouter(InjectedPatientPaymentInsurance))));
