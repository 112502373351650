import styled from 'styled-components/macro';
import { BackButtonHolder } from '../../BackButton/styled';

export const ButtonsHolder = styled.div`
  margin: 32px 0 8px;
`;

export const ChangePlanImageHolder = styled.span`
  margin-right: 13px;
  svg path {
    fill: #250044;
  }
`;

export const Button = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  text-transform: uppercase;
  border: none;
  background: none;
  padding: 0;
  color: #250044;
`;

export const Title = styled.div<{ main: boolean }>`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: ${props => (props.main ? '30px' : '10px')};
  text-align: left;
`;

export const Description = styled.div<{ left: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.43px;
  color: #6786ff;
  margin-bottom: 15px;
  margin-left: ${props => props.left && '15px'};
`;

export const MedicationWrapper = styled.div`
  display: flex;
  color: #6786ff;
`;

export const Dash = styled.div`
  &:after {
    content: '-';
  }
`;

export const List = styled.ul`
  padding: 20px 0 0 0;
  margin: 0 0 20px 0;
  border-top: 1px solid #250044;
  border-bottom: 1px solid #250044;
`;

export const ListItem = styled.li`
  margin-bottom: 20px;
  list-style: none;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.43px;
  color: #250044;
`;

export const Wrapper = styled.div`
  ${BackButtonHolder} {
    top: 0;
    line-height: 5em;
    @media (max-width: 991px) {
      top: 24px;
      line-height: 6em;
    }
  }
`;
