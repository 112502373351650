import { UPDATE_INSURANCE } from './actions';
import { formatDob } from '../../../utils/common';

export const initialState = {
  insurance: {},
  user: {}
};

export default (state, action) => {
  switch (action.type) {
    case UPDATE_INSURANCE:
      return {
        ...state,
        insurance: {
          ...action.data,
          dob: formatDob(action.data.dob, 'dashes')
        },
        currentStepIndex: state.currentStepIndex + 1
      };
    default:
      return state;
  }
};
