import React, { Fragment, useMemo, memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import { differenceInYears, differenceInWeeks, format } from '../../../utils/date-fns';
import Message from '../../Message';
import UserTyping from '../UserTyping';
import { Separator, SeparatorValue, StickySeparator, StickySeparatorValue } from './styled';
import { useApi } from '../../../utils/api';
import tracking from '../../../utils/tracking';

const formatDate = date => {
  let formatOptions;

  if (differenceInYears(date) > 0) {
    formatOptions = { month: 'short', year: 'numeric' };
  } else if (differenceInWeeks(date) > 0) {
    formatOptions = { weekday: 'short', month: 'short', day: 'numeric' };
  } else {
    formatOptions = { weekday: 'long' };
  }
  return format(date, formatOptions);
};

const detectAdHocAndSuperAdHocLink = message =>
  ['schedule/provider', 'schedule/care_manager', 'schedule/therapist'].some(type => message.includes(type));

const detectMessage = (listMessages = [], isLink = false) =>
  listMessages.filter(detail =>
    isLink
      ? detail?.message.startsWith('http') && detectAdHocAndSuperAdHocLink(detail?.message)
      : !(detail?.message.startsWith('http') && detectAdHocAndSuperAdHocLink(detail?.message))
  );

const getLastLink = listMessages => {
  const listAdhocAndSuperAdHocLink = detectMessage(listMessages, true);
  return listAdhocAndSuperAdHocLink[listAdhocAndSuperAdHocLink.length - 1];
};

const MessageWrapper = memo(
  ({
    message,
    separatorValue,
    isLastMessage,
    isFirstMessage,
    setName,
    next,
    setRef,
    isRedirected,
    listDisabledReassign
  }) => {
    let showDateSeparator = true;
    let date = separatorValue;
    const createdAt = formatDate(message.created_at);

    if (date === createdAt) {
      if (!isFirstMessage) {
        showDateSeparator = false;
      }
    } else {
      showDateSeparator = true;
      date = createdAt;
    }

    return (
      <Fragment key={message.id}>
        {showDateSeparator && (
          <>
            <StickySeparator>
              <StickySeparatorValue>{date}</StickySeparatorValue>
            </StickySeparator>
            <Separator className="separator">
              <SeparatorValue>{date}</SeparatorValue>
            </Separator>
          </>
        )}
        <Message
          setRef={isLastMessage ? setRef : undefined}
          onNext={next}
          {...message}
          name={setName(message)}
          isRedirected={isRedirected}
          listDisabledReassign={listDisabledReassign}
        />
      </Fragment>
    );
  }
);

const Messages = ({ setRef, next, setName, scrollDown }) => {
  const isUserTyping = useSelector(state => state.messenger_reducer && state.messenger_reducer.user_typing);
  const activeMessenger = useSelector(({ messenger_reducer }) => messenger_reducer.active_messenger);
  const messages = useSelector(({ messenger_reducer }) => messenger_reducer.messages);
  const [listMessages, setListMessages] = useState([]);
  const [listDisabledReassign, setListDisabledReassign] = useState({
    provider: false,
    care_manager: false,
    therapist: false
  });
  const api = useApi();

  const checkExpiredLastLink = async lastLink => {
    try {
      const listMessagesReMain = detectMessage(messages[activeMessenger]);
      if (lastLink?.message?.includes('/super?id=')) {
        const superAdhocLinkId = lastLink?.message.split('id=')[1];
        const response = await api.get(`/api/calendar/check_super_adhoc_link?super_adhoc_link_id=${superAdhocLinkId}`);
        // if last link expired, set message remain, else add lastLink and sort by created_at
        if (response?.data?.is_expired) {
          setListMessages(listMessagesReMain);
        } else {
          setListMessages(sortBy([...listMessagesReMain, lastLink].filter(Boolean), ['created_at']));
        }
      } else {
        setListMessages(sortBy([...listMessagesReMain, lastLink].filter(Boolean), ['created_at']));
      }
    } catch (error) {
      tracking.error({ e: error.toString() });
    }
  };

  const checkUserCanReassignOrNot = async () => {
    try {
      const { data } = await api.get('/api/v1/adhoc_clinician_reassignment/eligible_for_reassign');
      setListDisabledReassign(data);
    } catch (error) {
      tracking.error({ e: error.toString() });
    }
  };

  useEffect(() => {
    const lastLink = getLastLink(messages[activeMessenger]);
    checkExpiredLastLink(lastLink);
  }, [messages[activeMessenger]]);

  // check user can reassign or not;
  useEffect(() => {
    checkUserCanReassignOrNot();
  }, []);

  const { separator, messageList } = useMemo(() => {
    setTimeout(() => {
      scrollDown({ block: 'end' });
    }, 100);
    if (listMessages && listMessages.length > 0) {
      return {
        separator: formatDate(new Date(listMessages?.created_at ?? new Date())),
        messageList: listMessages
      };
    }
    return { separator: null, messageList: [] };
  }, [listMessages]);

  return (
    <div className="parent-messages">
      <ul className="chat_body_messages">
        {messageList.map((message, index) => (
          <MessageWrapper
            message={message}
            separatorValue={separator}
            isFirstMessage={index === 0}
            isLastMessage={messageList.length - 1 === index}
            setName={setName}
            setRef={setRef}
            next={next}
            key={`message-${message.id}`}
            isRedirected={message.isRedirected}
            listDisabledReassign={listDisabledReassign}
          />
        ))}
        {isUserTyping ? <UserTyping /> : null}
      </ul>
    </div>
  );
};

Messages.propTypes = {
  setRef: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired
};

export default Messages;
