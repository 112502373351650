import styled from 'styled-components/macro';
import Button from 'components/UI/Button';
import TextButton from 'components/UI/TextButton';
import { Content } from 'components/UI/Modal';
import errorImage from './img/not-eligible-error.png';

export const ErrorImage = styled.div`
  background-image: url(${errorImage});
  width: 209px;
  height: 200px;
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const Error = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -1px;
  color: #ff4b4b;
  margin-top: 5px;
`;

export const Message = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -1px;
  color: #6786ff;
  margin-top: 30px;
`;

export const SubmitButton = styled(Button)`
  margin-top: 30px;
`;

export const ReSubmitButton = styled(TextButton)`
  margin: 10px auto 0;
  display: block;
`;

export const ErrorContent = styled(Content)`
  text-align: center;
`;
