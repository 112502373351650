import axios from 'axios';
import * as global_actions from './user_auth_action';

export const SET_THERAPIST = 'SET_THERAPIST';
export const SET_THERAPIST_STATE = 'SET_THERAPIST_STATE';
export const SET_QUESTIONS = 'SET_QUESTIONS';
export const SET_REFER_ANSWERS = 'SET_REFER_ANSWERS';
export const SET_REFER_INDEX = 'SET_REFER_INDEX';
export const SET_REFER_PATIENTS = 'SET_REFER_PATIENTS';
export const CLEAN_REFER_DATA = 'CREAN_REFER_DATA';

const { get_user_attr, make_headers } = global_actions;

export const set_therapist_questions = data => ({
  type: SET_QUESTIONS,
  questions: data
});

export const set_refer_answers = answers => ({
  type: SET_REFER_ANSWERS,
  ref_answers: answers
});

export const set_refer_index = index => ({
  type: SET_REFER_INDEX,
  ref_index: index
});

export const set_refer_patients = patients => ({
  type: SET_REFER_PATIENTS,
  ref_patients: patients
});

export const set_therapist = therapist_object => ({
  type: SET_THERAPIST,
  therapist_object
});

export const set_therapist_state = state => ({
  type: SET_THERAPIST_STATE,
  state
});

export const update_therapist_state = state => dispatch => {
  return dispatch(set_therapist_state(state));
};

// TODO: update to use api middleware
export const refer_patient = (patient_info, answers) => (dispatch, getState) => {
  // create a user for the patient first
  // TODO: what happens if the user already exists here?
  patient_info.user_class = 'patient';
  return dispatch(global_actions.register_user(patient_info)).then(patient_user_info => {
    const user_attr = get_user_attr(getState());
    const { therapist } = user_attr;
    const body = { user_id: patient_user_info.id, answers };

    return axios
      .post(`/api/therapists/${therapist.id}/patients`, body, { headers: make_headers(user_attr) })
      .then(() => {
        // TODO: update global store with patient information
      });
  });
};

export const get_patients_for_therapist = () => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  const { therapist } = user_attr;
  return axios.get(`/api/therapists/${therapist.id}/patients`, { headers: make_headers(user_attr) });
};

export const get_referral_infos = () => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  return axios.get('/api/referral_infos', { headers: make_headers(user_attr) });
};

export const get_patient_details = patient_id => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  const { therapist } = user_attr;
  return axios.get(`/api/therapists/${therapist.id}/patients/${patient_id}`, { headers: make_headers(user_attr) });
};

export const get_service_lines = async () => {
  const { data } = await axios.get('/api/service_lines');
  return data;
};

export const get_patient_info_questions = () => async dispatch => {
  const bankName = 'therapist_patients_info';
  const resp = await axios.get(`/api/question_banks/search?name=${bankName}`);
  const { data } = await axios.get(`/api/question_banks/${resp.data.id}/questions`);
  const serviceLines = await get_service_lines();
  const index = data.findIndex(q => q.name === 'referral_reason');
  data[index].options = serviceLines;
  dispatch(set_therapist_questions(data));
};

export const update_refer_answers = answers => dispatch => {
  return dispatch(set_refer_answers(answers));
};

export const update_refer_index = index => dispatch => {
  return dispatch(set_refer_index(index));
};

export const update_refer_patients = patients => dispatch => {
  return dispatch(set_refer_patients(patients));
};

export const clean_refer_data = () => dispatch => {
  return dispatch({ type: CLEAN_REFER_DATA });
};

export const write_note_for_patients = (patient_id, note) => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  const { therapist } = user_attr;
  const body = { patient_id, note };
  return axios.post(`/api/therapists/${therapist.id}/notes`, body, { headers: make_headers(user_attr) });
};
