import React from 'react';
import Modal from 'react-modal';

const defaultStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 0,
    overflow: 'visible'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    zIndex: 99
  }
};

const ModalWrapper = ({ children, isOpen, onClose, shouldCloseOnOverlayClick = true }) => {
  return (
    <Modal
      style={defaultStyle}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      contentLabel="Modal"
      ariaHideApp={false}
      onRequestClose={onClose}
    >
      {children}
    </Modal>
  );
};

export default ModalWrapper;
