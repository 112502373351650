import React, { useState } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { sign_in, reset_password, change_password } from '../../actions/user_auth_action';
import { get_patient_details } from '../../actions/patient_action';
import InputDefault from '../UI/InputDefault';
import SubmitButton from '../UI/SubmitButton';
import Spinner from '../Spinner';
import { mapResponseToError } from '../../utils/common';
import PasswordValidator from '../../utils/password-validator';
import PasswordErrors from '../PasswordErrors/PasswordErrors';

const Wrapper = styled.div`
  .input-holder {
    margin-top: 10px;
  }
`;

function PasswordReset({ history }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordValidator, setPasswordValidator] = useState(new PasswordValidator(''));
  const [confirmation, setConfirmation] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const search = new URLSearchParams(window.location.search);

  const onResetClick = async () => {
    if (email === '') {
      setError('Please enter your email');
    } else {
      try {
        setLoading(true);
        setError('');
        await dispatch(reset_password(email, `${process.env.REACT_APP_URL}/user/password_reset/change`));
        history.push('/user/password_reset/sent');
      } catch (e) {
        setError(mapResponseToError(e));
      }
      setLoading(false);
    }
  };

  const onSavePasswordClick = async () => {
    const localPasswordValidator = new PasswordValidator(password);
    setPasswordValidator(localPasswordValidator);

    if (!localPasswordValidator.isValid) {
      setError('Password invalid');
    } else if (password !== confirmation) {
      setError('Passwords do not match');
    } else {
      try {
        setLoading(true);

        await dispatch(
          change_password({
            password,
            'access-token': search.get('access-token'),
            client: search.get('client'),
            uid: search.get('uid')
          })
        );
        await dispatch(sign_in({ email: search.get('uid'), password }));
        await dispatch(get_patient_details());

        window.location.href = '/';
      } catch (e) {
        if ('data' in e && e.data.success === false) {
          setError(e.data.errors.full_messages.pop());
        }
      }
      setLoading(false);
    }
  };

  return (
    <div className="d-flex flex-column container-noprogress">
      <div className="d-flex flex-row justify-content-left therapist-header">
        <a href={process.env.REACT_APP_MAIN_PAGE_URL}>
          <img className="cerebral-logo" src={`${process.env.PUBLIC_URL}/img/logo.svg`} alt="link to main page" />
        </a>
      </div>

      <Wrapper>
        <Switch>
          <Route
            path="/user/password_reset"
            exact
            render={() => (
              <div className="question-block-center">
                <div className="d-flex flex-column main-noprogress">
                  <div className="description_noprogress">
                    <h1>Reset password for your account.</h1>
                  </div>
                  <div className="patient_signin">
                    <InputDefault
                      type="email"
                      event_handler={e => setEmail(e.target.value)}
                      error={error}
                      text="Email"
                      name="email"
                    />
                    <SubmitButton onClick={onResetClick}>
                      {loading ? <Spinner size="2.0rem" /> : 'Reset password'}
                    </SubmitButton>
                  </div>
                </div>
              </div>
            )}
          />

          <Route
            path="/user/password_reset/sent"
            render={() => (
              <div className="question-block-center">
                <div className="d-flex flex-column main-noprogress">
                  <div className="description_noprogress">
                    <h1>Check Your Email</h1>
                  </div>
                  <div className="patient_signin">
                    <p>A password reset link has been sent to {email}. Please check your inbox.</p>
                  </div>
                </div>
              </div>
            )}
          />

          <Route
            path="/user/password_reset/change"
            render={() => (
              <div className="question-block-center">
                <div className="d-flex flex-column main-noprogress">
                  <div className="description_noprogress">
                    <h1>Choose a password for your account.</h1>
                  </div>
                  <div className="patient_signin">
                    <InputDefault
                      type="password"
                      event_handler={e => setPassword(e.target.value)}
                      text="Password"
                      error={error && !passwordValidator.isValid ? ' ' : ''}
                      enableTogglePassword
                      name="password"
                    />
                    <InputDefault
                      type="password"
                      event_handler={e => setConfirmation(e.target.value)}
                      text="Confirm password"
                      error={error}
                      showErrorMessage={!!passwordValidator.isValid}
                      enableTogglePassword
                      name="password-confirm"
                    />
                    {error && !passwordValidator.isValid ? (
                      <PasswordErrors password_validator={passwordValidator} />
                    ) : null}
                    <div className="buttons">
                      <SubmitButton onClick={onSavePasswordClick}>
                        {loading ? <Spinner size="2.0rem" /> : 'Save new password'}
                      </SubmitButton>
                    </div>
                  </div>
                </div>
              </div>
            )}
          />
        </Switch>
      </Wrapper>
    </div>
  );
}

export default withRouter(PasswordReset);
