import React from 'react';
import styled from 'styled-components/macro';

const StyledDivider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  color: #979797;

  ::before,
  ::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #979797;
  }

  :not(:empty)::before {
    margin-right: 30px;
  }

  :not(:empty)::after {
    margin-left: 30px;
  }
`;

const Divider = () => {
  return <StyledDivider>or</StyledDivider>;
};

export default Divider;
