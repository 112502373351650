import styled, { css } from 'styled-components/macro';

export const BackButtonInner = styled.span`
  color: #250044;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
`;

export const BackButtonHolder = styled.div`
  line-height: 6em;
  position: fixed;
  z-index: 11;
  margin-left: 1em;
  @media (max-width: 991px) {
    height: 49px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    ${props =>
      props.showBanner
        ? css`
            height: 0;
            top: 64px;
          `
        : css`
            height: 0;
            top: 24px;
          `}
  }
`;
