import React from 'react';
import styled from 'styled-components/macro';

const Button = ({ children, icon = null, className, ...props }) => {
  return (
    <button {...props} type="button" className={className}>
      {icon} {children}
    </button>
  );
};

export default styled(Button)`
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  width: 300px;
  height: 65px;
  border: 1px solid #250044;
  border-radius: 2px;
  transition: ease-in-out 0.3s;
  background: #ffffff;
  letter-spacing: -0.4px;
  color: #250044;
  text-align: center;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 25px;
    height: 25px;
    margin-right: 10px;

    path {
      transition: ease-in-out 0.3s;
      fill: #250044;
    }
  }

  @media (hover: hover) {
    &:hover {
      background: #250044;
      color: #ffffff;
      path {
        fill: #ffffff;
      }
    }
  }

  &:active {
    background: #250044;
    color: #ffffff;
  }

  @media (max-width: 991px) {
    min-width: auto;
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
`;
