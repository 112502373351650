import React, { Fragment, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RolloutContext } from 'contexts/rollout';
import {
  ButtonsHolder,
  Text,
  ViewPlanDetails,
  CardInner,
  ReactivateButton,
  NoActiveSubscriptions,
  DeactivationReasons
} from './styled';
import plans, { isMentalHealthPlanOnly } from '../../utils/plans';
import { ReactComponent as ChangePlanImage } from '../dashboard/assets/change_plan.svg';
import { ReactComponent as AddPlanImage } from '../dashboard/assets/add_plan.svg';
import { ReactComponent as ViewSubscriptionImage } from '../dashboard/assets/view_subscription.svg';
import { ReactComponent as PrepayFSA } from '../dashboard/assets/prepay_fsa.svg';
import {
  isUserCanceled,
  directProviderMessagingAndTherapy,
  convertIdToPlanName,
  isNutritionOffering
} from '../../utils/common';
import { useGlobals } from '../../contexts/globals';

const FsaViewPlanDetails = styled(ViewPlanDetails)``;
// Account Info Page Subscription Plan Box if Error or Paused
const SubscriptionInfo = ({
  patient,
  subscription,
  subscriptionError,
  onViewDetails,
  is_loading,
  onReactivate,
  onPlanChange
}) => {
  const { flags, loadFlags } = useContext(RolloutContext);
  const { isTherapistRegion } = useGlobals();
  const b2bClient = useSelector(({ patient_reducer }) => patient_reducer?.accountId);

  const history = useHistory();
  const eligibleServiceLines = useSelector(({ patient_reducer }) => patient_reducer.eligibleServiceLines);
  const insuranceVisitsPolicy = useSelector(({ patient_reducer }) => patient_reducer.insurance_visits_policy);
  const onAddNutritionPlan = () => {
    history.push('/patient/add-nutrition-plan');
  };
  const onMoveToLandingPage = () => {
    window.location.href = `${process.env.REACT_APP_MAIN_PAGE_URL}/flexible-spending-accounts`;
  };
  if (subscriptionError || patient.status === 'paused') {
    return (
      <div className="main-content-small-card d-flex">
        <CardInner className="d-flex flex-column card-items-container justify-content-between">
          <div className="flex-shrink-1 flex-grow-1">
            <div className="small-card-title">Subscription Plan</div>
            {subscriptionError && <NoActiveSubscriptions>{subscriptionError}</NoActiveSubscriptions>}
            {!subscriptionError && (
              <NoActiveSubscriptions>
                Your subscription is paused. Please contact your care team at{' '}
                <a href="mailto:support@getcerebral.com">support@getcerebral.com</a> to resume your subscription.
              </NoActiveSubscriptions>
            )}
          </div>
        </CardInner>
      </div>
    );
  }

  // Account Info Page  Subscription Plan Box if Cancelled
  const { status, next_charge } = subscription;
  if (status === 'cancelled' || isUserCanceled(patient.status)) {
    return (
      <div className="main-content-small-card d-flex">
        <CardInner className="d-flex flex-column card-items-container justify-content-between">
          <div className="flex-shrink-1 flex-grow-1">
            <div className="small-card-title">Subscription Plan</div>
            <NoActiveSubscriptions>No active subscriptions</NoActiveSubscriptions>
            <ReactivateButton onClick={onReactivate}>Reactivate my subscription</ReactivateButton>
            <DeactivationReasons>
              <h4>* How did my account become deactivated?</h4>
              <h4>Possible reasons:</h4>
              <ul>
                <li>You requested for your account to be cancelled</li>
                <li>The credit card on your account is expired or invalid</li>
                <li>Your membership payment failed</li>
              </ul>
              <p>Reactivate your account by clicking the button below</p>
              <p>OR</p>
              <p>If a subscription was cancelled in error, please contact us at support@getcerebral.com.</p>
            </DeactivationReasons>
          </div>
        </CardInner>
      </div>
    );
  }

  useEffect(() => {
    if (flags && !Object.keys(flags).length) {
      loadFlags();
    }
  }, []);

  const plan =
    patient.insurance_status === 'active'
      ? plans.insurancePlans[patient.offering_key]
      : isTherapistRegion(patient.region) || b2bClient
      ? plans.cashPlans[patient.offering_key]
      : plans.cashPlans.legacy;
  const wording = status === 'scheduled for cancelation' ? 'Scheduled to cancel' : 'Next order date';
  const showChangePlan =
    patient.insurance_status !== 'active' &&
    (isTherapistRegion(patient.region) || b2bClient) &&
    (patient.offering_key !== 'counseling' || b2bClient) &&
    (!patient.direct_messaging_pilot || directProviderMessagingAndTherapy(patient.region)) &&
    !isNutritionOffering(patient.offering_key);
  const showAddNutritionPlan =
    patient.insurance_status !== 'active' &&
    insuranceVisitsPolicy?.active !== true &&
    eligibleServiceLines?.Nutrition &&
    isMentalHealthPlanOnly(patient.offering_key) &&
    flags?.enable_client_dashboard_upsell_nutrition;
  // Account Info Page Subscription Box with Active Plan
  return (
    <div className="main-content-small-card d-flex">
      <CardInner className="d-flex flex-column card-items-container justify-content-between">
        <div className="d-flex flex-column justify-content-between flex-shrink-1 flex-grow-1">
          <div>
            <div className="small-card-title">Subscription Plan</div>
            {!is_loading && next_charge && (
              <Fragment>
                <div className="small-card-item">{`${
                  patient.direct_messaging_pilot && !plan.name.toLowerCase().includes('therapy')
                    ? 'Medication & Care Plan'
                    : convertIdToPlanName(plan.id, plan.name)
                }`}</div>
                <span>{`${wording}: ${next_charge || 'mm/dd/yyyy'}`}</span>
              </Fragment>
            )}
          </div>
          {next_charge && (
            <ButtonsHolder>
              {showAddNutritionPlan && (
                <ViewPlanDetails onClick={() => onAddNutritionPlan()}>
                  <AddPlanImage />
                  <Text>Add nutrition to my plan</Text>
                </ViewPlanDetails>
              )}
              <FsaViewPlanDetails onClick={() => onMoveToLandingPage()}>
                <PrepayFSA />
                <Text>Pre-pay with FSA</Text>
              </FsaViewPlanDetails>
              {showChangePlan && (
                <ViewPlanDetails onClick={() => onPlanChange()}>
                  <ChangePlanImage />
                  <Text>Change my plan</Text>
                </ViewPlanDetails>
              )}
              <ViewPlanDetails onClick={onViewDetails}>
                <ViewSubscriptionImage />
                <Text>{!b2bClient ? 'View membership plan details' : 'View subscription plan details'}</Text>
              </ViewPlanDetails>
            </ButtonsHolder>
          )}
          {!is_loading && !next_charge ? <NoActiveSubscriptions>No active subscriptions</NoActiveSubscriptions> : null}
        </div>
        {!is_loading && !next_charge && (
          <ReactivateButton className="small-card-btn" onClick={onReactivate}>
            Reactivate my subscription
          </ReactivateButton>
        )}
      </CardInner>
    </div>
  );
};

SubscriptionInfo.propTypes = {
  onViewDetails: PropTypes.func.isRequired,
  is_loading: PropTypes.bool.isRequired,
  onReactivate: PropTypes.func,
  subscription: PropTypes.shape({}).isRequired
};

SubscriptionInfo.defaultProps = {
  onReactivate: () => {}
};

export default SubscriptionInfo;
