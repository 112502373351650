import React from 'react';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import styled from 'styled-components/macro';
import { CardElement, ElementsConsumer, Elements } from '@stripe/react-stripe-js';
import {
  update_payment_info,
  get_payment_info,
  update_subscription,
  update_stripe_payment_info,
  update_stripe_subscription
} from '../../actions/patient_action';
import {
  formatCardNumber,
  getCardTypeAndMaxLength,
  testCards,
  countryFromStorage,
  countryFromUrl
} from '../../utils/common';
import PaymentForm from '../PaymentForm';
import ProgressModal from '../ProgressModal';
import Error from '../Error';
import './index.scss';
import { withAcceptScript } from '../../utils/accept-script';
import strings from '../../localization';

const delay = seconds => new Promise(resolve => setTimeout(resolve, seconds));

const envStripe =
  countryFromUrl() === 'uk' ? process.env.REACT_APP_STRIPEJS_KEY : process.env.REACT_APP_STRIPE_API_KEY_US;
const stripePromise = loadStripe(envStripe);

const CreditCardElement = styled(CardElement)`
  border: 1px solid black;
  width: 100%;
  height: 60px;
  border: 1px solid #d3dbf8;
  border-radius: 4px;
  box-shadow: 0px 0px 12px 4px #f6f6f9;
  padding: 12px;
  padding-top: 20px;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
`;

const ELEMENT_OPTIONS = {
  style: {
    base: {
      display: 'block',
      marginTop: '10px',
      maxWidth: '500px',
      padding: '10px',
      border: '1px solid #d3dbf8',
      boxShadow: '0px 0px 12px 4px #f6f6f9',
      background: 'white',
      outline: 0,
      borderRadius: '4px',
      fontSize: '14px',
      letterSpacing: '0.025em',
      fontFamily: `"Montserrat Regular", sans-serif`,
      fontSmoothing: 'antialiased',
      '::placeholder': {
        fontFamily: `"Montserrat Regular", sans-serif`,
        fontSize: '14px',
        color: '#250044',
        fontWeight: 400
      }
    },
    invalid: {
      color: '#9e2146'
    }
  }
};

class CreditCard extends React.Component {
  state = {
    is_loading: false,
    cardHolder: '',
    cardNumber: '',
    postal_code: '',
    month: '',
    year: '',
    cardCode: '',
    showZip: false,
    errors: [],
    is_fetching_new_payment: false,
    cardComplete: false
  };

  componentDidMount() {
    const { acceptScript } = this.props;
    acceptScript.injectScript();
  }

  createPaymentAsync = data => {
    return new Promise(resolve => {
      window.Accept.dispatchData(data, resolve);
    });
  };

  parseErrors = response => {
    let i = 0;
    const result = [];

    while (i < response.messages.message.length) {
      result.push(response.messages.message[i].text);
      i += 1;
    }
    return this.setState({
      errors: result
    });
  };

  refinedData = () => {
    const authData = {
      clientKey: process.env.REACT_APP_AUTHORIZE_NET_CLIENT_KEY,
      apiLoginID: process.env.REACT_APP_AUTHORIZE_NET_API_LOGIN_ID
    };

    const { cardNumber, month, year, cardCode, postal_code, cardHolder } = this.state;

    const numbers = cardNumber.split(' ').join('');

    return {
      authData,
      cardData: {
        cardNumber: numbers,
        month,
        year,
        cardCode,
        fullName: cardHolder,
        zip: postal_code
      }
    };
  };

  createPayment = () => {
    const { updateSubscription, getPaymentInfo, submitPayment, visit_object, user } = this.props;

    const { cardNumber, cardHolder, postal_code, cardCode } = this.state;

    if (cardHolder.split(' ').length < 2) {
      return this.setState({
        errors: ["Please input the cardholder's full name."]
      });
    }

    if (cardCode.length < 3) {
      return this.setState({
        errors: ['Please confirm that your payment details are correct.']
      });
    }

    if (postal_code.length < 5) {
      return this.setState({
        errors: ['Please confirm that your ZIP code is entered correctly.']
      });
    }

    if (process.env.REACT_APP_ALLOW_TESTCARDS === 'false' && testCards.includes(cardNumber.replace(/\s/g, ''))) {
      return this.setState({
        errors: ['Please provide valid credit card number.']
      });
    }

    return this.createPaymentAsync(this.refinedData()).then(response => {
      if (response.messages.resultCode === 'Error') {
        return this.parseErrors(response);
      }

      const body = {
        opaqueData: response.opaqueData,
        patient_id: user.attributes.patient.id,
        visit_id: visit_object.id,
        cardholder_name: cardHolder,
        zip: postal_code
      };
      this.setState({ is_loading: true });
      return submitPayment(body, user.attributes.patient.id)
        .then(() => {
          // I moved this ".then().catch()" block inside because otherwise we always get there, even from line #86
          // we can handle a response somehow
          this.setState({
            is_loading: false,
            is_fetching_new_payment: true
          });

          return Promise.all([updateSubscription(user.attributes.patient.id), delay(10000)]);
        })
        .then(() => {
          return getPaymentInfo(user.attributes.patient.id);
        })
        .then(({ data }) => {
          this.props.onUpdate(data);
        })
        .catch(err => {
          this.setState({
            errors: [err.data.errors],
            is_loading: false,
            is_fetching_new_payment: false
          });
        });
    });
  };

  createStripePayment = async () => {
    const { stripe, elements, updateStripeSubscription, getPaymentInfo, submitStripePayment, visit_object, user } =
      this.props;
    const { cardHolder, cardComplete } = this.state;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    if (cardHolder.split(' ').length < 2) {
      return this.setState({
        errors: ["Please input the cardholder's full name."]
      });
    }
    if (!cardComplete) {
      return this.setState({
        errors: ['Please confirm that your payment details are correct.']
      });
    }
    const card = elements.getElement(CardElement);

    if (card == null) {
      return;
    }
    try {
      this.setState({ is_loading: true });
      const { token, error } = await stripe.createToken(card);
      if (error) {
        console.error(error);
        this.setState({
          errors: ['It seems something went wrong and your payment could not be submitted. Please try again.'],
          is_loading: false,
          is_fetching_new_payment: false
        });
        return;
      }
      if (token) {
        const body = {
          token: token.id,
          patient_id: user.attributes.patient.id,
          visit_id: visit_object.id,
          cardholder_name: cardHolder
        };

        submitStripePayment(body, user.attributes.patient.id)
          .then(() => {
            // I moved this ".then().catch()" block inside because otherwise we always get there, even from line #86
            // we can handle a response somehow
            this.setState({
              is_loading: false,
              is_fetching_new_payment: true
            });

            return Promise.all([updateStripeSubscription(user.attributes.patient.id), delay(10000)]);
          })
          .then(() => {
            return getPaymentInfo(user.attributes.patient.id);
          })
          .then(({ data }) => {
            this.props.onUpdate(data);
          })
          .catch(err => {
            this.setState({
              errors: [err.data.errors],
              is_loading: false,
              is_fetching_new_payment: false
            });
          });
      }
    } catch (err) {
      return Error('failed to create token, try again');
    }
  };

  // TODO: validate MM/YY CVV and ZIP so they are numbers
  validateCC = (val, type) => {
    let value = val;

    switch (type) {
      case 'month':
      case 'year':
        value = value.replace(/\D/g, '');
        return value.length > 2 ? value.substring(0, 2) : value;
      case 'cardCode':
        value = value.replace(/\D/g, '');
        return value.length > 3 ? value.substring(0, 3) : value;
      case 'postal_code':
        value = value.replace(strings.zipCodeParser, '');
        return value.length > strings.zipCodeLength ? value.substring(0, strings.zipCodeLength) : value;
      case 'cardNumber':
        return value.length < 2 || value.length < this.state[type].length ? value : formatCardNumber(value);
      case 'cardHolder':
        return value.trim().split(' ').filter(Boolean).join(' ');
      default:
        return value;
    }
  };

  isZipShow = () => {
    const { month, year, cardCode, cardNumber } = this.state;
    const maxLength = getCardTypeAndMaxLength(cardNumber)[1];

    if (month.length === 2 && year.length === 2 && cardCode.length === 3 && cardNumber.length === maxLength) {
      return true;
    }
    return false;
  };

  updateField = (e, type) => {
    this.setState(
      {
        [type]: this.validateCC(e.target.value, type)
      },
      () => {
        this.setState({
          showZip: this.isZipShow()
        });
      }
    );
  };

  render() {
    const { is_fetching_new_payment, showZip, cardNumber, cardCode, month, year, postal_code, errors } = this.state;
    const { acceptScript, abTest } = this.props;

    const isStripeUS = abTest?.stripe_gateway_checkout === 'stripe' && countryFromUrl() === 'us';

    if (is_fetching_new_payment) {
      return (
        <p>
          Your new payment information is in the process of being updated. Please check back momentarily to confirm that
          the new payment information has been submitted successfully.
        </p>
      );
    }

    return (
      <>
        <div className="small-card-item">
          <input type="text" placeholder="Card Holder Name" onChange={e => this.updateField(e, 'cardHolder')} />
        </div>
        {countryFromStorage() === 'uk' || isStripeUS ? (
          <CreditCardElement
            options={ELEMENT_OPTIONS}
            onChange={e => {
              if (e.error) this.setState({ errors: [e.error.message] });
              if (e.complete) this.setState({ cardComplete: true, errors: [] });
            }}
          />
        ) : (
          <div className="bank-card-holder d-flex">
            <PaymentForm
              values={{
                cardCode,
                cardNumber,
                month,
                year,
                postal_code
              }}
              showZip={showZip}
              onUpdateField={this.updateField}
            />
          </div>
        )}
        {errors.length > 0 && (
          <div className="errors-holder">
            {errors.map((error, idx) => (
              <Error key={idx} error={error} />
            ))}
          </div>
        )}
        {acceptScript.loaded && (
          <div className="question-block">
            <div
              className="small-card-btn"
              onClick={countryFromStorage() === 'uk' || isStripeUS ? this.createStripePayment : this.createPayment}
            >
              Submit
            </div>
            <div className="small-card-btn" onClick={this.props.onClose}>
              Cancel
            </div>
          </div>
        )}
        <ProgressModal open={this.state.is_loading} />
      </>
    );
  }
}

const mapStateToProps = state => ({
  visit_object: state.patient_reducer.visit_object,
  user: state.global_reducer.current_user,
  abTest: state.patient_reducer.abTest
});

const mapDispatchToProps = {
  submitPayment: update_payment_info,
  submitStripePayment: update_stripe_payment_info,
  updateSubscription: update_subscription,
  updateStripeSubscription: update_stripe_subscription,
  getPaymentInfo: get_payment_info
};

const InjectedCreditCard = props => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            family: 'Montserrat Regular',
            weight: 400,
            src: `local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2')`
          }
        ]
      }}
    >
      <ElementsConsumer>
        {({ stripe, elements }) => <CreditCard stripe={stripe} elements={elements} {...props} />}
      </ElementsConsumer>
    </Elements>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withAcceptScript(InjectedCreditCard));
