import React from 'react';
import Spinner from '../../Spinner';
import TimedModal from '../../TimedModal/TimedModal';

const PageLoading: React.FC = () => (
  <TimedModal
    component={() => (
      <div style={{ minHeight: '100vh' }} className="d-flex align-items-center justify-content-center">
        <Spinner size="2.0rem" borderWidth="3px" />
      </div>
    )}
    delay={200}
  />
);

export default PageLoading;
