import React from 'react';
import styled from 'styled-components/macro';
import isotypeImage from '../../img/iso.svg';

export const Paragraph = styled.div<{ fontSize?: string }>`
  font-size: ${props => props?.fontSize ?? '16px'};
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.43px;
  color: #250044;
  margin: 43px auto 0;
  width: 80%;
`;

export const Button = styled.button<{ width?: string }>`
  background: #6786ff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 28.5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  height: 60px;
  width: ${props => props.width || '250px'};
  padding: 0 20px;
  margin: 48px auto 0;
  border: none;
  outline: none;
  display: block;
`;

const Isotype = styled.div`
  background-image: url(${isotypeImage});
  height: 38px;
  width: 56px;
  margin: 0 auto;
  background-position: center top;
`;

const Header = styled.div`
  padding: 31px 0;
`;

const Content = styled.div`
  text-align: center;
`;

const Title = styled.div`
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #6786ff;
  margin-top: 6px;
`;

const Wrapper = styled.div`
  background: #eff1fa;
  border: 1px solid #e9edff;
  border-radius: 2px;
  max-width: 360px;
  width: 100%;
  margin: 12px;
  padding-bottom: 47px;
`;

export const ScheduleButton = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #6786ff;
  margin: 20px auto 0;
  border: none;
  outline: none;
  background: transparent;
`;

interface Props {
  title: string;
}

const Box: React.FC<Props> = ({ title, children }) => {
  return (
    <Wrapper>
      <Header>
        <Isotype />
        <Title>{title}</Title>
      </Header>
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default Box;
