import React from 'react';
import { bool } from 'prop-types';
import WithMessageMethods from '../../HOC/WithMessageMethods';
import MobileMessenger from './MobileMessenger';
import DesktopMessenger from './DesktopMessenger';
import Holder from './styled';

const Messenger = ({ withHeader, dashboard = false, providerMessenger = false, ...rest }) => (
  <WithMessageMethods
    {...rest}
    dashboard={dashboard}
    providerMessenger={providerMessenger}
    render={(props, methods) => {
      if (window.innerWidth < 992) {
        return (
          <Holder mobile dashboard={dashboard}>
            <MobileMessenger {...props()} {...methods()} />
          </Holder>
        );
      }
      return (
        <Holder>
          <DesktopMessenger withHeader={withHeader} {...props()} {...methods()} />
        </Holder>
      );
    }}
  />
);

Messenger.propTypes = {
  withHeader: bool
};

Messenger.defaultProps = {
  withHeader: true
};

export default Messenger;
