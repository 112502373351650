import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const MainContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px 75px 75px 75px;
  background-color: #fcfbfe;
  min-height: calc(100vh - 80px);
  height: 100%;

  @media (max-width: 768px) {
    padding: 40px 24px;
  }
`;

export const SectionTitle = styled.h3`
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
  letter-spacing: -0.43px;
`;

export const AssignmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 950px;
  justify-content: space-between;
  margin: 20px auto;
  flex-wrap: wrap;
`;

export const NoAssignmentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 360px;
  height: 100px;
  background: #ffffff;
  box-shadow: 0px 2px 2px #d3dbf8;
  border-radius: 4px;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #6786ff;
  margin: 0 auto 50px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const HistoryLink = styled(Link)`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.43px;
  color: #6786ff;
  margin-top: 80px;
  text-decoration: underline;
`;
