import { getVariantValue } from '../utils';

const abTestServiceLines = () => {
  let valueKameleoon: string | undefined = 'Reference';

  const experimentName = 'Test Service line Other and Im not sure';
  valueKameleoon = getVariantValue(experimentName);
  return ['Excluded', 'Disabled'].includes(valueKameleoon) ? 'Reference' : valueKameleoon;
};

export { abTestServiceLines };
