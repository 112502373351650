import styled from 'styled-components/macro';

const Footnote = styled.p<{ isNormal?: boolean }>`
  &,
  + ul {
    font-style: ${({ isNormal }) => (isNormal ? 'normal' : 'italic')};
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.43px;
  }

  + ul {
    padding-left: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  a {
    color: #95abff;
    text-decoration: none;
  }
`;

export default Footnote;
