import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updatePatientAddress } from '../../../actions/patient_action';
import AddressForm, { validate } from '../../MultiStepForm/Form/AddressForm';
import { VariationContext } from '../../Variations/VariationContext';
import usePromise from '../../../utils/usePromise';

const ShippingInformation = () => {
  const user = useSelector(({ global_reducer: { current_user } }) => current_user);
  const { country } = useContext(VariationContext);
  const [editable, setEditable] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [prevAddress, loadedPrevAddress] = usePromise(`/api/patients/${user.attributes.patient.id}/addresses`, [
    reFetch
  ]);
  const [newAddress, setNewAddress] = useState({});
  const [errors, setErrors] = useState({});
  const { address_1, address_2, address_3, city, region, postal_code } = newAddress;
  const dispatch = useDispatch();

  const resetAddress = () => setNewAddress(prevAddress?.data?.[0] || {});

  useEffect(() => {
    if (!loadedPrevAddress) return;
    resetAddress();
  }, [loadedPrevAddress]);

  const toggleEditable = () => {
    setEditable(prevState => !prevState);
    resetAddress();
  };

  const submitHandler = async () => {
    if (address_1 && city && region && postal_code) {
      const addr = {
        address_1,
        address_2,
        address_3,
        city,
        region,
        postal_code
      };

      if (!/^\d{5}$/.test(postal_code) && country === 'us') {
        return alert('Please input valid address');
      }

      await dispatch(updatePatientAddress(addr));
      setEditable(prevState => !prevState);
      setReFetch(prevState => !prevState);
      alert('Your shipping information has been updated successfully');
    } else {
      alert('Please input valid address');
    }
  };

  return (
    <div className="d-flex align-self-start main-content-small-card">
      <div className="d-flex flex-column justify-content-between flex-grow-1 flex-shrink-1 card-items-container">
        <div>
          <div className="small-card-title">SHIPPING INFORMATION</div>
          <AddressForm
            addressState={[newAddress, setNewAddress]}
            editable={editable}
            addressLoaded={loadedPrevAddress}
            errors={errors}
          />
        </div>
        {!editable ? (
          <div className="small-card-btn" onClick={toggleEditable}>
            EDIT
          </div>
        ) : (
          <div className="question-block">
            <div className="small-card-btn" onClick={() => validate(newAddress, setErrors, submitHandler, country)}>
              Submit
            </div>
            <div className="small-card-btn" onClick={toggleEditable}>
              Cancel
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShippingInformation;
