import styled from 'styled-components/macro';

export default styled.button<{ noMargin?: boolean; disabled?: boolean }>`
  margin-top: ${({ noMargin }) => (noMargin ? '0' : '50px')};
  background: #250044;
  width: 240px;
  max-width: 100%;
  height: 60px;
  box-shadow: 0 2px 14px rgba(34, 34, 52, 0.13);
  border-radius: 28.5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border: none;
  @media (hover: hover) {
    &:hover {
      background: #3e0370;
      border-radius: 28.5px;
    }
  }
  opacity: ${({ disabled }) => (disabled ? '.2' : '1')};
`;
