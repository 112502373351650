import React from 'react';
import styled from 'styled-components/macro';
import { ConsentInfo, CheckboxWrapper, ConsentLink } from './styled';
import CheckBoxDefault from '../../CheckBoxDefault';

const CheckBoxWrapper = styled(CheckboxWrapper)`
  margin-left: 15px;
  margin-right: 15px;
`;
const TermAndConditionFooter = ({ setAgreeTerm, agreeTermOpt, setAgreeConsent, agreeConsentOpt }) => {
  return (
    <>
      <CheckBoxWrapper>
        <CheckBoxDefault eventHandler={setAgreeTerm} isChecked={agreeTermOpt} name="consent_checkbox1">
          <ConsentInfo>
            I have read and agree to Cerebral’s&nbsp;
            <ConsentLink href="https://getcerebral.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
              Terms & Conditions
            </ConsentLink>
            ,&nbsp;
            <ConsentLink href="https://getcerebral.com/privacy-policy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </ConsentLink>
            &nbsp;and&nbsp;
            <ConsentLink
              href="https://getcerebral.com/telehealth-informed-consent"
              target="_blank"
              rel="noopener noreferrer"
            >
              Telehealth Consent.
            </ConsentLink>
          </ConsentInfo>
        </CheckBoxDefault>
      </CheckBoxWrapper>
      <CheckBoxWrapper>
        <CheckBoxDefault eventHandler={setAgreeConsent} isChecked={agreeConsentOpt} name="consent_checkbox2">
          <ConsentInfo>
            I consent to receive SMS messages and phone calls (which may be automated) regarding my treatment plan, as
            well as updates regarding Cerebral’s products & services (message and data rates may apply).
          </ConsentInfo>
        </CheckBoxDefault>
      </CheckBoxWrapper>
    </>
  );
};

export default TermAndConditionFooter;
