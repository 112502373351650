import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import uuidv1 from 'uuid';
import { update_password } from '../../actions/user_auth_action';
import PasswordValidator from '../../utils/password-validator';
import { common_errors } from '../../utils/common';
import InputDefault from '../UI/InputDefault';

const EditPassword = props => {
  const [newPassword, setNewPassword] = useState({
    new_password: '',
    new_password_confirm: ''
  });
  const [forceUpdate, setForceUpdate] = useState();

  const update_password_handler = (type, value) => {
    setNewPassword(prevState => ({
      ...prevState,
      [type]: value
    }));
  };

  const submit_btn_handler = () => {
    const password_validator = new PasswordValidator(newPassword.new_password);
    if (!password_validator.isValid) {
      const errors = [];
      if (!password_validator.isValidLength) errors.push(`- ${common_errors.password_length}`);
      if (!password_validator.isValidUpperAndLowerCase) errors.push(`- ${common_errors.password_upper_and_lower_case}`);
      if (!password_validator.isValidNumber) errors.push(`- ${common_errors.password_at_least_one_number}`);
      if (!password_validator.isValidSymbols) errors.push(`- ${common_errors.password_at_least_one_symbol}`);
      alert(`Your password must: \n${errors.join('\n')}.`);
    } else if (newPassword.new_password === newPassword.new_password_confirm) {
      props
        .update_password(newPassword.new_password_confirm, newPassword.new_password)
        .then(() => {
          setNewPassword({ new_password: '', new_password_confirm: '' });
          setForceUpdate({});
          alert('Your password has been changed successfully');
        })
        .catch(() => {
          alert('your password should be at least 8 characters');
        });
    } else {
      alert("Password confirmation doesn't match password");
    }
  };

  const uuid = useMemo(() => uuidv1(), [forceUpdate]);

  return (
    <div className="d-flex align-self-start main-content-small-card" key={uuid}>
      <div className="d-flex flex-column justify-content-between flex-grow-1 flex-shrink-1 card-items-container">
        <div>
          <div className="small-card-title">Change password</div>
          <div className="small-card-item">
            <InputDefault
              event_handler={e => update_password_handler('new_password', e.target.value)}
              text="Password"
              default_value={newPassword.new_password}
              type="password"
              autoComplete="new-password"
              name="password"
              showErrorMessage={false}
              enableTogglePassword
            />
          </div>
          <div className="small-card-item">
            <InputDefault
              event_handler={e => update_password_handler('new_password_confirm', e.target.value)}
              text="Confirm Password"
              default_value={newPassword.new_password_confirm}
              type="password"
              autoComplete="new-password"
              name="confirm-password"
              showErrorMessage={false}
              enableTogglePassword
            />
          </div>
        </div>
        <div className="small-card-btn" onClick={() => submit_btn_handler()}>
          SUBMIT
        </div>
      </div>
    </div>
  );
};

export default connect(null, { update_password })(EditPassword);
