import React from 'react';
import { BackButtonHolder, BackButtonInner } from './styled';

const BackButton = ({ onClick, children, left, showBanner }) => (
  <BackButtonHolder showBanner={showBanner} left={left}>
    <BackButtonInner onClick={onClick}>{children}</BackButtonInner>
  </BackButtonHolder>
);

export default BackButton;
