import { AssessmentType } from 'types/assessments';

export const getTotalScore = (type: AssessmentType): number =>
  ({
    phq: 27,
    gad: 21,
    isi: 28,
    asrs: 6,
    alcohol: 12,
    smoking: 10
  }[type] || 0);

export const getDotPoint = (type: AssessmentType): number =>
  ({
    phq: 4,
    gad: 6,
    isi: 5,
    asrs: 1,
    alcohol: 5,
    smoking: 4
  }[type] || 4);

export const getYAxisRange = (type: AssessmentType): number =>
  ({
    phq: 30,
    gad: 21,
    isi: 28,
    asrs: 6,
    alcohol: 3,
    smoking: 10
  }[type] || 30);

export const getSeverity = (type: AssessmentType, score: number): string => {
  if (type === 'phq') {
    if (score < 5) return 'No Depression';
    if (score < 10) return 'Mild';
    if (score < 15) return 'Moderate';
    if (score < 20) return 'Moderately severe';
    return 'Severe';
  }
  if (type === 'gad') {
    if (score < 5) return 'No Anxiety';
    if (score < 10) return 'Mild';
    if (score < 15) return 'Moderate';
    return 'Severe';
  }
  if (type === 'isi') {
    if (score < 8) return 'No Insomnia';
    if (score < 15) return 'Subthreshold';
    if (score < 22) return 'Moderately severe';
    return 'Severe';
  }
  if (type === 'asrs') {
    if (score < 4) return 'Mild';
    if (score === 4) return 'Moderate';
    return 'Severe';
  }

  if (type === 'alcohol') {
    if (score < 4) return 'Mild';
    if (score < 7) return 'Moderate';
    return 'Severe';
  }

  if (type === 'smoking') {
    if (score < 4) return 'Minimal';
    if (score < 6) return 'Moderate';
    if (score < 10) return 'High';
    return 'Severe';
  }

  return 'Unknown';
};

export const getGraphLabel = (type: AssessmentType): string =>
  ({
    phq: 'Depression score',
    gad: 'Anxiety score',
    isi: 'Insomnia score',
    asrs: 'ADHD score',
    alcohol: 'Alcohol score',
    smoking: 'Smoking score'
  }[type] || 'Unknown');
