import styled from 'styled-components/macro';

export interface HolderProps {
  noBackground: boolean;
  opacity: number;
}

export const Holder = styled.div<HolderProps>`
  min-width: 360px;
  max-width: 360px;
  ${({ noBackground }) =>
    !noBackground &&
    `
    background: #FFFFFF;
    box-shadow: 0px 2px 2px #D3DBF8;
    border-radius: 4px;
  `}
  padding: 0 20px;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  opacity: ${({ opacity }) => opacity};
  @media (max-width: 991px) {
    min-width: calc(100% - 20px);
    max-width: 355px;
    padding: 0 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const HolderInner = styled.div`
  padding: 5px 0 0 0;
  .text-main-title {
    margin: 15px 0;
  }
`;
