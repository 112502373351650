import React, { Fragment } from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';
import { prescriberVariant } from 'utils/care-person-helpers';
import { useSelector } from 'react-redux';
import { isNotSupportInsurancePayment as checkInsurancePayment } from 'utils/plans';
import Messenger from '../../components/Messenger';
import Assessment from '../../components/Assessment';
import AppointmentsView from '../../components/AppointmentsView';
import PrescriptionInfoView from '../../components/PrescriptionInfoView';
import CompleteTheFlow from '../../components/dashboard/NotCompletedFlow';
import NotExistingPage from '../../components/dashboard/NotExistingPage';
import ProfileInfo from '../../components/dashboard/ProfileInfo';
import Invoices from '../../components/dashboard/Invoices';
import ReactivateInfo from '../../components/dashboard/ReactivateInfo';
import PatientPaymentInsurance from '../../components/question_types/patient_payment_insurance';
import ExerciseWrapper from '../exercises';
import { ProfileHolder } from './styled';
import { isAddictionServiceLine } from '../../utils/common';
import './dashboard.contents.scss';

export const Wrapper = ({ children, isNoPadding = false, showBanner = false }) => (
  <ProfileHolder showBanner={showBanner}>
    {isNoPadding ? children : <div className="profile-main-container">{children}</div>}
  </ProfileHolder>
);

const FluidWrapper = styled.div.attrs({ className: 'profile-container-fluid' })`
  padding-top: 80px;
  width: 100%;

  @media (max-width: 1024px) {
    padding-top: 48px;
  }
`;

// TODO: will use it as wrapper
const DashboardContents = mProps => {
  const { go_to = () => {}, match, handleLogo, showBanner } = mProps;
  const patient = useSelector(({ patient_reducer }) => patient_reducer);
  const visitValues = useSelector(({ patient_reducer: { visits } }) => visits);
  const isNotSupportInsurancePayment =
    checkInsurancePayment(patient?.offering_key) || isAddictionServiceLine(visitValues);

  return (
    <Switch>
      <Route
        key="messenger"
        path={`${match.url}/message`}
        render={() => (
          <Fragment>
            <Helmet>
              <title>Cerebral Patient Dashboard | Manage Your Care & Meds | Messages</title>
              <meta
                name="description"
                content="Chat with your care team.  Manage your treatment plan and medications for anxiety, depression, and insomnia."
              />
            </Helmet>
            <Messenger name="Sarah Crosswell" handleLogo={handleLogo} withHeader={false} dashboard />
          </Fragment>
        )}
      />
      <Route
        key="providerMessenger"
        path={`${match.url}/provider_message`}
        render={() => (
          <Fragment>
            <Helmet>
              <title>Cerebral Patient Dashboard | Manage Your Care & Meds | Messages</title>
              <meta
                name="description"
                content="Chat with your care team.  Manage your treatment plan and medications for anxiety, depression, and insomnia."
              />
            </Helmet>
            <Messenger name="Sarah Crosswell" handleLogo={handleLogo} withHeader={false} dashboard providerMessenger />
          </Fragment>
        )}
      />
      <Route
        path={`${match.url}/appointments`}
        render={props => (
          <Wrapper isNoPadding showBanner={showBanner}>
            <Helmet>
              <title>Cerebral Patient Dashboard | Manage Your Care & Meds | Appointments</title>
              <meta
                name="description"
                content={`Manage online consultations with your ${prescriberVariant.singularNormal} and Care Counselor.  Complete tasks.`}
              />
            </Helmet>
            <AppointmentsView
              showBanner={showBanner}
              {...props}
              handleLogo={handleLogo}
              isNotSupportInsurancePayment={isNotSupportInsurancePayment}
            />
          </Wrapper>
        )}
      />
      <Route
        path={`${match.url}/prescription`}
        render={({ history }) => (
          <Wrapper showBanner={showBanner}>
            <Helmet>
              <title>Cerebral Patient Dashboard | Manage Your Care & Meds | Prescription</title>
              <meta
                name="description"
                content="View and manage prescriptions, prescription history, and delivery schedule"
              />
            </Helmet>
            <PrescriptionInfoView history={history} />
          </Wrapper>
        )}
      />
      <Route path={`${match.url}/profile_info`}>
        <Wrapper showBanner={showBanner}>
          <Helmet>
            <title>Cerebral Patient Dashboard | Manage Your Care & Meds | Profile Info</title>
            <meta
              name="description"
              content="Dashboard to manage online consultations, prescriptions, and account information. Chat with care team and view anxiety, depression, and insomnia assessments."
            />
          </Helmet>
          <ProfileInfo handleLogo={handleLogo} isNotSupportInsurancePayment={isNotSupportInsurancePayment} />
        </Wrapper>
      </Route>

      <Route path={`${match.url}/update_visit_insurance_cards`}>
        <Wrapper showBanner={showBanner}>
          <Helmet>
            <title>Cerebral Patient Dashboard | Manage Your Care & Meds | Profile Info</title>
            <meta
              name="description"
              content="Dashboard to manage online consultations, prescriptions, and account information. Chat with care team and view anxiety, depression, and insomnia assessments."
            />
          </Helmet>
          <ProfileInfo checkDirectLink="insurance_direct" handleLogo={handleLogo} />
        </Wrapper>
      </Route>

      <Route path={`${match.url}/update_medication_insurance_cards`}>
        <Wrapper showBanner={showBanner}>
          <Helmet>
            <title>Cerebral Patient Dashboard | Manage Your Care & Meds | Profile Info</title>
            <meta
              name="description"
              content="Dashboard to manage online consultations, prescriptions, and account information. Chat with care team and view anxiety, depression, and insomnia assessments."
            />
          </Helmet>
          <ProfileInfo checkDirectLink="insurance_medication" handleLogo={handleLogo} />
        </Wrapper>
      </Route>
      <Route path={`${match.url}/update_insurance_cards`}>
        <Wrapper showBanner={showBanner}>
          <Helmet>
            <title>Cerebral Patient Dashboard | Manage Your Care & Meds | Profile Info</title>
            <meta
              name="description"
              content="Dashboard to manage online consultations, prescriptions, and account information. Chat with care team and view anxiety, depression, and insomnia assessments."
            />
          </Helmet>
          <ProfileInfo checkDirectLink="update_insurance_cards" handleLogo={handleLogo} />
        </Wrapper>
      </Route>
      <Route
        path={`${match.url}/result`}
        render={() => (
          <Wrapper showBanner={showBanner}>
            <Helmet>
              <title>Cerebral Patient Dashboard | Manage Your Care & Meds | Results</title>
              <meta name="description" content="View the results of your assessments" />
            </Helmet>
            <Assessment />
          </Wrapper>
        )}
      />
      <Route path={`${match.url}/invoices`}>
        <Wrapper showBanner={showBanner}>
          <Helmet>
            <title>Cerebral Patient Dashboard | Manage Your Care & Meds | Invoices</title>
            <meta
              name="description"
              content="View your transaction history. Manage your treatment plan and medications for anxiety, depression, and insomnia."
            />
          </Helmet>
          <Invoices />
        </Wrapper>
      </Route>
      <Route path={`${match.url}/patient_exercises`}>
        <FluidWrapper>
          <Helmet>
            <title>Cerebral Patient Dashboard | Manage Your Care & Meds | Exercises</title>
            <meta
              name="description"
              content="View and manage prescriptions, prescription history, and delivery schedule"
            />
          </Helmet>
          <ExerciseWrapper />
        </FluidWrapper>
      </Route>
      <Route
        path={`${match.url}/incomplete`}
        render={() => (
          <Wrapper showBanner={showBanner}>
            <CompleteTheFlow go_to={go_to} />
          </Wrapper>
        )}
      />
      <Route path={`${match.url}/reactivation_payment`}>
        <Wrapper showBanner={showBanner}>
          <Helmet>
            <title>Cerebral Patient Dashboard | Manage Your Care & Meds | Reactivation payment</title>
            <meta name="description" content="Reactivation payment" />
          </Helmet>
          <PatientPaymentInsurance isReactivate />
        </Wrapper>
      </Route>
      <Route path={`${match.url}/reactivation_info`}>
        <Wrapper showBanner={showBanner}>
          <Helmet>
            <title>Cerebral Patient Dashboard | Manage Your Care & Meds | Reactivation information</title>
            <meta name="description" content="Reactivation information" />
          </Helmet>
          <ReactivateInfo />
        </Wrapper>
      </Route>
      <Route
        path={`${match.url}/`}
        render={props => (
          <Wrapper showBanner={showBanner}>
            <NotExistingPage {...props} />
          </Wrapper>
        )}
      />
    </Switch>
  );
};

export default withRouter(DashboardContents);
