import LocalizedStrings from 'react-localization';
import us from './us';
import usb2b from './usb2b';
import uk from './uk';
import { IStrings } from './types';

const strings = new LocalizedStrings<IStrings>({
  us,
  usb2b,
  uk
});

export default strings;
