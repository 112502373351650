import React, { useState, useCallback } from 'react';
import InputMask from 'react-input-mask';
import Error from '../../Error';
import { InputWrapper, InputInnerWrapper, ToggleBtn } from './styled';
import iconEyeClosed from '../../../img/icons/ic-eye-closed.svg';
import iconEyeOpen from '../../../img/icons/ic-eye-open.svg';

export interface InputDefaultProps {
  wrapperStyle?: React.CSSProperties;
  event_handler?: (e: React.ChangeEvent) => void;
  text: string;
  default_value: string;
  error?: string;
  showErrorMessage?: boolean;
  enableTogglePassword?: boolean;
  mask: string;
  maskPlaceholder: string;
  type?: string;
  name: string;
  autoComplete: string;
  inputMode: 'none' | 'text' | 'search' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | undefined;
  showLabel?: boolean;
  placeholder?: string;
}

const InputDefault: React.FC<InputDefaultProps> = ({
  wrapperStyle = {},
  event_handler,
  text,
  default_value = '',
  error = '',
  showErrorMessage = true,
  enableTogglePassword = false,
  mask,
  maskPlaceholder = '',
  showLabel = true,
  ...inputAttrs
}) => {
  const [visiblePassword, setVisiblePassword] = useState(false);
  const fieldId = `input-${inputAttrs.name.toLowerCase().split(' ').join('-')}`;
  const inputType = inputAttrs.type === 'password' && visiblePassword ? 'text' : inputAttrs.type;

  const inputClasses = `input-type1 ${error ? 'error_border' : ''}`;

  const Input = useCallback(
    ({ defaultValue }) => {
      const inputProps = {
        ...inputAttrs,
        id: fieldId,
        className: inputClasses,
        type: inputType,
        onChange: event_handler
      };

      if (mask) {
        return (
          <InputMask
            mask={defaultValue ? mask : ''}
            placeholder={maskPlaceholder || text}
            {...inputProps}
            value={defaultValue}
          />
        );
      }
      return <input placeholder={text} defaultValue={defaultValue} {...inputProps} />;
    },
    [inputType, inputClasses, showLabel]
  );

  return (
    <InputWrapper style={wrapperStyle}>
      {showLabel && (
        <label htmlFor={fieldId}>{(default_value && default_value.length > 0) || mask ? text : null}</label>
      )}
      <InputInnerWrapper>
        <Input defaultValue={default_value} />
        {enableTogglePassword && inputAttrs.type === 'password' && (
          <ToggleBtn onClick={() => setVisiblePassword(!visiblePassword)} type="button">
            {visiblePassword ? (
              <img src={iconEyeClosed} alt="Hide password" />
            ) : (
              <img src={iconEyeOpen} alt="Show password" />
            )}
          </ToggleBtn>
        )}
      </InputInnerWrapper>
      {showErrorMessage && <Error error={error} />}
    </InputWrapper>
  );
};

export default InputDefault;
