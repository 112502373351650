import React from 'react';
import { useHistory } from 'react-router-dom';
import { SubmitButton } from 'components/question_components/styled_components';
import styled from 'styled-components/macro';
import { Title, Info } from './styled';

const Wrapper = styled.div`
  ${SubmitButton} {
    margin: 50px auto 0;
    display: block;
    max-width: 400px;
  }
`;

export const ReactivateInfo: React.FC = () => {
  const history = useHistory();
  const onContinue = () => {
    history.push('/patient/create-account');
  };

  return (
    <Wrapper>
      <Title>Thank you again for choosing to restart your Cerebral care!</Title>
      <Info>
        Because some of your information may have changed since the last time we connected, please answer the following
        questions to let us know where you are and how you&apos;re doing.
      </Info>
      <SubmitButton value="Continue" onClick={onContinue} />
    </Wrapper>
  );
};

export default ReactivateInfo;
