import axios from 'axios';
import { batch } from 'react-redux';
import { make_headers, get_user_attr } from './user_auth_action';
import { isBeforePayment } from '../utils/common';
import { CareUserEnum } from '../types/care-user.enum';

export const SET_SOCKET_CONNESTION = 'SET_SOCKET_CONNECTION';
export const SET_INIT_MESSAGES = 'SET_INIT_MESSAGES';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const SET_THREAD_ID = 'SET_THREAD_ID';
export const SET_SENDER_ID = 'SET_SENDER_ID';
export const SET_RECIPIENT_ID = 'SET_RECIPIENT_ID';
export const INITIATE_MESSENGER = 'INITIATE_MESSENGER';
export const SET_THREAD_KEY = 'SET_THREAD_KEY';
export const SET_USER_TYPING = 'SET_USER_TYPING';
export const SET_TYPING_USER_NAME = 'SET_TYPING_USER_NAME';

export const set_socket_connection = flag => ({
  type: SET_SOCKET_CONNESTION,
  bool: flag
});

export const set_init_messages = messages => ({
  type: SET_INIT_MESSAGES,
  messages
});

export const add_message = message => ({
  type: ADD_MESSAGE,
  message
});

export const set_thread_id = id => ({
  type: SET_THREAD_ID,
  id
});

export const set_thread_key = key => ({
  type: SET_THREAD_KEY,
  key
});

export const set_sender_id = id => ({
  type: SET_SENDER_ID,
  id
});

export const set_recipient_id = id => ({
  type: SET_RECIPIENT_ID,
  id
});

export const initiate_messenger = data => ({
  type: INITIATE_MESSENGER,
  data
});

export const set_user_typing = flag => ({
  type: SET_USER_TYPING,
  bool: flag
});

export const set_typing_user_name = typing_user_name => ({
  type: SET_TYPING_USER_NAME,
  typing_user_name
});

export const mapKeyToCarePerson = key => {
  switch (key) {
    case 'medication-therapy':
    case 'therapy':
      return CareUserEnum.THERAPIST;
    default:
      return CareUserEnum.CARE_MANAGER;
  }
};

export const get_messages =
  (providerMessenger = false) =>
  (dispatch, getState) => {
    const user = get_user_attr(getState());
    const headers = make_headers(user);
    const {
      patient: { offering_key, id, status, last_canceled_at }
    } = user;
    const beforePayment = isBeforePayment(status);
    const partOfDirectMessagingPilot = getState().patient_reducer.direct_messaging_pilot;
    let carePerson = null;

    if (beforePayment && !providerMessenger) {
      carePerson = CareUserEnum.CARE_MANAGER;
    } else if (providerMessenger && partOfDirectMessagingPilot) {
      carePerson = CareUserEnum.DOCTOR;
    } else {
      carePerson = mapKeyToCarePerson(offering_key);
    }

    let path = `/api/patients/${id}/${carePerson}s/message_history`;

    if (beforePayment && last_canceled_at) {
      path += `?from_date=${last_canceled_at}`;
    }

    return axios.get(path, { headers }).then(({ data }) => {
      const user_id = getState().global_reducer.current_user.attributes.id;
      const cm_id = providerMessenger
        ? getState().patient_reducer.current_provider.user.id
        : getState().patient_reducer[carePerson].user_id;
      const { nutritionist } = getState().patient_reducer;

      const options = {
        connected: true,
        recipient_id: cm_id ?? nutritionist.user.id,
        sender_id: user_id,
        messages: data,
        message_type: carePerson === CareUserEnum.DOCTOR ? CareUserEnum.PROVIDER : carePerson
      };

      dispatch(initiate_messenger(options));
    });
  };

export const user_is_typing = () => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  const headers = make_headers(user_attr);
  const { thread_id, sender_id, recipient_id, active_messenger } = getState().messenger_reducer;

  const body = {
    message_thread_id: thread_id[active_messenger],
    recipient_id: recipient_id[active_messenger],
    sender_id: sender_id[active_messenger]
  };

  return axios.post(`/api/message_threads/${thread_id[active_messenger]}/messages/user_typing`, body, { headers });
};

export const ping_care_manager = providerMessenger => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  const headers = make_headers(user_attr);

  return axios.post('/api/message_threads/ping_care_manager', {}, { headers }).then(({ data }) => {
    const message_type = providerMessenger ? CareUserEnum.PROVIDER : CareUserEnum.CARE_MANAGER;
    batch(() => {
      dispatch(set_thread_id({ id: data.message_thread_id, message_type }));
      dispatch(set_thread_key({ key: data.message_thread_key, message_type }));
    });
  });
};

export const get_message_thread = () => (dispatch, getState) => {
  const careManager = getState().patient_reducer.care_manager;
  const { nutritionist } = getState().patient_reducer;
  const user_attr = get_user_attr(getState());
  const headers = make_headers(user_attr);
  const {
    patient: { offering_key }
  } = user_attr;
  const partOfDirectMessagingPilot = getState().patient_reducer.direct_messaging_pilot;
  let carePerson = mapKeyToCarePerson(offering_key);

  const message_type = window.location.href.includes(CareUserEnum.PROVIDER) ? CareUserEnum.PROVIDER : carePerson;
  if (partOfDirectMessagingPilot && message_type === CareUserEnum.PROVIDER) {
    carePerson = CareUserEnum.DOCTOR;
  }

  if ((!careManager || !nutritionist) && carePerson === CareUserEnum.CARE_MANAGER) {
    return;
  }

  return axios.post(`/api/message_threads/get_${carePerson}`, {}, { headers }).then(({ data }) => {
    batch(() => {
      dispatch(set_thread_id({ id: data.message_thread_id, message_type }));
      dispatch(set_thread_key({ key: data.message_thread_key, message_type }));
    });
  });
};

export const post_message = (thread_id, message) => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  const headers = make_headers(user_attr);

  return axios.post(`/api/message_threads/${thread_id}/messages`, message, { headers });
};
