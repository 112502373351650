import styled, { css } from 'styled-components/macro';

export const PageWrapper = styled.div`
  width: 1000px;
  margin: 50px auto;
  position: relative;

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 0;
    padding: 50px;
  }
`;

export const SectionTitle = styled.h3`
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
  letter-spacing: -0.43px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 40px;
  color: #4f4f4f;
  letter-spacing: -0.4px;

  ul {
    list-style: none;
    padding: 0;
  }

  p {
    text-align: left;
  }
`;

export const MediaWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export const NextBtn = styled.button`
  display: inline-block;
  width: 178px;
  height: 43px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 43px;
  color: #ffffff;
  background: ${props => (props.disable ? 'rgba(103, 134, 255, 0.5)' : '#6786ff')};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 22px;
  transition: all 0.3s;
  margin: 5 0px auto;
`;

export const LinkButton = styled.button`
  display: inline-block;
  padding: 5px 10px;
  width: 178px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 33px;
  color: #6786ff;
  transition: all 0.3s;
  background: transparent;
  border: none;
  margin-top: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

export const QuestionText = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #250044;
  text-align: center;
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px auto 0;
  width: 200px;
`;

export const RadioItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.4px;

  color: #000000;

  .rate-score {
    width: 18px;
    margin-right: 40px;
    text-align-center;
  }

  .rate-text {
    margin-left: 40px;
  }
`;

export const RadioBox = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid ${props => props.color};
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    inset: 4px;
    background-color: ${props => props.color};
    border-radius: 50%;
    opacity: 0;
    transition: 0.2s opacity ease-in-out;

    ${props =>
      props.checked &&
      css`
        opacity: 1;
      `}
  }
`;

export const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: #d8dff8;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    background: linear-gradient(89.99deg, rgba(119, 35, 255, 0.6336) 0%, rgba(103, 134, 255, 0.66) 61.32%);
    border: 1px solid #6786ff;
    transition: 0.3s all ease-in-out;
    width: ${props => props.trackWidth}%;
    border-radius: 3px;
  }
`;

export const InputPromptText = styled.h4`
  font-weight: bold;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: -0.4px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 360px;
  border-radius: 4px;
  background-color: #f0f3ff;
  padding: 20px;
  border: none;
  transition: 0.2s all ease-in;
  outline-color: #aabaf9;
  resize: none;

  &:focus {
    background-color: #ffffff;
  }
`;

export const CompleteActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  padding-bottom: 50px;
  border-bottom: 1px solid #bdbdbd;
`;
