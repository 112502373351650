import { ReactElement, useEffect } from 'react';
import tracking from 'utils/tracking';
import mapErrorToMessage from './mapErrorToMessage';

type GlobalErrorCatcherProps = {
  children: ReactElement;
};

const GlobalErrorCatcher = ({ children }: GlobalErrorCatcherProps) => {
  useEffect(() => {
    const rejectionHandler = (event: PromiseRejectionEvent) => {
      tracking.error({
        e: event?.reason,
        message: mapErrorToMessage(event?.reason)
      });
    };

    const errorHandler = (event: ErrorEvent) => {
      tracking.error({ e: event?.error, message: mapErrorToMessage(event) });
    };

    window.addEventListener('error', errorHandler);
    window.addEventListener('unhandledrejection', rejectionHandler);

    return () => {
      window.removeEventListener('unhandledrejection', rejectionHandler);
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  return children;
};

export default GlobalErrorCatcher;
