import produce from 'immer';
import {
  SET_VIEW,
  SET_CANCELLATION_MODAL,
  SET_INSURANCE_VISITS_POLICY,
  SET_CANCEL_FINISHED,
  SET_SUBSCRIPTION,
  SET_SUBSCRIPTION_ERROR
} from './actions';

export const initialState = {
  view: 'profile',
  subscription: {},
  insurancePolicy: {},
  rawInsurance: {},
  errors: {},
  is_loading: true,
  cancellationModalVisible: false
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_VIEW:
      draft.view = action.payload;
      break;
    case SET_CANCELLATION_MODAL:
      draft.cancellationModalVisible = action.payload;
      break;
    case SET_INSURANCE_VISITS_POLICY:
      draft.insurancePolicy = action.insurance;
      draft.view = action.allowedInsurance ? 'insurance_estimates' : 'profile';
      draft.rawInsurance = action.rawInsurance;
      break;
    case SET_CANCEL_FINISHED:
      draft.cancellationModalVisible = false;
      draft.view = 'profile';
      break;
    case SET_SUBSCRIPTION:
      draft.subscription = action.payload;
      draft.is_loading = false;
      break;
    case SET_SUBSCRIPTION_ERROR:
      draft.errors = action.payload;
      draft.is_loading = false;
      break;
    default:
  }
}, initialState);
