import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as CheckMarkImage } from '../assets/green_checkmark.svg';

const CheckMark = styled(CheckMarkImage)``;

const CheckMarkHolder = styled.div`
  width: 20px;
  height: 20px;
  background: #c5fce7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Type = styled.div`
  display: flex;
`;
const Name = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.43px;
  color: #250044;
  margin-left: 10px;
`;

const Price = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: ${props => (props.small ? '12px' : '16px')};
  text-align: right;
  letter-spacing: -0.43px;
  color: #6786ff;
  white-space: pre;
`;

const Holder = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 70px;
  }
`;

const Estimate = ({ name, price, small, check }) => {
  return (
    <Holder>
      <Type>
        <CheckMarkHolder>{check && <CheckMark />}</CheckMarkHolder>
        <Name>{name}</Name>
      </Type>
      <Price small={small}>{price}</Price>
    </Holder>
  );
};

export default Estimate;
