import React from 'react';
import { Link } from 'react-router-dom';
import { Flexbox } from '@cerebral-inc/ui';
import { ReactComponent as CloseIcon } from '../../img/close.svg';
import {
  ActionPopup,
  ChargeOption,
  ClosePopup,
  ContentPopup,
  LinkStyle,
  MessagePopup,
  ModalInner,
  ModalMain,
  UpdateButton
} from './styled';
import { WhiteTitle, WhiteExclamation, BlueHeader } from '../UI/Modal';
import { formatCurrency } from '../../utils/currency.util';

export const PopupWarningModal = ({ onClose, onUpdate, routePath }) => (
  <ModalMain>
    <ModalInner noBottomPadding>
      <ClosePopup onClick={onClose}>
        <Flexbox className="w-100 h-100">
          <CloseIcon />
        </Flexbox>
      </ClosePopup>
      <BlueHeader>
        <WhiteExclamation />
        <WhiteTitle>
          Your visit isn’t currently
          <br /> covered by insurance
        </WhiteTitle>
      </BlueHeader>

      <ContentPopup>
        <MessagePopup>Please update the insurance information in your dashboard before your visit.</MessagePopup>
        <ChargeOption>
          If your insurance isn’t eligible, we’ll charge
          <br /> {formatCurrency(50)} to your account after the visit.
        </ChargeOption>
      </ContentPopup>
      <ActionPopup>
        <UpdateButton
          onClick={() => {
            onClose();
            onUpdate();
          }}
        >
          Update Insurance
        </UpdateButton>
        <LinkStyle onClick={onClose} as={Link} to={routePath}>
          Continue to Scheduling
        </LinkStyle>
      </ActionPopup>
    </ModalInner>
  </ModalMain>
);
