import React, { useState } from 'react';
import imgCredit from 'img/checkout/img-credit.svg';
import { PrescriptionResponse } from '@cerebral-inc/api';
import { formatCurrency } from 'utils/common';
import { ApproveModal, CreditCardImg, Header, Price, Text, ApproveButton, DenyButton } from './styled';

export interface Props {
  show: boolean;
  onHide: () => void;
  onApprove: () => void;
  prescriptions: PrescriptionResponse[];
  totalPrice: number;
}

const ConsentApproveModal: React.FC<Props> = ({ show, onHide, onApprove, prescriptions, totalPrice }) => {
  const [disabled, setDisabled] = useState(false);

  const sendResponse = async () => {
    setDisabled(true);
    await onApprove();
    setDisabled(false);
  };

  return (
    <ApproveModal show={show} onHide={onHide}>
      <CreditCardImg src={imgCredit} alt="Credit card" />
      <Header>
        Consent to Pay ({prescriptions.length} Prescription{prescriptions.length > 1 ? 's' : ''})
      </Header>
      <div>
        {prescriptions[0].order_status === 'consent_cash' && (
          <>
            <Text>
              Your medication from TruePill pharmacy is not covered by your insurance. However, you can still order your
              medication through out-of-pocket payment.
            </Text>

            <Text>
              By clicking “Yes” you consent to paying the amount listed below to order your medication. Your card on
              file will be charged immediately.
            </Text>
            <Price>
              Amount due: <strong>{formatCurrency(totalPrice)}</strong>
            </Price>
          </>
        )}

        {prescriptions[0].order_status === 'consent_insurance' && (
          <>
            <Text>
              In order to determine the co-pay amount owed, we need your consent to place this medication order.
            </Text>
            <Text>
              By clicking “Yes” you consent to paying the maximum amount listed below. Your card will not be charged
              until your insurance coverage has been processed.
            </Text>
            <Price>
              Prescription total: <strong>{formatCurrency(totalPrice)}</strong>
            </Price>
          </>
        )}

        <ApproveButton onClick={sendResponse} disabled={disabled}>
          Yes
        </ApproveButton>
        <DenyButton onClick={onHide} disabled={disabled}>
          Cancel
        </DenyButton>
      </div>
    </ApproveModal>
  );
};

export default ConsentApproveModal;
