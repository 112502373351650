import React, { createContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useDispatch } from 'react-redux';
import variations, { Variation } from './variations';
import { get_cookie_abtests } from '../../actions/patient_action';
import { OfferingKey, offerings } from '../../utils/plans';
import { countryFromUrl } from '../../utils/common';

export interface VariationContextProps {
  plan: OfferingKey;
  promo: string;
  variation: string;
  lookupTable: Variation;
  campaignId: string | boolean;
  backendAbVariation: string | boolean;
  country: string;
}

const VariationContext = createContext<Partial<VariationContextProps>>({});

const createSearch = () => new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '');

const promoFromUrl = () => {
  const search = createSearch();
  const promo = search.get('promo')?.toUpperCase() || null;

  if (promo) {
    localStorage.setItem('promo', promo);
    return promo;
  }

  return search.has('promo') ? 'EMPTY' : null;
};

const valueFromStorage = (key: string) => (typeof window !== 'undefined' ? localStorage.getItem(key) : null);

const planFromUrl = (): OfferingKey | null => {
  const search = createSearch();
  const plan = search.get('plan') as OfferingKey | null;
  const month = search.get('month') as string | null;
  const fsa = search.get('fsa') as string | null;
  if (plan && offerings.includes(plan)) {
    localStorage.setItem('plan', plan);

    if (month !== null) {
      sessionStorage.setItem('month', month);
    }
    if (fsa !== null) {
      sessionStorage.setItem('fsa', fsa);
    }
    return plan;
  }

  if (month !== null) {
    sessionStorage.setItem('month', month);
  }
  if (fsa !== null) {
    sessionStorage.setItem('fsa', fsa);
  }

  return null;
};

const countryFromStorage = () => valueFromStorage('country');

const defaultCountry = () => {
  return countryFromStorage() || countryFromUrl();
};

const defaultPlan = (): OfferingKey => planFromUrl() || (valueFromStorage('plan') as OfferingKey) || 'medication';

const defaultPromo = () => {
  const promo = promoFromUrl() || valueFromStorage('promo');

  if (promo && !variations.some(v => v.expired && v.variation.toLowerCase() === promo.toLowerCase())) {
    return promo;
  }

  return defaultCountry() === 'uk' ? 'START49' : 'CARE30';
};

const campaignFromUrl = () => {
  const search = createSearch();
  const campaign = search.has('utm_campaign') ? search.get('utm_campaign') : null;

  if (campaign) {
    localStorage.setItem('campaign', campaign);
    return campaign;
  }

  return search.has('campaign') ? 'EMPTY' : null;
};

const defaultCampaign = () => {
  const campaign = campaignFromUrl() || valueFromStorage('campaign');

  if (campaign && !variations.some(v => v.expired && v.variation.toLowerCase() === campaign.toLowerCase())) {
    return campaign;
  }

  return false;
};

const backendAbVariationFromUrl = () => {
  const search = createSearch();
  const backendAbVariation = search.has('abv') ? search.get('abv') : null;

  if (backendAbVariation) {
    localStorage.setItem('backendAbVariation', backendAbVariation);
    return backendAbVariation;
  }

  return search.has('backendAbVariation') ? 'EMPTY' : null;
};

const defaultBackendAbVariation = () => {
  const backendAbVariation = backendAbVariationFromUrl() || valueFromStorage('backendAbVariation');

  if (
    backendAbVariation &&
    !variations.some(v => v.expired && v.variation.toLowerCase() === backendAbVariation.toLowerCase())
  ) {
    return backendAbVariation;
  }

  return false;
};

const VariationProvider = withRouter(({ children, location }) => {
  const [plan, setPlan] = useState<OfferingKey>(defaultPlan());
  const [promo] = useState(defaultPromo());
  const [campaignId] = useState(defaultCampaign());
  const [country] = useState(defaultCountry());
  const [backendAbVariation] = useState(defaultBackendAbVariation());
  const variation = variations.some(v => v.variation === promo) ? promo : '*';
  const lookupTable = variations.find(v => v.variation === variation);
  const dispatch = useDispatch();

  useEffect(() => {
    const newPlan = defaultPlan();
    if (newPlan) setPlan(newPlan);
  }, [location.search]);

  useEffect(() => {
    dispatch(get_cookie_abtests());
  }, []);

  const value = {
    plan,
    promo,
    variation,
    lookupTable,
    campaignId,
    backendAbVariation,
    country
  };

  return <VariationContext.Provider value={value}>{children}</VariationContext.Provider>;
});

export { VariationProvider, VariationContext, countryFromUrl };
