import { handleActions } from 'redux-actions';
import * as messenger_action_type from '../actions/messenger_action';
import * as user_action_type from '../actions/user_auth_action';

export const init_messenger_state = {
  connected: false,
  messages: {
    provider: [],
    care_manager: [],
    therapist: []
  },
  thread_id: {},
  thread_key: {},
  sender_id: {},
  recipient_id: {},
  user_typing: false,
  active_messenger: ''
};

export const messenger_reducer = handleActions(
  {
    [messenger_action_type.INITIATE_MESSENGER]: (state, action) => ({
      ...state,
      connected: action.data.connected,
      messages: {
        ...state.messages,
        [action.data.message_type]: action.data.messages
      },
      recipient_id: {
        ...state.recipient_id,
        [action.data.message_type]: action.data.recipient_id
      },
      sender_id: {
        ...state.sender_id,
        [action.data.message_type]: action.data.sender_id
      },
      active_messenger: action.data.message_type
    }),
    [messenger_action_type.SET_SOCKET_CONNESTION]: (state, action) => ({
      ...state,
      connected: action.bool
    }),
    [messenger_action_type.SET_INIT_MESSAGES]: (state, action) => ({
      ...state,
      messages: [...action.messages]
    }),
    [messenger_action_type.SET_USER_TYPING]: (state, action) => ({
      ...state,
      user_typing: action.bool
    }),
    [messenger_action_type.SET_TYPING_USER_NAME]: (state, { typing_user_name }) => ({
      ...state,
      typing_user_name
    }),
    [messenger_action_type.SET_RECIPIENT_ID]: (state, action) => ({
      ...state,
      recipient_id: action.id
    }),
    [messenger_action_type.SET_SENDER_ID]: (state, action) => ({
      ...state,
      sender_id: {
        care_manager: action.id,
        provider: action.id,
        therapist: action.id
      }
    }),
    [user_action_type.SET_USER]: (state, { user_attr: { id } }) => ({
      ...state,
      sender_id: {
        care_manager: id,
        provider: id,
        therapist: id
      }
    }),
    [messenger_action_type.SET_THREAD_ID]: (state, action) => ({
      ...state,
      thread_id: {
        ...state.thread_id,
        [action.id.message_type]: action.id.id
      }
    }),
    [messenger_action_type.SET_THREAD_KEY]: (state, action) => ({
      ...state,
      thread_key: {
        ...state.thread_key,
        [action.key.message_type]: action.key.key
      }
    }),
    [messenger_action_type.ADD_MESSAGE]: (state, action) => {
      const systemMessage = action?.message?.showedHelpCenter
        ? []
        : {
            sender_name: 'System',
            created_at: action?.message?.message?.created_at,
            id: 'system_help_center_message',
            message: 'help center',
            isRedirected: true
          };
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.message.message_type]: [
            ...state.messages[action.message.message_type],
            action.message.message
          ].concat(systemMessage)
        }
      };
    }
  },
  init_messenger_state
);
