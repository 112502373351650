import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import cardIconImage from '../../../img/card-icon.png';

interface ReactivateProps {
  isReactivate: boolean;
}

const PaymentInput = styled.input<ReactivateProps>`
  background: #ffffff;
  border: 1px solid #d3dbf8;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px 4px #f6f6f9;
  border-radius: 4px;
  display: block;
  width: 100%;
  height: 40px;
  margin-top: 10px;
  padding: 10px;
  font-size: 14px;
  outline: none;
  appearance: none;
  &::placeholder {
    color: #250044;
  }
  ${({ isReactivate }) =>
    isReactivate &&
    `
      &::placeholder {
      color: #6786ff;
    }
    `}
`;

export const CardNameInput = styled(PaymentInput)`
  text-align: left;
`;

export const CardNumberInput = styled(PaymentInput)`
  text-align: left;
  position: relative;
  display: block;
  ${({ isReactivate }) =>
    !isReactivate &&
    `
      background-image: url(${cardIconImage});
    `}
  background-repeat: no-repeat;
  background-size: 20px 14px;
  background-position: 96% 12px;
`;

export const CvcInput = styled(PaymentInput)`
  min-width: 38px;
  text-align: left;
  flex: 1;
`;

export const ZipInput = styled(PaymentInput)`
  min-width: 53px;
  text-align: left;
  flex: 1;
`;

export const DateInput = styled(PaymentInput)`
  min-width: 50px;
  display: flex;
  flex: 1;
`;

export const CardExtra = styled.div`
  display: flex;
  ${CvcInput} {
    margin: 10px 12px 0;
  }
`;

export const Wrapper = styled.div``;

export const GreenBanner = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 50px;
  background: #9cffe7;
  z-index: 3;
`;

export const GreenBannerText = styled.h3`
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.43px;
  color: #250044;
  margin: 0;
  font-weight: 600;

  @media (max-width: 991px) {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    width: 300px;
  }
`;

export const InitAssessment = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: -0.43px;
  color: #250044;
`;

export const InitAssessmentFee = styled(InitAssessment)`
  font-size: 10px;
  font-weight: 400;
`;

export const Title = styled.h1`
  max-width: 600px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #250044;
  margin: 50px auto;
`;

export const Info = styled.div`
  margin-top: 90px;
  margin-bottom: 5px;
  margin-right: auto;
  margin-left: auto;
  max-width: 400px;
  background: #9cffe7;
  border-radius: 8px;
  text-align: center;
  padding: 15px 5px 15px 5px;
`;

export const NoteText = styled.p`
  margin: 0 auto;
  max-width: 300px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.43px;
  color: #250044;
`;

export const PaymentInformation = styled.h2`
  margin-top: 30px;
  margin-bottom: 5px;
  margin-right: auto;
  margin-left: auto;
  max-width: 222px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #250044;
`;

export const SmallInfo = styled.p`
  margin-top: 120px;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: -0.43px;
  color: #828282;
`;

export const ConsentInfo = styled.p`
  margin: auto;
  max-width: 350px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.43px;
  color: #444444;
`;

export const CheckboxWrapper = styled.div`
  height: 56px;
`;

export const ConsentLink = styled.a`
  margin: auto;
  max-width: 350px;
  height: 40px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.43px;
  color: #6786ff;
  text-decoration: underline;
`;

export const ConfirmLink = styled(Link)`
  margin: auto;
  max-width: 350px;
  height: 40px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.43px;
  color: #6786ff;
  text-decoration: underline;
`;
