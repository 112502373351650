/* eslint-disable */
import React from 'react';
//@ts-ignore
import SmartBanner from 'react-smartbanner';
import { getVariantValue } from '../utils/kameleoon/utils';

const SmartAppBanner = () => {
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    if (!window.Kameleoon) return;

    const result = getVariantValue('Smart App Banner');
    if (result === 'Reference') {
      return setVisible(true);
    }
    window.document.querySelector("[name='apple-itunes-app']")?.remove();
  }, [window.Kameleoon]);
  if (!visible) return null;
  return <SmartBanner title="Cerebral" />;
};
export default SmartAppBanner;
