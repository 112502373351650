import { AnyAction, Reducer } from 'redux';
import update from 'immutability-helper';
import * as TYPES from '../actions/account/types';
import { AccountDto } from '../utils/platform/dto';

export type AccountState = {
  account: {
    api: {
      loading: boolean;
      error: string | null;
    };
    data: AccountDto | null;
  };
  prospect: {
    exists: {
      api: {
        loading: boolean;
        error: string | null;
      };
      valid: boolean;
    };
  };
};

export type Payload = { data: unknown } | { error: string };

export const init_account_state: AccountState = {
  account: {
    api: {
      loading: false,
      error: null
    },
    data: null
  },
  prospect: {
    exists: {
      api: {
        loading: false,
        error: null
      },
      valid: false
    }
  }
};

export const account_reducer: Reducer = (state = init_account_state, action: AnyAction): AccountState => {
  switch (action.type) {
    case TYPES.GET_ACCOUNT.BEGIN: {
      return update(state, {
        account: {
          api: {
            loading: { $set: true },
            error: { $set: null }
          },
          data: { $set: null }
        }
      });
    }
    case TYPES.GET_ACCOUNT.SUCCESS: {
      return update(state, {
        account: {
          api: {
            loading: { $set: false }
          },
          data: {
            $set: action.data
          }
        }
      });
    }
    case TYPES.GET_ACCOUNT.FAILED: {
      return update(state, {
        account: {
          api: {
            loading: { $set: false },
            error: { $set: action.error }
          }
        }
      });
    }
    case TYPES.CHECK_PROSPECT_EXISTS.BEGIN: {
      return update(state, {
        prospect: {
          exists: {
            api: {
              loading: { $set: true },
              error: { $set: null }
            },
            valid: { $set: false }
          }
        }
      });
    }
    case TYPES.CHECK_PROSPECT_EXISTS.SUCCESS: {
      return update(state, {
        prospect: {
          exists: {
            api: {
              loading: { $set: false }
            },
            valid: { $set: true }
          }
        }
      });
    }
    case TYPES.CHECK_PROSPECT_EXISTS.FAILED: {
      return update(state, {
        prospect: {
          exists: {
            api: {
              loading: { $set: false },
              error: { $set: action.error }
            }
          }
        }
      });
    }
    case TYPES.RESET_PROSPECT: {
      return update(state, {
        prospect: { $set: init_account_state.prospect }
      });
    }
    default:
      return state;
  }
};
