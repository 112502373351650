import { getVariantValue } from '../utils';

const checkChangePriceMed = () => {
  let valueKameleoon: string | undefined = 'Reference';
  const experimentName = 'Apply PromoCode and Coupon for ADHD';

  valueKameleoon = getVariantValue(experimentName);
  return ['Excluded', 'Disabled'].includes(valueKameleoon) ? 'Reference' : valueKameleoon;
};

export { checkChangePriceMed };
