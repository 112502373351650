import React from 'react';
import { AssessmentType, Scores } from 'types/assessments';
import ChartArea from '../ChartArea';
import { getTotalScore, getSeverity } from '../utils';

export interface AssessmentResultProps {
  type: AssessmentType;
  title: string;
  data: Scores[AssessmentType];
}

const AssessmentResult: React.FC<AssessmentResultProps> = ({ type, title, data }) => (
  <div className="align-self-start main-content-wide-card">
    <div className="amt-rst-container">
      <div className="d-flex flex-column amt-left-panel">
        <div className="d-flex flex-row description">{title}</div>
        <div className="d-flex flex-row title">{getSeverity(type, data[data.length - 1].uv)}</div>
        <div className="d-flex flex-row description">Current Assessment score:</div>
        <div className="d-flex flex-row score">
          {data[data.length - 1].uv}
          <div className="total">/{getTotalScore(type)}</div>
        </div>
      </div>
      <div className="d-flex flex-column align-self-center amt-graph-panel">
        <ChartArea type={type} data={data} width="100%" height={320} />
        <div className="d-flex justify-content-center description">Assessment timeline</div>
      </div>
    </div>
  </div>
);

export default AssessmentResult;
