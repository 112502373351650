import React from 'react';
import { PrescriptionPharmacy } from '@cerebral-inc/types';
import { PharmacyHolder, Name, Street, Holder } from './styled';

const Pharmacy: React.FC<PrescriptionPharmacy> = ({ name, address }) => {
  const [street, city, state, zipcode] = address.split(',');

  return (
    <PharmacyHolder>
      <Name>{name}</Name>
      <Street>{street}</Street>
      <Holder>{`${city}, ${state} ${zipcode}`}</Holder>
    </PharmacyHolder>
  );
};

export default Pharmacy;
