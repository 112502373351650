import { Modal } from 'components/UI/Modal';
import styled from 'styled-components/macro';
import TextButton from 'components/UI/TextButton';
import approveLady from './img/approve-lady.svg';
import { ConsentButton } from '../styled';

export const ApproveModal = styled(Modal)`
  .modal-content {
    background: url(${approveLady}) bottom right no-repeat #dafff1;
    border-radius: 15px;
    padding: 10px 62px 125px;
    text-align: center;
    max-width: 325px;
  }
`;

export const CreditCardImg = styled.img`
  width: 80px;
  margin: 0 auto 15px;
`;

export const Header = styled.h3`
  font-style: italic;
  letter-spacing: -1px;
`;

export const Text = styled.p`
  font-size: 12px;
  line-height: 20px;
`;

export const Price = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin: 2rem 0;

  strong {
    color: #6786ff;
  }
`;

export const ApproveButton = styled(ConsentButton)`
  margin-top: 0;
  padding: 5px 38px;
`;

export const DenyButton = styled(TextButton)`
  text-decoration: none;
`;
