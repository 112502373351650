import createPricing from './createPricing';
import { IStrings } from './types';
import { USPaymentTerm, USSignupTerm } from '../components/ImportantInformation/ImportantInformation';

const discountedTherapyPrice = 99;

export default {
  nationalSuicideHotlineName: 'National Suicide Hotline',
  nationalSuicideHotlineNumber: '1-800-273-8255',
  nationalEmergencyNumber: '911',
  currencySymbol: '$',
  assessmentFee: 15,
  nutritionManagementUpsellPriceDefault: 69,
  nutritionManagementPriceDefault: 69,
  nutritionMedicationUpsellPriceDefault: 85,
  nutritionMedicationPriceDefault: 85,
  conselingUpgradePrice: 30,
  nutritionTherapyUpgradePrice: 99,
  smokingAssessmentUpgradePrice: 40,
  medsTherapyCta: 'Start Meds + Therapy Today',
  medicationTherapyPrices: createPricing(325, 139),
  medicationNicotinePrices: createPricing(40, 28),
  medicationBupropionPrices: createPricing(40, 17),
  medicationBupropionNicotinePrices: createPricing(80, 40),
  therapyPrices: createPricing(259, discountedTherapyPrice),
  discountedTherapyPrice,
  medicationPrices: createPricing(85, 30),
  nutritionPrices: createPricing(69, 25),
  nutritionMedicationPrices: createPricing(85, 30),
  medsPlanTitle: {
    title: 'Medication &\nCare Counseling',
    name: 'Medication & Care Counseling'
  },
  medsTherapyPlanTitle: {
    title: 'Medication &\nTherapy',
    name: 'Medication + Therapy'
  },
  prescriberText: 'prescriber',
  defaultSubpriceMessage: 'Billed monthly <br />FSA / HSA eligible<br /> Cancel anytime',
  weeklyVideoPhone: 'Weekly video/phone sessions with a licensed therapist',
  regularVideo: 'Regular video sessions with an assigned care counselor',
  defaultPromoCode: 'CARE30',
  defaultTherapyCode: 'START99',
  defaultTherapyCampaignCode: 'CARE79',
  defaultMedsTherapyCampaignCode: 'CARE99',
  defaultMedsTherapyCode: 'START139',
  defaultNicotineCode: '12OFF',
  defaultBupropionCode: '23OFF',
  defaultBupropionNicotineCode: '40OFF',
  defaultNicotineCounselingCode: '52OFF',
  defaultBupropionCounselingCode: '63OFF',
  defaultBupropionNicotineCounselingCode: '80OFF',
  defaultNicotineCodePrice: 12,
  defaultBupropionCodePrice: 23,
  defaultBupropionNicotineCodePrice: 40,
  defaultNicotineCounselingCodePrice: 52,
  defaultBupropionCounselingCodePrice: 63,
  defaultBupropionNicotineCounselingCodePrice: 80,
  defaultTherapyCampaignPrice: 79,
  defaultMedsTherapyCampaignPrice: 99,
  defaultAssessmentFeeAddonCode: 'assessmentfee-15',
  defaultNutritionCode: '44OFF',
  defaultNutritionMedicationCode: '55OFF',
  defaultNutritionCodePrice: 44,
  defaultNutritionMedicationCodePrice: 55,
  countryCode: 'US',
  infoScreeningMessage:
    'The following questions serve as a preliminary screen to establish whether medication is the right course of treatment for you.',
  zipCodeLength: 5,
  zipCodeParser: /\D/g,
  phoneRegion: 'US',
  subscriptionApi: '/api/authorizenet_payments/subscription_info',
  PaymentTerms: USPaymentTerm,
  SignupTerms: USSignupTerm,
  checkoutTitle: 'Cerebral'
} as IStrings;
