import axios from 'axios';
import { triggerEventKameleoon } from 'utils/kameleoon/utils';
import segment from '../utils/segment';
// import { batch } from 'react-redux';

export const RESET_STATE = 'RESET';
export const SIGN_IN = 'SIGN_IN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const REGISTER = 'REGISTER';
export const SET_USER = 'SET_USER';
export const SET_QUESTIONS = 'SET_QUESTIONS';
export const SET_PATIENT = 'SET_USER_PATIENT';
export const SET_THERAPIST = 'SET_USER_THERAPIST';
export const SET_NUTRITIONIST = 'SET_USER_NUTRITIONIST';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const SAVE_URL = 'SAVE_URL';
export const SET_PHONE = 'SET_PHONE';

// set_user will update global state with information corresponding to the User object in the database
export const set_user = user_info => ({
  type: SET_USER,
  user_attr: user_info
});

export const update_user_info = user_info => ({
  type: UPDATE_USER_INFO,
  user_info
});

export const remove_token = () => ({
  type: REMOVE_TOKEN
});

export const sign_out = () => dispatch => {
  return dispatch({ type: RESET_STATE });
};

export const reset_state = () => dispatch => {
  return dispatch({ type: RESET_STATE });
};

export const get_user_attr = state => {
  return state.global_reducer.current_user.attributes;
};

export const setPhone = ({ phone }) => ({
  type: SET_PHONE,
  phone
});

export const make_headers = user_attr => {
  const headers = {
    'Content-Type': 'application/json',
    'access-token': user_attr['access-token'],
    client: user_attr.client,
    uid: user_attr.uid
  };

  if (user_attr['jwt-token']) {
    headers.Authorization = `Bearer ${user_attr['jwt-token']}`;
  }

  return headers;
};

export const reset_password = (email, redirect_url) => () => {
  // sign in API call here
  const headers = { 'Content-Type': 'application/json' };
  return axios.post('/api/auth/password', { email, redirect_url }, { headers });
};

export const change_password = data => () => {
  const headers = make_headers(data);

  return axios.put(
    '/api/auth/password',
    { password: data.password, password_confirmation: data.password },
    { headers }
  );
};

export const update_password = (new_pwd, new_pwd_confirm) => (dispatch, getState) => {
  return axios.put(
    '/api/auth/password',
    { password: new_pwd, password_confirmation: new_pwd_confirm },
    { headers: make_headers(get_user_attr(getState())) }
  );
};

export const is_signed_in = () => (dispatch, getState) => {
  return axios
    .get('/api/auth/validate_token', { headers: make_headers(get_user_attr(getState())) })
    .then(resp => {
      const {
        headers: { expiry }
      } = resp;
      const dayInMiliseconds = 86400000;

      if (expiry && Number(expiry) * 1000 - Date.now() >= dayInMiliseconds) {
        return Promise.resolve(true);
      }
      return Promise.resolve(false);
    })
    .catch(() => {
      return Promise.resolve(false);
    });
};

export const sign_in = user_info => dispatch => {
  const { email, password } = user_info;

  // sign in API call here
  const headers = { 'Content-Type': 'application/json' };
  if (email && password) {
    return axios
      .post('/api/auth/sign_in', { email, password }, { headers })
      .then(resp => {
        resp.data.data.client = resp.headers.client;
        resp.data.data['access-token'] = resp.headers['access-token'];
        resp.data.data['jwt-token'] = resp.headers['jwt-token'];

        if (resp?.data?.data?.country === 'us') {
          window.localStorage.setItem('user-state', resp?.data?.data?.patient?.region);
          // Trigger event state for Kameleoon
          triggerEventKameleoon(`State-${resp?.data?.data?.patient?.region}`);
        } else {
          window.localStorage.setItem('user-state', '');
        }

        dispatch(set_user(resp.data.data));
        segment.signIn(resp.data.data);
        return resp.data;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  return Promise.reject(new Error('Please provide an email address and password'));
};

export const authenticateFromMobile = user => dispatch => {
  dispatch(set_user(JSON.parse(user)));
};

// eslint-disable-next-line consistent-return
export const register_user = user_info => () => {
  const {
    sms_opted_in,
    first_name,
    last_name,
    email,
    password,
    password_confirm,
    phone_number,
    phone_region,
    skip_password_validation,
    acquisition_string,
    feature_flag,
    service_line,
    country,
    is_guest = false
  } = user_info;

  // NOTE: you can pass in both skip_password_validation and password+password_confirm but the server will basically ignore the password

  // register API call here
  //
  const headers = { 'Content-Type': 'application/json' };
  let user_data = {};
  if (first_name && last_name) {
    user_data = { first_name, last_name };
  }
  if (email && ((password && password_confirm) || skip_password_validation)) {
    user_data = {
      ...user_data,
      sms_opted_in,
      email,
      password,
      password_confirmation: password_confirm,
      skip_password_validation,
      phone: `${phone_region.code} ${phone_number}`,
      acquisition_string,
      initial_service_line: service_line,
      country,
      is_guest
    };

    if (feature_flag) user_data.feature_flag = feature_flag;
    if (phone_number) user_data.phone = `${phone_region.code} ${phone_number}`;
    if (user_info.user_class) user_data.user_class = user_info.user_class;
    if (user_info.partner_code) user_data.partner_code = user_info.partner_code;

    return axios
      .post('/api/users', user_data, { headers })
      .then(resp => {
        segment.signUp(resp.data);
        return Promise.resolve({ password, ...resp.data });
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }
};

// eslint-disable-next-line consistent-return
export const update_user_information = user_info => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());

  return axios.put(`/api/users/${user_attr.id}`, user_info, { headers: make_headers(user_attr) }).then(resp => {
    segment.identify(resp.data);
    dispatch(update_user_info(user_info));
    return resp;
  });
};

export const register_and_set_user = user_info => dispatch => {
  // reset state before sign_up
  dispatch(reset_state());

  return dispatch(register_user(user_info)).then(resp => {
    return dispatch(set_user(resp));
  });
};

export const set_patient = patient => dispatch => {
  return dispatch({ type: SET_PATIENT, patient });
};

export const set_therapist = therapist => dispatch => {
  return dispatch({ type: SET_THERAPIST, therapist });
};

export const set_nutritionist = nutritionist => dispatch => {
  return dispatch({ type: SET_NUTRITIONIST, nutritionist });
};

export const save_url = url => dispatch => {
  return dispatch({ type: SAVE_URL, url });
};

export const get_message_threads_for_current_user = () => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  return axios.get(`/api/users/${user_attr.id}/message_threads`, { headers: make_headers(user_attr) });
};

export const create_message_thread = (recipient_id, sender_id, for_patient_id) => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  const body = { recipient_id, sender_id, for_patient_id };
  return axios.post(`/api/users/${user_attr.id}/message_threads`, body, { headers: make_headers(user_attr) });
};

export const create_message = (sender_id, thread_id, recipient_id, message) => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  const body = { message, recipient_id, sender_id };
  return axios.post(`/api/message_threads/${thread_id}/messages`, body, { headers: make_headers(user_attr) });
};

export const get_messages_for_thread = thread_id => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  return axios.get(`/api/message_threads/${thread_id}/messages`, { headers: make_headers(user_attr) });
};

export const register_subscriber = data => () => {
  const body = { first_name: data.first_name, last_name: data.last_name, email: data.email, region: data.region };

  const headers = { 'Content-Type': 'application/json' };
  return axios.post('/api/subscribers', body, { headers });
};
