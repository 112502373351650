import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { fetchExerciseHistory } from '../../../actions/exercise_actions';
import { format } from '../../../utils/date-fns';
import CategoryCard from '../CategoryCard';

const baseUrl = process.env.REACT_APP_API_SERVER_URL || '';

const SectionTitle = styled.h3`
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 50px;
  letter-spacing: -0.43px;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ListWrapper = styled.div`
  display: flex;
  width: 950px;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
  }
`;

export const BackButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  border: none;

  &:focus {
    outline: none;
  }
`;

const EmptyHistory = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 360px;
  height: 100px;
  background: #ffffff;
  box-shadow: 0px 2px 2px #d3dbf8;
  border-radius: 4px;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #6786ff;
  margin: 0 auto 50px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ExerciseHistory = ({ historyData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleGoBack = () => {
    history.replace('/patient/dashboard/patient_exercises');
  };

  useEffect(() => {
    dispatch(fetchExerciseHistory());
  }, []);

  return (
    <Wrapper>
      <BackButton onClick={handleGoBack}>
        <img src={`${process.env.PUBLIC_URL}/img/exercises/arrow-left.svg`} alt="Go Back" />
      </BackButton>
      <SectionTitle>Exercise History</SectionTitle>
      <ListWrapper>
        {(!historyData || !historyData.length) && <EmptyHistory>You have no completed exercises.</EmptyHistory>}
        {historyData &&
          historyData.map((record, idx) => (
            <CategoryCard
              name={record.exercise.title}
              imageUrl={`${baseUrl}${record.exercise.image_url}`}
              key={idx}
              type="completed"
              subTitle={`Completed ${format(record.completion_date, {
                month: 'numeric',
                day: 'numeric',
                year: 'numeric'
              })}`}
              ctaLink={`/patient/dashboard/patient_exercises/exercise_flow/${record.exercise_id}`}
              pdfLink={record.pdf_url && `${baseUrl}${record.pdf_url}`}
            />
          ))}
      </ListWrapper>
    </Wrapper>
  );
};

export default ExerciseHistory;
