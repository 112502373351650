import styled, { css } from 'styled-components/macro';
import Button from '../../UI/RegularButton';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
`;

export const PageTitle = styled.h2`
  position: relative;
  display: inline;
  margin: 0 auto;
  font-size: 14px;
  line-height: 20px;
`;

export const TitleBadge = styled.span`
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #6786ff;
  color: #ffffff;
  border-radius: 50%;
  text-align: center;
  top: -20px;
  right: -20px;
`;

export const TableWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
  height: 500px;
  transition: height 0.3s ease-in;
  margin-left: -30px;
  padding-left: 30px;
  overflow: hidden;

  ${props =>
    props.expand &&
    css`
      height: auto;
      overflow: auto;
    `}

  .header {
    &--date,
    &--invoice {
      flex: 2;
    }

    &--description {
      flex: 3;
    }

    &--status {
      flex: 0 0 150px;
    }
  }
`;

export const Expansion = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 200px;
  background-image: linear-gradient(to bottom, rgba(252, 251, 254, 0), rgba(252, 251, 254, 1));
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const TableHeader = styled.div`
  position: relative;
  display: flex;
  padding: 8px 0;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: #6786ff;
    border-radius: 5px;
  }
`;

export const TableCell = styled.div`
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.43px;
  color: #250044;
  text-transform: capitalize;
  ${props => props.isHeader && 'font-weight: 600;'}
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 16px;

  .header--invoice {
    display: flex;
    align-items: center;
    .download-wrapper {
      &:hover {
        cursor: pointer;
      }

      span {
        color: inherit;
        text-decoration: underline;
        margin-right: 10px;
      }
    }
  }

  .header--status {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const TableRow = styled.div`
  position: relative;
  display: flex;
  padding: 1rem 0;
  align-items: center;
  ${({ unpaid }) =>
    unpaid &&
    css`
      &::before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        background: #6786ff;
        border-radius: 50%;
        border: 1px solid #250044;
        box-shadow: 0px 0px 4px #7370dd;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
      }
    `}
`;

export const PayBtn = styled.button`
  display: inline-block;
  margin-left: 20px;
  padding: 5px 10px;
  width: 73px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background: #6786ff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 15px;
  transition: all 0.3s;
  &:hover {
    background: #250044;
    text-decoration: none;
    color: #ffffff;
  }
`;

export const MobileWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 100%;
  padding: 0 40px;
  height: 100vh;
  overflow: hidden;
  transition: height 0.3s ease-in;

  ${props =>
    props.expand &&
    css`
      height: auto;
      overflow: auto;
    `}
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .field-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.43px;
    color: #250044;
    margin-bottom: 15px;
  }

  .field-value {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.43px;
    color: #250044;
    margin-bottom: 15px;
    text-transform: capitalize;
    &.invoice {
      display: flex;
      align-items: center;

      span {
        color: inherit;
        text-decoration: underline;
        margin-right: 10px;
      }
    }
  }
`;

export const InvoiceItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 323px;
  border-bottom: 3px solid #eff1fa;
  margin-bottom: 30px;
`;

export const SeeMoreBtn = styled(Button)`
  display: flex;
  align-items: center;
  span {
    margin: 0 10px;
  }
`;

export const UnpaidBadge = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  font-weight: 600;
  font-size: 12px;
  line-height: 30px;
  background: #6786ff;
  color: #ffffff;
  border-radius: 50%;
  text-align: center;
  top: 0;
  right: 40px;
`;
