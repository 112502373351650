import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { string, bool, shape } from 'prop-types';
import { prescriberVariant } from 'utils/care-person-helpers';
import { ProfileImage } from '../question_components/styled_components';
import { mapRoleToType } from '../../utils/care-person-helpers';
import DefaultCarePersonImage from '../../img/default-care-person.png';

const CarePersonProfileLink = styled.a`
  margin-top: 3px;
  font-weight: 600;
  font-size: 16px;
  color: #6786ff;
  text-decoration: underline;
`;

const provider = ({ patient_reducer: { current_provider } }) => current_provider;

const Profile = ({ classHolder, carePerson, isWidget, providerMessenger }) => {
  const imageHolder = isWidget
    ? 'chat_body_sidebar_image_holder_widget sidebar-header__image'
    : 'chat_body_sidebar_image_holder';
  const Provider = useSelector(provider);
  const { name, profile_url, photo_url, bio, education } = providerMessenger ? Provider : carePerson;

  const type = mapRoleToType(carePerson);

  const altText = () => {
    if (providerMessenger) {
      return 'provider';
    }

    return 'care person';
  };

  const profileType = () => {
    if (providerMessenger) {
      return `${prescriberVariant.singularCapitalize}`;
    }
    return type;
  };

  return (
    <div className={classHolder}>
      <div className="d-flex flex-row align-items-flex-start sidebar-header">
        <div className={imageHolder}>
          <ProfileImage width="69px" height="69px" src={photo_url || DefaultCarePersonImage} alt={altText()} />
        </div>
        <div className="d-flex flex-column align-items-flex-start sidebar-header__title">
          {profile_url && profile_url.length > 0 ? (
            <CarePersonProfileLink href={profile_url} target="_blank">
              {name}
            </CarePersonProfileLink>
          ) : (
            <span className="chat_body_sidebar_name">{name}</span>
          )}
          <div className="d-flex justify-content-center align-items-center chat_body_sidebar_lpc_holder">
            <img src={`${process.env.PUBLIC_URL}/img/white_checkmark_in_blue_circle.svg`} alt="blue checkmark" />
            <span className="chat_body_sidebar_lcp">{`Cerebral ${profileType()}`}</span>
          </div>
        </div>
      </div>
      {education && (
        <div className="d-flex justify-content-center">
          <p style={{ fontSize: '10px' }} className="chat_body_sidebar_lcp">
            {education}
          </p>
        </div>
      )}
      <div className="d-flex justify-content-center">
        <p className="chat_body_sidebar_paragraph">{bio}</p>
      </div>
    </div>
  );
};

Profile.propTypes = {
  classHolder: string.isRequired,
  carePerson: shape({
    first_name: string,
    last_name: string
  }).isRequired,
  isWidget: bool,
  isOnline: bool
};

Profile.defaultProps = {
  isWidget: false,
  isOnline: false
};

export default Profile;
