import React, { useState, useCallback, useEffect, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  cancel_subscription,
  cancel_subscription_finished,
  apply_coupon,
  apply_coupon_finished,
  saveCancellationAnswer
} from '../../../actions/patient_action';
import { sleep } from '../../../utils/common';
import {
  Header,
  Wrapper,
  Title,
  Isotype,
  Heading,
  Contents,
  Paragraph,
  Pills,
  Controls,
  Button,
  Step,
  CancellationList,
  CancellationItem,
  ItemIcon,
  ItemContent,
  ItemDescription,
  ItemTitle,
  Form,
  Option,
  TextArea,
  ConfirmControls,
  TextButton
} from './styled';

const PaymentProgressModal = lazy(() => import('../../PaymentProgressModal'));
const { CustomRadioButton } = lazy(() => import('../../question_components/components'));

const selectCouponProgress = ({ patient_reducer: { applyCoupon } }) => {
  return {
    open: applyCoupon.loading || applyCoupon.redirecting,
    loading: applyCoupon.loading,
    textOnLoading: applyCoupon.textOnLoading || '',
    textOnLoaded: applyCoupon.textOnLoaded || '',
    descriptionOnLoaded: applyCoupon.descriptionOnLoaded
  };
};

const selectCancelProgress = ({ patient_reducer: { cancelSubscription } }) => {
  return {
    open: cancelSubscription.loading || cancelSubscription.redirecting,
    loading: cancelSubscription.loading,
    textOnLoading: cancelSubscription.textOnLoading || '',
    textOnLoaded: cancelSubscription.textOnLoaded || '',
    descriptionOnLoaded: cancelSubscription.descriptionOnLoaded
  };
};

const reasons = [
  { value: 'inperson', label: 'I prefer in-person care' },
  { value: 'gotbetter', label: 'I got better' },
  { value: 'isntworking', label: 'My treatment plan isn’t working ' },
  { value: 'cantafford', label: 'I can’t afford it anymore' },
  { value: 'other', label: 'Other' }
];

export default function CancelSubscription({ onFinished, onClose, onStep }) {
  const [step, setStep] = useState(1);
  const [selectedReason, setSelectedReason] = useState('');
  const [elaboration, setElaboration] = useState('');
  const dispatch = useDispatch();
  const couponProgress = useSelector(selectCouponProgress);
  const cancelProgress = useSelector(selectCancelProgress);
  const firstCancellation = isEmpty(
    useSelector(({ patient_reducer }) => patient_reducer.cancellation_discount_end_date)
  );
  const [savingAnswer, setSavingAnswer] = useState(false);

  const onNextStep = () => {
    setStep(s => s + 1);
  };

  const onPrevStep = () => {
    setStep(s => s - 1);
  };

  const onReasonChange = ({ value }) => {
    setSelectedReason(value);
  };

  const confirmEnabled = useCallback(() => {
    return selectedReason !== '' && elaboration !== '';
  }, [selectedReason, elaboration]);

  const onElaborationChange = e => {
    setElaboration(e.target.value);
  };

  const onConfirmAnswerClick = useCallback(async () => {
    const reason = reasons.find(r => r.value === selectedReason);
    const answer = { answer: reason.label, value: reason.value, elaboration };

    setSavingAnswer(true);

    await dispatch(saveCancellationAnswer({ answer }));

    setSavingAnswer(false);
    setStep(s => s + 1);
  }, [selectedReason, elaboration]);

  const onConfirmCancelationClick = async () => {
    await dispatch(cancel_subscription());
    await sleep(3000);
    dispatch(cancel_subscription_finished());
    onFinished();
  };

  const onApply50Discount = async () => {
    await dispatch(apply_coupon({ coupon_id: process.env.REACT_APP_CHARGEBEE_CANCELLATION_COUPON_ID }));
    await sleep(3000);
    dispatch(apply_coupon_finished());
    onFinished({ price: 49.5 });
  };

  const onCloseClick = () => {
    onClose();
  };

  useEffect(() => {
    onStep({ step });
  }, [step]);

  return (
    <>
      {!(cancelProgress.open || couponProgress.open) && (
        <Wrapper>
          {step === 1 && (
            <Step>
              <Header>
                <Isotype />
                <Title>Experience &amp; Expertise Matters</Title>
              </Header>
              <Contents>
                <Heading>Make your mental health a priority.</Heading>
                <Paragraph>
                  Unlike most PCPs, our prescribers are trained specifically in personalized treatment for mental
                  health, which allows them to work directly with you to find and maintain the best treatment to fit
                  your needs.
                </Paragraph>
                <Pills />
                <Paragraph>
                  <p>
                    Regardless of your treatment journey, we recommend that you have access to a prescriber specifically
                    trained in mental health and a Care Counselor to support you along the way.
                  </p>
                  <p>
                    Your Cerebral Care Team, which comprises a mental health prescriber and a Care Counselor, work
                    collaboratively to make sure that your treatment is meeting your specific needs and results in
                    positive outcomes.
                  </p>
                </Paragraph>
                <Controls>
                  <Button onClick={onClose}>Stick with Cerebral</Button>
                  <Button onClick={onNextStep}>Continue with cancellation</Button>
                </Controls>
              </Contents>
            </Step>
          )}
          {step === 2 && (
            <Step>
              <Header>
                <Isotype />
                <Title>Completing your treatment is important.</Title>
              </Header>
              <Contents>
                <Heading>Getting better is a journey and there typically aren’t quick fixes in mental health.</Heading>
                <CancellationList>
                  <CancellationItem>
                    <ItemIcon />
                    <ItemContent>
                      <ItemTitle>4 - 6 Weeks</ItemTitle>
                      <ItemDescription>
                        It typically takes 4-6 weeks for you to feel improvement when using the right treatment.
                      </ItemDescription>
                    </ItemContent>
                  </CancellationItem>
                  <CancellationItem>
                    <ItemIcon />
                    <ItemContent>
                      <ItemTitle>20%</ItemTitle>
                      <ItemDescription>
                        20% of people experience recurrence of symptoms within the first 6 months after stopping
                        treatment. For your safety, it’s important to discuss tapering off your medication with your
                        prescriber before stopping treatment.
                      </ItemDescription>
                    </ItemContent>
                  </CancellationItem>
                  <CancellationItem>
                    <ItemIcon />
                    <ItemContent>
                      <ItemTitle>12 months</ItemTitle>
                      <ItemDescription>
                        It is recommended that you continue your treatment for at least 12 months after experiencing
                        positive outcomes to achieve long term results.
                      </ItemDescription>
                    </ItemContent>
                  </CancellationItem>
                </CancellationList>
                <Controls>
                  <Button onClick={onClose}>Stick with Cerebral</Button>
                  <Button onClick={onNextStep}>Continue with cancellation</Button>
                </Controls>
              </Contents>
            </Step>
          )}
          {step === 3 && (
            <Step>
              <Header>
                <Isotype />
                <Title>Let us know why you’d like to cancel.</Title>
              </Header>
              <Contents>
                <Form saving={savingAnswer}>
                  {reasons.map(({ value, label }) => {
                    return (
                      <Option key={value}>
                        <CustomRadioButton
                          checked={selectedReason === value}
                          onChange={() => onReasonChange({ value })}
                        />
                        {label}
                      </Option>
                    );
                  })}

                  <TextArea
                    placeholder="Please elaborate on your reason for cancelling."
                    rows="4"
                    value={elaboration}
                    onChange={onElaborationChange}
                  />
                  <ConfirmControls>
                    <TextButton type="button" onClick={onPrevStep}>
                      Back
                    </TextButton>
                    <TextButton
                      type="button"
                      disabled={!confirmEnabled() || savingAnswer}
                      onClick={onConfirmAnswerClick}
                    >
                      Confirm your answer
                    </TextButton>
                  </ConfirmControls>
                </Form>
              </Contents>
            </Step>
          )}
          {step === 4 && (
            <Step>
              <Header>
                <Isotype />
                <Title>
                  {selectedReason === 'gotbetter' && <span>Good luck on your journey.</span>}
                  {selectedReason !== 'gotbetter' && <span>We’ve got you.</span>}
                </Title>
              </Header>
              <Contents>
                {selectedReason === 'gotbetter' && (
                  <>
                    <Paragraph>
                      <p>We are sorry to see you go! But we are very happy that your time with us was a success.</p>
                      <p>We’ll be here if you ever need us again.</p>
                    </Paragraph>
                    <Controls>
                      <Button onClick={onCloseClick}>I changed my mind</Button>
                      <Button onClick={onConfirmCancelationClick}>Confirm my cancellation</Button>
                    </Controls>
                  </>
                )}
                {selectedReason !== 'gotbetter' && (
                  <>
                    <Paragraph noMargin>
                      <p>Getting better is a journey and there typically aren’t quick fixes in mental health.</p>
                      {firstCancellation && (
                        <p className="blue">
                          If you choose to continue your Cerebral plan, you will receive a 50% off discount on your
                          subscription for the next two months.
                        </p>
                      )}
                      {!firstCancellation && <p>We’ll be here if you ever need us again.</p>}
                    </Paragraph>
                    <Controls>
                      {firstCancellation && <Button onClick={onApply50Discount}>Apply 50% discount</Button>}
                      {!firstCancellation && <Button onClick={onCloseClick}>I changed my mind</Button>}
                      <Button onClick={onConfirmCancelationClick}>Confirm my cancellation</Button>
                    </Controls>
                  </>
                )}
              </Contents>
            </Step>
          )}
        </Wrapper>
      )}

      <PaymentProgressModal
        open={cancelProgress.open}
        loading={cancelProgress.loading}
        textOnLoading={cancelProgress.textOnLoading}
        textOnLoaded={cancelProgress.textOnLoaded}
      />

      <PaymentProgressModal
        open={couponProgress.open}
        loading={couponProgress.loading}
        textOnLoading={couponProgress.textOnLoading}
        textOnLoaded={couponProgress.textOnLoaded}
        descriptionOnLoaded={couponProgress.descriptionOnLoaded}
      />
    </>
  );
}
