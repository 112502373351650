import React, { Component, /* useState, useEffect, */ Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { connect } from 'react-redux';
import Profile from '../../Profile';
import CareCoordinatorProfile from '../CareCoordinatorProfile/CareCoordinatorProfile';
import Messages from '../Messages';
import ResizableTextarea from '../../ResizableTextarea';
import { ProfileImage } from '../../question_components/styled_components';
import BackButton from '../../BackButton';
import { HeaderHolder, ContinueButton, Name } from '../styled';
import { mapRoleToType, hasCareCoordinator } from '../../../utils/care-person-helpers';
import { careCoordinatorBio, carePersonBio } from '../bios';
import { HelpCenterSection } from '../DesktopMessenger/styled';
import { goToHelpCenter } from '../../Message/Message';
import { Button } from '../../EndOfFlowScreens/Box';
import { ChatBodyWrapper } from './styled';

export const ForwardSpan = styled.span`
  margin-left: 10px;
  ${props =>
    props.isCareTeam &&
    css`
      font-size: 10px;
      margin-left: 5px;
    `}
`;

export const Spacer = styled.div`
  padding: 0.4em;
`;

export const CareCoordinatorImage = styled.img`
  margin-left: -17.5px;
  width: 40px;
  border-radius: 50%;
  border: 2px solid #d3dbf8;
  background-color: #d3dbf8;
  position: absolute;
  top: 5px;
  left: 50px;
`;

class MobileMessenger extends Component {
  state = {
    show: false
  };

  componentDidMount() {
    const { connectToSocket, handleLogo, scrollDown } = this.props;
    connectToSocket();
    scrollDown({ block: 'end' });
    if (handleLogo) {
      handleLogo(false);
    }
  }

  componentWillUnmount() {
    if (this.props.handleLogo) {
      this.props.handleLogo(true);
    }
  }

  // eslint-disable-next-line consistent-return
  componentDidUpdate(prevProps, prevState) {
    const { connectToSocket, scrollDown, messages, providerMessenger, activeMessenger } = this.props;
    if (prevProps.providerMessenger !== providerMessenger) {
      connectToSocket();
    }

    if (prevState.show && messages[activeMessenger].length > 0) {
      return scrollDown({ block: 'end' });
    }
  }

  setShow = () => {
    this.setState({
      show: true
    });
  };

  setHide = () => {
    this.setState({
      show: false
    });
  };

  render() {
    const {
      userIsTyping,
      next,
      carePerson,
      careCoordinator,
      isCareManagerAvailable,
      setRef,
      sendMessage,
      setName,
      handleLogo,
      providerMessenger,
      provider,
      scrollDown
    } = this.props;
    const { name } = carePerson;
    const type = mapRoleToType(carePerson);

    const hasCoordinator = hasCareCoordinator(careCoordinator);
    let careCoordinatorWithDefaultBio;
    if (careCoordinator) careCoordinatorWithDefaultBio = { ...careCoordinator, bio: careCoordinatorBio(type) };

    const carePersonWithDefaultBio = { ...carePerson, bio: carePersonBio(type) };
    const messageRecipientName = hasCoordinator && !providerMessenger ? 'your care team' : name;

    const getName = () => {
      if (providerMessenger) {
        return provider.name;
      }
      return name;
    };

    return this.state.show ? (
      <Fragment>
        {handleLogo ? (
          <BackButton left={15} onClick={this.setHide}>
            &larr; Messages
          </BackButton>
        ) : (
          <HeaderHolder>
            <BackButton left={15} onClick={this.setHide}>
              &larr; Messages
            </BackButton>
            <ContinueButton onClick={next}>
              Continue<span>&#8594;</span>
            </ContinueButton>
          </HeaderHolder>
        )}
        <ChatBodyWrapper className="chat_body_messages ">
          <Spacer />
          <Profile
            classHolder="d-flex flex-column align-items-center chat_body_profile"
            carePerson={providerMessenger ? provider : carePersonWithDefaultBio}
            isOnline={isCareManagerAvailable}
          />
          {!providerMessenger && hasCoordinator && (
            <CareCoordinatorProfile careCoordinator={careCoordinatorWithDefaultBio} carePersonType={type} />
          )}
          <HelpCenterSection direction="column" justifyContent="flex-end">
            <div>
              <p>Don&apos;t want to wait for a response from someone?</p>
              <Button onClick={goToHelpCenter}>Go to the Help Center</Button>
            </div>
          </HelpCenterSection>
        </ChatBodyWrapper>
      </Fragment>
    ) : (
      <Fragment>
        {handleLogo ? (
          <BackButton left={15} underline onClick={this.setShow}>
            <ProfileImage
              width="40px"
              src={carePerson.photo_url}
              alt="care manager"
              absolute={hasCoordinator}
              className="profile-image-back-button"
            />
            {!providerMessenger && hasCoordinator && (
              <CareCoordinatorImage width="40px" src={careCoordinator.photo_url} alt="care coordinator" />
            )}
            <Name isCareTeam={hasCoordinator} isProviderMessenger={providerMessenger}>
              {hasCoordinator && !providerMessenger ? 'Your Care Team' : getName()}
            </Name>
            <ForwardSpan isCareTeam={hasCoordinator}>&#8594;</ForwardSpan>
          </BackButton>
        ) : (
          <HeaderHolder>
            <BackButton left={15} underline onClick={this.setShow}>
              <ProfileImage
                width="40px"
                src={carePerson.photo_url}
                alt="care manager"
                absolute
                className="profile-image-back-button"
              />
              <Name isCareTeam={hasCoordinator}>{hasCoordinator ? 'Your Care Team' : getName()}</Name>
            </BackButton>
            <ContinueButton onClick={next}>
              Continue<span>&#8594;</span>
            </ContinueButton>
          </HeaderHolder>
        )}
        <Messages setName={setName} setRef={setRef} next={next} scrollDown={scrollDown} />
        <div className="d-flex chat_footer">
          <ResizableTextarea
            rows={1}
            userTyping={userIsTyping}
            submitMessage={sendMessage}
            name={messageRecipientName}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    provider: state.patient_reducer.current_provider
  };
};

MobileMessenger.propTypes = {
  carePerson: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }).isRequired,
  messages: PropTypes.object.isRequired
};

export default connect(mapStateToProps, null)(MobileMessenger);
