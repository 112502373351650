import React, { ChangeEvent, ChangeEventHandler } from 'react';
import styled from 'styled-components/macro';
import Error from '../Error';

const TextFieldArea = styled.textarea`
  display: flex !important;
  flex-direction: column !important;
  margin-bottom: 10px;
  border: 1px solid #250044;
  width: 100%;
  min-height: 100px;
  color: #250044;
  padding: 7px;
  background: #ffffff;
  border: 1px solid #d3dbf8;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px 4px #f6f6f9;
  border-radius: 4px;
  font-size: 14px;
  margin: 10px 0px;

  ::placeholder {
    font-size: 1em;
    font-style: italic;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.4px;
    color: #250044;
  }
`;

export interface CustomCheckBoxProps {
  event_handler: ChangeEventHandler;
  isChecked?: boolean;
  name?: string;
  radio: boolean | undefined;
  rectangle?: boolean | undefined;
}

export interface TextAreaProps {
  error?: string;
  onChange(e: React.ChangeEvent): void;
  value: string | undefined;
  rows: number;
  placeholder: string;
  maxLength?: number;
}

export interface CustomRadioButtonProps {
  onChange?: () => void;
  name?: string;
  checked?: boolean;
}

export interface CustomRadioButtonWithTextProps {
  onChange(): void;
  name?: string;
  checked?: boolean;
  text: string;
  className: string;
}

export interface ButtonProps {
  onClick?: () => void;
  className?: string;
  value?: string;
  id?: string;
  is_disabled?: boolean;
}

export const CustomCheckBox: React.FC<CustomCheckBoxProps> = ({
  event_handler,
  isChecked = false,
  name = '',
  radio
}) => (
  <div className={`checkbox-container ${radio ? 'checkbox-radio' : ''}`}>
    <input aria-label="checkBox-input" type="checkbox" onChange={event_handler} name={name} checked={isChecked} />
    <span className="checkbox-checkmark">
      <img src={`${process.env.PUBLIC_URL}/img/check-dark.svg`} alt="" />
    </span>
  </div>
);

export const TextArea: React.FC<TextAreaProps> = ({ error = '', onChange, value, rows, placeholder, maxLength }) => (
  <>
    <TextFieldArea
      aria-label="textArea-input"
      placeholder={placeholder}
      onChange={onChange}
      rows={rows}
      defaultValue={value}
      maxLength={maxLength}
    />
    <Error error={error} style={{ marginBottom: '10px', textAlign: 'center' }} />
  </>
);

export const CustomRadioButton: React.FC<CustomRadioButtonProps> = ({
  onChange = () => {},
  name = 'radio',
  checked = false
}) => (
  <label className="radio-button-container">
    <input type="radio" defaultChecked={checked} name={name} onChange={onChange} />
    <span className="radio-button" />
  </label>
);

export const CustomRadioButtonWithText: React.FC<CustomRadioButtonWithTextProps> = ({
  onChange,
  checked,
  name,
  text,
  className
}) => (
  <>
    <CustomRadioButton onChange={onChange} checked={checked} name={name} />
    <div className={className}>{text}</div>
  </>
);

interface Divwrap {
  [key: string]: unknown;
}

export const divwrap = (...rest: number[]) => {
  let classNames = '';
  let styles = {};

  if (typeof rest[0] === 'object') {
    styles = rest[0];
  }

  if (typeof rest[0] === 'string') {
    classNames = rest[0];
  }

  if (rest[1] && typeof rest[1] === 'object') {
    styles = rest[1];
  }

  return (Component: React.FC<Divwrap>) => {
    return (props: Divwrap) => {
      return (
        <div className={classNames} style={styles}>
          <Component {...props} />
        </div>
      );
    };
  };
};

export const Button: React.FC<ButtonProps> = ({
  className = '',
  onClick = () => {},
  value = '',
  id = '',
  is_disabled = false
}) => (
  <button type="button" className={className} id={id} onClick={onClick} disabled={is_disabled}>
    {value}
  </button>
);

interface InputTypeProps {
  labelStyles: React.CSSProperties | undefined;
  className: string;
  isLabel: boolean;
  event_handler: () => void;
  text: string;
  error: string;
  value: string;
  autoComplete: string;
  id: string;
  pattern: string;
}

// remove classNames and styles when new payment screen will be implemented
export const inputType = (type: string) => {
  return (props: InputTypeProps) => {
    const label: React.CSSProperties = { width: '100%', textAlign: 'left', color: '#250044', flex: 1 };
    const {
      labelStyles = {},
      className = '',
      isLabel = true,
      event_handler = () => {},
      text = '',
      error = '',
      value = '',
      autoComplete = 'on',
      id = '',
      pattern = '.*'
    } = props;

    const labelStyle = { ...label, ...labelStyles };
    const isOn = value.length > 0 && isLabel;

    return (
      <label style={labelStyle}>
        {isOn ? text : ''}
        <input
          id={id}
          className={className}
          onChange={event_handler}
          type={type}
          pattern={pattern}
          defaultValue={value}
          placeholder={text}
          autoComplete={autoComplete}
        />
        <Error error={error} />
      </label>
    );
  };
};

export interface inputNoBorderProps {
  event_handler: (event: ChangeEvent<HTMLInputElement>) => void;
  text: string;
  default_value: string;
  type: string;
}

export const input_type_no_border = (
  event_handler: (v: string | React.ReactNode) => void,
  text?: string,
  default_value = '',
  type = 'text'
) => (
  <div className="d-flex justify-content-start input-no-border-holder">
    <input
      pattern="\d*"
      className="input-no-border"
      onChange={event_handler}
      defaultValue={default_value}
      type={type}
      placeholder={text}
    />
  </div>
);

export interface resetButtonProps {
  event_handler: (e: React.MouseEvent, type: string) => void;
  type: string;
  description: string;
}

export const pwd_reset_button = (
  event_handler: (e: React.MouseEvent, type: string) => void,
  description: string,
  type: string
) => <input className="btn-pwd-reset" onClick={e => event_handler(e, type)} type="button" value={description} />;
