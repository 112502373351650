import React from 'react';
import PropTypes from 'prop-types';

const Error = ({ className, error, style }) => (
  <p className={className} style={style}>
    {error}
  </p>
);

Error.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  style: PropTypes.shape({})
};

Error.defaultProps = {
  className: 'red left pad-0 marg-0 weight_normal',
  error: '',
  style: {}
};

export default Error;
