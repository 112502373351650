import { datadogRum } from '@datadog/browser-rum';

const env = process.env.REACT_APP_NODE_ENV || 'development';

export default () => {
  if (env === 'development') return null;
  datadogRum.init({
    applicationId: '53dee54c-31f4-4f39-9015-da51577d0492',
    clientToken: 'pub78022276b60eb529d001d23c9276592e',
    site: 'datadoghq.com',
    service: 'Client',
    env,
    version: process.env.REACT_APP_GIT_SHA || '1.0.0',
    sampleRate: 100,
    replaySampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask',
    allowedTracingOrigins: [
      /https:\/\/.*\.cerebral\.com/,
      /https:\/\/.*\.getcerebral\.com/,
      /https:\/\/.*\.cerebral-development\.com/
    ]
  });

  datadogRum.startSessionReplayRecording();
};
