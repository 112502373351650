import styled from 'styled-components/macro';
import { Modal as DefaultModal } from 'react-bootstrap';
import isotypeImage from 'img/iso.svg';
import whiteIsotypeImage from 'img/iso.png';
import closeImage from 'img/close.svg';
import exclamationImg from './img/exclamation.svg';

export const Isotype = styled.div<{ isotypeImage: string }>`
  background-image: url(${isotypeImage});
  height: 38px;
  width: 56px;
  margin: 0 auto;
  background-position: center top;
`;

export const Header = styled.div`
  background: #eff1fa;
  padding: 30px;
`;

export const Content = styled.div`
  padding: 29px;
`;

export const Title = styled.div`
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #6786ff;
  width: 80%;
  margin: 0 auto;
`;

export const Modal = styled(DefaultModal)`
  .modal-content {
    max-width: 360px;
    margin: 0 auto;
    border-radius: 4px;
    overflow: hidden;
  }
`;

export const CloseButton = styled.button<{ closeImage: string }>`
  background-image: url(${closeImage});
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  border: transparent;
  position: absolute;
  background-color: transparent;
  right: 0px;
  top: 2px;
  display: flex;
  background-position: center;
`;

export const BlueHeader = styled.div<{ noBottomPadding?: boolean }>`
  background: #6786ff;
  padding: 30px 30px ${props => (props.noBottomPadding ? '0px;' : '30px;')};
  border-top-radius: 10px;
`;

export const WhiteTitle = styled.div<{ small?: boolean }>`
  font-style: italic;
  font-weight: 600;
  font-size: ${props => (props.small ? '14px;' : '20px;')};
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  margin-top: 5px;
`;

export const WhiteIsotype = styled.div`
  background-image: url(${whiteIsotypeImage});
  height: 38px;
  width: 56px;
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  &.careteamIcon {
    margin-top: 20px;
  }
`;

export const WhiteExclamation = styled(WhiteIsotype)`
  background-image: url(${exclamationImg});
  margin-bottom: 20px;
`;
