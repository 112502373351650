import React, { useEffect, useState } from 'react';
import { useParams, useLocation, Redirect, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import RatingForm from './RatingForm';
import FinalPreview from './FinalPreview';
import StepView from './StepView';
import {
  setBeforeRating,
  setStepAnswer,
  completeExercise,
  setActiveExerciseId,
  clearActiveStep
} from '../../../actions/exercise_actions';
import Spinner, { SpinnerHolder } from '../../Spinner';
import { useApi } from '../../../utils/api';
import tracking from '../../../utils/tracking';

import { ProgressBar } from './styled';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ExerciseFlow = () => {
  const { exerciseId } = useParams();
  const query = useQuery();
  const api = useApi();
  const location = useLocation();
  const history = useHistory();
  const stepIdx = Number(query.get('step') || '0');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [detail, setDetail] = useState(null);

  const fetchExercise = async () => {
    try {
      const { data } = await api.get(`/api/exercises/${exerciseId}`);

      if (!data.includes_rating) history.replace(`${location.pathname}?step=1`);
      setDetail(data);
      dispatch(setActiveExerciseId(Number(exerciseId)));
      setLoading(false);
    } catch (err) {
      tracking.error({ e: err.toString() });
    }
  };

  useEffect(() => {
    if (exerciseId) {
      setLoading(true);
      fetchExercise();
      dispatch(clearActiveStep());
    }
  }, [exerciseId]);

  if (!detail || loading) {
    return (
      <SpinnerHolder>
        <Spinner size="2.5rem" color="#6786FF" borderWidth="4px" />
      </SpinnerHolder>
    );
  }

  const activeStep = detail.sorted_steps[stepIdx - 1];

  const handleClickNext = answer => {
    if (answer) {
      dispatch(setStepAnswer({ step_id: activeStep.id, answer }));
    }
    if (!detail.includes_rating && stepIdx === detail.sorted_steps.length) {
      dispatch(completeExercise());
    }
    history.push(`${location.pathname}?step=${stepIdx + 1}`);
  };

  const handleRateSubmit = (rate, isBegin) => {
    if (isBegin) {
      dispatch(setBeforeRating(rate));
    } else {
      dispatch(completeExercise(rate));
    }
    handleClickNext();
  };

  const handleGoBack = () => {
    history.goBack();
  };

  if (stepIdx > detail.sorted_steps.length + 2) {
    return <Redirect to={`/patient/dashboard/patient_exercises/${detail.category_id}`} />;
  }

  if (stepIdx === detail.sorted_steps.length + (detail.includes_rating ? 2 : 1)) {
    return <FinalPreview exerciseData={detail} />;
  }

  const isBeforeAfter = detail.includes_rating && (!stepIdx || stepIdx === detail.sorted_steps.length + 1);
  const { title } = detail;

  return (
    <>
      {isBeforeAfter ? (
        <RatingForm isBegin={!stepIdx} onSubmit={handleRateSubmit} title={title} />
      ) : (
        <>
          <ProgressBar trackWidth={(stepIdx / detail.sorted_steps.length) * 100} />
          <StepView
            stepData={activeStep}
            onNext={handleClickNext}
            goBack={handleGoBack}
            title={title}
            isLast={!detail.includes_rating && stepIdx === detail.sorted_steps.length}
          />
        </>
      )}
    </>
  );
};

export default ExerciseFlow;
