import React, { useEffect, lazy, Suspense, useState } from 'react';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import Alert from 'react-s-alert';
import { parse } from 'query-string';
import { ThemeProvider } from 'styled-components/macro';
import { useRolloutFlag } from './contexts/RolloutFlag';
import PasswordReset from './components/PasswordReset';
import { sign_out } from './actions/user_auth_action';
import TimerModal, { showTimerModal } from './components/OutstandingTasksModal/TimerModal';
import ErrorBoundary from './ErrorBoundary';
import 'react-s-alert/dist/s-alert-default.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/cerebral.scss';
import ApiTester from './components/ApiTester';
import initRum from './utils/datadog-rum';
import segment from './utils/segment';
import PageLoading from './components/UI/PageLoading/PageLoading';
import { countryFromUrl, isB2bEligibleCountry } from './utils/common';
import { triggerEventKameleoon } from './utils/kameleoon/utils';
import strings from './localization';

axios.defaults.baseURL = process.env.REACT_APP_API_SERVER_URL;
// Jacob's right
// Add custom header to sent for track segment user on server
const segmentAID = localStorage.getItem('ajs_anonymous_id');

if (segmentAID) {
  axios.defaults.headers.common['X-AnonymousId'] = segmentAID.replace(/['"]+/g, '');
}

const Patient = lazy(() => import('./containers/patient'));
const DirectRoutes = lazy(() => import('./components/DirectRoutes'));
const MemberSignIn = lazy(() => import('./components/SignIn'));
const Unsubscribe = lazy(() => import('./components/Unsubscribe'));
const SignUp = lazy(() => import('./components/sign_up/sign_up'));
const NutritionGlp1Information = lazy(() => import('./components/NutritionGlp1Information'));
const Fsa = lazy(() => import('./components/question_types/patient_payment_insurance/FSA'));
const ThankYou = lazy(() => import('./components/question_types/patient_payment_insurance/Thankyou'));

export const theme = {
  background: 'white',
  boxShadow: '0px 0px 12px 4px #f6f6f9',
  border: '1px solid #D3DBF8'
};

const App = props => {
  const [isLoading, setIsLoading] = useState(true);
  const rollout = useRolloutFlag();
  const history = useHistory();
  const { pathname, search, state } = useLocation();
  const country = countryFromUrl();
  const UrlParams = parse(search);

  useEffect(() => {
    if (!Object.keys(rollout?.flag).length) {
      setIsLoading(true);
      rollout.loadFlag('nutrition_glp1_toggle');
    } else {
      setIsLoading(false);
    }
  }, [rollout?.flag]);

  useEffect(() => {
    if (
      !state?.nutritionGlp1 &&
      UrlParams?.plan === 'nutrition' &&
      rollout?.flag?.nutrition_glp1_toggle &&
      pathname?.includes('signup')
    ) {
      history.push('/nutrition-glp1');
    }
  }, [state?.nutritionGlp1, UrlParams?.plan, rollout?.flag?.nutrition_glp1_toggle, pathname]);

  useEffect(() => {
    initRum();

    // Check utm_content in URL parameter to set into localStorage
    const params = new URLSearchParams(window.location.search);

    if (params.get('utm_content')) {
      window.localStorage.setItem('utm_content', params.get('utm_content'));
      triggerEventKameleoon(params.get('utm_content'));
    }
  }, []);

  useEffect(() => {
    window.dataLayer.push({ event: 'optimize.activate' });
    segment.page(pathname);
  }, [pathname]);

  useEffect(() => {
    if (isB2bEligibleCountry(country) && props.accountId) {
      strings.setLanguage(`${country}b2b`);
    } else {
      strings.setLanguage(country);
    }
  }, [country, props.accountId]);

  const onLogout = () => {
    props.logout();
    history.push('/member_sign_in');
  };

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App d-flex justify-content-center">
        {props.isSignedIn && showTimerModal(pathname) && <TimerModal onLogout={onLogout} />}
        <ErrorBoundary>
          <Suspense fallback={<PageLoading />}>
            <Switch>
              <Route
                path="/patient/sign-up"
                render={() => {
                  return <Redirect to={`/signup${search}`} />;
                }}
              />
              <Route path="/patient" component={Patient} />
              <Route path="/user" component={PasswordReset} />
              <Route path="/member_sign_in" component={MemberSignIn} />
              <Route
                path="/signup"
                render={() => {
                  if (UrlParams?.plan === 'nutrition') {
                    const service_line = rollout?.flag?.nutrition_glp1_toggle ? 'nutrition-glp1' : 'nutrition';
                    return <SignUp service_line={service_line} history={history} />;
                  }
                  return <SignUp history={history} />;
                }}
              />
              <Route path="/unsubscribe" component={Unsubscribe} />
              <Route path="/appointment" component={DirectRoutes} />
              <Route
                path="/payment-fsa"
                render={({ location }) => {
                  const service_line = parse(location.search)?.plan;
                  const month_line = parse(location.search)?.month;
                  const fsa_line = parse(location.search)?.fsa;
                  return props.isSignedIn ? (
                    <Fsa {...props} />
                  ) : (
                    <Redirect
                      to={{
                        pathname: '/member_sign_in/',
                        search: `?plan=${service_line}&fsa=${fsa_line}&month=${month_line}`
                      }}
                    />
                  );
                }}
              />
              <Route path="/nutrition-glp1" component={NutritionGlp1Information} />
              <Route
                path="/thank-you"
                render={() => {
                  return props.isSignedIn ? <ThankYou /> : <Redirect to="/member_sign_in/" />;
                }}
              />
              <Route component={MemberSignIn} />
            </Switch>
          </Suspense>
          <Alert stack={{ limit: 3 }} />
        </ErrorBoundary>
        {process.env.REACT_APP_ENVIRONMENT !== 'production' && <ApiTester />}
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = state => ({
  isSignedIn: state.global_reducer.current_user.is_signedIn,
  accountId: state.patient_reducer.accountId
});

const mapDispatchToProps = {
  logout: sign_out
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
