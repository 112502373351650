import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  ping_care_manager,
  get_messages,
  post_message,
  user_is_typing,
  get_message_thread
} from '../../actions/messenger_action';
import { set_bank_step_and_question_step } from '../../actions/patient_action';
import { sleep } from '../../utils/common';
import tracking from '../../utils/tracking';
import { withGlobals } from '../../contexts/globals';

export class MessageMethods extends React.Component {
  myRef = null;

  // eslint-disable-next-line no-return-assign
  setRef = el => {
    if (!el) return;
    this.myRef = el;
  };

  connectToSocket = async () => {
    const { connected, pingCareManager, getMessages, dashboard, providerMessenger, getMessageThread } = this.props;

    try {
      !dashboard && !connected && (await pingCareManager(providerMessenger));
      await sleep(500);
      if (providerMessenger) {
        await getMessages(providerMessenger);
      } else {
        await getMessages();
      }
      await getMessageThread();
    } catch (err) {
      tracking.error({ message: 'Could not ping care manager or get message history', e: err });
    }
  };

  shouldComponentUpdate(nextProps) {
    const { messages, activeMessenger } = this.props;

    if (!messages[activeMessenger]) return true;
    if (nextProps.messages[nextProps.activeMessenger]) {
      return nextProps.messages[nextProps.activeMessenger].length !== messages[activeMessenger].length;
    }
    return false;
  }

  componentDidUpdate() {
    if (this.myRef !== null) this.scrollDown({ behavior: 'smooth' });
  }

  setName = ({ sender_id, sender_name, is_coordinator_sender, sender_name_pii_friendly }) => {
    const { user } = this.props;
    return user.id !== sender_id ? (is_coordinator_sender ? sender_name_pii_friendly : sender_name) : 'You';
  };

  scrollDown = options => {
    if (!this.myRef) return;
    this.myRef.scrollIntoView(options);
  };

  next = () => {
    const {
      history,
      user: {
        patient: { region }
      },
      isReactivation,
      globals
    } = this.props;

    if (isReactivation) {
      this.handleReactivation();
    } else {
      let path = '/patient/offers';
      if (globals.isTherapistRegion(region)) {
        path = '/patient/choose-plan';
      }
      history.push(path);
    }
  };

  handleReactivation = () => {
    const { nextBankId, setNextBank, history } = this.props;

    setNextBank(nextBankId, 0);
    history.push('/patient/question_bank');
  };

  sendMessage = msg => {
    const { thread_id, postMessage, recipient_id, sender_id, active_messenger } = this.props;

    const body = {
      message: msg,
      recipient_id: recipient_id[active_messenger],
      sender_id: sender_id[active_messenger]
    };

    return postMessage(thread_id[active_messenger], body);
  };

  getCarePerson = () => {
    const { user, care_manager, therapist, liveChat, providerMessenger, provider } = this.props;
    const {
      patient: { offering_key }
    } = user;

    if (providerMessenger) {
      return provider;
    }
    if (liveChat || !offering_key.includes('therapy')) {
      return care_manager;
    }

    return therapist;
  };

  getCareCoordinator = () => {
    const { care_coordinator } = this.props;

    return care_coordinator;
  };

  getNutritionist = () => {
    const { nutritionist } = this.props;

    return nutritionist;
  };

  getMethods = () => ({
    setName: this.setName,
    sendMessage: this.sendMessage,
    setRef: this.setRef,
    scrollDown: this.scrollDown,
    next: this.next,
    connectToSocket: this.connectToSocket,
    userIsTyping: this.props.userIsTyping,
    handleLogo: this.props.handleLogo
  });

  getProperties = () => ({
    carePerson: this.getCarePerson(),
    careCoordinator: this.getCareCoordinator(),
    nutritionist: this.getNutritionist(),
    messages: this.props.messages,
    isCareManagerAvailable: this.props.isCareManagerAvailable,
    activeMessenger: this.props.active_messenger,
    providerMessenger: this.props.providerMessenger
  });

  render() {
    return (
      <div style={this.props.styles || {}} className="chat_holder">
        {this.props.render(this.getProperties, this.getMethods)}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.messenger_reducer,
    user: state.global_reducer.current_user.attributes,
    care_manager: state.patient_reducer.care_manager,
    care_coordinator: state.patient_reducer.care_coordinator,
    nutritionist: state.patient_reducer.nutritionist,
    therapist: state.patient_reducer.therapist,
    isReactivation: state.patient_reducer.visit_object.is_reactivation,
    nextBankId: state.patient_reducer.currentPatientPath.findIndex(bank => bank.name === 'checkout'),
    isCareManagerAvailable: state.patient_reducer.is_care_manager_available,
    activeMessenger: state.messenger_reducer.active_messenger,
    provider: state.patient_reducer.current_provider
  };
};

const mapDispatchToProps = dispatch => {
  return {
    userIsTyping: () => dispatch(user_is_typing()),
    pingCareManager: providerMessenger => dispatch(ping_care_manager(providerMessenger)),
    postMessage: (thread_id, message) => dispatch(post_message(thread_id, message)),
    getMessages: providerMessenger => dispatch(get_messages(providerMessenger)),
    setNextBank: (bankStep, questionStep) => dispatch(set_bank_step_and_question_step(bankStep, questionStep)),
    getMessageThread: () => dispatch(get_message_thread())
  };
};

MessageMethods.propTypes = {
  user: PropTypes.shape({}).isRequired,
  therapist: PropTypes.shape({}).isRequired,
  care_manager: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  styles: PropTypes.shape({}),
  render: PropTypes.func,
  recipient_id: PropTypes.object,
  sender_id: PropTypes.object,
  thread_id: PropTypes.object,
  messages: PropTypes.object.isRequired,
  postMessage: PropTypes.func.isRequired,
  pingCareManager: PropTypes.func.isRequired
};

const WithMessageMethods = withRouter(connect(mapStateToProps, mapDispatchToProps)(withGlobals(MessageMethods)));

export default WithMessageMethods;
