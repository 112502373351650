import React, { useEffect, useState, LegacyRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { differenceInMinutes, format } from '../../utils/date-fns';
import ButtonLink from './ButtonLink';
import { findUrlsInText } from '../../utils/common';
import { BubleHelpCenter } from './styled';
import { ReactComponent as CerebralIcon } from './assert/CerebralIcon.svg';
import { Button } from '../EndOfFlowScreens/Box';
import { HELP_CENTER } from '../../utils/default-url';
import { PopupWarningModal } from '../OutstandingTasksModal/PopupWarningModal';

const isButton = (value: string) => {
  return [
    'offers',
    'schedule/provider',
    'schedule/care_manager',
    'schedule/nutritionist',
    'reassign/prescriber',
    'reassign/care_manager',
    'reassign/therapist',
    'visit/followup',
    'schedule/therapist',
    'choose-plan/therapy',
    'choose-plan/medication',
    'choose-plan/medication-therapy',
    'exercise'
  ].some(type => value.includes(type));
};

const getTime = (created_at: string) => {
  let displayTime = format(created_at, {
    hour: 'numeric',
    minute: '2-digit'
  });

  const min_diff = differenceInMinutes(created_at);
  if (min_diff < 1) {
    displayTime = 'Just now';
  } else if (min_diff < 10) {
    const diff = Math.floor(min_diff);
    displayTime = `${diff} min${diff === 1 ? '' : 's'} ago`;
  }

  return displayTime;
};

export const goToHelpCenter = (): void => window.location.replace(HELP_CENTER);

export interface ListDisabled {
  provider: boolean;
  care_manager: boolean;
  therapist: boolean;
}
interface MessageProps {
  onNext(): void;
  name: string;
  message: string;
  created_at: string;
  setRef: LegacyRef<HTMLLIElement> | undefined;
  isRedirected: boolean;
  listDisabledReassign: ListDisabled;
}

const Message: React.FC<MessageProps> = ({
  onNext,
  name,
  message,
  created_at,
  setRef,
  isRedirected,
  listDisabledReassign
}) => {
  const position = name === 'You' ? 'end' : 'start';
  const classNames = name === 'You' ? 'sent_message' : 'received_message';
  const styles = name === 'You' ? { paddingLeft: '40px' } : { paddingRight: '40px' };
  const [displayTime, setDisplayTime] = useState(getTime(created_at));
  const [openPopup, setOpenPopup] = useState(false);
  const [routeConfig, setRouteConfig] = useState('');
  const openModal = (newRoute: string) => {
    setOpenPopup(true);
    setRouteConfig(newRoute);
  };
  const history = useHistory();
  const openInsuranceUpdate = () => {
    history.push('/patient/dashboard/update_insurance_cards');
  };
  useEffect(() => {
    const timeCheck = setInterval(() => {
      setDisplayTime(getTime(created_at));
    }, 30000);

    return () => {
      clearInterval(timeCheck);
    };
  }, [created_at]);

  if (!message) return null;

  return (
    <>
      <li ref={setRef} style={styles} className={`d-flex justify-content-${position} message`}>
        <div className="d-flex flex-column message_holder">
          <span className={`align-self-${position} message_name`}>{name}</span>
          {message.startsWith('http') && isButton(message) ? (
            <ButtonLink
              link={message}
              onNext={onNext}
              classNames={classNames}
              openModal={openModal}
              listDisabledReassign={listDisabledReassign}
            />
          ) : isRedirected ? (
            <BubleHelpCenter direction="column" alignItems="flex-start" className={classNames}>
              <p className="header-text-buble">
                *Please note: When your Care Counselor is not immediately available or if you have administrative
                issues, your coordinator will take care of it.
              </p>
              <p className="header-text-buble">
                If you do not want to wait, you can{' '}
                <span onClick={goToHelpCenter} className="check-out-buble">
                  check out our Help Center
                </span>{' '}
                first.
              </p>
              <div className="content-buble">
                <CerebralIcon height="35" />
                <span className="text-logo-buble">Cerebral Help Center</span>
                <p>
                  Our Help Center was created to give you a simple way to get answers to all of your Cerebral questions.
                  Want to get quick answers to common questions about .rescheduling a missed visit, getting insurance
                  reimbursements, or updating your shipping address? Check out our Help Center!
                </p>
                <Button onClick={goToHelpCenter}>Go to Help Center</Button>
              </div>
            </BubleHelpCenter>
          ) : (
            <span className={classNames}>{findUrlsInText(message)}</span>
          )}
          <span className="align-self-end message_date">{displayTime}</span>
        </div>
      </li>
      {openPopup && (
        <PopupWarningModal onClose={() => setOpenPopup(false)} onUpdate={openInsuranceUpdate} routePath={routeConfig} />
      )}
    </>
  );
};

Message.propTypes = {
  onNext: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired
};

export default Message;
