import React, { Component } from 'react';
import { connect } from 'react-redux';
import { InputPhone, getPhoneRegion } from '@cerebral-inc/ui';
import { update_user_information } from '../../actions/user_auth_action';
import { update_patient } from '../../actions/patient_action';
import { stateToErrorReducer } from '../../utils/common';
import InputDefault from '../UI/InputDefault';
import InputWrapper from '../UI/InputWrapper';
import { VariationContext } from '../Variations/VariationContext';
import strings from '../../localization';

// not sure patient and therapist can share this component
class EditProfile extends Component {
  static contextType = VariationContext;

  constructor(props) {
    super(props);
    const {
      email,
      first_name,
      last_name,
      uid,
      phone,
      patient: { pronoun }
    } = props.attr;
    this.state = {
      type: 'read',
      email: email || uid,
      first_name,
      last_name,
      phone,
      phone_region: {
        code: '',
        country: ''
      },
      new_email: '',
      new_first_name: '',
      new_last_name: '',
      new_phone: phone,
      pronoun,
      new_pronoun: pronoun,
      errors: {}
    };
  }

  componentDidMount() {
    const [currentCode, ...currentPhone] = this.state.phone.split(' ');

    if (this.context && this.context.country) {
      this.setState({ new_phone: currentPhone.join(' ') });
      this.setState({ phone_region: getPhoneRegion({ code: currentCode }, strings.phoneRegion) });
    }
  }

  edit_btn_handler = type => {
    if (type === 'read') this.setState({ type: 'write' });
    else this.setState({ type: 'read' });
  };

  submit_btn_handler = async () => {
    const {
      new_email,
      email,
      new_first_name,
      new_phone,
      phone,
      phone_region,
      first_name,
      new_last_name,
      last_name,
      new_pronoun
    } = this.state;

    const fieldToCheck = {
      phone_number: new_phone
    };

    const errors = stateToErrorReducer(fieldToCheck);

    if (Object.keys(errors).length > 0) {
      this.setState({
        errors
      });

      return;
    }

    const newEmail = new_email || email;
    const newFirstName = new_first_name || first_name;
    const newLastName = new_last_name || last_name;
    const newPhone = new_phone || phone;
    const newUserInfo = {
      email: newEmail,
      first_name: newFirstName,
      last_name: newLastName,
      phone: `${phone_region.code} ${newPhone}`
    };

    await this.props.update_user_information(newUserInfo);
    await this.props.update_patient({ pronoun: new_pronoun });

    // eslint-disable-next-line no-alert
    alert('Your information has been changed successfully');
    this.setState({
      ...newUserInfo,
      pronoun: new_pronoun,
      type: 'read',
      errors: {}
    });
  };

  update_user_info_handler = (type, val) => {
    if (type === 'phone_region') {
      this.setState({ new_phone: '' });
      this.setState(prevState => ({ phone_region: getPhoneRegion({ country: val }, prevState.phone_region.country) }));
    } else {
      this.setState({ [type]: val });
    }
  };

  render() {
    const {
      type,
      first_name,
      last_name,
      email,
      phone,
      new_phone,
      pronoun,
      new_pronoun,
      errors,
      new_first_name,
      new_last_name,
      new_email
    } = this.state;
    return type === 'read' ? (
      <div className="d-flex align-self-start main-content-small-card">
        <div className="d-flex flex-column justify-content-between flex-grow-1 flex-shrink-1 card-items-container">
          <div>
            <div className="small-card-title">Profile Information</div>
            <div className="small-card-item">
              {`${first_name} ${last_name}`} {}
            </div>
            <div className="small-card-item">{pronoun}</div>
            <div className="small-card-item">{email}</div>
            <div className="small-card-item">{phone}</div>
          </div>
          <div>
            <div className="d-flex flex-row justify-content-start">
              <div className="small-card-btn" onClick={() => this.edit_btn_handler('read')}>
                EDIT
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="d-flex align-self-start main-content-small-card">
        <div className="d-flex flex-column justify-content-between flex-grow-1 flex-shrink-1 card-items-container">
          <div>
            <div className="small-card-title">Profile Information</div>
            <div className="small-card-item">
              <InputWrapper>
                <InputDefault
                  event_handler={e => this.update_user_info_handler('new_first_name', e.target.value)}
                  text="Legal First Name"
                  default_value={new_first_name || first_name}
                  autoComplete="given-name"
                  name="given-name"
                  disabled
                />
              </InputWrapper>
            </div>
            <div className="small-card-item">
              <InputWrapper>
                <InputDefault
                  event_handler={e => this.update_user_info_handler('new_first_name', e.target.value)}
                  text="Legal Last Name"
                  default_value={new_last_name || last_name}
                  autoComplete="family-name"
                  name="family-name"
                  disabled
                />
              </InputWrapper>
            </div>
            <div className="small-card-item">
              <InputWrapper>
                <InputDefault
                  event_handler={e => this.update_user_info_handler('new_pronoun', e.target.value)}
                  text="Gender Pronouns"
                  default_value={new_pronoun || pronoun}
                  name="pronouns"
                />
              </InputWrapper>
            </div>
            <div className="small-card-item">
              <InputWrapper>
                <InputDefault
                  event_handler={e => this.update_user_info_handler('new_email', e.target.value)}
                  text="Email"
                  default_value={new_email || email}
                  autoComplete="email"
                  name="email"
                />
              </InputWrapper>
            </div>
          </div>
          <div className="small-card-item">
            <InputPhone
              onChange={e => this.update_user_info_handler('new_phone', e.target.value)}
              onRegionChange={region => this.update_user_info_handler('phone_region', region)}
              error={errors.phone_number}
              value={new_phone}
              region={this.state.phone_region.country}
              isMultiRegional
            />
          </div>
          <div className="question-block">
            <div className="small-card-btn" onClick={() => this.submit_btn_handler('write')}>
              Save changes
            </div>
            <div className="small-card-btn" onClick={() => this.edit_btn_handler('write')}>
              Cancel
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { update_user_information, update_patient })(EditProfile);
