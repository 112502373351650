import styled from 'styled-components/macro';

const PaymentInput = styled.input`
  width: 40px;
  outline: none;
  -webkit-appearance: none;
  border: none;
  color: #250044;
  padding: 0;
  &::placeholder {
    color: #250044;
  }
`;

export const CardNumberInput = styled(PaymentInput)`
  flex: 1;
  text-align: left;
`;

export const MonthInput = styled(PaymentInput)`
  width: 30px;
  text-align: right;
`;

export const YearInput = styled(MonthInput)`
  text-align: left;
`;

export const CvcInput = styled(PaymentInput)`
  width: 38px;
  text-align: left;
`;

export const ZipInput = styled(PaymentInput)`
  width: 53px;
  text-align: left;
`;
