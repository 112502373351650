import React, { useState } from 'react';
import { format } from '../../../utils/date-fns';
import { Expansion, PayBtn, SeeMoreBtn, TableBody, TableCell, TableHeader, TableRow, TableWrapper } from './styled';

import iconPDF from '../../../img/icons/ic-pdf.svg';
import iconChevronDown from '../../../img/icons/ic-chevron-down.svg';
import iconEye from '../../../img/icons/ic-eye.svg';
import { Skeleton } from '../../UI/Skeleton/Skeleton';

const LandingView = ({ invoices, onPay, onDownload, invoicesLoading }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <TableWrapper expand={showMore}>
      <TableHeader>
        <TableCell isHeader className="header--date">
          Date
        </TableCell>
        <TableCell isHeader className="header--description">
          Description
        </TableCell>
        <TableCell isHeader className="header--invoice">
          Invoice
        </TableCell>
        <TableCell isHeader className="header--status">
          Status
        </TableCell>
      </TableHeader>
      <TableBody>
        {invoicesLoading &&
          Array(4)
            .fill(0)
            .map((_, index) => (
              <TableRow key={index}>
                <Skeleton />
              </TableRow>
            ))}
        {!invoicesLoading &&
          invoices.map(invoice => (
            <TableRow key={invoice.id} unpaid={invoice.status !== 'paid'}>
              <TableCell className="header--date">{format(invoice.created_at)}</TableCell>
              <TableCell className="header--description">{invoice.title}</TableCell>
              <TableCell className="header--invoice">
                <div className="download-wrapper" onClick={() => onDownload(invoice.id)}>
                  <span>View (PDF)</span>
                  <img src={iconPDF} alt="View PDF" />
                </div>
              </TableCell>
              <TableCell className="header--status">
                {invoice.status === 'paid' ? (
                  invoice.status
                ) : (
                  <>
                    <span>{invoice.status}</span>
                    <PayBtn onClick={() => onPay(invoice)}>Pay</PayBtn>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
      {invoices.length >= 9 && !showMore && (
        <Expansion>
          <SeeMoreBtn onClick={() => setShowMore(!showMore)}>
            <img src={iconEye} alt="eye" />
            <span>See History</span>
            <img src={iconChevronDown} alt="arrow down" />
          </SeeMoreBtn>
        </Expansion>
      )}
    </TableWrapper>
  );
};

export default LandingView;
