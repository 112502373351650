import styled from 'styled-components/macro';

export const NutritionHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;

export const ResultText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19.5px;
  text-align: center;
  letter-spacing: -0.4px;
  margin-top: 6px;
  margin-bottom: 6px;
  @media (max-width: 991px) {
    font-size: 13px;
    line-height: 17px;
  }
`;
