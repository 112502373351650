import axios from 'axios';
import { batch } from 'react-redux';
import { make_headers, get_user_attr } from './user_auth_action';
import tracking from '../utils/tracking';

export const exercise_actions = {
  SET_CATEGORIES: '@EXERCISE/SET_CATEGORIES',
  SET_ASSIGNMENTS: '@EXERCISE/SET_ASSIGNMENTS',
  SET_EXERCISE_HISTORY: '@EXERCISE/SET_EXERCISE_HISTORY',
  SET_ALL_EXERCISES: '@EXERCISE/SET_ALL_EXERCISES',
  SET_ACTIVE_EXERCISE: '@EXERCISE/SET_ACTIVE_EXERCISE',
  SET_BEFORE_RATING: '@EXERCISE/SET_BEFORE_RATING',
  SET_AFTER_RATING: '@EXERCISE/SET_AFTER_RATING',
  SET_STEP_ANSWER: '@EXERCISE/SET_STEP_ANSWER',
  CLEAR_ACTIVE_STEP: '@EXERCISE/CLEAR_ACTIVE_STEP',
  SET_COMPLETED_EXERCISE: '@EXERCISE/SET_COMPLETED_EXERCISE'
};

export const setCategories = categories => ({
  type: exercise_actions.SET_CATEGORIES,
  categories
});

export const setAssignments = assignments => ({
  type: exercise_actions.SET_ASSIGNMENTS,
  assignments
});

export const setExerciseHistory = exercises => ({
  type: exercise_actions.SET_EXERCISE_HISTORY,
  exercises
});

export const setAllExercises = allExercises => ({
  type: exercise_actions.SET_ALL_EXERCISES,
  allExercises
});

export const setActiveExerciseId = exercise_id => ({
  type: exercise_actions.SET_ACTIVE_EXERCISE,
  exercise_id
});

export const setBeforeRating = rating_before => ({
  type: exercise_actions.SET_BEFORE_RATING,
  payload: { rating_before }
});

export const setAfterRating = rating_after => ({
  type: exercise_actions.SET_AFTER_RATING,
  payload: { rating_after }
});

export const clearActiveStep = () => ({
  type: exercise_actions.CLEAR_ACTIVE_STEP
});

export const setCompletedExercise = payload => ({
  type: exercise_actions.SET_COMPLETED_EXERCISE,
  payload
});

export const fetchExerciseHistory = () => async (dispatch, getState) => {
  const user = get_user_attr(getState());
  const headers = make_headers(user);

  try {
    const { data: completed } = await axios.get(`/api/patients/${user.patient.id}/exercise_patients/completed`, {
      headers
    });
    batch(() => {
      dispatch(setExerciseHistory(completed));
    });
  } catch (err) {
    tracking.error({ e: err });
  }
};

export const fetchExerciseData = () => async (dispatch, getState) => {
  const user = get_user_attr(getState());
  const headers = make_headers(user);

  try {
    const { data: categories } = await axios.get('/api/categories', { headers });
    const { data: assignments } = await axios.get(`/api/patients/${user.patient.id}/exercise_patients/assigned`, {
      headers
    });
    const { data: exercises } = await axios.get('/api/exercises', { headers });

    batch(() => {
      dispatch(setCategories(categories));
      dispatch(setAssignments(assignments));
      dispatch(setAllExercises(exercises));
    });
  } catch (err) {
    tracking.error({ e: err });
  }
};

export const setStepAnswer = ({ step_id, answer }) => ({
  type: exercise_actions.SET_STEP_ANSWER,
  payload: { step_id, answer }
});

export const completeExercise = rating_after => async (dispatch, getState) => {
  if (rating_after) dispatch(setAfterRating(rating_after));
  const user = get_user_attr(getState());
  const headers = make_headers(user);
  const activeStepPayload = getState().exercise_reducer.active_exercise;

  try {
    const { data } = await axios.post(
      `/api/patients/${user.patient.id}/exercise_patients/complete`,
      activeStepPayload,
      { headers }
    );
    batch(() => {
      dispatch(setCompletedExercise(data));
      dispatch(fetchExerciseData());
    });
  } catch (err) {
    tracking.error({ e: err });
  }
};

export const sendExerciseAnswers =
  (exercise_id, exercise_patient_id, send = false) =>
  async (dispatch, getState) => {
    const user = get_user_attr(getState());
    const headers = make_headers(user);

    try {
      await axios.post(
        `/api/patients/${user.patient.id}/exercise_patients/${exercise_patient_id}/share_with_doctor`,
        {
          exercise_id,
          patient_id: user.patient.id,
          share_with_doctor: send
        },
        { headers }
      );
      dispatch(clearActiveStep());
    } catch (err) {
      tracking.error({ e: err });
    }
  };

export const dismissExercise = exercise_patient_id => async (dispatch, getState) => {
  const user = get_user_attr(getState());
  const headers = make_headers(user);

  try {
    await axios.delete(`/api/patients/${user.patient.id}/exercise_patients/${exercise_patient_id}/unassign_mobile`, {
      headers
    });
    dispatch(fetchExerciseData());
  } catch (err) {
    tracking.error({ e: err });
  }
};
