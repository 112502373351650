import React from 'react';
import Error from '../Error';
import CustomCheckBox from '../CustomCheckBox';

interface CheckBoxDefaultProps {
  name: string;
  eventHandler: () => void;
  isChecked: boolean;
  error?: string;
  disabled?: boolean;
  children: React.ReactNode;
}

const CheckBoxDefault: React.FC<CheckBoxDefaultProps> = ({
  name = '',
  eventHandler,
  isChecked,
  error,
  children,
  disabled
}) => (
  <div className="d-flex flex-column">
    {error ? <Error error={error} className="red center pad-0 marg-0" /> : null}
    <label className="agree-holder">
      <CustomCheckBox name={name} event_handler={eventHandler} isChecked={isChecked} disabled={!!disabled} />
      <span className="checkbox-text">{children}</span>
    </label>
  </div>
);

export default CheckBoxDefault;
