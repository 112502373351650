import produce from 'immer';
import { SET_CHECKOUT_RESULT, SET_SELECTED_INVOICE, SET_PAID_INVOICE, SET_CHECKOUT_ERROR } from './actions';

export const initialState = {
  selectedInvoice: null,
  checkoutResult: ''
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_SELECTED_INVOICE:
      draft.selectedInvoice = action.payload;
      break;
    case SET_CHECKOUT_RESULT:
      draft.checkoutResult = action.payload;
      break;
    case SET_PAID_INVOICE: {
      draft.selectedInvoice = null;
      draft.checkoutResult = action.payload.status;
      break;
    }
    case SET_CHECKOUT_ERROR:
      draft.checkoutResult = 'failed';
      break;
    default:
  }
}, initialState);
