import React from 'react';
import { Modal, BlueHeader, WhiteIsotype, WhiteTitle } from 'components/UI/Modal';
import { ErrorImage, Error, SubmitButton, ReSubmitButton, Message, ErrorContent } from './styled';

export interface Props {
  show: boolean;
  error?: string[];
  onPrimaryClick: () => void;
  onSecondaryClick: () => void;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  title?: string;
  primaryButtonTextAbTest?: string;
  secondaryButtonTextAbTest?: string;
  titleAbTest?: string;
  childrenAbTest?: string;
  systemError?: string;
  isErrorModalAbtest?: boolean;
}

const ErrorModal: React.FC<Props> = ({
  show,
  error,
  onPrimaryClick,
  onSecondaryClick,
  primaryButtonText = 'Continue without insurance',
  secondaryButtonText = 'Resubmit Information',
  title = 'Failed to retrieve eligibility information.',
  children,
  primaryButtonTextAbTest = 'Try again',
  secondaryButtonTextAbTest = 'I’ll add it later',
  titleAbTest = 'Oops! We’re having issues processing your information.',
  childrenAbTest = 'Please try to re-submit your insurance information.',
  isErrorModalAbtest
}) => (
  <Modal show={show} onHide={() => {}}>
    <BlueHeader>
      <WhiteIsotype />
      <WhiteTitle>{isErrorModalAbtest ? titleAbTest : title}</WhiteTitle>
    </BlueHeader>
    <ErrorContent>
      <ErrorImage />
      {error && (
        <Error>
          {error.map(e => (
            <p key={e}>{e}</p>
          ))}
        </Error>
      )}
      <Message>{isErrorModalAbtest ? childrenAbTest : children}</Message>
      <SubmitButton
        data-testid="save-and-continue-error-modal"
        onClick={isErrorModalAbtest ? onSecondaryClick : onPrimaryClick}
      >
        {isErrorModalAbtest ? primaryButtonTextAbTest : primaryButtonText}
      </SubmitButton>
      <ReSubmitButton onClick={isErrorModalAbtest ? onPrimaryClick : onSecondaryClick}>
        {isErrorModalAbtest ? secondaryButtonTextAbTest : secondaryButtonText}
      </ReSubmitButton>
    </ErrorContent>
  </Modal>
);

export default ErrorModal;
