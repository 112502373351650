import React, { useState, useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { AppointmentModal } from '../OutstandingTasksModal/AppointmentModal';
import { get_abtest, get_patient_tasks, update_patient_task } from '../../actions/patient_action';
import { ProviderCallWrapper } from './styled';
import usePromise from '../../utils/usePromise';
import { useApi } from '../../utils/api';
import { PopupWarningModal } from '../OutstandingTasksModal/PopupWarningModal';

const OutstandingTasks = lazy(() => import('./OutstandingTasks'));
const AppointmentsList = lazy(() => import('./AppointmentsList'));
const ScheduleVisit = lazy(() => import('../ScheduleVisit'));
const BackButton = lazy(() => import('../BackButton'));
const ILVStart = lazy(() => import('../ManageInitialProviderTask/ILVStart'));

export const getTypeFromView = view =>
  ({
    'reschedule-cm': 'care_manager',
    'reschedule-th': 'therapist',
    'reschedule-nt': 'nutritionist'
  }[view] || 'provider');

export const getViewFromType = type =>
  ({
    care_manager: 'reschedule-cm',
    therapist: 'reschedule-th',
    nutritionist: 'reschedule-nt'
  }[type] || 'reschedule');

export const getTaskFromView = view =>
  ({
    'schedule-cm': 'schedule-video-cm',
    'schedule-initial-cm': 'schedule-initial-cm'
  }[view] || 'schedule-video-dr');

const findTaskWithView = (tasks, view) => tasks.find(({ task_key }) => task_key === getTaskFromView(view));

const isScheduleView = view => ['schedule-dr', 'schedule-cm', 'schedule-initial-cm'].includes(view);

const scrollToBottom = () => {
  setTimeout(() => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  }, 0);
};

const eligibleStatusMapping = {
  IN_NETWORK: 'in_network'
};

const AppointmentsView = ({ handleLogo, isNotSupportInsurancePayment = false, showBanner }) => {
  const outstandingTasks = useSelector(state => state.patient_reducer.outstanding_tasks);
  const offering = useSelector(state => state.patient_reducer?.offering_key);
  const ilvFeatureFlag = useSelector(state => state?.patient_reducer?.abTest?.ilv_available_ab_test ?? 'no_value');
  const abTestEligibility_check = useSelector(state => state?.patient_reducer?.abTest);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [openPopup, setPopup] = useState(false);
  const history = useHistory();
  const { selectedAppointment, view, is_open_modal, modalText, isScrollToBottom = false } = state || {};
  const [currentAppointment, setCurrentAppointment] = useState(selectedAppointment);
  const [currentView, setCurrentView] = useState(view || 'appointments');
  const [modalStatus, setModalStatus] = useState(false);
  const [detailMissedAndCancelAppointment, setDetailMissedAndCancelAppointment] = useState(null);
  const api = useApi();

  const isPatientPlanWasMedMOrMedTherapy = ['medication-therapy', 'medication'].includes(offering);

  const [ilvResponse] = usePromise('/api/visit_requests');
  const isShowILV = ilvResponse?.data?.ilv_available ?? false;
  const isPatientHasMissedOrCanceledSchedule = outstandingTasks.some(task => task.task_key === 'schedule-initial-dr');

  useEffect(() => {
    if (isPatientHasMissedOrCanceledSchedule) {
      api.get('api/calendar/missed_or_canceled_by_patient_last_appointment').then(({ data }) => {
        setDetailMissedAndCancelAppointment(data ?? null);
      });
    }
  }, [isPatientHasMissedOrCanceledSchedule]);

  useEffect(() => {
    if (isScrollToBottom) {
      scrollToBottom();
    }
  }, [isScrollToBottom]);

  useEffect(() => {
    handleLogo(true);
    if (is_open_modal) setModalStatus(modalText);
    dispatch(get_patient_tasks());
    return () => handleLogo(true);
  }, []);

  const selectAppointment = newAppointment => {
    setCurrentAppointment(newAppointment);
    setCurrentView(getViewFromType(newAppointment.appointment_type));
    handleLogo(false);
  };

  const setView = newView => {
    setCurrentView(newView);
    if (isScheduleView(newView)) handleLogo(false);
  };

  const goBack = () => {
    setCurrentView('appointments');
    setCurrentAppointment(null);
    handleLogo(true);
  };

  const onAppointmentRescheduled = newView => () => {
    if (isScheduleView(newView)) {
      const task = findTaskWithView(outstandingTasks, newView);
      dispatch(update_patient_task(task));
    }
    goBack();
  };
  const [routeConfig, setRouteConfig] = useState('');
  const openModal = newRoute => {
    setPopup(true);
    setRouteConfig(newRoute);
  };
  const openInsuranceUpdate = () => {
    history.push('/patient/dashboard/update_insurance_cards');
  };

  const [isEligible, setEligible] = useState(false);
  const insuranceVisitsPolicy = useSelector(
    ({ patient_reducer: { insurance_visits_policy } }) => insurance_visits_policy
  );

  useEffect(() => {
    dispatch(get_abtest({ id: 'perform_eligibility_check_upon_appointment_creation' }));
    const isEligibleCheck = eligibleStatus => eligibleStatus === eligibleStatusMapping.IN_NETWORK;
    setEligible(isEligibleCheck(insuranceVisitsPolicy?.status));
  }, []);

  return (
    <div className="d-flex flex-column profile-main-content">
      {currentView === 'appointments' ? (
        <>
          {ilvFeatureFlag === 'ilv_available' && isShowILV && isPatientPlanWasMedMOrMedTherapy && (
            <ProviderCallWrapper direction="column">
              <ILVStart isHiddenSchedule textButtonMeetProvider="Meet a prescriber now" history={history} />
            </ProviderCallWrapper>
          )}
          <OutstandingTasks
            onClose={setView}
            outstanding_tasks={outstandingTasks}
            selectAppointment={selectAppointment}
            isShowILV={isShowILV}
            ilvFeatureFlag={ilvFeatureFlag}
            isPatientPlanWasMedMOrMedTherapy={isPatientPlanWasMedMOrMedTherapy}
            detailMissedAndCancelAppointment={detailMissedAndCancelAppointment}
            openModal={openModal}
            abTestEligibility_check={abTestEligibility_check}
            isEligible={isEligible}
            isNotSupportInsurancePayment={isNotSupportInsurancePayment}
          />
          <AppointmentsList onSelect={selectAppointment} />
          {modalStatus ? <AppointmentModal onRequestClose={() => setModalStatus(false)} text={modalStatus} /> : null}
          {openPopup && (
            <PopupWarningModal routePath={routeConfig} onClose={() => setPopup(false)} onUpdate={openInsuranceUpdate} />
          )}
        </>
      ) : (
        <>
          <BackButton showBanner={showBanner} onClick={goBack}>
            &larr; Appointments & Tasks
          </BackButton>
          <ScheduleVisit
            dashboard
            view={currentView}
            type={getTypeFromView(currentView)}
            selectedAppointment={currentAppointment}
            onAppointmentScheduledCallback={onAppointmentRescheduled(currentView)}
            reschedulingSuperAdhoc={currentAppointment?.is_super_adhoc ?? false}
            duration={currentAppointment?.duration}
            reschedulingAdhoc={currentAppointment?.adhoc ?? false}
          />
        </>
      )}
    </div>
  );
};

AppointmentsView.propTypes = {
  handleLogo: PropTypes.func.isRequired
};

export default AppointmentsView;
