import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Profile from '../../Profile';
import CareCoordinatorProfile from '../CareCoordinatorProfile/CareCoordinatorProfile';
import Messages from '../Messages';
import { Button } from '../../EndOfFlowScreens/Box';
import ResizableTextarea from '../../ResizableTextarea';
import { ContinueButton } from '../styled';
import { mapRoleToType, hasCareCoordinator } from '../../../utils/care-person-helpers';
import { careCoordinatorBio, carePersonBio } from '../bios';
import { HelpCenterSection, LeftMenuWrapper } from './styled';
import { goToHelpCenter } from '../../Message/Message';
import strings from '../../../localization';

const YourCareTeamTitle = styled.p`
  width: 238px;
  margin: 1em 56px;
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #250044;
`;

export const carePersionProfile = (bio, isCareManagerAvailable, providerMessenger) => {
  return (
    <Profile
      classHolder="d-flex flex-column align-items-center chat_body_profile"
      carePerson={bio}
      isOnline={isCareManagerAvailable}
      providerMessenger={providerMessenger}
    />
  );
};

const DesktopMessenger = ({
  userIsTyping,
  withHeader,
  next,
  carePerson,
  nutritionist,
  careCoordinator,
  isCareManagerAvailable,
  messages,
  setRef,
  sendMessage,
  scrollDown,
  setName,
  connectToSocket,
  providerMessenger,
  activeMessenger
}) => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (messages[activeMessenger] && messages[activeMessenger].length > 0) {
      return scrollDown({ block: 'end' });
    }
  }, [messages[activeMessenger]]);

  useEffect(() => {
    connectToSocket();
  }, [providerMessenger]);
  const type = mapRoleToType(carePerson);
  const nutritionistType = mapRoleToType(nutritionist);

  const hasCoordinator = hasCareCoordinator(careCoordinator);
  let careCoordinatorWithDefaultBio;
  if (hasCoordinator) careCoordinatorWithDefaultBio = { ...careCoordinator, bio: careCoordinatorBio(type) };

  let carePersonWithDefaultBio;
  let nutritionistWithDefaultBio;
  if (carePerson) carePersonWithDefaultBio = { ...carePerson, bio: carePersonBio(type) };
  if (nutritionist) nutritionistWithDefaultBio = { ...nutritionist, bio: carePersonBio(nutritionistType) };

  const { name } = carePerson;
  const messageRecipientName = hasCoordinator && !providerMessenger ? 'your care team' : name;

  return (
    <Fragment>
      {withHeader && (
        <div className="justify-content-start chat_header">
          <div className="d-flex chat_header_logo_holder">
            <img className="cerebral-logo" src={`${process.env.PUBLIC_URL}/img/logo.svg`} alt="cerebral logo" />
          </div>
          <ContinueButton onClick={next}>
            Continue<span>&#8594;</span>
          </ContinueButton>
        </div>
      )}
      <div className={`d-flex chat_body${!withHeader ? ' no-header' : ''}`}>
        <LeftMenuWrapper direction="column" justifyContent="flex-start">
          {!providerMessenger && hasCoordinator && <YourCareTeamTitle>Your Emotional Support Team</YourCareTeamTitle>}
          {carePerson.id && carePersionProfile(carePersonWithDefaultBio, isCareManagerAvailable, providerMessenger)}
          {nutritionist.id && carePersionProfile(nutritionistWithDefaultBio, isCareManagerAvailable, providerMessenger)}
          {!providerMessenger && hasCoordinator && (
            <CareCoordinatorProfile careCoordinator={careCoordinatorWithDefaultBio} carePersonType={type} />
          )}
          <HelpCenterSection direction="column" justifyContent="flex-end">
            <div>
              <p>Don&apos;t want to wait for a response from someone?</p>
              <Button onClick={goToHelpCenter}>Go to the Help Center</Button>
            </div>
          </HelpCenterSection>
        </LeftMenuWrapper>
        <Messages setName={setName} setRef={setRef} next={next} scrollDown={scrollDown} />
      </div>
      <div className="d-flex chat_footer">
        <div>
          <div className="chat_footer_sidebar">
            <span>
              If this is an emergency, please call <b>{strings.nationalEmergencyNumber}</b> or call the{' '}
              {strings.nationalSuicideHotlineName} <b>{strings.nationalSuicideHotlineNumber}</b>.
            </span>
          </div>
        </div>
        <ResizableTextarea rows={2} userTyping={userIsTyping} submitMessage={sendMessage} name={messageRecipientName} />
      </div>
    </Fragment>
  );
};

DesktopMessenger.propTypes = {
  withHeader: PropTypes.bool.isRequired,
  carePerson: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }).isRequired,
  messages: PropTypes.object.isRequired
};

export default DesktopMessenger;
