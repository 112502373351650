import React from 'react';
import styled from 'styled-components/macro';
import Modal from '../../UI/RawModal';
import { formatCurrency } from '../../../utils/common';
import Button from '../../UI/RegularButton';
import imgCredit from '../../../img/checkout/img-credit.svg';

const Wrapper = styled.div`
  position: relative;
  width: 325px;
  height: 450px;
  background-color: #dafff1;
  padding: 10px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    background-image: url('${process.env.PUBLIC_URL}/img/img-confirm-payment.svg');
    height: 195px;
    width: 260px;
    bottom: 0;
    right: -55px;
  }
`;

const CardTitle = styled.h3`
  font-size: 16px;
  line-height: 20px;
  font-style: italic;
  font-weight: bold;
  color: #250044;
  text-align: center;
  letter-spacing: 0.1px;
  margin-bottom: 24px;
`;

const CardDescription = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #250044;
  max-width: 210px;
  margin-bottom: 30px;
`;

const DueAmountText = styled.h4`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.43px;
  color: #250044;
  margin-bottom: 24px;

  span {
    color: #6786ff;
  }
`;

const ConfirmBtn = styled(Button)`
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
`;

const ConfirmPaymentModal = ({ data, onPay, isOpen, onClose }) => {
  const handleConfirm = () => {
    onPay();
  };

  if (!data) return null;

  const amount = parseFloat(data.total);

  return (
    <Modal isOpen={isOpen} onClose={onClose} onHide={() => {}}>
      <Wrapper>
        <img src={imgCredit} alt="credit card" />
        <CardTitle>Confirm Payment</CardTitle>
        <CardDescription>
          Your card on file will be charged the the full outstanding amount as stipulated on this invoice:
        </CardDescription>
        <DueAmountText>
          Total Due: <span>{formatCurrency(amount)}</span>
        </DueAmountText>
        <ConfirmBtn rounded color="primary" onClick={handleConfirm}>
          Confirm
        </ConfirmBtn>
        <ConfirmBtn onClick={() => onClose()}>Cancel</ConfirmBtn>
      </Wrapper>
    </Modal>
  );
};

export default ConfirmPaymentModal;
