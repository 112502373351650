import styled from 'styled-components/macro';
import { Flexbox } from '@cerebral-inc/ui';

export const ProviderCallWrapper = styled(Flexbox)`
  background-color: #e7ebfe;
  padding-bottom: 30px;

  @media (min-width: 993px) {
    margin-bottom: 30px;
  }

  > div > div {
    margin-bottom: 30px;
    > h1 {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
`;
