import React from 'react';
import { Container, Text, ContinueButton } from './styled';

const CompleteTheFlow = props => {
  const goToQuestions = () => props.go_to('/patient/question_bank');

  return (
    <Container>
      <Text>It seems like your intake is not complete yet. Please click here to continue where you left off.</Text>
      <ContinueButton value="Continue" onClick={goToQuestions} />
    </Container>
  );
};

export default CompleteTheFlow;
