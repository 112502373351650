import styled from 'styled-components/macro';

// eslint-disable-next-line import/prefer-default-export
export const InputWrapper = styled.div.attrs(() => ({ className: 'input-holder' }))`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const InputInnerWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ToggleBtn = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  outline: none;
  border: none;
  background: transparent;
  margin-right: 8px;

  &:focus {
    outline: none;
  }
`;
