import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as InfoImage } from './assets/information.svg';

export const PhoneSupportWrapper = styled.div`
  background: #fbfbfb;
  overflow: hidden;
  border-radius: 4px;
  color: #6786ff;
  padding: 15px 40px;
  text-align: center;
  width: 300px;
  max-width: 300px;
  margin: 50px auto;
`;
export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: -0.43px;
  margin: 10px 0px;
`;
export const PrimaryInfo = styled.div`
  font-size: 10px;
  line-height: 12px;
  margin: 10px 0px;
`;
export const SecondaryInfo = styled.div`
  font-size: 10px;
  line-height: 12px;
  margin: 10px 0px;
`;
export const Info = styled(InfoImage)`
  display: block;
  margin: auto;
  width: 20px;
  height: 20px;
`;

interface PhoneSupportProps {
  isNutritionServiceLine?: boolean;
}

const PhoneSupport: React.FC<PhoneSupportProps> = ({ isNutritionServiceLine = false }) => {
  let phoneSupportText = {
    title: 'Do you need help?',
    phoneNumber: 'Call (415) 403-2156',
    mailSupport: 'support@getcerebral.com'
  };

  if (isNutritionServiceLine) {
    phoneSupportText = {
      ...phoneSupportText,
      title: 'Need more assistance?',
      phoneNumber: '(415) 403 - 2156'
    };
  }
  return (
    <PhoneSupportWrapper>
      <Info />
      <Title>{phoneSupportText.title}</Title>
      <PrimaryInfo>{phoneSupportText.phoneNumber}</PrimaryInfo>
      <SecondaryInfo>{phoneSupportText.mailSupport}</SecondaryInfo>
    </PhoneSupportWrapper>
  );
};
export default PhoneSupport;
