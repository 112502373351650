import { getVariantValue } from '../utils';

const checkPriceSensitivity = () => {
  let valueKameleoon: string | undefined = 'Reference';

  const experimentName = 'A/B Test Price Sensitivity Base Price Plan 85 vs 99 vs 109 vs 119';

  valueKameleoon = getVariantValue(experimentName);
  return ['Excluded', 'Disabled'].includes(valueKameleoon) ? 'Reference' : valueKameleoon;
};

export { checkPriceSensitivity };
