import React from 'react';
import { useSelector } from 'react-redux';
import strings from 'localization';
import { Store } from 'types/store';
import { OfferingKey } from 'utils/plans';
import { GreenBanner, GreenBannerText } from '../styled';
import { testPriceSensitivity } from '../../../../utils/kameleoon';

export const mapPlanToCoupon = (plan: string, abtest: string | undefined): string => {
  if (abtest && abtest !== 'Reference') {
    switch (abtest) {
      case 'Group 2':
        return (
          {
            medication: 'CEREBRAL15',
            therapy: 'CEREBRAL65',
            'medication-therapy': 'CEREBRAL79',
            nicotine: strings.defaultNicotineCode,
            bupropion: strings.defaultBupropionCode,
            'bupropion-nicotine': strings.defaultBupropionNicotineCode,
            'nutrition-management': strings.defaultNutritionCode,
            'nutrition-medication-management': strings.defaultNutritionMedicationCode,
            'nutrition-medication-management-glp1': strings.defaultNutritionMedicationGlp1Code
          }[plan] || strings.defaultPromoCode
        );
      case 'Group 3':
        return (
          {
            medication: 'CEREB49',
            therapy: 'CEREB155',
            'medication-therapy': 'CEREB195',
            nicotine: strings.defaultNicotineCode,
            bupropion: strings.defaultBupropionCode,
            'bupropion-nicotine': strings.defaultBupropionNicotineCode,
            'nutrition-management': strings.defaultNutritionCode,
            'nutrition-medication-management': strings.defaultNutritionMedicationCode,
            'nutrition-medication-management-glp1': strings.defaultNutritionMedicationGlp1Code
          }[plan] || strings.defaultPromoCode
        );
      case 'Group 4':
        return (
          {
            medication: 'CERE65',
            therapy: 'CERE195',
            'medication-therapy': 'CERE245',
            nicotine: strings.defaultNicotineCode,
            bupropion: strings.defaultBupropionCode,
            'bupropion-nicotine': strings.defaultBupropionNicotineCode,
            'nutrition-management': strings.defaultNutritionCode,
            'nutrition-medication-management': strings.defaultNutritionMedicationCode,
            'nutrition-medication-management-glp1': strings.defaultNutritionMedicationGlp1Code
          }[plan] || strings.defaultPromoCode
        );
      case 'Group 5':
        return (
          {
            medication: '',
            therapy: '',
            'medication-therapy': '',
            nicotine: strings.defaultNicotineCode,
            bupropion: strings.defaultBupropionCode,
            'bupropion-nicotine': strings.defaultBupropionNicotineCode,
            'nutrition-management': strings.defaultNutritionCode,
            'nutrition-medication-management': strings.defaultNutritionMedicationCode,
            'nutrition-medication-management-glp1': strings.defaultNutritionMedicationGlp1Code
          }[plan] || ''
        );
      default:
        break;
    }
  }
  return (
    {
      therapy: strings.defaultTherapyCode,
      'medication-therapy': strings.defaultMedsTherapyCode,
      nicotine: strings.defaultNicotineCode,
      bupropion: strings.defaultBupropionCode,
      'bupropion-nicotine': strings.defaultBupropionNicotineCode,
      'nutrition-management': strings.defaultNutritionCode,
      'nutrition-medication-management': strings.defaultNutritionMedicationCode,
      'nutrition-medication-management-glp1': strings.defaultNutritionMedicationGlp1Code
    }[plan] || strings.defaultPromoCode
  );
};

interface PaymentTopTextProps {
  couponId: string;
  isInsurance: boolean;
}

const PaymentTopText: React.FC<PaymentTopTextProps> = ({ couponId, isInsurance }) => {
  const planId = useSelector<Store, OfferingKey>(({ patient_reducer: { plan } }) => plan.offering_key || 'medication');
  const value = testPriceSensitivity();
  const promo = couponId || (isInsurance ? '50OFF' : mapPlanToCoupon(planId, value));

  const checkPromoEmpty = () => {
    return promo !== undefined && promo !== null && promo !== '';
  };

  return checkPromoEmpty() ? (
    <GreenBanner>
      <GreenBannerText>{`Your promo “${promo}” has been applied to your subscription`}</GreenBannerText>
    </GreenBanner>
  ) : (
    <></>
  );
};

export default PaymentTopText;
