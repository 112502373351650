import React, { PropsWithChildren } from 'react';
import styled, { keyframes } from 'styled-components/macro';

export const pulseAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  :before {
    content: '\\00a0';
  }
  background-color: rgba(0, 0, 0, 0.11);
  height: auto;
  width: 100%;
  animation: ${pulseAnimation} 1.5s ease-in-out 0.5s infinite;
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SkeletonProps {
  className?: string;
}

export const Skeleton: React.FC = ({ className = '', children }: PropsWithChildren<SkeletonProps>) => (
  <Container className={className}>{children}</Container>
);
