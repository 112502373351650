import { prescriberVariant } from 'utils/care-person-helpers';

export const careCoordinatorBio = carePersonType => {
  return `Your coordinator is here to answer questions about our services, billing, and scheduling. They'll also communicate your needs to your ${
    prescriberVariant.singularNormal
  } and ${carePersonType.toLowerCase()}.`;
};

export const carePersonBio = carePersonType => {
  if (carePersonType === 'Care Coordinator') {
    return `Your coordinator is here to answer questions about our services, billing, scheduling and provide a direct line to your ${prescriberVariant.singularNormal}.`;
  }
  if (carePersonType === 'Care Counselor') {
    return 'Your counselor works with you to change behaviors or patterns of thought that can be interfering with your mental health, and teaches you techniques designed to help you feel better and stay well.';
  }
  if (carePersonType === 'Doctor') {
    return '';
  }
  if (carePersonType === 'Nutritionist') {
    return 'Your Nutritionist works with you on developing strategies and techniques to help you manage your wellbeing on the day-to-day.';
  }
  return "Your therapist works with you closely every week to change behaviors or thought patterns that interfere with your mental health. They'll teach you techniques designed to help you feel better and stay well.";
};
