const throttle = function (callback, delay) {
  let wait = false;
  return function (event) {
    if (!wait) {
      callback(event);
      wait = true;
      setTimeout(() => {
        wait = false;
      }, delay);
    }
  };
};

export default throttle;
