import React, { useContext, createContext, useState } from 'react';
import { noop } from './default-values';
import tracking from './tracking';

interface ContextProps {
  loaded: boolean;
  error: boolean;
  injectScript(): void;
}

const AcceptScriptContext = createContext<ContextProps>({
  loaded: false,
  error: false,
  injectScript: noop
});

const AcceptScriptProvider: React.FC = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const injectScript = () => {
    const checkForScript = setInterval(() => {
      if (!window || !document || !process.env.REACT_APP_ACCEPTJS_URL) {
        setError(true);
        tracking.error({
          message:
            'Error loading Accept.js: REACT_APP_ACCEPTJS_URL undefined or problem identifying the window or document.'
        });
        clearInterval(checkForScript);
        return;
      }

      const loadedScripts = Array.from(document.scripts).map(script => script.src);

      if (!loadedScripts.includes(process.env.REACT_APP_ACCEPTJS_URL)) {
        const tag = document.createElement('script');
        tag.src = process.env.REACT_APP_ACCEPTJS_URL;
        tag.type = 'text/javascript';
        document.body.appendChild(tag);
      }

      if (window.Accept) {
        setLoaded(true);
        clearInterval(checkForScript);
      }
    }, 500);

    setTimeout(() => {
      if (loaded || error) return;

      clearInterval(checkForScript);
      setError(true);
      tracking.error({
        message: 'Error loading Accept.js'
      });
    }, 10000);
  };

  const value = {
    loaded,
    error,
    injectScript
  };
  return <AcceptScriptContext.Provider value={value}>{children}</AcceptScriptContext.Provider>;
};

const withAcceptScript = (Component: React.ComponentType<{ acceptScript: ContextProps }>): React.FC => {
  return props => {
    const acceptScript = useContext(AcceptScriptContext);
    return (
      <Component {...props} acceptScript={acceptScript}>
        {props.children}
      </Component>
    );
  };
};

const useAcceptScript = (): ContextProps => useContext(AcceptScriptContext);

export { AcceptScriptProvider, useAcceptScript, withAcceptScript };
