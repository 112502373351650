import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Spinner, InnerSpinner, OkSign, PaymentModalBody, Processing, Redirecting, Description } from './styled';

const PaymentProgressModal = ({ open, loading = false, textOnLoading, textOnLoaded, descriptionOnLoaded = '' }) => (
  <Modal className="loading-modal" show={open} onHide={() => {}}>
    <Modal.Body className="loading-modal-body">
      <PaymentModalBody>
        <Spinner loading={loading.toString()}>
          <InnerSpinner>{!loading ? <OkSign /> : null}</InnerSpinner>
        </Spinner>
        <Processing>{loading ? textOnLoading : textOnLoaded}</Processing>
        {!loading && descriptionOnLoaded && <Description>{descriptionOnLoaded}</Description>}
        <Redirecting>{loading ? '' : 'Redirecting...'}</Redirecting>
      </PaymentModalBody>
    </Modal.Body>
  </Modal>
);

PaymentProgressModal.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  textOnLoading: PropTypes.string,
  textOnLoaded: PropTypes.string,
  descriptionOnLoaded: PropTypes.string
};

export default PaymentProgressModal;
