import { useSelector } from 'react-redux';
import React from 'react';
import reducer, { initialState } from './reducer';

export function useStateDefault() {
  const prevInsurance = useSelector(({ patient_reducer: { insurance_visits_policy } }) => insurance_visits_policy);
  const prevUser = useSelector(
    ({
      global_reducer: {
        current_user: { attributes }
      }
    }) => attributes
  );

  const prefillReducers = defaultState => ({
    ...defaultState,
    insurance: {
      ...prevInsurance,
      payer_id: prevInsurance?.payer?.id
    },
    user: {
      first_name: prevUser?.first_name,
      last_name: prevUser?.last_name,
      region: prevUser?.patient.region,
      dob: prevUser?.patient.dob
    }
  });
  const [stateDefault] = React.useReducer(reducer, initialState, prefillReducers);

  return stateDefault;
}
