const fireGtagConversion = type => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }
  let gtmKey = '';
  const gtm = window.dataLayer;
  switch (type) {
    case 'create_account':
      gtmKey = 'Create Account';
      break;
    case 'initiate_checkout':
      gtmKey = 'Initiate Checkout';
      break;
    case 'subscription-signup-medication-therapy':
      gtmKey = 'Meds + Therapy Subscription';
      break;
    case 'subscription-signup-medication':
      gtmKey = 'Subscription Signup';
      break;
    case 'subscription-signup-therapy':
      gtmKey = 'Therapy Subscription';
      break;
    case 'complete':
      gtmKey = 'Complete Assessment';
      break;
    case 'upsell-from-medication-&-care-counseling':
      gtmKey = 'Upsell Counseling to Meds + Therapy';
      break;
    case 'upsell-from-therapy':
      gtmKey = 'Upsell Therapy to Meds + Therapy';
      break;
    default:
      gtmKey = '';
      break;
  }

  if (gtmKey && process.env.REACT_APP_GTM_KEY) {
    gtm.push({ event: gtmKey });
  }
};

export default fireGtagConversion;
