import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import cls from 'classnames';
import { upperFirst, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { get_abtest } from 'actions/patient_action';
import type { Store } from 'types/store';
import { isNotSupportInsurancePayment as checkInsurancePayment } from 'utils/plans';
import { mapNameToType } from 'utils/common';
import Spinner, { SpinnerHolder } from '../Spinner';
import CategoryCard from '../exercises/CategoryCard';
import { getCBTExerciseTitle, isAddictionServiceLine } from '../../utils/common';
import { useApi } from '../../utils/api';
import { useStateDefault } from '../AppointmentsView/store/hook';
import { noop } from '../../utils/default-values';
import { ReactComponent as CerebralIcon } from './assert/CerebralIcon.svg';
import {
  ReassignMessageWrapper,
  TitleReassign,
  LinkChangeClinical,
  BoxChangeClinical,
  TitleBoxChange,
  ContentBoxChange,
  ButtonReassign
} from './styled';
import { ListDisabled } from './Message';

const baseUrl = process.env.REACT_APP_API_SERVER_URL;

export const CustomLink = styled(Link)`
  font-size: 14px;
  padding: 8px 15px;
  background: #f9fafd;
  border: 2px solid #250044;
  box-shadow: 0 0 5px #3b36e1;
  border-radius: 30px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.314286px;
  color: #250044;
  &:hover {
    text-decoration: none;
    color: #250044;
  }

  @media (max-width: 991px) {
    font-size: 10px;
    padding: 8px 10px;
  }
`;

// http+schedule/therapist+45/super?id=123
const splitLink = (message: string) => {
  const words = message.split('+').map(w => w.trim());
  const [duration, rest] = words[words.length - 1].split('/');

  let isSuper = false;
  let id = null;

  if (rest && rest.includes('super?')) {
    isSuper = true;
    [, id] = rest.split('super?');
  }

  return [duration || '30', isSuper, id];
};

interface AdhocData {
  id: string;
  title: string;
  image_url: string;
}

export interface ButtonLinkProps {
  link: string;
  classNames: string;
  listDisabledReassign: ListDisabled;
  onNext: () => void;
  openModal: (route: string) => void;
}

const ButtonLink: React.FC<ButtonLinkProps> = ({
  link,
  classNames,
  listDisabledReassign,
  onNext,
  openModal = noop
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const abTestEligibility_check = useSelector(
    (state: Store) => state.patient_reducer.abTest.perform_eligibility_check_upon_appointment_creation
  );
  const visitValues = useSelector(({ patient_reducer: { visits } }: Store) => visits);
  const currentPlan = useSelector(({ patient_reducer: { plan } }: Store) => plan);
  const isNotSupportInsurancePayment =
    (currentPlan?.offering_key ? checkInsurancePayment(currentPlan.offering_key) : false) ||
    isAddictionServiceLine(visitValues);

  const [duration, isSuper, id] = splitLink(link);
  const cbtAdTitle = getCBTExerciseTitle(link);
  const [adhocData, setAdhocData] = useState<null | number | AdhocData>(null);
  const [isEligible, setEligible] = useState(false);

  const api = useApi();
  const { user, insurance } = useStateDefault();

  useEffect(() => {
    dispatch(get_abtest({ id: 'perform_eligibility_check_upon_appointment_creation' }));

    api
      .post('/api/insurance_policies', {
        ...{ ...user, ...insurance },
        active: true,
        out_of_network: !currentPlan?.is_insurance
      })
      .then(({ data }) => {
        setEligible(Boolean(data.eligible));
      });
  }, []);

  useEffect(() => {
    if (link.includes('exercise/') && cbtAdTitle) {
      api
        .get(`/api/exercises/search?title=${cbtAdTitle}`)
        .then(res => {
          setAdhocData(res.data);
        })
        .catch(() => {
          setAdhocData(-1);
        });
    }
  }, []);

  let path = '';

  const linkWithPopupFunc = (pathLink: string, content: string) => (
    <CustomLink onClick={() => openModal(pathLink)} to="#">
      {content}
    </CustomLink>
  );
  const linkDefaultFunc = (pathLink: string, content: string) => (
    <CustomLink as={Link} to={encodeURI(pathLink)}>
      {content}
    </CustomLink>
  );
  const linkByFeatureFlagCreator = (flag: string, eligibleValue: boolean) => (pathLink: string, content: string) => {
    if (pathLink.includes('schedule')) {
      if (flag === 'enable_eligibility_check') {
        return eligibleValue || isNotSupportInsurancePayment
          ? linkDefaultFunc(pathLink, content)
          : linkWithPopupFunc(pathLink, content);
      }
    }
    return linkDefaultFunc(pathLink, content);
  };

  const linkByFeatureFlagFunc = linkByFeatureFlagCreator(abTestEligibility_check, isEligible);

  const messContent = {
    Schedule_prescriber: 'Schedule a video visit with your prescriber',
    Schedule_Counselor: 'Schedule a session with your Care Counselor',
    Schedule_Therapist: 'Schedule a session with your Therapist',
    ReAssign_Prescriber: 'Choose new Prescriber',
    ReAssign_Counselor: 'Choose new Care Counselor',
    ReAssign_Therapist: 'Choose new Therapist',
    Schedule_Nutritionist: 'Schedule a session with your Nutritionist',
    Complete: ' Complete your self-assessment',
    Therapy_plan: 'Therapy plan',
    Medication_Therapy_plan: 'Medication + Therapy plan',
    Medication_plan: 'Medication plan'
  };
  const listReAssign = ['reassign/prescriber', 'reassign/care_manager', 'reassign/therapist'];
  switch (true) {
    case listReAssign.some(type => link.includes(type)): {
      const type = listReAssign.find(typeCarePerson => link.includes(typeCarePerson))?.replace('reassign/', '');
      const nameMapped = mapNameToType(type);
      return (
        <ReassignMessageWrapper direction="column" alignItems="flex-start">
          <TitleReassign>
            Unfortunately, as some special reasons, your Cerebral {upperFirst(nameMapped)} was not available anymore!
            Please change your {nameMapped} & reschedule your appointment at this link:
            <LinkChangeClinical
              onClick={() => history.push(`/patient/reassign/${type === 'prescriber' ? 'provider' : type}`)}
              disabled={!(type && get(listDisabledReassign, type === 'prescriber' ? 'provider' : type, false))}
            >
              {process.env.REACT_APP_URL}/patient/reassign/{type}
            </LinkChangeClinical>
          </TitleReassign>
          <BoxChangeClinical>
            <CerebralIcon height="35" />
            <TitleBoxChange>Change {upperFirst(nameMapped)}</TitleBoxChange>
            <ContentBoxChange>
              We will help you to change your {nameMapped} when an unexpected case happen to you and/or your{' '}
              {nameMapped}. We are sorry about that.
            </ContentBoxChange>
            <ButtonReassign
              onClick={() => history.push(`/patient/reassign/${type === 'prescriber' ? 'provider' : type}`)}
              disabled={!(type && get(listDisabledReassign, type === 'prescriber' ? 'provider' : type, false))}
            >
              Choose new {upperFirst(nameMapped)}
            </ButtonReassign>
          </BoxChangeClinical>
        </ReassignMessageWrapper>
      );
    }
    case link.includes('offers'):
      return (
        <CustomLink type="button" onClick={onNext} to="">
          Continue to subscription plan
        </CustomLink>
      );
    case link.includes('schedule/provider'): {
      path = `/patient/schedule/provider/schedule-dr/${duration}`;
      if (isSuper) {
        path += `?${id}`;
      }
      return linkByFeatureFlagFunc(path, messContent.Schedule_prescriber);
    }
    case link.includes('schedule/care_manager'): {
      path = `/patient/schedule/care_manager/schedule-cm/${duration}`;
      if (isSuper) {
        path += `?${id}`;
      }
      return linkByFeatureFlagFunc(path, messContent.Schedule_Counselor);
    }
    case link.includes('schedule/therapist'):
      path = `/patient/schedule/therapist/schedule-th/${duration}`;
      if (isSuper) {
        path += `?${id}`;
      }
      return linkByFeatureFlagFunc(path, messContent.Schedule_Therapist);
    case link.includes('schedule/nutritionist'):
      path = `/patient/schedule/nutritionist/schedule-nt/${duration}`;
      if (isSuper) {
        path += `?${id}`;
      }
      return linkByFeatureFlagFunc(path, messContent.Schedule_Nutritionist);
    case link.includes('visit/followup'):
      path = '/patient/service-lines';
      return linkByFeatureFlagFunc(path, messContent.Complete);
    case link.includes('choose-plan/therapy'):
      path = '/patient/choose-plan?plan=therapy';
      return linkByFeatureFlagFunc(path, messContent.Therapy_plan);
    case link.includes('choose-plan/medication-therapy'):
      path = '/patient/choose-plan?plan=medication-therapy';
      return linkByFeatureFlagFunc(path, messContent.Medication_Therapy_plan);
    case link.includes('choose-plan/medication'):
      path = '/patient/choose-plan?plan=medication';
      return linkByFeatureFlagFunc(path, messContent.Medication_plan);
    case link.includes('exercise/') && adhocData !== -1:
      return (
        <span className={cls(classNames, 'adhoc-card')}>
          {!adhocData ? (
            <SpinnerHolder>
              <Spinner size="3.5rem" color="#6786FF" borderWidth="4px" />
            </SpinnerHolder>
          ) : (
            <CategoryCard
              name={(adhocData as AdhocData).title}
              title=""
              subTitle=""
              summary=""
              imageUrl={`${baseUrl}${(adhocData as AdhocData).image_url}`}
              ctaLink={`/patient/dashboard/patient_exercises/exercise_flow/${(adhocData as AdhocData).id}`}
              type="exercise"
              pdfLink=""
              isAdhocCard
            />
          )}
        </span>
      );
    default:
      return <span className={classNames}>{link}</span>;
  }
};

export default ButtonLink;
