import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const CardWrapper = styled.div`
  display: flex;
  width: 450px;
  height: 200px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 2px 2px #d3dbf8;
  padding: 20px;
  align-items: center;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 16px 10px 10px 16px;
  }

  ${props =>
    props.isAdhocCard &&
    css`
      background: transparent;
      box-shadow: none;
      margin-bottom: 0;
      @media (max-width: 768px) {
        height: auto;
        justify-content: space-between;
      }
    `}
`;

export const Img = styled.img`
  width: 150px;
  margin-right: 45px;

  @media (max-width: 768px) {
    width: 131px;
    margin-right: 14px;
  }
`;

export const RightWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TopicText = styled.h5`
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 10px;
  letter-spacing: -0.43px;
  height: 20px;
`;

export const NameText = styled.h3`
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  letter-spacing: -0.43px;
`;

export const TitleText = styled(NameText)`
  font-size: 24px;
  margin-top: 30px;
`;

export const DescriptionText = styled.p`
  font-size: 12px;
  letter-spacing: -0.43px;
  line-height: 20px;
  margin-bottom: 0;
`;

export const ActionWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: flex-start;

  ${props =>
    props.type === 'assignment' &&
    css`
      flex-direction: column;
    `}
`;

const buttonStyles = `
  display: inline-block;
  padding: 5px 16px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background: #6786ff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 15px;
  transition: all 0.3s;
  margin-bottom: 8px;
  margin-right: 8px;
  white-space: nowrap;

  &:hover {
    background: #250044;
    text-decoration: none;
    color: #ffffff;
  }
`;

export const Button = styled(Link)`
  ${buttonStyles}
`;

export const PDFLink = styled.a`
  ${buttonStyles}
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  ${props =>
    props.disabled &&
    `
    background: rgba(103, 134, 255, 0.5);
  `}

  &:hover {
    cursor: pointer;
  }
`;

export const LinkButton = styled.button`
  display: inline-block;
  padding: 5px 10px;
  width: 80px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #6786ff;
  transition: all 0.3s;
  background: transparent;
  border: none;
  &:hover {
    text-decoration: underline;
  }
`;
