import styled from 'styled-components/macro';
import { Modal as DefaultModal } from 'react-bootstrap';
import ButtonSelector from '../../UI/ButtonSelector';
import TextButton from '../../UI/TextButton';

export const ShortButton = styled(ButtonSelector)`
  &:first-child {
    margin-right: 10px;
  }
`;

export const LongButton = styled(ButtonSelector)`
  width: 100%;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const QuestionHolder = styled.div`
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 604px;
  display: flex;
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  justify-content: space-between;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

export const getButton = flag => {
  return flag ? LongButton : ShortButton;
};

export const OkButton = styled(TextButton)`
  margin: 10px auto 0;
  display: block;
`;
export const CloseButton = styled.button`
  width: 43px;
  height: 43px;
  background-repeat: no-repeat;
  border: transparent;
  border-radius: 50%;
  position: absolute;
  background-color: white;
  right: -16px;
  top: -16px;
  display: flex;
  background-position: center;
`;
export const SelectorModal = styled(DefaultModal)`
  .modal-content {
    max-width: 420px;
    margin: 0 auto;
    border-radius: 4px;
  }
`;
export const Message = styled.p`
  color: #6786ff;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.4px;
  margin: 20px 0 0 0;
  &:first-child {
    margin: 0;
  }
`;
