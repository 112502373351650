import React, { useState, useEffect, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { prescriberVariant } from 'utils/care-person-helpers';
import { useHistory } from 'react-router';
import PhoneSupport from 'components/PhoneSupport';
import { Flexbox } from '@cerebral-inc/ui';
import ButtonSelector from '../../../UI/ButtonSelector';
import Select from '../../../UI/Select';
import Divider from '../../../UI/Divider';
import { QuestionHolder } from '../styled';
import Spinner from '../../../Spinner';
import { baseSearchPayerUrl, basePayerUrl } from '../../../MultiStepForm/Form/InsuranceForm/url.util';
import AsyncSelect from '../../../UI/AsyncSelect';
import { setInsuranceVisitsPolicy, setPayerOnboarding } from '../../../../actions/patient_action';

import usePromise from '../../../../utils/usePromise';
import { Question } from '../../../../types/question';

export const StyledButton = styled(ButtonSelector)`
  background: #250044;
  color: white;
  padding: 20px;
  border-radius: 28px;
  margin: 30px 0;

  :disabled {
    opacity: 0.5;
  }

  @media (max-width: 991px) {
    width: 80%;
    margin-bottom: 30px;
  }
`;

const TextSubScript = styled.p`
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.4px;
  color: #250044;
  text-align: center;

  @media (max-width: 991px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const LinktoOONI = styled.div`
  text-decoration: underline;
  margin-bottom: 20px;
  font-weight: 600;
  cursor: pointer;
  color: #6786ff;
`;

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
`;

interface Props {
  loading: string | null;
  onSelectYes: () => void;
  onSelectNo: () => void;
  question: Question;
  enableNewflowValue: boolean;
  updateView: (view: string) => void;
}

interface Payer {
  label?: string;
  value?: number;
}

interface PayerOptions {
  options: Payer[];
  has_more: boolean;
}

const ABInsuranceSelector: React.FC<Props> = props => {
  const { loading, onSelectYes, onSelectNo, question, enableNewflowValue, updateView } = props;
  const [selectedPayer, setSelectedPayer] = useState<number | string>('');
  const [searchedPayer, setSearchedPayer] = useState<Payer>();
  const [searchParams, setSearchParams] = useState<unknown[]>([null, 1]);
  const [payerOptions, payerOptionsLoaded] = usePromise<PayerOptions>(basePayerUrl(null, 1, false), []);
  const [searchPayerOptions, searchPayerOptionsLoaded] = usePromise<PayerOptions>(
    baseSearchPayerUrl(...searchParams, 1, true),
    searchParams as never[]
  );
  const [payers, setPayers] = useState([] as Payer[]);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (!payerOptionsLoaded || !payerOptions || !('data' in payerOptions)) return;

    const options: Payer[] = payerOptions?.data?.options;
    const defaultOption = options?.[0];

    if (!defaultOption) return;

    setPayers(options);
  }, [payerOptions, payerOptionsLoaded]);

  const onChangeSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedPayer(+event.target.value);
  };

  const onYes = () => {
    if (question) {
      const prefilledInsurance = { payer_id: enableNewflowValue ? searchedPayer?.value : selectedPayer };
      dispatch(setInsuranceVisitsPolicy({ policy: prefilledInsurance }));
      onSelectYes();
    } else {
      history.push('/patient/dashboard/profile_info');
      updateView('new_update_insurance');
    }
    if (searchedPayer) {
      dispatch(setPayerOnboarding(searchedPayer));
    } else if (selectedPayer) {
      dispatch(setPayerOnboarding({ value: selectedPayer }));
    }
  };
  const onNo = () => {
    if (question) {
      dispatch(setPayerOnboarding({ ooni: true }));
      onSelectNo();
    } else {
      dispatch(setPayerOnboarding({ ooni: true }));
      history.push('/patient/dashboard/profile_info');
      updateView('new_update_insurance');
    }
  };
  const onPayerChange = (option: Payer) => {
    if (!searchPayerOptionsLoaded || !option) {
      setSearchedPayer({});
      return;
    }
    setSearchedPayer(option);
  };
  const loadPayerOptions = (search: string, _: never, { page }: { page: number }) => {
    setSearchParams([search, page]);
    if (!searchPayerOptionsLoaded || !searchPayerOptions || !('data' in searchPayerOptions)) return;
    const options: Payer[] = searchPayerOptions?.data?.options;

    const hasMore: boolean = searchPayerOptions?.data?.has_more;

    return {
      options,
      hasMore,
      additional: {
        page: page + 1
      }
    };
  };

  return (
    <QuestionHolder>
      <Title>Let’s check your insurance coverage</Title>
      <Wrapper>
        <Flexbox direction="column">
          <TextSubScript>Your Cerebral service might be covered by your insurance.</TextSubScript>
          <TextSubScript>{`${enableNewflowValue ? `Search for` : `Select`} your insurance ${
            prescriberVariant.singularNormal
          }:`}</TextSubScript>
        </Flexbox>
      </Wrapper>

      {enableNewflowValue ? (
        <div style={{ width: '500px' }}>
          <AsyncSelect
            isClearable
            name="payer_id"
            loadOptions={loadPayerOptions}
            onChange={onPayerChange}
            debounceTimeout={300}
            additional={{
              page: 1
            }}
          />
        </div>
      ) : (
        <Select onChange={onChangeSelect} value={selectedPayer}>
          <option hidden label="" value="" />
          {payers.map(payer => (
            <option value={payer.value} key={payer.value}>
              {payer.label}
            </option>
          ))}
        </Select>
      )}
      {enableNewflowValue ? (
        <StyledButton onClick={onYes} disabled={loading === 'yes' || !searchedPayer}>
          {loading === 'yes' ? <Spinner size="2.0rem" /> : 'Next'}
        </StyledButton>
      ) : (
        <StyledButton onClick={onYes} disabled={loading === 'yes' || !selectedPayer}>
          {loading === 'yes' ? <Spinner size="2.0rem" /> : 'Next'}
        </StyledButton>
      )}

      {enableNewflowValue ? (
        <>
          <Divider />
          <StyledButton onClick={onNo} disabled={loading === 'no'}>
            {loading === 'no' ? <Spinner size="2.0rem" /> : 'My insurance is not listed'}
          </StyledButton>
        </>
      ) : (
        <LinktoOONI onClick={onNo}>My insurance is not listed</LinktoOONI>
      )}
      <Flexbox>{question && <TextSubScript>Note: {question.subscript}</TextSubScript>}</Flexbox>
      <PhoneSupport />
    </QuestionHolder>
  );
};

export default ABInsuranceSelector;
