import styled from 'styled-components/macro';
import { Modal as DefaultModal } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import TextButton from '../UI/TextButton';
import { ReactComponent as InformationImage } from './assets/information.svg';

export const MainHeader = styled.div`
  font-style: normal;
  margin-bottom: 30px;
  margin-top: 80px;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  letter-spacing: -0.4px;
  @media (max-width: 991px) {
    margin-top: 70px;
    font-size: 18px;
    line-height: 22px;
  }
`;

export const Plans = styled.div<{ singleViewMode: boolean; cardNumber: number }>`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  max-width: ${({ cardNumber }) => (cardNumber > 3 ? `${cardNumber * 360}px` : '1150px')};
  justify-content: ${({ singleViewMode }) => (singleViewMode ? 'center' : 'space-between')};
  margin: 0 auto;

  @media (max-width: 1149px) {
    flex-direction: column;
    align-items: center;
    & > * + * {
      margin: 50px 0 0 0;
    }
  }
`;

export const Subscript = styled.div`
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.4px;
  color: #250044;
  text-align: center;
  max-width: 394px;
  margin-bottom: 30px;

  @media (max-width: 991px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const Header = styled(ModalHeader)`
  &.modal-header {
    background: white;
    border-bottom: none;
  }
`;

export const Body = styled(ModalBody)`
  &.modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    padding: 0 26px 50px;
    border-radius: 0 0 0.3rem 0.3rem;
  }
`;

export const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Information = styled(InformationImage)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Error = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -1px;
  margin-bottom: 50px;
  color: #ff4b4b;
`;

export const Modal = styled(DefaultModal)`
  .modal-content {
    max-width: 300px;
    margin: 0 auto;
  }
`;

export const ContactSupportButton = styled(TextButton)`
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.314286px;
  color: #6786ff;
  padding: 0;
`;

export const TryAgainButton = styled.button`
  background: #6786ff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 28.5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  letter-spacing: -0.314286px;
  color: #ffffff;
  width: 100px;
  height: 30px;
  border: none;
`;

export const AdditionalDueText = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.43px;

  color: #250044;
`;

export const AdditionalPriceText = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.43px;

  color: #6786ff;
  margin: 20px 0 10px 0px;
`;

export const NewConfirmUpgrade = styled.button`
  background: #6786ff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 28.5px;
  padding: 8px 11px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.314286px;
  border: none;
`;
