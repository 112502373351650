import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import InformationIcon from 'components/InformationIcon/InformationIcon';
import { TotalTooltip } from './PaymentInfoInsurance';
import { is_touch_device } from '../../../utils/common';

interface PromoHeadingProps {
  isOnlySmoking: boolean;
}

const PromoHeading: React.FC<PromoHeadingProps> = ({ isOnlySmoking }) => {
  return (
    <>
      Promo Code?{' '}
      {isOnlySmoking && (
        <OverlayTrigger
          rootClose
          key="bottom"
          placement="bottom"
          trigger={is_touch_device() ? 'click' : ['hover', 'focus']}
          overlay={
            <TotalTooltip id="tooltip-bottom">
              Promo codes will be applied to medications that are prescribed
            </TotalTooltip>
          }
        >
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
          <span tabIndex={0}>
            <InformationIcon iconStyle="blue" />
          </span>
        </OverlayTrigger>
      )}
    </>
  );
};

export default PromoHeading;
