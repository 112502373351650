import React, { createContext, useEffect } from 'react';
import { useHistory } from 'react-router';

export interface Props {
  refId?: string;
}
export interface ReferContextProviderProps {
  children: React.ElementType;
}

export const ReferContext = createContext<Props>({});

const ReferContextProvider: React.FC<ReferContextProviderProps> = props => {
  const { children } = props;
  const history = useHistory();
  let refvar: string | null = new URLSearchParams(history.location.search).get('referral_key');

  useEffect(() => {
    window.onbeforeunload = function () {
      if (refvar) {
        localStorage.setItem('unload_time', new Date().getTime().toString());
      }
      return '';
    };
  }, []);

  if (refvar) {
    localStorage.setItem('refKey', refvar);
  } else {
    const unload_time = localStorage.getItem('unload_time');
    if (unload_time && new Date().getTime() - parseInt(unload_time, 10) < 300000) {
      refvar = localStorage.getItem('refKey');
    } else {
      localStorage.removeItem('refKey');
      localStorage.removeItem('unload_time');
    }
  }

  return <ReferContext.Provider value={{ refId: refvar || '' }}>{children}</ReferContext.Provider>;
};

export default ReferContextProvider;
