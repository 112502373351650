import Cookies from 'js-cookie';

const gtagSession = () => {
  const gaSession = Cookies.get('_ga');
  const localSession = `ga-${gaSession}`;

  const add = tag => {
    let cookieValue = Cookies.get(localSession);
    const cookieJSON = cookieValue ? JSON.parse(cookieValue) : {};
    cookieJSON[tag] = true;
    cookieValue = JSON.stringify(cookieJSON);
    return cookieValue;
  };

  const set = tag => {
    Cookies.set(localSession, add(tag));
  };

  const contains = tag => {
    const cookieValue = Cookies.get(localSession);
    const cookieJSON = cookieValue ? JSON.parse(cookieValue) : {};

    if (cookieJSON[tag]) {
      return true;
    }
    return false;
  };

  gtagSession.set = set;
  gtagSession.contains = contains;
};

export default gtagSession;
