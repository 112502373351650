import styled, { css } from 'styled-components/macro';

export default styled.button`
  display: inline-block;
  padding: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #6786ff;
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;

  ${props =>
    props.rounded &&
    css`
      border-radius: 15px;
    `}

  ${props =>
    props.color === 'primary' &&
    css`
      background: #6786ff;
      color: #ffffff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
      padding: 5px 20px;

      &:hover {
        background: #250044;
        text-decoration: none;
        color: #ffffff;
      }
    `}

  transition: all .3s;
`;
