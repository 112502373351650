import React from 'react';
import { Holder, HolderInner } from './styled';

interface WhiteBoxProps {
  children: React.ReactNode;
  noBackground?: boolean;
  opacity?: number;
}

const WhiteBox: React.FC<WhiteBoxProps> = ({ children, noBackground = false, opacity = 1 }) => (
  <Holder className="align-self-center" noBackground={noBackground} opacity={opacity}>
    <HolderInner className="d-flex flex-column">{children}</HolderInner>
  </Holder>
);

export default WhiteBox;
