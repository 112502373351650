import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import CreditCard from './credit_card';
import { get_payment_info } from '../../actions/patient_action';
import { capitalize, isUserCanceled } from '../../utils/common';

const PaymentInformation = ({ getPaymentInfo, patient }) => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState('');
  const [type, setType] = useState('read');
  const [cardData, setCardData] = useState({
    provider: '',
    last4: '',
    exp_month: '',
    exp_year: ''
  });

  useEffect(() => {
    let allowSetState = true;
    if (isUserCanceled(patient.status)) {
      setErrors('No payment information');
      setLoading(false);
      return;
    }

    getPaymentInfo(patient.id)
      .then(({ data }) => {
        if (!allowSetState) return;
        setLoading(false);
        setCardData(data);
      })
      .catch(() => {
        if (!allowSetState) return;

        setLoading(false);
        setErrors('Could not obtain your payment information. Please contact your Care Counselor.');
      });

    return () => {
      allowSetState = false;
    };
  }, []);

  const edit_btn_handler = () => {
    setType(type === 'read' ? 'write' : 'read');
  };

  const showEditButton = () => {
    return !(loading || errors || isUserCanceled(patient.status));
  };

  const update_card_info = card => {
    if (card) {
      setCardData({
        provider: card.provider,
        exp_month: card.exp_month,
        exp_year: card.exp_year,
        last4: card.last4
      });
    }
    setType('read');
  };

  const dot_for_number = () => (
    <div>
      <span>&#9679;</span>
      <span>&#9679;</span>
      <span>&#9679;</span>
      <span>&#9679;</span> <span>&#9679;</span>
      <span>&#9679;</span>
      <span>&#9679;</span>
      <span>&#9679;</span> <span>&#9679;</span>
      <span>&#9679;</span>
      <span>&#9679;</span>
      <span>&#9679;</span> {cardData.last4}
    </div>
  );

  return (
    <div className="d-flex align-self-start main-content-small-card">
      <div className="d-flex flex-column justify-content-between flex-grow-1 flex-shrink-1 card-items-container">
        {type === 'read' ? (
          <>
            <div>
              <div className="small-card-title">PAYMENT INFORMATION</div>
              {loading ? <div>Loading payment information...</div> : null}
              <div className="blue-text">{errors}</div>
              <div className="small-card-item">
                {cardData.provider ? `${capitalize(cardData.provider)} Card` : null}
              </div>
              <div className="small-card-item">{cardData.last4 ? dot_for_number() : null}</div>
              <div className="small-card-item">
                {cardData.exp_month ? `Exp date: ${cardData.exp_month}/${cardData.exp_year}` : null}
              </div>
            </div>
            {showEditButton() ? (
              <div className="small-card-btn" onClick={() => edit_btn_handler()}>
                EDIT
              </div>
            ) : null}
          </>
        ) : (
          <>
            <div className="small-card-title">PAYMENT INFORMATION</div>
            <CreditCard onUpdate={update_card_info} onClose={() => edit_btn_handler()} />
          </>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  getPaymentInfo: get_payment_info
};

export default connect(null, mapDispatchToProps)(PaymentInformation);
