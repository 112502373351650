import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';
import differenceBy from 'lodash/differenceBy';
import tracking from '../utils/tracking';
import { mapResponseToError, calculateStep } from '../utils/common';
import { set_visit_is_complete, answer_current_question, setCommunicationPreferences } from '../actions/patient_action';

const initialState = {
  // navigate or not to next question
  toNextQuestion: true,

  // loading or realoding questions or banks
  loading: false,

  // this is a cache of all of the question bank information, used so we can draw the correct
  // title for the question bank at the top of screen
  questionsByBank: {},

  // all of the question_bank names for the current user that has not been answered yet
  questionBanks: [],

  // which question bank the user is currently looking at
  currentBankIndex: 0,

  // if true we need to refresh banks
  refreshBanks: false,

  // question bank name that for current questions
  currentBankName: '',

  // current question name
  currentQuestionName: '',

  // current question index
  currentQuestionIndex: 0,

  // if true we need to refresh questions
  refreshQuestions: false,

  // current width of progress bar
  currentWidth: 0,

  // step at which increament progress bar
  step: 0,

  // error message
  error: ''
};

const make_headers = state => {
  const {
    global_reducer: {
      current_user: { attributes }
    }
  } = state;
  const headers = {
    'Content-Type': 'application/json',
    'access-token': attributes['access-token'],
    client: attributes.client,
    uid: attributes.uid
  };

  if (attributes['jwt-token']) {
    headers.Authorization = `Bearer ${attributes['jwt-token']}`;
  }

  return headers;
};

const questionsDetails = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setQuestions: (state, { payload }) => {
      const { total_unanswered_questions, total_answered_questions, total_questions, ...banks } = payload;

      if (total_unanswered_questions > 0) {
        const bankNames = Object.keys(banks).filter(name => !isEmpty(banks[name].unanswered_questions));
        const questions = banks[bankNames[0]].unanswered_questions;
        const step = calculateStep(100, total_questions);
        const currentWidth = Math.round(total_answered_questions * step * 100) / 100;

        state.refreshBanks = false;
        state.loading = false;
        state.questionsByBank = banks;
        state.questionBanks = bankNames;
        state.currentBankIndex = 0;
        state.currentBankName = bankNames[0];
        state.currentQuestionIndex = 0;
        state.currentQuestionName = questions[0].name;
        state.step = step;
        state.currentWidth = currentWidth;
      } else {
        state.checkVisitCompletion = true;
      }
    },

    nextQuestion: (state, { payload }) => {
      const { step } = payload;
      const { questionBanks, questionsByBank, currentBankIndex: bankIdx, currentQuestionIndex: questionIdx } = state;
      const bankName = questionBanks[bankIdx];

      const questions = questionsByBank[bankName].unanswered_questions;
      const nextQuestionIndex = questionIdx + step;
      const nextBankIdx = bankIdx + 1;

      if (questions[questionIdx] && questions[questionIdx].refresh_after) {
        state.refreshQuestions = true;
        state.loading = true;
        state.toNextQuestion = false;
        state.checkVisitCompletion = false;
      } else if (questions[nextQuestionIndex]) {
        state.currentQuestionIndex = nextQuestionIndex;
        state.currentQuestionName = questions[nextQuestionIndex].name;
        state.toNextQuestion = true;
        state.checkVisitCompletion = false;
      } else if (bankName && questionsByBank[bankName].refresh_after) {
        state.refreshBanks = true;
        state.loading = true;
        state.toNextQuestion = false;
        state.checkVisitCompletion = false;
      } else if (questionBanks[nextBankIdx]) {
        state.toNextQuestion = true;
        state.checkVisitCompletion = false;
        state.currentBankIndex = nextBankIdx;
        state.currentBankName = questionBanks[nextBankIdx];
        state.currentQuestionIndex = 0;
        state.currentQuestionName = questionsByBank[questionBanks[nextBankIdx]].unanswered_questions[0].name;
      } else {
        state.checkVisitCompletion = true;
      }
    },

    setBankAndQuestion: (state, { payload }) => {
      const { questionBanks, questionsByBank } = state;
      const { bankName, questionName } = payload;
      const questions = questionsByBank[bankName].unanswered_questions;

      state.currentBankName = bankName;
      state.currentBankIndex = questionBanks.findIndex(b => b === bankName);
      state.currentQuestionName = questionName;
      state.currentQuestionIndex = questions.findIndex(q => q.name === questionName);
      state.toNextQuestion = false;
      state.loading = false;
    },

    setLoading: (state, { payload }) => {
      state.loading = payload.loading;
    },

    setError: (state, { payload }) => {
      const { errorMessage } = payload;

      state.loading = false;
      state.error = errorMessage;
    },

    resetValues: () => initialState,

    refreshQuestionBanks: (state, { payload }) => {
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const { total_answered_questions, total_questions, total_unanswered_questions, ...banks } = payload;
      const { questionsByBank, currentBankName } = state;

      // find all the bank (answered and unaswered) before current one(included)
      const names = Object.keys(questionsByBank);
      const currentIdx = names.findIndex(bank => bank === currentBankName);
      const beforeBankNames = names.slice(0, currentIdx + 1);

      // all incoming bank names
      const incomingBankNames = Object.keys(banks);

      // remove duplicates from afterBankNames if any
      const afterBankNames = difference(incomingBankNames, beforeBankNames);

      // add incoming banks
      const newBankNames = beforeBankNames.concat(afterBankNames);

      // copy banks and preserve the order
      const questionsMap = newBankNames.reduce((qMap, key) => {
        if (beforeBankNames.includes(key)) {
          qMap.set(key, questionsByBank[key]);
        } else {
          qMap.set(key, banks[key]);
        }

        return qMap;
      }, new Map());

      const newQuestionsByBank = Object.fromEntries(questionsMap);

      // unanswered bank names
      const bankNames = Object.keys(newQuestionsByBank).filter(
        name => !isEmpty(newQuestionsByBank[name].unanswered_questions)
      );

      // calculate next bankIndex
      const newBankIndex = bankNames.indexOf(currentBankName) + 1;

      if (bankNames[newBankIndex]) {
        state.questionsByBank = newQuestionsByBank;
        state.questionBanks = bankNames;
        state.currentBankIndex = newBankIndex;
        state.currentBankName = bankNames[newBankIndex];
        state.currentQuestionIndex = 0;
        state.currentQuestionName = newQuestionsByBank[bankNames[newBankIndex]].unanswered_questions[0].name;
        state.checkVisitCompletion = false;
        state.toNextQuestion = true;
        state.refreshBanks = false;
      } else {
        state.loading = false;
        state.checkVisitCompletion = true;
      }
    },

    refreshQuestions: (state, { payload }) => {
      const { questionsByBank, questionBanks } = state;
      const { question_bank_name, unanswered_questions } = payload;

      const currentQuestions = questionsByBank[question_bank_name].unanswered_questions.slice(
        0,
        state.currentQuestionIndex + 1
      ); // to include current question

      const newQuestions = differenceBy(unanswered_questions, currentQuestions, 'name');

      const newQuestionsForBank = currentQuestions.concat(newQuestions);

      const newQuestionIndex = state.currentQuestionIndex + 1;
      const nextQuestionBankIndex = state.currentBankIndex + 1;

      state.refreshQuestions = false;
      state.questionsByBank[question_bank_name].unanswered_questions = newQuestionsForBank;

      if (newQuestionsForBank[newQuestionIndex]) {
        state.currentQuestionIndex = newQuestionIndex;
        state.toNextQuestion = true;
        state.currentQuestionName = newQuestionsForBank[newQuestionIndex].name;
      } else if (questionsByBank[question_bank_name].refresh_after) {
        state.refreshBanks = true;
        state.loading = true;
        state.toNextQuestion = false;
        state.checkVisitCompletion = false;
      } else if (questionBanks[nextQuestionBankIndex]) {
        state.toNextQuestion = true;
        state.currentBankIndex = nextQuestionBankIndex;
        state.currentBankName = questionBanks[nextQuestionBankIndex];
        state.currentQuestionIndex = 0;
        state.currentQuestionName = questionsByBank[questionBanks[nextQuestionBankIndex]].unanswered_questions[0].name;
      } else {
        state.loading = false;
        state.checkVisitCompletion = true;
      }
    }
  }
});

export const {
  setQuestions,
  nextQuestion,
  setBankAndQuestion,
  resetValues,
  refreshQuestionBanks,
  refreshQuestions,
  setError,
  setLoading
} = questionsDetails.actions;

export default questionsDetails.reducer;

/** Fetch question banks from BE */
export const fetchQuestionBanks =
  (isRefresh = false) =>
  async (dispatch, getState) => {
    const patientId = getState().global_reducer.current_user.attributes.patient.id;
    const visitId = getState().patient_reducer.visit_object.id;
    const shouldSkipILV = sessionStorage.getItem('shouldSkipILV') === 'true';

    let url = '';
    if (shouldSkipILV) {
      url = `/api/patients/${patientId}/visits/${visitId}/flow_status?flow_options[is_mobile]=true`;
    } else {
      url = `/api/patients/${patientId}/visits/${visitId}/flow_status`;
    }

    try {
      const { data } = await axios.get(url, { headers: make_headers(getState()) });

      if (data.completed || data.already_completed) {
        dispatch(set_visit_is_complete({ pendingUpdates: false, isComplete: true }));
      } else if (isRefresh) {
        dispatch(refreshQuestionBanks(data));
      } else {
        dispatch(setQuestions(data));
      }
    } catch (err) {
      const errorMessage = mapResponseToError(err);
      tracking.error({ e: err, message: errorMessage });
      dispatch(setError({ errorMessage }));
    }
  };

/** Fetch questions for the specific bank from BE */
export const fetchQuestionsForBank = () => async (dispatch, getState) => {
  const visitId = getState().patient_reducer.visit_object.id;
  const bankName = getState().questions_reducer.currentBankName;
  const url = `/api/question_banks/flow_status?visit_id=${visitId}&name=${bankName}`;

  try {
    const { data } = await axios.get(url, { headers: make_headers(getState()) });
    dispatch(refreshQuestions(data));
  } catch (err) {
    const errorMessage = mapResponseToError(err);
    tracking.error({ e: err, message: errorMessage });
    dispatch(setError({ errorMessage }));
  }
};

const handleControlledMedication = (dispatch, answer) => {
  const ans = JSON.parse(answer.answer);

  if (['Yes', "I don't know"].includes(ans.answer)) {
    dispatch(setCommunicationPreferences('video'));
  }
};

const handleCommunicationPreferences = (dispatch, answer) => {
  const ans = JSON.parse(answer.answer);

  dispatch(setCommunicationPreferences(ans.answer));
};

/** submit an answer to the question */
export const submitAnswerAndNext =
  ({ answer, question }) =>
  async dispatch => {
    !question.refresh_after &&
      question.name !== 'emergency_contact' &&
      question.name !== 'elated_mood' &&
      dispatch(nextQuestion({ step: 1 }));

    if (question.name === 'communication_preference') {
      handleCommunicationPreferences(dispatch, answer);
    }

    if (question.name === 'controlled_medication') {
      handleControlledMedication(dispatch, answer);
    }

    if (question.name === 'elated_mood') {
      dispatch(setLoading({ loading: true }));
    }

    try {
      await dispatch(answer_current_question(answer, question));
      (question.refresh_after || question.name === 'emergency_contact' || question.name === 'elated_mood') &&
        dispatch(nextQuestion({ step: 1 }));
    } catch (err) {
      tracking.error({ e: err, message: `Unable to submit answer for the ${question.name}` });
    }
  };

/** check if the visit has been completed */
export const checkVisitCompletion = () => async (dispatch, getState) => {
  const patientId = getState().global_reducer.current_user.attributes.patient.id;
  const visitId = getState().patient_reducer.visit_object.id;
  const url = `/api/patients/${patientId}/visits/${visitId}/complete_flow`;

  try {
    const { data } = await axios.post(url, {}, { headers: make_headers(getState()) });
    const { completed, already_completed, ...rest } = data;

    if (completed || already_completed) {
      dispatch(set_visit_is_complete({ pendingUpdates: false, isComplete: true }));
    } else {
      dispatch(refreshQuestionBanks(rest));
    }
  } catch (err) {
    const errorMessage = mapResponseToError(err);
    tracking.error({ e: err, message: errorMessage });
    dispatch(setError({ errorMessage }));
  }
};
