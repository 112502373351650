import styled from 'styled-components/macro';
import Button from 'components/UI/Button';

export const Holder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`;

export const Title = styled.div`
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #6786ff;
`;

export const ChangePlanButton = styled.button`
  width: 256px;
  height: 40px;
  margin-top: 17px;
  background: linear-gradient(90deg, #6786ff 6.2%, #85abee 100%);
  box-shadow: 0px 7px 14px rgba(34, 34, 52, 0.13);
  border-radius: 30px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
`;

export const ConsentButton = styled(Button)`
  padding: 11px 70px;
  background: #6786ff;
`;
