import { PriceModel } from 'localization/types';
import locale from '../localization';
import createPricing from '../localization/createPricing';
import { countryFromUrl } from './common';

const country = countryFromUrl();
locale.setLanguage(country);

const {
  regularVideo,
  defaultSubpriceMessage,
  weeklyVideoPhone,
  medsTherapyPlanTitle,
  medsTherapyCta,
  medsPlanTitle,
  medicationManagementTXCAPlan,
  medicationManagementNYFLPlan,
  currencySymbol,
  prescriberText,
  medicationPrices,
  therapyPrices,
  medicationTherapyPrices,
  nutritionPrices,
  nutritionMedicationPrices,
  medicationNicotinePrices,
  medicationBupropionPrices,
  medicationBupropionNicotinePrices,
  nutritionMedicationGlp1Prices,
  nutritionMedicationGlp1TherapyPrices
} = locale;

export const offerings = [
  'medication',
  'therapy',
  'medication-therapy',
  'nicotine',
  'bupropion',
  'bupropion-nicotine',
  'nicotine-counseling',
  'bupropion-counseling',
  'bupropion-nicotine-counseling',
  'medication-smoking-cessation-treatment',
  'medication-therapy-smoking-cessation-treatment',
  'nutrition-management',
  'nutrition-medication-management',
  'nutrition-management-therapy',
  'nutrition-medication-management-therapy',
  'counseling',
  'medication-therapy-nutrition-management',
  'medication-counseling-nutrition-management',
  'counseling',
  'medication-adhd-115-management',
  'medication-adhd-135-management',
  'therapy-nutrition-management',
  '99_med_management',
  '109_med_management',
  '345_meds_therapy',
  '275_therapy',
  '119_med_management',
  '365_meds_therapy',
  '290_therapy',
  'nutrition-medication-management-glp1',
  'nutrition-medication-management-glp1-therapy',
  'medication-management-50',
  'medication-management-85'
] as const;

export type OfferingKey = typeof offerings[number];

type PlanType = OfferingKey | 'counseling' | 'legacy' | 'medication-only';

export type NutritionOfferingKeys =
  | 'nutrition-management'
  | 'nutrition-medication-management'
  | 'nutrition-management-therapy'
  | 'nutrition-medication-management-therapy';

const ctas = {
  THERAPY: 'Start Therapy Today',
  MEDS: 'Start Today',
  ADD_COUNSEL: 'Add Care Counseling',
  MEDS_THERAPY: medsTherapyCta,
  ADD_THERAPY: 'Add Therapy',
  ADD_SMOKING_CESSATION: 'Add Smoking Cessation',
  CHOOSE_PLAN: 'Choose this plan'
};

export interface Plan {
  mostComprehensive?: boolean;
  title: string;
  name: string;
  description?: string;
  bullets: string[];
  paymentBullets?: string[];
  price: number;
  pricePerWeek?: string;
  pricePerMonth?: string;
  pricePerWeekTotal?: number;
  discountedPrice: number;
  discountedPricePerWeek?: string;
  discountedPricePerMonth?: string;
  discountedPricePerWeekTotal?: number;
  priceWeekPlusDiscount?: number;
  upsellPrice?: number;
  subprice: string;
  cta?: string;
  id: OfferingKey;
  upsell?: {
    x2Effective: string;
    title: string;
    flowTitle: string;
    bullets: string[];
    subprice: string;
    cta: string;
    cancelLabel?: string;
  };
  category?: string | null | undefined;
  planId?: number;
}

const membershipFee = 29;

export type PlanTitle = Record<'title' | 'name', string>;

const medicationPlan = medsPlanTitle;
const medicationTherapyPlan = medsTherapyPlanTitle;

const therapyPlan: PlanTitle = {
  title: 'Therapy',
  name: 'Therapy'
};

const medicationManagementPlan: PlanTitle = {
  title: 'Medication \nManagement',
  name: 'Medication Management'
};

export const couponMapping = (coupon: string): number =>
  ({
    therapyCampaign: locale.defaultTherapyCampaignPrice,
    medsTherapyCampaign: locale.defaultMedsTherapyCampaignPrice,
    bupropion: locale.defaultBupropionCodePrice,
    nicotine: locale.defaultNicotineCodePrice,
    'bupropion-nicotine': locale.defaultBupropionNicotineCodePrice,
    'nutrition-medication-management': locale.defaultNutritionMedicationCodePrice,
    'nutrition-medication-management-glp1': locale.defaultNutritionMedicationGlp1CodePrice,
    'nutrition-management': locale.defaultNutritionCodePrice
  }[coupon] || 0);

export const mapPlanToCouponCode: Record<string, string> = {
  therapy: locale.defaultTherapyCode,
  'medication-therapy': locale.defaultMedsTherapyCode,
  'medication-management-50': locale.defaultMedicationManagementTXAndCA,
  'medication-management-85': locale.defaultMedicationManagementNYAndFL,
  nicotine: locale.defaultNicotineCode,
  bupropion: locale.defaultBupropionCode,
  'bupropion-nicotine': locale.defaultBupropionNicotineCode,
  'nutrition-management': locale.defaultNutritionCode,
  'nutrition-medication-management': locale.defaultNutritionMedicationCode,
  'nutrition-medication-management-glp1': locale.defaultNutritionMedicationGlp1Code
};

export const priceWeek = (price: number) => `${currencySymbol}${price}/week`;
export const priceWeekDiscount = (price: number) => `${currencySymbol}${price}/week for first month`;
export const priceMonth = (price: number) => `${currencySymbol}${price}/month`;
export const priceMonthDiscount = (price: number) => `${currencySymbol}${price} for first month`;

const x2EffectiveTherapy = '2X as effective when combined with Therapy.';

const bullets = {
  evaluationDiagnosisPrescription: `Evaluation, diagnosis, and prescription by a medical ${prescriberText}`,
  medicationDelivery: 'Monthly medication delivery (if prescribed)',
  weeklyVideoPhone,
  weeklyLiveTherapy: 'Weekly live therapy sessions using proven methods',
  regularPhoneVideo: `Regular video/phone visits with your dedicated ${prescriberText}`,
  ongoingSessionsCounselor: 'Ongoing sessions with your Care Counselor',
  ongoingSessionsProviderCounselor: `Ongoing sessions with your ${prescriberText} & Care Counselor`,
  textChat: 'Text chat securely anytime',
  evidenceBasedHealth: 'Evidence-based behavioral health counseling & regular progress checks',
  talkTherapy: 'Evidence-based talk therapy',
  unlimitedMessagingCareTeam: 'Unlimited messaging with your care team',
  unlimitedMessagingTherapist: 'Unlimited, direct messaging with your therapist and coordinator',
  psychotherapyEvaluations: 'Regular psychotherapy evaluations to make sure you’re meeting your mental health goals',
  assignedProvider: `An assigned ${prescriberText} and Care Counselor`,
  assignedCoordinator: 'Assigned care coordinator to help navigate the system & insurance issues',
  assignedCoordinatorQuestions: 'Assigned care coordinator to help with administrative and insurance questions',
  recordsAccess: `Your ${prescriberText}s have access to your records for personalized individual care`,
  bookAppointments: 'Book appointments with just a few clicks',
  collaborativeCare: 'Collaborative care for comprehensive mental health treatment',
  privateMessaging: 'Private messaging anytime with your care team between sessions',
  regularAssessments: `Regular assessments and progress tracking with your ${prescriberText}`,
  regularAssessmentsTherapist: 'Regular assessments and progress tracking with your therapist',
  regularAssessmentsProviderTherapist: `Regular assessments and progress tracking with your ${prescriberText} and therapist`,
  progressTrackingTherapist: 'Regular progress tracking by your therapist',
  progressTrackingProviderTherapist: `Regular progress tracking by your ${prescriberText} & therapist`,
  possibleBupropionPrescription:
    'Possible Bupropion prescription, which can help reduce cravings and withdrawal symptoms',
  nicotineReplacementTherapy: 'Nicotine replacement therapy gum can help reduce cravings and withdrawal symptoms',
  monthlyNrtDelivery: 'Monthly delivery, right to your door',
  nicotineReplaceGum: 'Nicotine replacement therapy gum',
  onGoingCareCoordination: 'Ongoing care coordination',
  nutritionRegular: 'Regular video/phone sessions with an assigned Nutritionist',
  nutritionEvidence: 'Evidence-based nutritional and cognitive behavioral therapy counseling',
  nutritionCareTeam: 'Regular progress checks & unlimited messaging with your care team',
  nutritionEvaluation: 'Evaluation, diagnosis, and presciption by a medical provider',
  nutritionDelivery: 'Monthly medication delivery (if prescribed)',
  unlimitedMessagingCareCounselor: 'Unlimited messaging with your care counselor',
  weeklyVideoPhoneCounselor: 'Weekly video/phone sessions with your care counselor',
  nutritionEvidenceUpsell: 'Evidence-based nutritional counseling & regular progress checks',
  nutritionChat: 'Chat securely with your Nutritionist anytime',
  medicationManagementEvaluation: 'Evaluation, diagnosis and prescription by a medical prescriber - ',
  medicationManagementUnlimited: 'Unlimited messaging with your medical prescriber',
  medicationManagementRegular: 'Regular behavioral health progress checks'
};

interface PlanPriceModeDetail {
  pricePerWeekTotal: number;
  discountedPricePerWeekTotal: number;
  pricePerWeek: string;
  discountedPricePerWeek: string;
  price: number;
  discountedPrice: number;
  pricePerMonth: string;
  discountedPricePerMonth: string;
  priceWeekPlusDiscount: number;
}

export const planPriceModeDetail = (price: PriceModel): PlanPriceModeDetail => ({
  pricePerWeekTotal: price.priceWeek,
  discountedPricePerWeekTotal: price.priceWeekDiscount,
  pricePerWeek: priceWeek(price.priceWeek),
  discountedPricePerWeek: priceWeekDiscount(price.priceWeekDiscount),
  price: price.priceMonth,
  discountedPrice: price.priceMonthDiscount,
  pricePerMonth: priceMonth(price.priceMonth),
  discountedPricePerMonth: priceMonthDiscount(price.priceMonthDiscount),
  priceWeekPlusDiscount: price.priceWeekDiscount + price.priceWeek
});

const medicationOnly: Plan = {
  id: 'medication',
  mostComprehensive: false,
  title: 'Medication &\nCare',
  name: 'Medication & Care',
  description: `Receive collaborative care by a Cerebral ${prescriberText} & Care.`,
  bullets: [
    bullets.evaluationDiagnosisPrescription,
    bullets.medicationDelivery,
    bullets.regularPhoneVideo,
    bullets.unlimitedMessagingCareTeam
  ],
  ...planPriceModeDetail(medicationPrices),
  subprice: defaultSubpriceMessage,
  cta: ctas.MEDS,
  upsell: {
    x2Effective: x2EffectiveTherapy,
    title: 'Add Therapy to improve \nyour care',
    flowTitle: 'Therapy',
    bullets: [bullets.weeklyLiveTherapy, bullets.privateMessaging, bullets.psychotherapyEvaluations],
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS_THERAPY
  }
};

const cashPlans: Record<PlanType, Plan> = {
  'medication-therapy': {
    id: 'medication-therapy',
    mostComprehensive: true,
    title: medicationTherapyPlan.title,
    name: medicationTherapyPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & licensed therapist.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.talkTherapy,
      bullets.weeklyVideoPhone,
      bullets.medicationDelivery,
      bullets.textChat,
      bullets.progressTrackingProviderTherapist
    ],
    ...planPriceModeDetail(medicationTherapyPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS_THERAPY
  },
  'medication-management-50': {
    id: 'medication-management-50',
    mostComprehensive: false,
    title: medicationManagementPlan.title,
    name: medicationManagementPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.medicationManagementEvaluation,
      bullets.medicationManagementUnlimited,
      bullets.medicationManagementRegular
    ],
    ...planPriceModeDetail(medicationManagementTXCAPlan),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS
  },
  'medication-management-85': {
    id: 'medication-management-85',
    mostComprehensive: false,
    title: medicationManagementPlan.title,
    name: medicationManagementPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.medicationManagementEvaluation,
      bullets.medicationManagementUnlimited,
      bullets.medicationManagementRegular
    ],
    ...planPriceModeDetail(medicationManagementNYFLPlan),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS
  },
  medication: {
    id: 'medication',
    mostComprehensive: false,
    title: medicationPlan.title,
    name: medicationPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.medicationDelivery,
      regularVideo,
      bullets.evidenceBasedHealth
    ],
    paymentBullets: [
      `A ${prescriberText} and Care Counselor`,
      bullets.medicationDelivery,
      bullets.unlimitedMessagingCareTeam,
      `Regular video/phone visits with your ${prescriberText} and Care Counselor`,
      bullets.regularAssessments
    ],
    ...planPriceModeDetail(medicationPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: x2EffectiveTherapy,
      title: 'Add Therapy to improve \nyour care',
      flowTitle: 'Therapy',
      bullets: [
        'Weekly one-on-one sessions with a psychotherapist custom matched to you',
        bullets.privateMessaging,
        bullets.psychotherapyEvaluations
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY
    }
  },
  therapy: {
    id: 'therapy',
    mostComprehensive: false,
    title: therapyPlan.title,
    name: therapyPlan.name,
    description: 'Have your licensed therapist right there in your pocket.',
    bullets: [bullets.weeklyVideoPhone, bullets.textChat, bullets.progressTrackingTherapist],
    paymentBullets: [
      bullets.weeklyVideoPhone,
      bullets.talkTherapy,
      bullets.textChat,
      bullets.progressTrackingTherapist
    ],
    ...planPriceModeDetail(therapyPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.THERAPY,
    upsell: {
      x2Effective: '2X as effective when combined with Medication Management.',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [
        'Ongoing clinical visits for diagnosis, evaluation, and prescription management',
        'Your monthly prescriptions delivered for free (if prescribed)',
        'Regular assessments, prescription adjustments, and progress checks with your care team'
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY
    }
  },
  counseling: {
    id: 'counseling',
    mostComprehensive: false,
    title: 'Care \nCounseling',
    name: 'Care Counseling',
    bullets: [bullets.weeklyVideoPhoneCounselor, bullets.unlimitedMessagingCareCounselor, bullets.evidenceBasedHealth],
    paymentBullets: [
      bullets.weeklyVideoPhoneCounselor,
      bullets.unlimitedMessagingCareCounselor,
      bullets.evidenceBasedHealth
    ],
    price: 0,
    discountedPrice: 0,
    subprice: '',
    cta: ctas.MEDS
  },
  'medication-only': medicationOnly,
  legacy: {
    id: 'medication',
    mostComprehensive: false,
    title: 'Cerebral Membership',
    name: 'Cerebral Membership',
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.assignedProvider,
      bullets.medicationDelivery,
      bullets.unlimitedMessagingCareTeam,
      bullets.ongoingSessionsProviderCounselor
    ],
    paymentBullets: [
      bullets.assignedProvider,
      bullets.medicationDelivery,
      bullets.unlimitedMessagingCareTeam,
      bullets.ongoingSessionsProviderCounselor
    ],
    price: 99,
    discountedPrice: 30,
    subprice: 'Billed monthly <br />Free Shipping<br />FSA / HSA eligible<br /> Cancel anytime'
  },
  nicotine: {
    id: 'nicotine',
    mostComprehensive: false,
    title: 'Nicotine Gum',
    name: 'Nicotine Gum',
    description: 'Receive collaborative care by a Cerebral prescriber & licensed therapist.',
    bullets: [bullets.nicotineReplacementTherapy, bullets.monthlyNrtDelivery, bullets.onGoingCareCoordination],
    ...planPriceModeDetail(medicationNicotinePrices),
    subprice: defaultSubpriceMessage,
    upsellPrice: 20,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: '',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [
        'Regular video sessions with an assigned care counselor',
        'Evidence-based health counseling & regular progress checks'
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.ADD_COUNSEL
    }
  },
  bupropion: {
    id: 'bupropion',
    mostComprehensive: false,
    title: 'Bupropion',
    name: 'Bupropion',
    description: 'Receive collaborative care by a Cerebral prescriber & licensed therapist.',
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.possibleBupropionPrescription,
      bullets.medicationDelivery,
      bullets.onGoingCareCoordination
    ],
    ...planPriceModeDetail(medicationBupropionPrices),
    subprice: defaultSubpriceMessage,
    upsellPrice: 20,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: '',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [
        'Regular video sessions with an assigned care counselor',
        'Evidence-based health counseling & regular progress checks'
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.ADD_COUNSEL
    }
  },
  'bupropion-nicotine': {
    id: 'bupropion-nicotine',
    mostComprehensive: true,
    title: 'Bupropion +\nNicotine Gum',
    name: 'Bupropion Nicotine',
    description: 'Receive collaborative care by a Cerebral prescriber & licensed therapist.',
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.possibleBupropionPrescription,
      bullets.nicotineReplaceGum,
      bullets.medicationDelivery,
      bullets.onGoingCareCoordination
    ],
    ...planPriceModeDetail(medicationBupropionNicotinePrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsellPrice: 20,
    upsell: {
      x2Effective: '',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [
        'Regular video sessions with an assigned care counselor',
        'Evidence-based health counseling & regular progress checks'
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.ADD_COUNSEL
    }
  },
  'nicotine-counseling': {
    id: 'nicotine-counseling',
    mostComprehensive: false,
    title: 'Nicotine Counseling',
    name: 'Nicotine Counseling',
    description: 'Receive collaborative care by a Cerebral prescriber & licensed therapist.',
    bullets: [bullets.nicotineReplacementTherapy, bullets.monthlyNrtDelivery, bullets.onGoingCareCoordination],
    ...planPriceModeDetail(medicationTherapyPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: '',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [
        'Regular video sessions with an assigned care counselor',
        'Evidence-based health counseling & regular progress checks'
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.ADD_COUNSEL
    }
  },
  'bupropion-counseling': {
    id: 'bupropion-counseling',
    mostComprehensive: false,
    title: 'Bupropion Counseling',
    name: 'Bupropion Counseling',
    description: 'Receive collaborative care by a Cerebral prescriber & licensed therapist.',
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.possibleBupropionPrescription,
      bullets.medicationDelivery,
      bullets.onGoingCareCoordination
    ],
    ...planPriceModeDetail(medicationTherapyPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: '',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [
        'Regular video sessions with an assigned care counselor',
        'Evidence-based health counseling & regular progress checks'
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.ADD_COUNSEL
    }
  },
  'bupropion-nicotine-counseling': {
    id: 'bupropion-nicotine-counseling',
    mostComprehensive: false,
    title: 'Bupropion Nicotine Counseling',
    name: 'Bupropion Nicotine Counseling',
    description: 'Receive collaborative care by a Cerebral prescriber & licensed therapist.',
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.possibleBupropionPrescription,
      bullets.nicotineReplaceGum,
      bullets.medicationDelivery,
      bullets.onGoingCareCoordination
    ],
    ...planPriceModeDetail(medicationTherapyPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: '',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [
        'Regular video sessions with an assigned care counselor',
        'Evidence-based health counseling & regular progress checks'
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.ADD_COUNSEL
    }
  },
  'nutrition-management': {
    id: 'nutrition-management',
    mostComprehensive: false,
    title: 'Nutrition \nManagement',
    name: 'Nutrition Management',
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care.`,
    bullets: [bullets.nutritionRegular, bullets.nutritionEvidence, bullets.nutritionCareTeam],
    upsellPrice: locale.nutritionManagementUpsellPriceDefault,
    price: locale.nutritionManagementPriceDefault,
    pricePerWeek: priceWeek(nutritionPrices.priceWeek),
    pricePerMonth: priceMonth(nutritionPrices.priceMonth),
    pricePerWeekTotal: nutritionPrices.priceWeek,
    discountedPrice: nutritionPrices.priceMonthDiscount,
    discountedPricePerWeek: priceWeekDiscount(nutritionPrices.priceWeekDiscount),
    discountedPricePerMonth: priceMonthDiscount(nutritionPrices.priceMonthDiscount),
    discountedPricePerWeekTotal: nutritionPrices.priceWeekDiscount,
    subprice: 'Billed monthly <br />FSA / HSA eligible<br /> Cancel anytime',
    cta: ctas.MEDS,
    upsell: {
      x2Effective: '',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [
        'Weekly video/phone sessions with a licensed therapist',
        'Chat securely with your therapist anytime',
        'Regular progress tracking by your therapist'
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.ADD_THERAPY
    }
  },
  'nutrition-management-therapy': {
    id: 'nutrition-management-therapy',
    mostComprehensive: false,
    title: 'Nutrition \nManagement',
    name: 'Nutrition Management',
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care.`,
    bullets: [bullets.nutritionRegular, bullets.nutritionEvidence, bullets.nutritionCareTeam],
    upsellPrice: locale.nutritionManagementUpsellPriceDefault,
    price: locale.nutritionManagementPriceDefault,
    pricePerWeek: priceWeek(nutritionPrices.priceWeek),
    pricePerMonth: priceMonth(nutritionPrices.priceMonth),
    pricePerWeekTotal: nutritionPrices.priceWeek,
    discountedPrice: nutritionPrices.priceMonthDiscount,
    discountedPricePerWeek: priceWeekDiscount(nutritionPrices.priceWeekDiscount),
    discountedPricePerMonth: priceMonthDiscount(nutritionPrices.priceMonthDiscount),
    discountedPricePerWeekTotal: nutritionPrices.priceWeekDiscount,
    subprice: 'Billed monthly <br />FSA / HSA eligible<br /> Cancel anytime',
    cta: ctas.MEDS,
    upsell: {
      x2Effective: '',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [
        'Weekly video/phone sessions with a licensed therapist',
        'Chat securely with your therapist anytime',
        'Regular progress tracking by your therapist'
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.ADD_THERAPY
    }
  },
  'nutrition-medication-management': {
    id: 'nutrition-medication-management',
    mostComprehensive: false,
    title: 'Nutrition + \nMedication Management',
    name: 'Nutrition + Medication Management',
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care.`,
    bullets: [
      bullets.nutritionEvaluation,
      bullets.nutritionDelivery,
      bullets.nutritionRegular,
      bullets.nutritionEvidence,
      bullets.nutritionCareTeam
    ],
    upsellPrice: locale.nutritionMedicationUpsellPriceDefault,
    price: locale.nutritionMedicationPriceDefault,
    pricePerWeek: priceWeek(nutritionMedicationPrices.priceWeek),
    pricePerMonth: priceMonth(nutritionMedicationPrices.priceMonth),
    pricePerWeekTotal: nutritionMedicationPrices.priceWeek,
    discountedPrice: nutritionMedicationPrices.priceMonthDiscount,
    discountedPricePerWeek: priceWeekDiscount(nutritionMedicationPrices.priceWeekDiscount),
    discountedPricePerMonth: priceMonthDiscount(nutritionMedicationPrices.priceMonthDiscount),
    discountedPricePerWeekTotal: nutritionMedicationPrices.priceWeekDiscount,
    subprice: 'Billed monthly <br />FSA / HSA eligible<br /> Cancel anytime',
    cta: ctas.MEDS,
    upsell: {
      x2Effective: '',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [
        'Weekly video/phone sessions with a licensed therapist',
        'Chat securely with your therapist anytime',
        'Regular progress tracking by your therapist'
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.ADD_THERAPY
    }
  },
  'nutrition-medication-management-therapy': {
    id: 'nutrition-medication-management-therapy',
    mostComprehensive: false,
    title: 'Nutrition + \nMedication Management',
    name: 'Nutrition + Medication Management',
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care.`,
    bullets: [
      bullets.nutritionEvaluation,
      bullets.nutritionDelivery,
      bullets.nutritionRegular,
      bullets.nutritionEvidence,
      bullets.nutritionCareTeam
    ],
    upsellPrice: locale.nutritionMedicationUpsellPriceDefault,
    price: locale.nutritionMedicationPriceDefault,
    pricePerWeek: priceWeek(nutritionMedicationPrices.priceWeek),
    pricePerMonth: priceMonth(nutritionMedicationPrices.priceMonth),
    pricePerWeekTotal: nutritionMedicationPrices.priceWeek,
    discountedPrice: nutritionMedicationPrices.priceMonthDiscount,
    discountedPricePerWeek: priceWeekDiscount(nutritionMedicationPrices.priceWeekDiscount),
    discountedPricePerMonth: priceMonthDiscount(nutritionMedicationPrices.priceMonthDiscount),
    discountedPricePerWeekTotal: nutritionMedicationPrices.priceWeekDiscount,
    subprice: 'Billed monthly <br />FSA / HSA eligible<br /> Cancel anytime',
    cta: ctas.MEDS,
    upsell: {
      x2Effective: '',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [
        'Weekly video/phone sessions with a licensed therapist',
        'Chat securely with your therapist anytime',
        'Regular progress tracking by your therapist'
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.ADD_THERAPY
    }
  },
  'medication-smoking-cessation-treatment': {
    id: 'medication-smoking-cessation-treatment',
    mostComprehensive: false,
    title: 'Would you like to add smoking cessation to your plan?',
    name: 'Add Smoking Cessation \nTreatment',
    description:
      'Smoking is known to contribute to conditions like the ones you’re experiencing. We can help you quit.',
    bullets: [bullets.nicotineReplacementTherapy, bullets.monthlyNrtDelivery],
    ...planPriceModeDetail(medicationPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: '',
      title: 'Add Smoking Cessation \nTreatment',
      flowTitle: 'Add Smoking Cessation \nTreatment',
      bullets: [bullets.nicotineReplacementTherapy, bullets.monthlyNrtDelivery],
      subprice: defaultSubpriceMessage,
      cta: ctas.ADD_SMOKING_CESSATION
    }
  },
  'medication-therapy-smoking-cessation-treatment': {
    id: 'medication-therapy-smoking-cessation-treatment',
    mostComprehensive: false,
    title: 'Would you like to add smoking cessation to your plan?',
    name: 'Add Smoking Cessation \nTreatment',
    description:
      'Smoking is known to contribute to conditions like the ones you’re experiencing. We can help you quit.',
    bullets: [bullets.nicotineReplacementTherapy, bullets.monthlyNrtDelivery],
    ...planPriceModeDetail(medicationTherapyPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS_THERAPY,
    upsell: {
      x2Effective: '',
      title: 'Add Smoking Cessation \nTreatment',
      flowTitle: 'Add Smoking Cessation \nTreatment',
      bullets: [bullets.nicotineReplacementTherapy, bullets.monthlyNrtDelivery],
      subprice: defaultSubpriceMessage,
      cta: ctas.ADD_SMOKING_CESSATION
    }
  },
  'medication-therapy-nutrition-management': {
    id: 'medication-therapy-nutrition-management',
    mostComprehensive: false,
    title: 'Would you like to upgrade your plan to include Nutrition Management?',
    name: 'Nutrition Management',
    description: '',
    bullets: [bullets.nutritionRegular, bullets.nutritionEvidenceUpsell, bullets.nutritionChat],
    ...planPriceModeDetail(medicationTherapyPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS_THERAPY,
    upsell: {
      x2Effective: '',
      title: 'Nutrition Management',
      flowTitle: '',
      bullets: [bullets.nutritionRegular, bullets.nutritionEvidenceUpsell, bullets.nutritionChat],
      subprice: defaultSubpriceMessage,
      cta: ctas.CHOOSE_PLAN
    }
  },
  'medication-counseling-nutrition-management': {
    id: 'medication-counseling-nutrition-management',
    mostComprehensive: false,
    title: 'Would you like to upgrade your plan to include Nutrition Management?',
    name: 'Nutrition Management',
    description: '',
    bullets: [bullets.nutritionRegular, bullets.nutritionEvidenceUpsell, bullets.nutritionChat],
    ...planPriceModeDetail(medicationTherapyPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS_THERAPY,
    upsell: {
      x2Effective: '',
      title: 'Nutrition Management',
      flowTitle: '',
      bullets: [bullets.nutritionRegular, bullets.nutritionEvidenceUpsell, bullets.nutritionChat],
      subprice: defaultSubpriceMessage,
      cta: ctas.CHOOSE_PLAN
    }
  },
  'medication-adhd-115-management': {
    id: 'medication-adhd-115-management',
    mostComprehensive: false,
    title: medicationPlan.title,
    name: medicationPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.medicationDelivery,
      regularVideo,
      bullets.evidenceBasedHealth
    ],
    paymentBullets: [
      `A ${prescriberText} and Care Counselor`,
      bullets.medicationDelivery,
      bullets.unlimitedMessagingCareTeam,
      `Regular video/phone visits with your ${prescriberText} and Care Counselor`,
      bullets.regularAssessments
    ],
    ...planPriceModeDetail(createPricing(115, 41)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: x2EffectiveTherapy,
      title: 'Add Therapy to improve \nyour care',
      flowTitle: 'Therapy',
      bullets: [
        'Weekly one-on-one sessions with a psychotherapist custom matched to you',
        bullets.privateMessaging,
        bullets.psychotherapyEvaluations
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY
    }
  },
  'medication-adhd-135-management': {
    id: 'medication-adhd-135-management',
    mostComprehensive: false,
    title: medicationPlan.title,
    name: medicationPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.medicationDelivery,
      regularVideo,
      bullets.evidenceBasedHealth
    ],
    paymentBullets: [
      `A ${prescriberText} and Care Counselor`,
      bullets.medicationDelivery,
      bullets.unlimitedMessagingCareTeam,
      `Regular video/phone visits with your ${prescriberText} and Care Counselor`,
      bullets.regularAssessments
    ],
    ...planPriceModeDetail(createPricing(135, 48)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: x2EffectiveTherapy,
      title: 'Add Therapy to improve \nyour care',
      flowTitle: 'Therapy',
      bullets: [
        'Weekly one-on-one sessions with a psychotherapist custom matched to you',
        bullets.privateMessaging,
        bullets.psychotherapyEvaluations
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY
    }
  },
  'therapy-nutrition-management': {
    id: 'therapy-nutrition-management',
    mostComprehensive: false,
    title: 'Would you like to upgrade your plan to include Nutrition Management?',
    name: 'Therapy + Nutrition',
    description: '',
    bullets: [bullets.nutritionRegular, bullets.nutritionEvidenceUpsell, bullets.nutritionChat],
    ...planPriceModeDetail(therapyPrices),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS_THERAPY,
    upsell: {
      x2Effective: '',
      title: 'Nutrition Management',
      flowTitle: '',
      bullets: [bullets.nutritionRegular, bullets.nutritionEvidenceUpsell, bullets.nutritionChat],
      subprice: defaultSubpriceMessage,
      cta: ctas.CHOOSE_PLAN
    }
  },
  'nutrition-medication-management-glp1': {
    id: 'nutrition-medication-management-glp1',
    mostComprehensive: false,
    title: 'Nutrition + \nMedication Management',
    name: 'Nutrition + Medication Management',
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care.`,
    bullets: [
      bullets.nutritionEvaluation,
      bullets.nutritionDelivery,
      bullets.nutritionRegular,
      bullets.nutritionEvidence,
      bullets.nutritionCareTeam
    ],
    ...planPriceModeDetail(nutritionMedicationGlp1Prices),
    upsellPrice: locale.nutritionMedicationGlp1UpsellPriceDefault,
    subprice: 'Billed monthly <br />FSA / HSA eligible<br /> Cancel anytime',
    cta: ctas.MEDS,
    upsell: {
      x2Effective: '',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [
        'Weekly video/phone sessions with a licensed therapist',
        'Chat securely with your therapist anytime',
        'Regular progress tracking by your therapist'
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.ADD_THERAPY
    }
  },
  'nutrition-medication-management-glp1-therapy': {
    id: 'nutrition-medication-management-glp1-therapy',
    mostComprehensive: false,
    title: 'Nutrition + \nMedication Management',
    name: 'Nutrition + Medication + Therapy',
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care.`,
    bullets: [
      bullets.nutritionEvaluation,
      bullets.nutritionDelivery,
      bullets.nutritionRegular,
      bullets.nutritionEvidence,
      bullets.nutritionCareTeam
    ],
    upsellPrice: locale.nutritionMedicationUpsellPriceDefault,
    ...planPriceModeDetail(nutritionMedicationGlp1TherapyPrices),
    subprice: 'Billed monthly <br />FSA / HSA eligible<br /> Cancel anytime',
    cta: ctas.MEDS,
    upsell: {
      x2Effective: '',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [
        'Weekly video/phone sessions with a licensed therapist',
        'Chat securely with your therapist anytime',
        'Regular progress tracking by your therapist'
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.ADD_THERAPY
    }
  },
  '99_med_management': {
    id: '99_med_management',
    mostComprehensive: false,
    title: medicationPlan.title,
    name: medicationPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.medicationDelivery,
      regularVideo,
      bullets.evidenceBasedHealth
    ],
    paymentBullets: [
      `A ${prescriberText} and Care Counselor`,
      bullets.medicationDelivery,
      bullets.unlimitedMessagingCareTeam,
      `Regular video/phone visits with your ${prescriberText} and Care Counselor`,
      bullets.regularAssessments
    ],
    ...planPriceModeDetail(createPricing(99, 30)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: x2EffectiveTherapy,
      title: 'Add Therapy to improve \nyour care',
      flowTitle: 'Therapy',
      bullets: [
        'Weekly one-on-one sessions with a psychotherapist custom matched to you',
        bullets.privateMessaging,
        bullets.psychotherapyEvaluations
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY
    }
  },
  '109_med_management': {
    id: '109_med_management',
    mostComprehensive: false,
    title: medicationPlan.title,
    name: medicationPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.medicationDelivery,
      regularVideo,
      bullets.evidenceBasedHealth
    ],
    paymentBullets: [
      `A ${prescriberText} and Care Counselor`,
      bullets.medicationDelivery,
      bullets.unlimitedMessagingCareTeam,
      `Regular video/phone visits with your ${prescriberText} and Care Counselor`,
      bullets.regularAssessments
    ],
    ...planPriceModeDetail(createPricing(109, 30)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: x2EffectiveTherapy,
      title: 'Add Therapy to improve \nyour care',
      flowTitle: 'Therapy',
      bullets: [
        'Weekly one-on-one sessions with a psychotherapist custom matched to you',
        bullets.privateMessaging,
        bullets.psychotherapyEvaluations
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY
    }
  },
  '345_meds_therapy': {
    id: '345_meds_therapy',
    mostComprehensive: true,
    title: medicationTherapyPlan.title,
    name: medicationTherapyPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & licensed therapist.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.talkTherapy,
      bullets.weeklyVideoPhone,
      bullets.medicationDelivery,
      bullets.textChat,
      bullets.progressTrackingProviderTherapist
    ],
    ...planPriceModeDetail(createPricing(345, 139)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS_THERAPY
  },
  '275_therapy': {
    id: '275_therapy',
    mostComprehensive: false,
    title: therapyPlan.title,
    name: therapyPlan.name,
    description: 'Have your licensed therapist right there in your pocket.',
    bullets: [bullets.weeklyVideoPhone, bullets.textChat, bullets.progressTrackingTherapist],
    paymentBullets: [
      bullets.weeklyVideoPhone,
      bullets.talkTherapy,
      bullets.textChat,
      bullets.progressTrackingTherapist
    ],
    ...planPriceModeDetail(createPricing(275, 99)),
    subprice: defaultSubpriceMessage,
    cta: ctas.THERAPY,
    upsell: {
      x2Effective: '2X as effective when combined with Medication Management.',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [
        'Ongoing clinical visits for diagnosis, evaluation, and prescription management',
        'Your monthly prescriptions delivered for free (if prescribed)',
        'Regular assessments, prescription adjustments, and progress checks with your care team'
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY
    }
  },
  '119_med_management': {
    id: '119_med_management',
    mostComprehensive: false,
    title: medicationPlan.title,
    name: medicationPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.medicationDelivery,
      regularVideo,
      bullets.evidenceBasedHealth
    ],
    paymentBullets: [
      `A ${prescriberText} and Care Counselor`,
      bullets.medicationDelivery,
      bullets.unlimitedMessagingCareTeam,
      `Regular video/phone visits with your ${prescriberText} and Care Counselor`,
      bullets.regularAssessments
    ],
    ...planPriceModeDetail(createPricing(119, 30)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS,
    upsell: {
      x2Effective: x2EffectiveTherapy,
      title: 'Add Therapy to improve \nyour care',
      flowTitle: 'Therapy',
      bullets: [
        'Weekly one-on-one sessions with a psychotherapist custom matched to you',
        bullets.privateMessaging,
        bullets.psychotherapyEvaluations
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY
    }
  },
  '365_meds_therapy': {
    id: '365_meds_therapy',
    mostComprehensive: true,
    title: medicationTherapyPlan.title,
    name: medicationTherapyPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & licensed therapist.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.talkTherapy,
      bullets.weeklyVideoPhone,
      bullets.medicationDelivery,
      bullets.textChat,
      bullets.progressTrackingProviderTherapist
    ],
    ...planPriceModeDetail(createPricing(365, 139)),
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS_THERAPY
  },
  '290_therapy': {
    id: '290_therapy',
    mostComprehensive: false,
    title: therapyPlan.title,
    name: therapyPlan.name,
    description: 'Have your licensed therapist right there in your pocket.',
    bullets: [bullets.weeklyVideoPhone, bullets.textChat, bullets.progressTrackingTherapist],
    paymentBullets: [
      bullets.weeklyVideoPhone,
      bullets.talkTherapy,
      bullets.textChat,
      bullets.progressTrackingTherapist
    ],
    ...planPriceModeDetail(createPricing(290, 99)),
    subprice: defaultSubpriceMessage,
    cta: ctas.THERAPY,
    upsell: {
      x2Effective: '2X as effective when combined with Medication Management.',
      title: 'Add Medication \nManagement to improve care',
      flowTitle: 'Medication Management',
      bullets: [
        'Ongoing clinical visits for diagnosis, evaluation, and prescription management',
        'Your monthly prescriptions delivered for free (if prescribed)',
        'Regular assessments, prescription adjustments, and progress checks with your care team'
      ],
      subprice: defaultSubpriceMessage,
      cta: ctas.MEDS_THERAPY
    }
  }
};

const insurancePlans: Partial<Record<PlanType, Plan>> = {
  'medication-therapy': {
    id: 'medication-therapy',
    mostComprehensive: true,
    title: medicationTherapyPlan.title,
    name: medicationTherapyPlan.name,
    description: `Receive collaborative care by a Cerebral ${prescriberText} & licensed therapist.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.weeklyVideoPhone,
      bullets.medicationDelivery,
      bullets.textChat,
      bullets.progressTrackingProviderTherapist
    ],
    paymentBullets: [
      bullets.regularPhoneVideo,
      bullets.medicationDelivery,
      bullets.unlimitedMessagingCareTeam,
      bullets.regularAssessmentsProviderTherapist,
      bullets.talkTherapy,
      bullets.assignedCoordinatorQuestions
    ],
    price: membershipFee,
    pricePerWeek: priceWeek(8),
    pricePerMonth: priceMonth(membershipFee),
    pricePerWeekTotal: 7.25,
    discountedPrice: 15,
    discountedPricePerWeek: priceWeekDiscount(4),
    discountedPricePerMonth: priceMonthDiscount(15),
    discountedPricePerWeekTotal: 3.75,
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS_THERAPY
  },
  medication: {
    id: 'medication',
    mostComprehensive: false,
    title: 'Medication &\nCare',
    name: 'Medication & Care',
    description: `Receive collaborative care by a Cerebral ${prescriberText} & Care Counselor.`,
    bullets: [
      bullets.evaluationDiagnosisPrescription,
      bullets.medicationDelivery,
      'Regular video/phone sessions with an assigned care counselor',
      bullets.evidenceBasedHealth
    ],
    paymentBullets: [
      `Regular video/phone visits with your dedicated ${prescriberText}`,
      bullets.medicationDelivery,
      bullets.unlimitedMessagingCareTeam,
      bullets.regularAssessments,
      bullets.assignedCoordinator
    ],
    price: membershipFee,
    pricePerWeek: priceWeek(8),
    pricePerMonth: priceMonth(membershipFee),
    pricePerWeekTotal: 7.25,
    discountedPrice: 15,
    discountedPricePerWeek: priceWeekDiscount(4),
    discountedPricePerMonth: priceMonthDiscount(15),
    discountedPricePerWeekTotal: 3.75,
    subprice: defaultSubpriceMessage,
    cta: ctas.MEDS
  },
  therapy: {
    id: 'therapy',
    mostComprehensive: false,
    title: therapyPlan.title,
    name: therapyPlan.name,
    description: 'Have your licensed therapist right there in your pocket.',
    bullets: [bullets.weeklyVideoPhone, bullets.textChat, bullets.progressTrackingTherapist],
    paymentBullets: [
      bullets.weeklyVideoPhone,
      bullets.unlimitedMessagingTherapist,
      bullets.regularAssessmentsTherapist,
      bullets.talkTherapy,
      bullets.assignedCoordinator
    ],
    price: membershipFee,
    pricePerWeek: priceWeek(8),
    pricePerMonth: priceMonth(membershipFee),
    pricePerWeekTotal: 7.25,
    discountedPrice: 15,
    discountedPricePerWeek: priceWeekDiscount(4),
    discountedPricePerMonth: priceMonthDiscount(15),
    discountedPricePerWeekTotal: 3.75,
    subprice: defaultSubpriceMessage,
    cta: ctas.THERAPY
  },
  'medication-only': medicationOnly
};

export const isAsyncSmoking = (offeringKey: string): boolean => {
  return [
    'nicotine',
    'bupropion',
    'bupropion-nicotine',
    'nicotine-counseling',
    'bupropion-counseling',
    'bupropion-nicotine-counseling'
  ].includes(offeringKey);
};

export const isNotSupportInsurancePayment = (offeringKey: string): boolean => {
  return [
    'nicotine',
    'bupropion',
    'bupropion-nicotine',
    'nicotine-counseling',
    'bupropion-counseling',
    'bupropion-nicotine-counseling',
    'nutrition-management',
    'nutrition-medication-management',
    'nutrition-management-therapy',
    'nutrition-medication-management-therapy',
    'nutrition-medication-management-glp1',
    'nutrition-medication-management-glp1-therapy',
    'medication-management-85',
    'medication-management-50'
  ].includes(offeringKey);
};

export const isMentalHealthPlanOnly = (offeringKey: string): boolean => {
  return ['medication', 'medication-therapy', 'therapy'].includes(offeringKey);
};

export const upSellPlans = {
  nutrition: {
    x2Effective: '',
    title: 'Nutrition Management',
    flowTitle: '',
    bullets: [bullets.nutritionRegular, bullets.nutritionEvidenceUpsell, bullets.nutritionChat],
    subprice: defaultSubpriceMessage,
    cta: ctas.CHOOSE_PLAN,
    price: nutritionPrices.priceMonth,
    discountedPrice: nutritionPrices.priceMonthDiscount
  }
};

export default {
  membershipFee,
  cashPlans,
  insurancePlans,
  upSellPlans
};
