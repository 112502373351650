import styled from 'styled-components/macro';
import { Flexbox } from '@cerebral-inc/ui';

export const HelpCenterSection = styled(Flexbox)`
  flex-grow: 1;
  > div {
    background-color: #fafffd;
    padding: 10px 0px;
  }
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.4px;
    color: #250044;
    margin-bottom: 5px;
  }
  button {
    margin-top: 15px;
    height: 40px;
    width: 180px;
  }
`;

export const LeftMenuWrapper = styled(Flexbox)`
  overflow-x: hidden;
  overflow-y: auto;
`;
