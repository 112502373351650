import React from 'react';
import { useSelector } from 'react-redux';
import tracking from 'utils/tracking';
import { Store } from 'types/store';

interface ABTestProps {
  id: string;
  map: {
    [value: string]: React.ComponentElement<unknown, never>;
    no_value: React.ComponentElement<unknown, never>;
  };
}

const ABTest: React.FC<ABTestProps> = ({ id, map }) => {
  const test = useSelector((store: Store) => store.patient_reducer.abTest[id] || 'no_value');
  const Component = map[test] || map.no_value;

  if (!map[test] && process.env.REACT_APP_NODE_ENV !== 'development') {
    tracking.error({ message: `Missing AB test component for test [${id}] and variation [${test}]` });
  }

  return Component;
};

export default ABTest;
