import React from 'react';
import { useSelector } from 'react-redux';
import BackButton from '../../BackButton';
import { ReactComponent as ChangePlanImage } from '../assets/change_plan.svg';
import { Title, Description, List, ListItem, Button, ButtonsHolder, ChangePlanImageHolder, Wrapper } from './styled';
import plans from '../../../utils/plans';
import strings from '../../../localization';
import { useGlobals } from '../../../contexts/globals';
import { convertIdToPlanName, nutritionPlanOrderedList } from '../../../utils/common';

const formatAmount = num => {
  if (num.isNaN && !num) return '99';
  return num % 1 > 0 ? num.toFixed(2) : num.toString();
};

const getDirectMessagingPilot = ({ patient_reducer: { direct_messaging_pilot } }) => direct_messaging_pilot;

const CerebralMembership = ({ currentPlan, updateView, subscription, onPlanChange }) => {
  const { isTherapistRegion } = useGlobals();
  const isAllowedToChoosePlan = ({
    global_reducer: {
      current_user: {
        attributes: {
          patient: { region }
        }
      }
    }
  }) => {
    return isTherapistRegion(region);
  };
  const b2bClient = useSelector(({ patient_reducer }) => patient_reducer?.accountId);
  const allowedChoosePlan = useSelector(isAllowedToChoosePlan) || b2bClient;
  const directMessagingPilot = useSelector(getDirectMessagingPilot);
  const { is_insurance, offering_key } = currentPlan;
  let wording = `Your next payment is for ${strings.currencySymbol}${formatAmount(subscription.amount)} on ${
    subscription.next_charge
  }`;

  const getPlan = () => {
    if (nutritionPlanOrderedList.includes(offering_key)) {
      return plans.cashPlans[offering_key];
    }
    if (is_insurance && !directMessagingPilot) {
      return plans.insurancePlans[offering_key];
    }
    if (directMessagingPilot) {
      return plans.cashPlans['medication-only'];
    }
    if (allowedChoosePlan) {
      return plans.cashPlans[offering_key];
    }
    return plans.cashPlans.legacy;
  };

  const plan = getPlan();
  const bullets = allowedChoosePlan && plan.paymentBullets ? plan.paymentBullets : plan.bullets;

  if (subscription.status === 'scheduled for cancelation') {
    wording = `Scheduled to cancel on ${subscription.next_charge}`;
  }

  return (
    <Wrapper>
      <BackButton onClick={() => updateView('profile')}>&larr; Back to my account</BackButton>
      <div className="text-main-title">My Membership Plan</div>
      <div className="d-flex flex-column patient-payment subscription-info">
        <div className="d-flex flex-column payment-info">
          <div className="d-flex flex-column payment-info-item">
            <Title main>{`${
              directMessagingPilot ? 'Medication & Care Plan' : convertIdToPlanName(plan.id, plan.name)
            }:`}</Title>
            <List>
              {bullets.map(listItem => (
                <ListItem key={listItem} dangerouslySetInnerHTML={{ __html: listItem }} />
              ))}
            </List>
          </div>
          <Title>Billing cycle:</Title>
          <Description>Monthly</Description>
          <Title>Next subscription renewal:</Title>
          <Description>{wording}</Description>

          {!directMessagingPilot &&
            !is_insurance &&
            allowedChoosePlan &&
            offering_key !== 'counseling' &&
            !nutritionPlanOrderedList.includes(offering_key) && (
              <ButtonsHolder>
                <ChangePlanImageHolder>
                  <ChangePlanImage />
                </ChangePlanImageHolder>
                <Button onClick={() => onPlanChange()}>Change my plan</Button>
              </ButtonsHolder>
            )}
        </div>
      </div>
    </Wrapper>
  );
};

export default CerebralMembership;
