import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { formatDate, medicationName, formatCurrency } from 'utils/common';
import { PrescriptionResponse as PrescriptionInterface } from '@cerebral-inc/api';
import {
  PrescriptionWrapper,
  StatusIndicator,
  StatusText,
  StatusHolder,
  MedicationName,
  Refill,
  Renewal,
  ShippingInfomation
} from './styled';
import Pharmacy from './Pharmacy';

interface Props {
  prescription: PrescriptionInterface;
  isUS: boolean;
}

const Prescription: React.FC<Props> = ({ prescription, isUS = true }) => {
  const consentNeeded = ['consent_insurance', 'consent_cash'].includes(prescription.order_status);
  const isPending = [
    'pending',
    'success',
    'order_failed',
    'order_error',
    'truepill_order_error',
    'processed',
    'insurance_consented',
    'cash_consented'
  ].includes(prescription.order_status);
  const isExpired = ['expired', 'canceled'].includes(prescription.order_status);
  const isInTransit = ['in_transit', 'shipment_failed'].includes(prescription.order_status);

  const pendingInsurance =
    prescription.order_status === 'insurance_consented' || prescription.insurance_status === 'pending';

  const { carrier } = prescription;
  const shippingNumber = prescription.shipping_number;

  return (
    <PrescriptionWrapper>
      <h2 className="text-main-title">{prescription.medication_name}</h2>
      <StatusHolder>
        <StatusIndicator isActive consentNeeded={consentNeeded} />
        <StatusText>
          {isPending && (
            <>
              {pendingInsurance && !prescription.copay_amount ? (
                <span>Processing insurance coverage</span>
              ) : (
                <>
                  <span>Prescription approved{isUS && ':'}</span>
                  <br />
                  {isUS && (prescription.is_local_pharmacy ? 'Sent to local pharmacy' : 'Tracking information pending')}
                </>
              )}
            </>
          )}
          {isInTransit && (
            <>
              <span>Prescription filled{isUS && ':'}</span>
              <br />
              {isUS &&
                (prescription.tracking_url?.length ? (
                  <>
                    <p>Your package is in transit</p>
                    <a href={prescription.tracking_url} target="_blank" rel="noopener noreferrer">
                      Track your package here
                    </a>
                  </>
                ) : (
                  'Your package is in transit'
                ))}
            </>
          )}
          {consentNeeded && (
            <>
              <span>Prescription pending{isUS && ':'}</span>
              <br />
              {isUS &&
                (prescription.order_status === 'consent_insurance'
                  ? 'Consent needed to place order'
                  : 'Not covered by your insurance')}
            </>
          )}
          {prescription.order_status === 'delivered' && (
            <>
              <span>Prescription filled{isUS && ':'}</span>
              <br />
              {isUS && 'Delivered'}
            </>
          )}
          {prescription.status === 'canceled' && <span>Prescription canceled</span>}
          {prescription.status === 'expired' && (
            <>
              <span>Prescription expired{isUS && ':'}</span>
              <br />
              {isUS && 'Delivered'}
            </>
          )}
        </StatusText>
      </StatusHolder>

      {isUS && !isExpired && !prescription.is_local_pharmacy && (
        <>
          {prescription.copay_amount && (
            <StatusText spaced>
              <span>Co-pay amount: </span>
              {formatCurrency(prescription.copay_amount)}
            </StatusText>
          )}

          {prescription.order_status !== 'consent_insurance' && !pendingInsurance && !prescription.copay_amount && (
            <StatusText spaced>
              <span>Prescription price: </span>
              {formatCurrency(prescription.cash_amount)}
            </StatusText>
          )}

          {!prescription.copay_amount && pendingInsurance && (
            <>
              <StatusText spaced>
                <span>Co-pay amount: </span>
                Pending
              </StatusText>
              <StatusText spaced>
                <span>Cash price if not covered: </span>
                {formatCurrency(prescription.cash_amount)}
              </StatusText>
            </>
          )}
        </>
      )}

      {prescription.is_local_pharmacy && !isEmpty(prescription.pharmacy) && prescription.pharmacy && (
        <Pharmacy {...prescription.pharmacy} />
      )}
      <MedicationName>{`${medicationName(prescription)} ${
        prescription.dosage_readable ? `(${prescription.dosage_readable})` : ''
      }`}</MedicationName>
      <Refill>{`Refills: ${prescription.refills}`}</Refill>
      <Renewal>
        Prescription renewal required by:
        <br />
        {`${formatDate(prescription.current_treatment_expiration_date)}`}
      </Renewal>
      {shippingNumber && carrier && (
        <ShippingInfomation>
          Shipping number ({carrier}): {shippingNumber}
        </ShippingInfomation>
      )}
    </PrescriptionWrapper>
  );
};

export default Prescription;
