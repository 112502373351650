import styled, { css } from 'styled-components/macro';

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  top: ${props => (props.mobile && props.dashboard ? '49px' : '0px')};
  bottom: 0;
  height: ${props => (props.mobile ? 'auto' : '100vh')};
  position: ${props => (props.mobile ? 'fixed' : 'static')};
  z-index: ${props => (props.mobile ? '10' : 'auto')};
`;

export const HeaderHolder = styled.div`
  min-height: 50px;
  position: relative;
  background: linear-gradient(91.71deg, #d3eaf6 3.79%, #c0d4f0 96.77%);
`;

export const CloseMessenger = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  width: 20px;
  height: 20px;
  &:before,
  &:after {
    display: block;
    content: '';
    border-bottom: 1px solid #fff;
    width: 15px;
    height: 15px;
    position: absolute;
    top: -2px;
    left: 0;
  }
  &:before {
    transform: rotate(45deg);
    left: 6px;
  }
  &:after {
    transform: rotate(-45deg);
    left: -4px;
  }
`;

export const ContinueButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  background: #6786ff !important;
  color: #ffffff !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 28.5px;
  border: none;
  width: 122px;
  height: 30px;
  padding: 5px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.314286px;
  span {
    margin-left: 8px;
  }
  @media (max-width: 991px) {
    height: 25px;
    font-size: 12px;
    width: 107px;
    right: 10px;
  }
`;

export const Name = styled.span`
  text-decoration: underline;

  ${props =>
    props.isCareTeam &&
    css`
      margin-left: 8em;
      font-size: 10px;
    `}

  ${props =>
    props.isProviderMessenger &&
    css`
      margin-left: 5em;
      font-size: 10px;
    `}
`;

export const FullName = styled.span`
  text-decoration: underline;
  display: none;
  @media (min-width: 834px) {
    display: inline;
  }
`;

export default Holder;
