import styled, { css } from 'styled-components/macro';

export interface InputWrapperProp {
  inlineExpand: string;
}

const InputWrapper = styled.div<InputWrapperProp>`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;
  margin-bottom: 0.5rem;

  ${props =>
    props.inlineExpand &&
    css`
      label {
        min-height: 24px;
      }
    `}
`;

export default InputWrapper;
