import React, { useEffect, useReducer, useLayoutEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import ABInsuranceSelector from '../../question_types/selector/ABInsuranceSelector/ABInsuranceSelector';
import EditProfile from '../edit_profile';
import EditPassword from '../edit_password';
import PaymentInformation from '../payment_information';
import ShippingInformation from '../ShippingInformation';
import SubscriptionInfo from '../../SubscriptionInfo';
import InsuranceEstimates from '../InsuranceEstimates';
import { loadNotificationSettings, saveNotificationSettings, get_prescriptions } from '../../../actions/patient_action';
import MultiStepForm, { formFlows } from '../../MultiStepForm';
import { CancellationModal, CancellationModalClose, UnsubscribeForm, Columns, Column } from './styled';
import CancelSubscription from '../CancelSubscription';
import CerebralMembership from '../CerebralMembership';
import reducer, { initialState } from './reducer';
import {
  setView,
  setCancellationModal,
  setInsuranceVisitsPolicy,
  setCancelFinished,
  setSubscription,
  setSubscriptionError
} from './actions';
import { formatDate, isNutritionOffering, isUserActive } from '../../../utils/common';
import InsuranceVisit from '../InsuranceVisit';
import InsuranceMedication from '../InsuranceMedication';
import BackButton from '../../BackButton';
import usePromise from '../../../utils/usePromise';
import strings from '../../../localization';
import segment from '../../../utils/segment';

const ProfileInfo = ({ handleLogo, checkDirectLink, isNotSupportInsurancePayment = false }) => {
  // TODO the below needs to be updated when insurance split is merged into BE
  const insuranceVisitsPolicy = useSelector(({ patient_reducer }) => patient_reducer.insurance_visits_policy);
  const payerNotInListed = useSelector(({ patient_reducer }) => patient_reducer.payer_not_in_listed);
  const insuranceMedicationsPolicy = useSelector(({ patient_reducer }) => patient_reducer.insurance_medications_policy);
  const abTestValues = useSelector(({ patient_reducer: { abTest } }) => abTest);
  const abTestStripe = abTestValues?.stripe_gateway_checkout === 'stripe';
  const currentPlan = useSelector(({ patient_reducer: { plan } }) => plan);
  const user = useSelector(({ global_reducer: { current_user } }) => current_user);
  const patient = useSelector(({ patient_reducer }) => patient_reducer);
  const [state, dispatchReact] = useReducer(reducer, initialState);
  const history = useHistory();
  const dispatchRedux = useDispatch();
  const serviceLine = useSelector(store => store.patient_reducer.visit_object?.service_lines);
  const offeringKey = useSelector(store => store.patient_reducer?.offering_key);
  const isNutritionServiceLine =
    (serviceLine?.length === 1 && serviceLine[0].name === 'nutrition') || isNutritionOffering(offeringKey);

  const { cancellationModalVisible, view, rawInsurance } = state;

  const price = useRef(0);
  const subscriptionURL = abTestStripe ? strings.subscriptionStripeApi : strings.subscriptionApi;

  const [subscriptionFetch, loadedSubscription] = usePromise(subscriptionURL, [price.current]);

  useEffect(() => {
    if (checkDirectLink) {
      dispatchReact(setView(checkDirectLink));
      handleLogo(false);
    }
    if (!loadedSubscription) return;
    const { data, error } = subscriptionFetch;

    if (error || !data) {
      const errors = {
        subscription: 'Subscription is not found'
      };

      return dispatchReact(setSubscriptionError(errors));
    }

    if (!isUserActive(user.attributes.patient.status)) {
      return dispatchReact(setSubscription({ status: 'cancelled' }));
    }

    const { status, amount, is_trial, next_charge, monthly_pay } = data.subscription;

    let current_price = monthly_pay;
    if (!current_price) {
      current_price = amount;
    }

    const subscription = {
      status,
      amount: Number(price.current || current_price),
      is_trial,
      next_charge: formatDate(next_charge)
    };

    dispatchReact(setSubscription(subscription));
  }, [loadedSubscription, subscriptionFetch]);

  const updateView = viewName => {
    dispatchReact(setView(viewName));
    handleLogo(viewName === 'profile');
  };

  const goToProfile = () => {
    updateView('profile');
  };
  const goToProfileDirec = () => {
    history.push('/patient/dashboard/profile_info');
    updateView('profile');
  };
  const onPlanChange = (details = {}) => {
    history.push('/patient/change-plan', { ...details });
  };

  const onCancelSubscriptionClick = () => {
    dispatchReact(setCancellationModal(true));
  };

  const onInsurance = ({ policy }, insurance) => {
    dispatchReact(setInsuranceVisitsPolicy(policy, true, insurance));
  };

  const onSwitchedPlan = () => {
    history.push('/patient/question_bank');
  };

  const onViewDetails = () => {
    dispatchRedux(get_prescriptions());
    updateView('subscription');
  };

  const onReactivate = () => {
    history.push('/patient/dashboard/reactivation_payment');
    segment.reactivateAccount();
  };

  const loadNotificationsConfig = async () => {
    const options = await dispatchRedux(loadNotificationSettings());
    return options;
  };

  const saveNotificationsConfig = async ({ options }) => {
    await dispatchRedux(saveNotificationSettings({ options }));
  };

  const onCancelSubscriptionClose = () => {
    dispatchReact(setCancellationModal(false));
  };

  const onCancelSubscriptionFinished = (data = {}) => {
    dispatchReact(setCancelFinished());
    price.current = data.price;
  };

  const onCancelSubscriptionStep = () => {
    // TODO: I couldn't get the ref properly :/
    if (document.getElementsByClassName('modal show').length) {
      document.getElementsByClassName('modal show')[0].scrollTop = 0;
    }
  };

  const handleInsuranceVisitsPolicy = () => {
    const insurance = { ...insuranceVisitsPolicy, ...insuranceMedicationsPolicy };
    if (isNutritionServiceLine && !isEmpty(insurance)) {
      return { ...insurance, rx_bin: '' };
    }
    return insuranceVisitsPolicy;
  };

  const renderView = () => {
    const { subscription, errors, view: viewMode, is_loading, insurancePolicy } = state;

    switch (viewMode) {
      case 'subscription':
        return (
          <CerebralMembership
            currentPlan={currentPlan}
            subscription={subscription}
            updateView={updateView}
            onPlanChange={onPlanChange}
            onCancelSubscriptionClick={onCancelSubscriptionClick}
          />
        );
      case 'insurance':
        return (
          <>
            <BackButton onClick={goToProfile}>&larr; Back to my account</BackButton>
            <MultiStepForm
              formFlow={formFlows.INSURANCE_FLOW}
              onSuccess={onInsurance}
              onSkip={goToProfile}
              onPlanChange={onPlanChange}
              inNetwork={currentPlan.is_insurance}
              showAllPayers
              isDashboard
              updateView={updateView}
            />
          </>
        );
      case 'new_update_insurance':
        return (
          <>
            <BackButton onClick={goToProfile}>&larr; Back to my account</BackButton>
            <MultiStepForm
              formFlow={formFlows.INSURANCE_FLOW}
              onSuccess={onInsurance}
              onSkip={goToProfile}
              onPlanChange={onPlanChange}
              inNetwork={currentPlan.is_insurance}
              showAllPayers={false}
              isDashboard
              updateView={updateView}
            />
          </>
        );

      case 'update_insurance_cards':
        return (
          <>
            <BackButton onClick={goToProfile}>&larr; Back to my account</BackButton>
            <ABInsuranceSelector updateView={updateView} enableNewflowValue />
          </>
        );
      case 'secondary_insurance':
        return (
          <>
            <BackButton onClick={goToProfile}>&larr; Back to my account</BackButton>
            <MultiStepForm
              formFlow={formFlows.SECONDARY_FLOW}
              onSuccess={onInsurance}
              onSkip={goToProfile}
              onPlanChange={onPlanChange}
              inNetwork={currentPlan.is_insurance}
              showAllPayers
              isDashboard
              updateView={updateView}
            />
          </>
        );
      case 'insurance_direct':
        return (
          <>
            <BackButton onClick={goToProfile}>&larr; Back to my account</BackButton>
            <MultiStepForm
              formFlow={formFlows.INSURANCE_DIRECT_FLOW}
              onSuccess={goToProfileDirec}
              onSkip={goToProfile}
              onPlanChange={onPlanChange}
              inNetwork={currentPlan.is_insurance}
              showAllPayers
              isDashboard
            />
          </>
        );
      case 'insurance_medication':
        return (
          <>
            <BackButton onClick={goToProfile}>&larr; Back to my account</BackButton>
            <MultiStepForm formFlow={formFlows.MEDICATION_FLOW} onSuccess={goToProfile} onSkip={goToProfile} />
          </>
        );
      case 'nutrition_insurance':
        return (
          <>
            <BackButton onClick={goToProfile}>&larr; Back to my account</BackButton>
            <MultiStepForm
              showAllPayers
              formFlow={formFlows.NUTRITION_FLOW}
              onSuccess={goToProfile}
              onSkip={goToProfile}
              isDashboard
            />
          </>
        );
      case 'insurance_estimates':
        return (
          <>
            <InsuranceEstimates
              insurance={insurancePolicy}
              rawInsurance={rawInsurance}
              switchToCashPlan={onPlanChange}
              currentPlan={currentPlan}
              updateView={updateView}
              onSwitchedPlan={onSwitchedPlan}
            />
          </>
        );
      default:
        return (
          <>
            <div className="text-main-title">My Account</div>
            <Columns>
              <Column>
                <SubscriptionInfo
                  patient={patient.direct_messaging_pilot ? patient : user.attributes.patient}
                  is_loading={is_loading}
                  subscription={subscription}
                  subscriptionError={errors.subscription}
                  onPlanChange={onPlanChange}
                  onViewDetails={onViewDetails}
                  onReactivate={onReactivate}
                />
                <PaymentInformation patient={user.attributes.patient} />
                <InsuranceMedication
                  updateView={updateView}
                  insurance={insuranceMedicationsPolicy}
                  isNotSupportInsurancePayment={isNotSupportInsurancePayment}
                />
                <UnsubscribeForm load={loadNotificationsConfig} save={saveNotificationsConfig} />
              </Column>
              <Column>
                <EditProfile attr={user.attributes} />
                <ShippingInformation />
                <InsuranceVisit
                  onPlanChange={onPlanChange}
                  updateView={updateView}
                  insurance={handleInsuranceVisitsPolicy()}
                  payerNotInListed={payerNotInListed}
                  isNotSupportInsurancePayment={isNotSupportInsurancePayment}
                />
                <EditPassword attr={user.attributes} />
              </Column>
            </Columns>
          </>
        );
    }
  };

  useLayoutEffect(() => {
    renderView();
    window.scrollTo(0, 0);
  }, [view]);

  return (
    <div className="d-flex flex-column profile-main-content">
      {renderView()}
      <CancellationModal show={cancellationModalVisible}>
        <CancellationModalClose onClick={onCancelSubscriptionClose} />
        <CancelSubscription
          onClose={onCancelSubscriptionClose}
          onFinished={onCancelSubscriptionFinished}
          onStep={onCancelSubscriptionStep}
        />
      </CancellationModal>
    </div>
  );
};

export default ProfileInfo;
