import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import { batch } from 'react-redux';
import { API_PATHS } from 'types/api-path';
import stringify from 'utils/stringify';
import queryString from 'query-string';
import { api_call } from '../middle/api';
import { getAbtests, isVisitCompleted, mapResponseToError } from '../utils/common';
import fireGtagConversion from '../utils/gtagConversions';
import { getInternalQuestionBanks, getQuestionsForInternalBanks } from '../utils/internal_question_banks';
import tracking from '../utils/tracking';
import create_visit from './patientActions/createVisit';
import * as global_actions from './user_auth_action';

const { get_user_attr, make_headers } = global_actions;

const authHeader = getState => {
  return { headers: make_headers(get_user_attr(getState())) };
};

export const SET_STEP = 'patient/SET_STEP';
export const SET_COUPON = 'patient/SET_COUPON';
export const SET_IS_PAYMENT_SUBMITTED = 'patient/IS_PAYMENT_SUBMITTED';
export const SET_VISIT_IS_COMPLETE = 'patient/SET_VISIT_IS_COMPLETE';
export const SET_VISIT_TYPE = 'patient/SET_VISIT_TYPE';
export const RESET_STATE = 'RESET';
export const RESET_QUESTIONS_AND_VISIT = 'patient/iRESET_QUESTIONS_AND_VISIT';
export const SET_PATIENT_STATE = 'patient/SET_STATE';
export const SET_STATE_WITH_STEP = 'patient/SET_STATE_WITH_STEP';
export const SET_QUESTION_BANKS = 'patient/SET_QUESTION_BANKS';
export const SET_QUESTION_BANKS_STEP = 'patient/SET_QUESTION_BANKS_STEP';
export const SET_PATIENT_QUESTIONS = 'patient/SET_PATIENT_QUESTIONS';
export const SET_CURRENT_PATIENT_PATH = 'patient/SET_CURRENT_PATIENT_PATH';
export const SET_PENDING_UPDATE = 'patient/SET_PENDING_UPDATE';
export const SET_SERVICE_LINE = 'patient/SET_SERVICE_LINE';
export const REMOVE_PATIENT_QUESTIONS = 'patient/REMOVE_PATIENT_QUESTIONS';
export const REMOVE_PATIENT_QUESTION_BANKS = 'patient/REMOVE_PATIENT_QUESTION_BANKS';
export const SET_BRANCH_QUESTION = 'patient/SET_BRANCH_QUESTION';
export const SET_BRANCH_QUESTION_STEP = 'patient/SET_BRANCH_QUESTION_STEP';
export const SET_BRANCH_QUESTION_ACTIVE = 'patient/SET_BRANCH_QUESTION_ACTIVE';
export const ADD_ADDITIONAL_QUESTIONS_TO_CURRENT_BANK = 'patient/ADD_ADDITIONAL_QUESTIONS_TO_CURRENT_BANK';
export const SET_BANK_STEP_AND_QUESTION_STEP = 'patient/SET_BANK_STEP_AND_QUESTION_STEP';
export const SET_GLOBAL_PROGRESSBAR_WIDTH = 'patient/SET_GLOBAL_PROGRESSBAR_WIDTH';
// step 2..9

export const SET_PATIENT = 'patient/SET_PATIENT';
export const SET_VISIT = 'patient/SET_VISIT';
export const SET_SCORE = 'patient/SET_SCORE';
export const SET_FOLLOWUP_VISIT = 'patient/SET_FOLLOWUP_VISIT';
export const SET_CARE_MANAGER = 'patient/SET_CARE_MANAGER';
export const SET_CARE_COORDINATOR = 'patient/SET_CARE_COORDINATOR';
export const SET_THERAPIST = 'patient/SET_THERAPIST';
export const SET_NUTRITIONIST = 'patient/SET_NUTRITIONIST';
export const SET_PROVIDER = 'SET_PROVIDER';
export const SET_PENDING_PLAN_CHANGE = 'patient/SET_PENDING_PLAN_CHANGE';
export const SET_CARE_MANAGER_AVAILABILITY = 'patient/SET_CARE_MANAGER_AVAILABILITY';
export const REMOVE_VISIT = 'patient/REMOVE_VISIT';
export const SET_TREATMENT = 'patient/SET_TREATMENT';
export const SET_TREATMENT_OBJECT = 'patient/SET_TREATMENT_OBJECT';
export const SET_DOSAGE = 'patient/SET_DOSAGE';

export const SET_PATIENT_TASKS = 'patient/SET_TASKS';
export const SET_PATIENT_STATUS = 'SET_PATIENT_STATUS';
export const SET_ELIGIBLE_SERVICE_LINES = 'patient/SET_ELIGIBLE_SERVICE_LINES';
export const UPDATE_TASK = 'patient/UPDATE_TASK';
export const SET_VISITS_FOR_PATIENT = 'patient/SET_VISITS_FOR_PATIENT';

export const SET_PATIENTS_APPOINTMENT = 'patient/SET_PATIENTS_APPOINTMENT';
export const REMOVE_APPOINTMENT_BY_ID = 'patient/REMOVE_APPOINTMENT_BY_ID';
export const SET_AVAILABLE_DATES = 'patient/SET_AVAILABLE_DATES';
export const SET_AVAILABLE_TIME_SLOTS = 'patient/SET_AVAILABLE_TIME_SLOTS';
export const SET_TIME_SLOT = 'patient/SET_TIME_SLOT';

export const GET_AVAILABLE_DATES_FETCH = 'patient/GET_AVAILABLE_DATES_FETCH';
export const GET_AVAILABLE_DATES_SUCCESS = 'patient/GET_AVAILABLE_DATES_SUCCESS';
export const GET_AVAILABLE_DATES_ERROR = 'patient/GET_AVAILABLE_DATES_ERROR';

export const GET_PRESCRIPTIONS_FETCH = 'patient/GET_PRESCRIPTIONS_FETCH';
export const GET_PRESCRIPTIONS_SUCCESS = 'patient/GET_PRESCRIPTIONS_SUCCESS';
export const GET_PRESCRIPTIONS_ERROR = 'patient/GET_PRESCRIPTIONS_ERROR';
export const SET_PRESCRIPTIONS = 'patient/SET_PRESCRIPTIONS';

export const INIT_CANCELLATION_FLOW = 'patient/INIT_CANCELLATION_FLOW';

export const CANCEL_SUBSCRIPTION_START = 'patient/CANCEL_SUBSCRIPTION_START';
export const CANCEL_SUBSCRIPTION_SUCESS = 'patient/CANCEL_SUBSCRIPTION_SUCESS';
export const CANCEL_SUBSCRIPTION_FINISHED = 'patient/CANCEL_SUBSCRIPTION_FINISHED';

export const APPLY_COUPON_START = 'patient/APPLY_COUPON_START';
export const APPLY_COUPON_SUCESS = 'patient/APPLY_COUPON_SUCESS';
export const APPLY_COUPON_FINISHED = 'patient/APPLY_COUPON_FINISHED';
export const SET_CONTROLLED_SUBSTANCE_REQUESTED = 'patient/SET_CONTROLLED_SUBSTANCE_REQUESTED';
export const SET_PLAN = 'patient/SET_PLAN';
export const SET_COMMUNICATION_PREFERENCES = 'patient/SET_COMMUNICATION_PREFERENCES';
export const SET_PRESCRIPTION_DELIVERY_METHOD = 'patient/SET_PRESCRIPTION_DELIVERY_METHOD';
export const SET_INSURANCE_STATUS = 'patient/SET_INSURANCE_STATUS';
export const SET_NEXT_RATING = 'patient/SET_NEXT_RATING';
export const SET_TERMS_VERSION_UPDATED = 'patient/SET_TERMS_VERSION_UPDATED';

export const SET_ABTEST = 'patient/SET_ABTEST';

export const OUTSTANDING_TASK_MODAL_CLOSED = 'patient/OUTSTANDING_TASK_MODAL_CLOSED';
export const NEW_APPOINTMENT_MODAL_CLOSED = 'patient/NEW_APPOINTMENT_MODAL_CLOSED';
export const SET_INSURANCE_VISITS_POLICY = 'patient/SET_INSURANCE_VISITS_POLICY';
export const SET_INSURANCE_MEDICATIONS_POLICY = 'patient/SET_INSURANCE_MEDICATIONS_POLICY';
export const REMOVE_QUESTION_BANK = 'patient/REMOVE_QUESTION_BANK';
export const SET_DIRECT_MESSAGING_PILOT = 'patient/SET_DIRECT_MESSAGING_PILOT';
export const SET_REGION = 'patient/SET_REGION';
export const SET_OFFERING_KEY = 'patient/SET_OFFERING_KEY';
export const SET_MEDICATION_PHOTOS = 'patient/SET_MEDICATION_PHOTOS';
export const SET_INSURANCE_PHOTOS = 'patient/SET_INSURANCE_PHOTOS';
export const SET_SECOND_INSURANCE_PHOTOS = 'patient/SET_SECOND_INSURANCE_PHOTOS';
export const SET_FLOW_KIND = 'patient/SET_FLOW_KIND';
export const SET_ELIGIBLE = 'patient/SET_ELIGIBLE';
export const SET_IS_MEDICARE_SECONDARY = 'patient/IS_MEDICARE_SECONDARY';
export const SET_PAYER_ONBOARDING = 'patient/SET_PAYER_ONBOARDING';
export const SET_PAYER_NOT_IN_LISTED = 'patient/SET_PAYER_NOT_IN_LISTED';
export const SET_ACCOUNT_ID = 'patient/SET_ACCOUNT_ID';
export const SET_SERVICE_LINE_NOT_AVAILABLE = 'patient/SET_SERVICE_LINE_NOT_AVAILABLE';

export const setPayerNotInListed = payer => ({
  type: SET_PAYER_NOT_IN_LISTED,
  payer
});
export const SET_PROVIDER_INI = 'patient/SET_PROVIDER_INI';

export const setHasProviderIni = (has_prescriber, has_therapist) => {
  return {
    type: SET_PROVIDER_INI,
    payload: {
      has_prescriber,
      has_therapist
    }
  };
};

export const setPayerOnboarding = payer => ({
  type: SET_PAYER_ONBOARDING,
  payer
});

export const setAccountId = accountId => ({
  type: SET_ACCOUNT_ID,
  accountId
});

export const setServiceLineNotAvailable = msg => ({
  type: SET_SERVICE_LINE_NOT_AVAILABLE,
  service_line_not_available: msg
});

export const setIsMedicareSecondary = isMedicareSecondary => ({
  type: SET_IS_MEDICARE_SECONDARY,
  isMedicareSecondary
});

export const setEligibleStatus = isEligible => ({
  type: SET_ELIGIBLE,
  isEligible
});

export const setFlowKind = flowKind => ({
  type: SET_FLOW_KIND,
  flowKind
});

export const setMedicationPhotos = photos => ({
  type: SET_MEDICATION_PHOTOS,
  photos
});

export const setInsurancePhotos = photos => ({
  type: SET_INSURANCE_PHOTOS,
  photos
});

export const setSecondInsurancePhotos = photos => ({
  type: SET_SECOND_INSURANCE_PHOTOS,
  photos
});

export const removeQuestionBank = ({ name }) => ({
  type: REMOVE_QUESTION_BANK,
  name
});

export const setInsuranceMedicationsPolicy = ({ policy }) => ({
  type: SET_INSURANCE_MEDICATIONS_POLICY,
  policy
});

export const setInsuranceVisitsPolicy = ({ policy }) => ({
  type: SET_INSURANCE_VISITS_POLICY,
  policy
});

export const close_outstanding_modal = () => ({
  type: OUTSTANDING_TASK_MODAL_CLOSED
});

export const close_new_appointment_modal = () => ({
  type: NEW_APPOINTMENT_MODAL_CLOSED
});

export const set_abtest = (abTestType, value) => ({
  type: SET_ABTEST,
  abTestType,
  value
});

export const setCoupon = (coupon, price, offering) => ({
  type: SET_COUPON,
  coupon,
  price,
  offering
});

export const setCommunicationPreferences = preference => ({
  type: SET_COMMUNICATION_PREFERENCES,
  preference
});

export const setPrescriptionDeliveryMethod = prescriptionDeliveryMethod => ({
  type: SET_PRESCRIPTION_DELIVERY_METHOD,
  prescriptionDeliveryMethod
});

export const setNextRating = ({ ratingType, userId }) => ({
  type: SET_NEXT_RATING,
  ratingType,
  userId
});

export const setVisitType = visitType => ({
  type: SET_VISIT_TYPE,
  visitType
});

export const setControlledSubstanceRequested = value => ({
  type: SET_CONTROLLED_SUBSTANCE_REQUESTED,
  value
});

export const reset_state = () => ({
  type: RESET_STATE
});

export const cleanup_questions_and_visit = () => ({
  type: RESET_QUESTIONS_AND_VISIT
});

export const set_visit_is_complete = ({ visitType = '', pendingUpdates = false, isComplete = false }) => ({
  type: SET_VISIT_IS_COMPLETE,
  visitType,
  pendingUpdates,
  isComplete
});

export const set_is_payment_submitted = (flag, is_insurance = false) => ({
  type: SET_IS_PAYMENT_SUBMITTED,
  is_payment_submitted: flag,
  is_insurance
});

// TODO: implement middleware for handling api call
export const set_step = (step_num, is_complete) => ({
  type: SET_STEP,
  step: step_num,
  is_complete
});

// https://redux.js.org/basics/actions#actions
// https://redux.js.org/basics/actions#action-creators

export const set_state_with_step = (state, new_step) => ({
  type: SET_STATE_WITH_STEP,
  new_state: state,
  new_step
});

export const set_patient_questions = (questions, bank_id, bank_name, q_id, bank_step) => ({
  type: SET_PATIENT_QUESTIONS,
  questions,
  total_step: questions.length,
  bank_id,
  bank_name,
  q_id,
  bank_step
});

export const set_bank_step_and_question_step = (bank_idx, question_idx, key) => ({
  type: SET_BANK_STEP_AND_QUESTION_STEP,
  bank_index: bank_idx,
  question_index: question_idx,
  key
});

export const set_global_progressbar_width = width => ({
  type: SET_GLOBAL_PROGRESSBAR_WIDTH,
  width
});

export const add_additional_questions_to_current_bank = (bank_name, idx, questions, region) => ({
  type: ADD_ADDITIONAL_QUESTIONS_TO_CURRENT_BANK,
  bank_name,
  idx,
  questions,
  region
});

export const set_question_banks = (question_bank_objects, questions_banks, bank_step = 0, completed_payment_at) => ({
  type: SET_QUESTION_BANKS,
  question_bank_objects,
  question_banks: questions_banks,
  question_banks_step: bank_step,
  completed_payment_at
});

export const set_branch_question = (data, bank_name, flow_type, region) => ({
  type: SET_BRANCH_QUESTION,
  questions: data,
  bank_name,
  flow_type,
  region
});

export const set_question_banks_step = question_banks_step => ({
  type: SET_QUESTION_BANKS_STEP,
  question_banks_step
});

export const set_patient = patient_object => ({
  type: SET_PATIENT,
  patient_object
});

export const set_visit = visit_object => ({
  type: SET_VISIT,
  visit_object
});

export const set_score = (assessment_type, name, value) => ({
  type: SET_SCORE,
  assessment_type,
  name,
  value
});

export const set_follow_up_visit = flag => ({
  type: SET_FOLLOWUP_VISIT,
  is_follow_up: flag
});

export const set_care_manager = manager => ({
  type: SET_CARE_MANAGER,
  care_manager: manager
});

export const set_care_coordinator = care_coordinator => ({
  type: SET_CARE_COORDINATOR,
  care_coordinator
});

export const set_provider = provider => ({
  type: SET_PROVIDER,
  provider
});

export const set_therapist = therapist => ({
  type: SET_THERAPIST,
  therapist
});

export const set_nutritionist = nutritionist => ({
  type: SET_NUTRITIONIST,
  nutritionist
});

export const setPendingPlanChange = change => ({
  type: SET_PENDING_PLAN_CHANGE,
  change
});

export const setCareTeamMember = (type, member) => {
  switch (type) {
    case 'care_manager':
      return set_care_manager(member);
    case 'therapist':
      return set_therapist(member);
    case 'provider':
      return set_provider(member);
    case 'nutritionist':
      return set_nutritionist(member);
    default:
      throw new Error('Unknow member type.');
  }
};

export const set_care_manager_availability = is_care_manager_available => ({
  type: SET_CARE_MANAGER_AVAILABILITY,
  is_care_manager_available
});

export const set_treatment_object = object => ({
  type: SET_TREATMENT_OBJECT,
  treatment_object: object
});

export const remove_visit = () => ({
  type: REMOVE_VISIT
});

export const set_treatment = treatment_object => ({
  type: SET_TREATMENT,
  treatment_object
});

export const set_dosage = dosage_object => ({
  type: SET_DOSAGE,
  dosage_object
});

export const remove_patient_questions = () => ({
  type: REMOVE_PATIENT_QUESTIONS
});

export const set_patient_state = pstate => ({
  type: SET_PATIENT_STATE,
  patient_state: pstate
});

export const set_service_line = ptype => ({
  type: SET_SERVICE_LINE,
  service_line: ptype
});

export const set_patient_tasks = tasks => ({
  type: SET_PATIENT_TASKS,
  tasks
});

export const setEligibleServiceLines = serviceLines => ({
  type: SET_ELIGIBLE_SERVICE_LINES,
  serviceLines
});

export const update_patient_task = task => ({
  type: UPDATE_TASK,
  task
});

export const set_visits_for_patient = visits => ({
  type: SET_VISITS_FOR_PATIENT,
  visits
});

export const set_available_time_slots = timeSlots => ({
  type: SET_AVAILABLE_TIME_SLOTS,
  timeSlots
});

export const set_available_dates = dates => ({
  type: SET_AVAILABLE_DATES,
  dates
});

export const set_patients_appointment = appointment => ({
  type: SET_PATIENTS_APPOINTMENT,
  appointment
});

export const remove_appointment_by_id = id => ({
  type: REMOVE_APPOINTMENT_BY_ID,
  id
});

export const select_time_slot = timeSlot => ({
  type: SET_TIME_SLOT,
  timeSlot
});

export const get_available_dates_fetch = () => ({
  type: GET_AVAILABLE_DATES_FETCH
});
export const get_available_dates_success = () => ({
  type: GET_AVAILABLE_DATES_SUCCESS
});
export const get_available_dates_error = () => ({
  type: GET_AVAILABLE_DATES_ERROR
});

export const get_prescriptions_fetch = () => ({
  type: GET_PRESCRIPTIONS_FETCH
});
export const get_prescriptions_success = () => ({
  type: GET_PRESCRIPTIONS_SUCCESS
});
export const get_prescriptions_error = () => ({
  type: GET_PRESCRIPTIONS_ERROR
});

export const set_prescriptions = (prescriptions /* , page */) => ({
  type: SET_PRESCRIPTIONS,
  prescriptions
  // page
});

export const set_patient_status = status => ({
  type: SET_PATIENT_STATUS,
  status
});

export const setPlan = ({ plan }) => ({
  type: SET_PLAN,
  plan
});

export const setDirectMessagingPilot = enabled => ({
  type: SET_DIRECT_MESSAGING_PILOT,
  dmFlag: enabled
});

export const setRegion = region => ({
  type: SET_REGION,
  region
});

export const setOfferingKey = offering_key => ({
  type: SET_OFFERING_KEY,
  offering_key
});

export const get_abtest =
  ({ id }) =>
  async (dispatch, getState) => {
    const user_attr = get_user_attr(getState());

    return axios.get(`/api/ab_tests/${id}`, { headers: make_headers(user_attr) }).then(({ data: value }) => {
      dispatch(set_abtest(id, value));
    });
  };

export const assign_plan =
  ({ plan_id }, nextQuestion) =>
  async (dispatch, getState) => {
    const user_attr = get_user_attr(getState());
    return dispatch(
      api_call(
        'POST',
        `/api/patients/${user_attr.patient.id}/plans/${plan_id}/assign_plan`,
        { headers: make_headers(user_attr) },
        {}
      )
    ).then(() => {
      nextQuestion();
      return Promise.resolve();
    });
  };

export const get_cookie_abtests = () => dispatch => {
  const abtests = getAbtests();
  Object.keys(abtests).forEach(id => dispatch(set_abtest(id, abtests[id])));
};

export const reactivate = () => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  const { patient } = getState().global_reducer.current_user.attributes;

  return axios.put(`/api/patients/${patient.id}/reactivate`, {}, { headers: make_headers(user_attr) }).then(() => {
    dispatch(set_patient_status('unassigned'));
  });
};

// simply get data with auth
export const get_data = (url, attributes) => {
  return axios.get(url, { headers: make_headers(attributes) }).then(resp => {
    return Promise.resolve(resp.data);
  });
};

export const get_adhoc_appointment = type => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());

  return axios
    .get(`/api/calendar/adhoc?appointment_type=${type}`, { headers: make_headers(user_attr) })
    .then(resp => resp.data);
};

export const get_current_patient = () => (dispatch, getState) => {
  let patient = getState().patient_reducer.patient_object;

  // TODO: why is this necessary? the patient object should be set after register or login
  if (!patient) {
    // eslint-disable-next-line prefer-destructuring
    patient = getState().global_reducer.current_user.attributes.patient;
  }
  return patient;
};

// export const get_patient = () => (dispatch, getState) => {
//   const user_attr = get_user_attr(getState());
//   const patient = dispatch(get_current_patient());
//   return axios.get(
//     `/api/patients/${patient.id}/`,
//     { headers: make_headers(user_attr) }
//   )
//     .then(resp => {
//       return dispatch(set_patient(resp.data));
//     });
// };

export const init_cancelation_flow = ({ discountEndDate }) => ({
  type: INIT_CANCELLATION_FLOW,
  discountEndDate
});

export const submitRating =
  ({ rating, comment, rating_type, user_id }) =>
  async (dispatch, getState) => {
    const user_attr = get_user_attr(getState());
    const body = {
      rating: { rating, comment, rating_type, user_id }
    };

    try {
      await axios.post(`/api/users/${user_id}/rate`, body, { headers: make_headers(user_attr) });
    } catch (e) {
      tracking.error({ e, message: 'Error submitting rating' });
    } finally {
      dispatch(setNextRating({ ratingType: null, userId: null }));
    }
  };

/**
 * check if ILV is available
 * We should probably replace get_visit_available with it
 */

export const isILVAvailable = () => async (_, getState) => {
  if (sessionStorage.getItem('shouldSkipILV') === 'true') {
    return false;
  }

  try {
    const { data } = await axios.get(API_PATHS.ILV_VISITS, authHeader(getState));

    return data.ilv_available;
  } catch (err) {
    tracking.error({ e: err, message: 'FAILED to fetch available states for ILV' });
    return false;
  }
};

export const get_patient_details =
  (saveVisit = false) =>
  async (dispatch, getState) => {
    const user_attr = get_user_attr(getState());
    const defaultPlan = { offering_key: 'medication', is_insurance: false };
    const {
      patient: { id: patientId }
    } = user_attr;
    const { data } = await axios.get(`/api/patients/${patientId}/`, { headers: make_headers(user_attr) });
    const flowKind = data.flow_kind === 'backend' ? 'BE' : 'FE';

    batch(() => {
      // TODO: this sholdn't be here, we should look to bring back the set_patient action commented out above
      dispatch(init_cancelation_flow({ discountEndDate: data.cancellation_discount_end_date }));
      dispatch(set_care_manager(data.care_manager));
      dispatch(set_care_coordinator(data.care_coordinator));
      dispatch(set_provider(data.current_doctor));
      dispatch(set_patient_status(data.status));
      saveVisit && dispatch(set_visit(data.first_visit));
      dispatch(
        set_patient_state({
          verification_complete: data.verification_complete,
          persona_inquiry_id: data.persona_inquiry_id,
          tac_update_needed: data.terms_and_conditions_update_needed,
          pronoun: data.pronoun,
          communication_preference: data.communication_preference,
          new_scheduled_upcoming_appointment: data?.new_scheduled_upcoming_appointment ?? false
        })
      );
      dispatch(set_therapist(data.therapist));
      dispatch(set_nutritionist(data.nutritionist));
      dispatch(setInsuranceVisitsPolicy({ policy: data.insurance_visits_policy || {} }));
      dispatch(setInsuranceMedicationsPolicy({ policy: data.insurance_medications_policy || {} }));
      dispatch(setIsMedicareSecondary(data.is_medicare_secondary));
      dispatch(setPendingPlanChange(data.pending_plan_change));
      dispatch(
        setNextRating(
          data.next_rating
            ? { userId: data.next_rating.user_id, ratingType: data.next_rating.type }
            : { userId: null, ratingType: null }
        )
      );
      dispatch(setPlan({ plan: data.plan || defaultPlan }));
      data.ab_test_values &&
        Object.keys(data.ab_test_values).forEach(id => dispatch(set_abtest(id, data.ab_test_values[id])));
      dispatch(setDirectMessagingPilot(data.direct_messaging_pilot));
      dispatch(setRegion(data.region));
      dispatch(setOfferingKey(data.offering_key));
      dispatch(setEligibleServiceLines(data.service_lines));
      dispatch(setInsurancePhotos(data.insurance_visits_policy ? data.insurance_cards : []));
      dispatch(setSecondInsurancePhotos(data.insurance_visits_policy ? data.insurance_second_cards : []));
      dispatch(setMedicationPhotos(data.insurance_medications_policy ? data.pharmacy_cards : []));
      dispatch(get_cookie_abtests());
      dispatch(setFlowKind(flowKind));
      dispatch(setPayerNotInListed(data?.insurance_payer_name));
      dispatch(setPayerOnboarding({}));
      dispatch(setAccountId(data.account_id));
    });
    return data;
  };

export const get_available_dates =
  ({
    getAllAvailableDates = false,
    appointmentType = 'provider',
    duration = 30,
    provider_id,
    superAdhocLinkId,
    reschedulingSuperAdhoc
  }) =>
  (dispatch, getState) => {
    let url = `/api/calendar/availability?appointment_type=${appointmentType}&duration=${duration}`;

    if (provider_id) {
      url += `&careperson_id=${provider_id}`;
    }

    if (superAdhocLinkId) {
      url += `&super_adhoc_link_id=${superAdhocLinkId}`;
    }

    if (reschedulingSuperAdhoc) {
      url += `&rescheduling_super_adhoc=${reschedulingSuperAdhoc}`;
    }

    if (getAllAvailableDates) {
      url = `/api/calendars/care_people_days_available?appointment_type=${appointmentType}`;
    }

    const user_attr = get_user_attr(getState());

    dispatch(get_available_dates_fetch());
    // dispatch(get_patient());
    return axios
      .get(url, {
        headers: make_headers(user_attr)
      })
      .then(resp => {
        batch(() => {
          dispatch(get_available_dates_success());
          dispatch(set_available_dates(resp.data));
        });
        return resp;
      });
  };

export const get_available_time_slots =
  ({ selectedDate, appointmentType = 'provider', duration = 30, provider_id, getAllTimeSlot = false }) =>
  (dispatch, getState) => {
    let url = `/api/calendar/slots_by_date?date=${selectedDate}&appointment_type=${appointmentType}&duration=${duration}`;

    if (provider_id) {
      url += `&careperson_id=${provider_id}`;
    }

    if (getAllTimeSlot) {
      url = `/api/calendars/care_people_slots_available?appointment_type=${appointmentType}&date=${selectedDate}`;
    }

    const user_attr = get_user_attr(getState());

    dispatch(get_available_dates_fetch());
    return axios
      .get(url, {
        headers: make_headers(user_attr)
      })
      .then(resp => {
        batch(() => {
          dispatch(get_available_dates_success());
          dispatch(set_available_time_slots(resp.data));
        });
        return resp;
      });
  };

export const get_available_provider_by_time_slot =
  (timeSlot, appointmentType = 'provider') =>
  (dispatch, getState) => {
    const user_attr = get_user_attr(getState());
    return axios
      .get(`/api/calendars/care_people_available?slot=${timeSlot}&appointment_type=${appointmentType}`, {
        headers: make_headers(user_attr)
      })
      .then(data => {
        return data;
      });
  };

export const get_providers_info = () => (dispatch, getState) => {
  const url = '/api/calendar/providers';

  return axios.get(url, authHeader(getState));
};

export const get_schedule_info =
  (appointmentType, cancel_appointment_id, provider_id, superAdhocLinkId) => (dispatch, getState) => {
    let url = `/api/calendar/schedule_info?appointment_type=${appointmentType}`;

    if (cancel_appointment_id) {
      url += `&cancel_appointment_id=${cancel_appointment_id}`;
    }

    if (provider_id) {
      url += `&careperson_id=${provider_id}`;
    }

    if (superAdhocLinkId) {
      url += `&super_adhoc_link_id=${superAdhocLinkId}`;
    }

    return axios.get(url, authHeader(getState));
  };

export const submit_appointment =
  (
    starts_at,
    cancel_appointment_id,
    appointment_type,
    duration,
    adhoc = false,
    provider_id,
    superAdhocLinkId,
    isReassign
  ) =>
  (dispatch, getState) => {
    const data = {
      starts_at,
      duration
    };

    if (adhoc) {
      data.adhoc = String(adhoc);
    }

    if (cancel_appointment_id) {
      data.cancel_appointment_id = cancel_appointment_id;
      dispatch(remove_appointment_by_id(cancel_appointment_id));
    }
    if (appointment_type) {
      data.appointment_type = appointment_type;
    }

    if (provider_id) {
      data.careperson_id = provider_id;
    }

    if (superAdhocLinkId) {
      data.super_adhoc_link_id = superAdhocLinkId;
    }

    if (isReassign) {
      const payload = {
        care_person_type: appointment_type,
        care_person_id: provider_id,
        starts_at,
        duration,
        adhoc: String(true)
      };
      return axios.post('/api/v1/adhoc_clinician_reassignment/adhoc_schedule', payload, authHeader(getState));
    }

    return axios.post('/api/calendar', data, authHeader(getState));
  };

export const get_care_manager_availability = () => (dispatch, getState) => {
  return axios.get('/api/globals', authHeader(getState)).then(resp => {
    dispatch(set_care_manager_availability(resp.data['chatting-available']));
    return resp.data;
  });
};

/** BE FLOW UPDATE: remove if not used */
export const update_service_line = name => (dispatch, getState) => {
  return axios.get(`/api/service_lines/search?name=${name}`, authHeader(getState)).then(resp => {
    // TODO: update global store with patient information
    return dispatch(set_service_line(resp.data));
  });
};

/** BE FLOW UPDATE: remove if not used */
export const set_profile_question = () => dispatch => {
  return dispatch(set_state_with_step('profile/screening', 1));
};

/** BE FLOW UPDATE: remove if not used */
export const move_patient_sign_in = () => dispatch => {
  return dispatch(set_state_with_step('profile/sign_in', 0));
};

/** BE FLOW UPDATE: remove if not used */
export const move_patient_sign_up = () => dispatch => {
  return dispatch(set_state_with_step('profile/sign_up', 0));
};

/** BE FLOW UPDATE: remove if not used */
export const update_patient_question_banks = (bank_names, step) => dispatch => {
  return axios.get('/api/question_banks').then(resp => {
    // TODO: right now we are fetching and saving all of the question banks information because
    // it's just as easy to get all of it
    // dispatch(set_question_bank_objects(resp.data))
    dispatch(set_question_banks(resp.data, bank_names, step));
    return Promise.resolve();
  });
};

export const get_additional_questions_for_current_bank = (bank_id, bank_name, index) => (dispatch, getState) => {
  return axios.get(`/api/question_banks/${bank_id}/questions`, authHeader(getState)).then(resp => {
    dispatch(add_additional_questions_to_current_bank(bank_name, index, resp.data));
  });
};

/** BE FLOW UPDATE: remove if not used */
export const get_additional_questions_for_current_bank_by_name =
  (name, currentBankName, index) => (dispatch, getState) => {
    return axios.get(`/api/question_banks/search?name=${name}`).then(bank => {
      return axios.get(`/api/question_banks/${bank.data.id}/questions`, authHeader(getState)).then(resp => {
        const { region } = getState().global_reducer.current_user.attributes.patient;

        dispatch(add_additional_questions_to_current_bank(currentBankName, index, resp.data, region));
      });
    });
  };

/** BE FLOW UPDATE: remove if not used */
export const get_branch_questions_by_id = (branch_question_id, branch_name) => (dispatch, getState) => {
  const { flow_type, region } = getState().global_reducer.current_user.attributes.patient;
  const questions = getQuestionsForInternalBanks(branch_name);

  if (!isEmpty(questions)) {
    dispatch(set_branch_question(questions, branch_name, flow_type, region));
    return Promise.resolve(questions);
  }

  return axios.get(`/api/question_banks/${branch_question_id}/questions`, authHeader(getState)).then(resp => {
    dispatch(set_branch_question(resp.data, branch_name, flow_type, region));
    return resp;
  });
};

/** BE FLOW UPDATE: remove if not used */
export const setCurrentPatientPath =
  ({ isPaymentSubmitted, isMedsTherapy = false, isTherapistRegion }) =>
  (dispatch, getState) => {
    const {
      global_reducer: {
        current_user: {
          attributes: {
            patient: { communication_preference }
          }
        }
      }
    } = getState();

    return dispatch({
      type: SET_CURRENT_PATIENT_PATH,
      isPaymentSubmitted,
      communicationPreferences: communication_preference,
      removeUpdatePlan: isMedsTherapy || !isTherapistRegion
    });
  };

export const setPendingUpdate = flag => ({
  type: SET_PENDING_UPDATE,
  flag
});

/** BE FLOW UPDATE: remove if not used */
export const get_question_banks_by_visit_id = (visit, therapistRegions) => (dispatch, getState) => {
  const {
    global_reducer: {
      current_user: {
        attributes: {
          patient: { flow_type, completed_payment_at }
        }
      }
    },
    patient_reducer: {
      visit_object,
      plan: { offering_key }
    }
  } = getState();

  if (!visit) {
    // eslint-disable-next-line no-param-reassign
    visit = visit_object;
  }

  const { id, is_follow_up, pending_updates } = visit;
  const is_full = flow_type === 'full_assessment_first';

  const is_nutrition_post_checkout =
    pending_updates &&
    [
      'medication-counseling-nutrition-management',
      'medication-therapy-nutrition-management',
      'therapy-nutrition-management'
    ].includes(offering_key);
  const reactivation = !is_follow_up && !is_nutrition_post_checkout;
  const query = {
    visit_id: id,
    is_full,
    reactivation_visit: reactivation,
    is_nutrition_upsell: is_nutrition_post_checkout
  };
  return axios
    .get(`/api/question_banks/for_visit?${queryString.stringify(query)}`, authHeader(getState))
    .then(({ data }) => {
      return data.map(bank => {
        if (bank.name === 'assessment_end' && is_full) {
          if (is_follow_up) {
            bank.num_questions -= 1; // remove question about what causing the issue
          }
          bank.num_questions += 1; // preliminary score
        }

        return bank;
      });
    })
    .then(data => {
      const internalBanks = getInternalQuestionBanks();
      const banks = data.concat(internalBanks);
      batch(() => {
        dispatch(set_question_banks({}, banks, 0, completed_payment_at));
        dispatch(
          setCurrentPatientPath({
            isPaymentSubmitted: !!completed_payment_at,
            isMedsTherapy: ['medication-therapy', '345_meds_therapy', '365_meds_therapy'].includes(offering_key),
            therapistRegions
          })
        );
      });

      return data;
    });
};

/** BE FLOW UPDATE: remove if not used */
export const get_branch_questions = bank_name => (dispatch, getState) => {
  return axios.get(`/api/question_banks/search?name=${bank_name}`).then(resp => {
    return axios.get(`/api/question_banks/${resp.data.id}/questions`, authHeader(getState)).then(res => {
      const { flow_type } = getState().global_reducer.current_user.attributes.patient;
      dispatch(set_branch_question(res.data, bank_name, flow_type));
      return res;
    });
  });
};

export const update_patient_seen_new_created_appointment = public_id => (dispatch, getState) => {
  return axios.put(`/api/appointments/${public_id}`, { is_seen_by_patient: true }, authHeader(getState)).then(resp => {
    dispatch(close_new_appointment_modal());
    return resp.data;
  });
};
export const get_patient_tasks = () => (dispatch, getState) => {
  return axios.get('/api/tasks', authHeader(getState)).then(resp => {
    dispatch(set_patient_tasks(resp.data));
    return resp.data;
  });
};

export const get_patient_appointments = () => (dispatch, getState) => {
  return axios.get('/api/calendar', authHeader(getState)).then(resp => {
    dispatch(set_patients_appointment(resp.data));
    return resp.data;
  });
};

/** BE FLOW UPDATE: remove if not used */
export const delete_patient_questions = () => dispatch => {
  return dispatch(remove_patient_questions());
};

// makes an authenticated GET call to the server and unwraps the response nicely
export const get_with_auth_and_return_just_data = url => (dispatch, getState) => {
  return axios.get(url, authHeader(getState)).then(resp => {
    return Promise.resolve(resp.data);
  });
};

/** BE FLOW UPDATE: remove if not used */
export const get_side_effects = service_line_id => dispatch => {
  return dispatch(get_with_auth_and_return_just_data(`/api/service_lines/${service_line_id}/side_effects`));
};

/** BE FLOW UPDATE: remove if not used */
export const get_treatments = service_line_id => dispatch => {
  return dispatch(get_with_auth_and_return_just_data(`/api/service_lines/${service_line_id}/treatments`));
};

/** BE FLOW UPDATE: remove if not used */
export const get_treatment_dosages = treatment_id => dispatch => {
  return dispatch(get_with_auth_and_return_just_data(`/api/treatments/${treatment_id}/dosages`));
};

/** BE FLOW UPDATE: remove if not used */
export const get_treatment_by_name = treatment_name => dispatch => {
  return dispatch(get_with_auth_and_return_just_data(`/api/treatments/search?name=${treatment_name}`));
};

/** BE FLOW UPDATE: remove if not used */
export const get_patient_payments = () => dispatch => {
  const patient = dispatch(get_current_patient());
  return dispatch(get_with_auth_and_return_just_data(`/api/patients/${patient.id}/payments`));
};

export const updatePatientAddress =
  (addr, address_type = 'ShippingAddress') =>
  (dispatch, getState) => {
    const user_attr = get_user_attr(getState());
    const patient = dispatch(get_current_patient());
    return axios.post(
      `/api/patients/${patient.id}/addresses`,
      { ...addr, address_type },
      { headers: make_headers(user_attr) }
    );
  };

export const update_patient = updates => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  const patient = dispatch(get_current_patient());
  return axios.put(`/api/patients/${patient.id}/`, updates, { headers: make_headers(user_attr) }).then(resp => {
    const { data = {} } = resp;

    batch(() => {
      if (data && data.care_manager) {
        dispatch(set_care_manager(data.care_manager));
      }
      data.ab_test_values &&
        Object.keys(data.ab_test_values).forEach(id => {
          dispatch(set_abtest(id, data.ab_test_values[id]));
        });

      const flowKind = data.flow_kind === 'backend' ? 'BE' : 'FE';

      dispatch(set_patient_state(updates));
      dispatch(setDirectMessagingPilot(data.direct_messaging_pilot));
      dispatch(setRegion(data.region));
      dispatch(setFlowKind(flowKind));
      dispatch(setEligibleServiceLines(data.service_lines));
      dispatch(setAccountId(data.account_id));
    });
    return resp;
  });
};

export const consentToPrescriptions = (type, answer) => (dispatch, getState) => {
  // answer: 'agreed' or 'disagree'
  // type: 'copay', 'fill', 'pay_in_cash'
  // method: 'email' 'online' 'text' 'verbal'

  const user_attr = get_user_attr(getState());
  const patient = dispatch(get_current_patient());

  const body = {
    type,
    answer,
    method: 'online'
  };

  return axios.put(`/api/patients/${patient.id}/consents`, body, { headers: make_headers(user_attr) });
};

export const ensure_visit = () => (dispatch, getState) => {
  const visit = getState().patient_reducer.visit_object;
  const { service_line } = getState().patient_reducer;
  // TODO: wecould also check to make sure the visit is not expired
  if (visit && !isVisitCompleted(visit)) {
    return Promise.resolve(visit);
  }
  // NOTE: the server will take care of creating a new visit or giving us back an existing visit if needed
  return dispatch(create_visit(service_line.name)).then(new_visit => {
    dispatch(set_visit(new_visit));
    return Promise.resolve(new_visit);
  });
};

export const get_current_patient_and_visit = () => (dispatch, getState) => {
  const patient = dispatch(get_current_patient());

  // TODO: if there is not patient in current state, we could get the patient object using user_id

  if (patient == null) {
    return Promise.resolve({ patient: null, visit: null });
  }

  const { service_line } = getState().patient_reducer;

  return dispatch(ensure_visit(false, service_line)).then(visit => {
    return Promise.resolve({ patient, visit });
  });
};

/** BE FLOW UPDATE: remove if not used */
export const get_current_treatment_and_dosage = () => (dispatch, getState) => {
  // TODO: get treatment and dosage from current answers if needed
  return Promise.resolve({
    treatment: getState().patient_reducer.treatment_object,
    dosage: getState().patient_reducer.dosage_object
  });
};

export const submit_payment = body => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  return axios.post('/api/authorizenet_payments', body, { headers: make_headers(user_attr) });
};

export const submit_stripe_payment = body => (_, getState) => {
  const user_attr = get_user_attr(getState());
  return axios.post('/api/stripe_payments', body, { headers: make_headers(user_attr) });
};

export const submit_stripe_payment_us = body => (_, getState) => {
  const user_attr = get_user_attr(getState());
  return axios.post('/api/payments/stripes/', body, { headers: make_headers(user_attr) });
};

export const upgrade_terms_to_charge_stripe_payment = body => (_, getState) => {
  const user_attr = get_user_attr(getState());
  return axios.put('/api/payments/stripes/upgrade_terms_to_charge', body, { headers: make_headers(user_attr) });
};
/** BE FLOW UPDATE: remove if not used */
// given a question name, get the answer for that question in the *current* visit
export const get_current_answer_by_name = name => dispatch => {
  return dispatch(get_current_patient_and_visit()).then(resp => {
    return dispatch(
      get_with_auth_and_return_just_data(
        `/api/patients/${resp.patient.id}/visits/${resp.visit.id}/answers/search?question[name]=${name}`
      )
    );
  });
};

/** BE FLOW UPDATE: remove if not used */
export const complete_current_visit = (type, carePerson) => (dispatch, getState) => {
  const {
    patient_reducer: {
      visit_object: { is_follow_up }
    }
  } = getState();

  return dispatch(get_current_patient_and_visit()).then(resp => {
    const user_attr = get_user_attr(getState());
    const path = `/api/patients/${resp.patient.id}/visits/${resp.visit.id}/complete`;

    const body = {
      reactivation_visit: String(!is_follow_up)
    };

    if (carePerson && carePerson.id) {
      body.care_person_id = carePerson.id;
      body.care_person_type = type;
    }

    return dispatch(api_call('PUT', path, { headers: make_headers(user_attr) }, body)).then(new_visit => {
      fireGtagConversion('complete');
      dispatch(set_visit_is_complete({ isComplete: true, pendingUpdates: false, visitType: new_visit.visit_type }));
      return Promise.resolve(new_visit);
    });
  });
};

/** BE FLOW UPDATE: remove if not used */
export const isVisitComplete = () => (dispatch, getState) => {
  return dispatch(get_current_patient_and_visit()).then(resp => {
    const user_attr = get_user_attr(getState());

    return axios
      .get(`/api/patients/${resp.patient.id}/visits/${resp.visit.id}/is_complete`, { headers: make_headers(user_attr) })
      .then(({ data: visit }) => {
        if (isVisitCompleted(visit)) {
          fireGtagConversion('complete');
          dispatch(set_visit_is_complete({ isComplete: true, pendingUpdates: false }));
        }

        return Promise.resolve(visit);
      });
  });
};

/** BE FLOW UPDATE: remove if not used */
export const answer_current_question = (answer, question) => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());

  const patient_state = getState().patient_reducer;

  const current_question = question || patient_state.branch_questions[patient_state.branch_question_step];

  // this answer does not need to be recorded because we have been explicitly told not to do so
  if (current_question == null || !current_question.save_answer) return Promise.resolve();

  // get_current_patient_and_visit which will create new records if they are needed
  return dispatch(get_current_patient_and_visit()).then(resp => {
    if (resp.patient == null || resp.visit == null) {
      // this answer does not need to be recorded because there is no current patient or visit
      return Promise.resolve();
    }

    fireGtagConversion('answer');

    const body = {
      ...answer,
      question_bank_id: patient_state.question_bank_id,
      question_id: current_question.id,
      question_name: current_question.name
    };

    return dispatch(
      api_call(
        'POST',
        `/api/patients/${resp.patient.id}/visits/${resp.visit.id}/answers`,
        { headers: make_headers(user_attr) },
        body
      )
    );
  });
};

export const skipVerifyQuestion = answer => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  const body = {
    ...answer,
    question_name: 'identity1'
  };
  return dispatch(get_current_patient_and_visit()).then(resp => {
    if (resp.patient == null || resp.visit == null) {
      // this answer does not need to be recorded because there is no current patient or visit
      return Promise.resolve();
    }

    fireGtagConversion('answer');
    return dispatch(
      api_call(
        'POST',
        `/api/patients/${resp.patient.id}/visits/${resp.visit.id}/answers`,
        { headers: make_headers(user_attr) },
        body
      )
    );
  });
};

export const upload_object_for_current_question = (file, file_type, file_name, question) => dispatch => {
  return dispatch(answer_current_question({ upload: true, file_type, file_name }, question))
    .then(resp => {
      const option = {
        headers: {
          ContentEncoding: 'base64',
          'Content-Type': file_type
        }
      };

      return axios.put(resp.object_url, file, option).then(res => {
        return Promise.resolve(res);
      });
    })
    .catch(err => Promise.reject(err));
};

/** BE FLOW UPDATE: remove if not used */
export const get_answers_for_visit = (patient_id, visit_id) => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  return axios.get(`/api/patients/${patient_id}/visits/${visit_id}/answers`, { headers: make_headers(user_attr) });
};

/** BE FLOW UPDATE: remove if not used */
export const is_valid_visit = (patient_id, visit_id) => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  return axios.get(`/api/patients/${patient_id}/visits/${visit_id}`, { headers: make_headers(user_attr) });
};

export const get_visits_for_patient = (patient_id, visitId) => async (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  const { patient } = user_attr;

  // in case patient_id has not been provided
  if (!patient_id) {
    // eslint-disable-next-line no-param-reassign
    patient_id = patient.id;
  }

  try {
    if (visitId) {
      await axios.post(
        `/api/patients/${patient_id}/visits/${visitId}/update_assessment_scores`,
        {},
        {
          headers: make_headers(user_attr)
        }
      );
    }
    const { data } = await axios.get(`/api/patients/${patient_id}/visits`, { headers: make_headers(user_attr) });
    dispatch(set_visits_for_patient(data));

    if (data.length > 0) {
      if (data[0].service_lines && data[0].service_lines.find(treatment => treatment.name === 'adhd')) {
        window.localStorage.setItem('isADHD', 'true');
      } else {
        window.localStorage.setItem('isADHD', 'false');
      }
    }

    return data;
  } catch (err) {
    throw new Error(
      `Could not get visits ${
        !isEmpty(patient)
          ? stringify({ patient_id: patient.id, user_id: patient.user_id })
          : 'No user information has been provided'
      }. Error: ${!isEmpty(err) ? stringify(err) : 'no data was passed from API call'}`
    );
  }
};

/** BE FLOW UPDATE: remove if not used */
export const collect_payments = (body, patient_id) => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  return axios.post(`/api/patients/${patient_id}/payment_methods/`, body, { headers: make_headers(user_attr) });
};

export const update_subscription = patient_id => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  return axios.post(
    `/api/patients/${patient_id}/payment_methods/update_subscription`,
    {},
    { headers: make_headers(user_attr) }
  );
};

export const update_stripe_subscription = patient_id => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  return axios.post(
    `/api/patients/${patient_id}/stripe_payment_methods/update_subscription`,
    {},
    { headers: make_headers(user_attr) }
  );
};

export const get_payment_info = () => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  return axios.get('/api/authorizenet_payments/card_info', { headers: make_headers(user_attr) });
};

// remove when new payment set up
/** BE FLOW UPDATE: remove if not used */
export const submit_paypal = () => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  return axios.post('/api/paypal', {}, { headers: make_headers(user_attr) });
};

export const update_payment_info = (body, patient_id) => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  return axios.post(`/api/patients/${patient_id}/payment_methods/`, body, { headers: make_headers(user_attr) });
};

export const update_stripe_payment_info = (body, patient_id) => (_, getState) => {
  const user_attr = get_user_attr(getState());
  return axios.post(`/api/patients/${patient_id}/stripe_payment_methods`, body, { headers: make_headers(user_attr) });
};

// /clients?status=activated
/** BE FLOW UPDATE: remove if not used */
export const validate_referral_code = code => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  return axios.get(`/api/partners?referral_code=${code}`, { headers: make_headers(user_attr) });
};

export const clean_up_patient_process = () => dispatch => {
  return dispatch(cleanup_questions_and_visit());
};

export const sign_out = () => dispatch => {
  return dispatch(reset_state());
};

/** BE FLOW UPDATE: remove if not used */
export const set_b_question_step = step => dispatch => {
  return dispatch({ type: SET_BRANCH_QUESTION_STEP, step });
};

/** BE FLOW UPDATE: remove if not used */
export const set_b_question_active = is_active => dispatch => {
  return dispatch({ type: SET_BRANCH_QUESTION_ACTIVE, is_active });
};

/** BE FLOW UPDATE: remove if not used */
export const get_doctors_for_patient = () => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  const { patient } = user_attr;
  return axios.get(`/api/patients/${patient.id}/doctors`, { headers: make_headers(user_attr) });
};

/** BE FLOW UPDATE: remove if not used */
export const get_patient_doctor = () => async (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  const { patient } = user_attr;
  const { data } = await axios.get(`/api/patients/${patient.id}/doctors`, { headers: make_headers(user_attr) });
  let doctor = null;

  if (data.length) {
    doctor = { ...data[0].user, calendly_url: data[0].calendly_url };
  }

  return doctor;
};

/** BE FLOW UPDATE: remove if not used */
export const get_doctor_details = doctor_id => (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  const { patient } = user_attr;
  return axios.get(`/api/patients/${patient.id}/doctors/${doctor_id}`, { headers: make_headers(user_attr) });
};

export const get_prescriptions = (/* page = 1 */) => (dispatch, getState) => {
  dispatch(get_prescriptions_fetch());
  const user_attr = get_user_attr(getState());
  return axios
    .get('/api/prescriptions', { headers: make_headers(user_attr) })
    .then(res => {
      dispatch(get_prescriptions_success());
      // dispatch(set_prescriptions(page > 1 ? prescriptionsMockPage2 : prescriptionsMock, page));
      dispatch(set_prescriptions(res.data));
    })
    .catch(() => {
      dispatch(get_prescriptions_error());
    });
};

export const cancel_subscription_start = ({ textOnLoading }) => ({
  type: CANCEL_SUBSCRIPTION_START,
  textOnLoading
});

export const cancel_subscription_success = ({ textOnLoaded, descriptionOnLoaded }) => ({
  type: CANCEL_SUBSCRIPTION_SUCESS,
  textOnLoaded,
  descriptionOnLoaded
});

export const cancel_subscription_finished = () => ({
  type: CANCEL_SUBSCRIPTION_FINISHED
});

export const cancel_subscription = () => async (dispatch, getState) => {
  const user_attr = get_user_attr(getState());
  const { patient } = user_attr;

  dispatch(cancel_subscription_start({ textOnLoading: 'Processing...' }));

  const { data } = await axios.put(
    `/api/patients/${patient.id}/cancellation`,
    {},
    { headers: make_headers(user_attr) }
  );

  if (data.success) {
    dispatch(
      cancel_subscription_success({
        textOnLoaded: 'Subscription cancelation confirmed.',
        descriptionOnLoaded: `Your account will be active untill ${data.subscription_end_date}`
      })
    );
  } else {
    dispatch(cancel_subscription_finished());
    throw new Error('Error cancelling subscription!');
  }
};

export const apply_coupon_start = ({ textOnLoading }) => ({
  type: APPLY_COUPON_START,
  textOnLoading
});

export const apply_coupon_success = ({ textOnLoaded, discountEndDate }) => ({
  type: APPLY_COUPON_SUCESS,
  textOnLoaded,
  discountEndDate
});

export const apply_coupon_finished = () => ({
  type: APPLY_COUPON_FINISHED
});

export const apply_coupon =
  ({ coupon_id }) =>
  async (dispatch, getState) => {
    const user_attr = get_user_attr(getState());
    const { patient } = user_attr;

    dispatch(apply_coupon_start({ textOnLoading: 'Processing...' }));

    const { data } = await axios.put(
      `/api/patients/${patient.id}/apply_coupon`,
      { coupon_id },
      { headers: make_headers(user_attr) }
    );

    if (data.success) {
      dispatch(
        apply_coupon_success({
          textOnLoaded: `50% discount applied untill ${data.cancellation_discount_end_date}`,
          discountEndDate: data.cancellation_discount_end_date
        })
      );
    } else {
      dispatch(apply_coupon_finished());
      throw new Error('Error applying coupon!');
    }
  };

export const saveCancellationAnswer =
  ({ answer }) =>
  async (dispatch, getState) => {
    const {
      global_reducer: {
        current_user: { attributes }
      },
      patient_reducer: { visits }
    } = getState();
    const visit = visits[visits.length - 1];
    const body = { answer, question_bank_id: '', question_name: 'reason_for_cancellation' };

    return dispatch(
      api_call(
        'POST',
        `/api/patients/${attributes.patient.id}/visits/${visit.id}/answers`,
        { headers: make_headers(attributes) },
        body
      )
    );
  };

export const verifyCoupon =
  ({ coupon_id, terms_to_charge }) =>
  async (dispatch, getState) => {
    const {
      patient_reducer: {
        plan: { offering_key, is_insurance }
      },
      global_reducer: {
        current_user: { attributes }
      }
    } = getState();
    const {
      patient: { id }
    } = attributes;

    return dispatch(
      api_call(
        'PUT',
        `/api/patients/${id}/coupon_valid`,
        { headers: make_headers(attributes) },
        { coupon_id, offering: offering_key, is_insurance, terms_to_charge }
      )
    );
  };

export const loadNotificationSettings = () => async (dispatch, getState) => {
  const {
    global_reducer: {
      current_user: { attributes }
    }
  } = getState();

  return dispatch(api_call('GET', '/api/notification_settings/show', { headers: make_headers(attributes) }));
};

export const saveNotificationSettings =
  ({ options }) =>
  async (dispatch, getState) => {
    const {
      global_reducer: {
        current_user: { attributes }
      }
    } = getState();

    return dispatch(
      api_call('POST', '/api/notification_settings/update', { headers: make_headers(attributes) }, { patient: options })
    );
  };

export const updateVisitType =
  (type = 'scheduled') =>
  async (dispatch, getState) => {
    const {
      global_reducer: {
        current_user: { attributes }
      },
      patient_reducer: {
        visit_object: { id: visitId }
      }
    } = getState();

    const headers = make_headers(attributes);
    const {
      patient: { id: patientId }
    } = attributes;

    await axios.put(`/api/patients/${patientId}/visits/${visitId}`, { visit_type: type }, { headers });
    dispatch(setVisitType(type));
  };

export const setImmediatVisitType =
  (type = 'video') =>
  (_, getState) => {
    const {
      global_reducer: {
        current_user: { attributes }
      }
    } = getState();

    const headers = make_headers(attributes);

    const body = {
      visit_type: type
    };

    if (type === 'phone') {
      body.phone = attributes.phone;
    }

    return axios.post('/api/visit_requests', body, { headers }).catch(err => {
      const errorMessage = mapResponseToError(err);
      tracking.error({ e: err, message: errorMessage });
      return err;
    });
  };

export const setInsuranceStatus = status => ({
  type: SET_INSURANCE_STATUS,
  status
});

export const createVisitsInsurance = (insurance, isOutOfNetwork) => async (dispatch, getState) => {
  const {
    global_reducer: {
      current_user: { attributes }
    }
  } = getState();
  const headers = make_headers(attributes);

  if (!isEmpty(insurance)) {
    const res = await axios.post(
      'api/insurance_policies',
      isOutOfNetwork
        ? { ...attributes.patient, ...insurance, active: true, out_of_network: true }
        : { ...attributes.patient, ...insurance, active: true },
      { headers }
    );
    if (res?.data?.insurance_policy) {
      dispatch(setInsuranceVisitsPolicy({ policy: { ...res.data.insurance_policy } }));
    }
  }
};

export const removeVisitsInsurance = () => async (dispatch, getState) => {
  const {
    patient_reducer: {
      insurance_visits_policy: { id }
    }
  } = getState();
  const {
    global_reducer: {
      current_user: { attributes }
    }
  } = getState();
  const headers = make_headers(attributes);

  id && (await axios.put(`api/insurance_policies/${id}`, { active: false }, { headers }));
  dispatch(setInsuranceVisitsPolicy({ policy: {} }));
};

export const updateMedicationsInsurance =
  ({ insurance, updates }) =>
  async (dispatch, getState) => {
    const {
      global_reducer: {
        current_user: { attributes }
      }
    } = getState();
    const headers = make_headers(attributes);

    if (insurance && insurance.id) {
      await axios.put(`api/insurance_medications_policies/${insurance.id}`, updates, { headers });
      dispatch(setInsuranceMedicationsPolicy({ policy: { ...insurance, ...updates } }));
    }
  };

export const removeMedicationsInsurance = () => async (dispatch, getState) => {
  const {
    patient_reducer: {
      insurance_medications_policy: { id }
    }
  } = getState();
  const {
    global_reducer: {
      current_user: { attributes }
    }
  } = getState();
  const headers = make_headers(attributes);

  id && (await axios.put(`api/insurance_medications_policies/${id}`, { active: false }, { headers }));
  dispatch(setInsuranceMedicationsPolicy({ policy: {} }));
};

export const updateInsuranceStatus = (status, statusForLastPolicy) => async (dispatch, getState) => {
  const {
    global_reducer: {
      current_user: { attributes }
    }
  } = getState();
  const headers = make_headers(attributes);
  let url = `/api/insurance/update_status?status=${status}`;
  if (statusForLastPolicy) {
    url += `&status_for_last_policy=${statusForLastPolicy}`;
  }
  await axios.put(url, null, { headers });
  dispatch(setInsuranceStatus(status));
};

export const setTermsVersionUpdated = status => ({
  type: SET_TERMS_VERSION_UPDATED,
  status
});

export const updateTermsVersion = () => async (dispatch, getState) => {
  const {
    global_reducer: {
      current_user: { attributes }
    }
  } = getState();
  const headers = make_headers(attributes);
  const patient = dispatch(get_current_patient());
  await axios.put(`/api/patients/${patient.id}/update_terms`, null, { headers });
  dispatch(setTermsVersionUpdated(false));
};

export const assignCarePerson = (type, carePerson) => async (dispatch, getState) => {
  const {
    global_reducer: {
      current_user: { attributes }
    }
  } = getState();

  const headers = make_headers(attributes);
  const {
    patient: { id }
  } = attributes;

  await axios.put(
    `/api/patients/${id}/assign_care_person`,
    { care_person_id: carePerson.id, care_person_type: type },
    { headers }
  );
  batch(() => {
    dispatch(setCareTeamMember(type, carePerson));
    type === 'provider' && dispatch(setVisitType('scheduled'));
  });
};
