export const getProgressBarWidth = ({ patient_reducer: { global_progressbar_width } }) => global_progressbar_width;

export const getCurrentPath = ({ patient_reducer: { currentPatientPath } }) => currentPatientPath;

export const getQuestionsObject = ({ patient_reducer: { question_bank_objects } }) => question_bank_objects;

export const selectRegion = ({
  global_reducer: {
    current_user: {
      attributes: { patient }
    }
  }
}) => patient.region;

export const selectAttributes = ({
  global_reducer: {
    current_user: { attributes }
  }
}) => attributes;

export const selectOffering = ({
  global_reducer: {
    current_user: {
      attributes: {
        patient: { offering_key }
      }
    }
  }
}) => offering_key;

export const selectInsuranceStatus = ({
  global_reducer: {
    current_user: {
      attributes: { patient }
    }
  }
}) => patient.insurance_status;
