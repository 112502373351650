import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { Store } from 'types/store';
import { datadogRum } from '@datadog/browser-rum';
import tracking from './utils/tracking';
import { Container, Text } from './components/dashboard/NotCompletedFlow/styled';
import { Wrapper } from './containers/patient/dashboard.contents';
import isUK from './utils/uk.util';

const ErrorBoundary: React.FC = ({ children }) => {
  const userAttributes = useSelector((state: Store) => state.global_reducer.current_user.attributes);
  const { id, email, first_name, last_name, patient } = userAttributes;

  useEffect(() => {
    if (!id) return;
    datadogRum.setUser({
      id: String(id),
      patientId: String(patient?.id)
    });
  }, [id, email, first_name, last_name]);

  const USFallback = () => (
    <>
      An unforeseen error has occurred. Our technical staff has been notified and are working on a solution to the
      problem. You can try navigating back to the <a href="/">login page</a> to continue.
    </>
  );

  const UKFallback = () => (
    <>
      We’re really sorry, but a technical error has occurred. Our team have been notified and are working on a solution.
      <br />
      In case this happens again, please take a note of our support team’s email address who can help you out directly:{' '}
      <a href="mailto:support@getcerebral.co.uk">support@getcerebral.co.uk</a>. Then, try refreshing the page or
      returning to the <a href="/">login page</a> to continue.
    </>
  );

  const ErrorFallback = () => (
    <Wrapper>
      <Container>
        <Text>{isUK() ? <UKFallback /> : <USFallback />}</Text>
      </Container>
    </Wrapper>
  );

  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={(error, info) => tracking.error({ e: error, info })}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
