import React, { useState } from 'react';
import { format } from '../../../utils/date-fns';
import { Expansion, FieldWrapper, InvoiceItemWrapper, MobileWrapper, PayBtn, SeeMoreBtn, UnpaidBadge } from './styled';

import iconPDF from '../../../img/icons/ic-pdf.svg';
import iconChevronDown from '../../../img/icons/ic-chevron-down.svg';
import iconEye from '../../../img/icons/ic-eye.svg';
import { Skeleton } from '../../UI/Skeleton/Skeleton';

const MobileView = ({ invoices, invoicesLoading, onPay, unpaidCount, onDownload }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <MobileWrapper expand={showMore}>
      {invoicesLoading &&
        Array(4)
          .fill(0)
          .map((_, index) => (
            <InvoiceItemWrapper key={index}>
              <Skeleton />
            </InvoiceItemWrapper>
          ))}
      {!invoicesLoading &&
        invoices.map(invoice => (
          <InvoiceItemWrapper key={invoice.id}>
            <FieldWrapper>
              <div className="field-title">Date</div>
              <div className="field-value">{format(invoice.created_at)}</div>
            </FieldWrapper>
            <FieldWrapper>
              <div className="field-title">Description</div>
              <div className="field-value">{invoice.title}</div>
            </FieldWrapper>
            <FieldWrapper>
              <div className="field-title">Invoice</div>
              <div className="field-value invoice" onClick={() => onDownload(invoice.id)}>
                <span>View (PDF)</span>
                <img src={iconPDF} alt="View PDF" />
              </div>
            </FieldWrapper>
            <FieldWrapper>
              <div className="field-title">Status</div>
              <div className="field-value">
                {invoice.status === 'paid' ? (
                  invoice.status
                ) : (
                  <>
                    <span>{invoice.status}</span>
                    <PayBtn onClick={() => onPay(invoice)}>Pay</PayBtn>
                  </>
                )}
              </div>
            </FieldWrapper>
          </InvoiceItemWrapper>
        ))}
      {invoices.length > 2 && !showMore && (
        <Expansion>
          <SeeMoreBtn onClick={() => setShowMore(!showMore)}>
            <img src={iconEye} alt="eye" />
            <span>See History</span>
            <img src={iconChevronDown} alt="arrow down" />
          </SeeMoreBtn>
        </Expansion>
      )}

      {!!unpaidCount && (
        <UnpaidBadge>
          {unpaidCount}/{invoices.length}
        </UnpaidBadge>
      )}
    </MobileWrapper>
  );
};

export default MobileView;
