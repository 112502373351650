import type { OfferingKey } from './plans';

export interface TrackingParams {
  [key: string]: string | number | ObjectProduct[];
}
interface ObjectProduct {
  [key: string]: string | number;
}

interface Person {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  patient: {
    id: number;

    patient: {
      id: number;
    };
  };
}

const isProduction = () => {
  const isDev = localStorage.getItem('enable_segment_track') || null;
  if (isDev !== null && isDev !== undefined) return true;
  return (
    process.env.NODE_ENV === 'production' &&
    !window.location.href.includes('app-staging') &&
    !window.location.href.includes('app-dev') &&
    !window.location.href.includes('app-release') &&
    !window.location.href.includes('app-fe-release') &&
    !window.location.href.includes('app-insurance')
  );
};

const formatPrice = (price: string) => {
  const parseNum = parseFloat(price);
  return ((Number.isNaN(parseNum) && 85) || parseNum).toFixed(2);
};

const track = (event: string, opts?: Partial<TrackingParams>): void => {
  if (!isProduction()) return;
  window.analytics.track(event, opts);
};

const convertCampaignId = (campaignId: string) => {
  return /^[1-9].[0-9]*$/.test(campaignId) ? Number(campaignId) : campaignId;
};

const trackSubscription = (
  offeringKey: OfferingKey,
  price: string,
  campaignId: string,
  insurance: { status: 'in_network' | string },
  orderId: string,
  eventID: string,
  email: string
): void => {
  let subscriptionType;
  switch (offeringKey) {
    case 'medication':
    case 'medication-adhd-115-management':
    case 'medication-adhd-135-management':
    case '99_med_management':
    case '109_med_management':
    case '119_med_management':
      subscriptionType = 'Med mgmt Sub Purchase';
      break;
    case 'therapy':
    case '275_therapy':
    case '290_therapy':
      subscriptionType = 'Therapy Sub Purchase';
      break;
    case 'medication-therapy':
    case '345_meds_therapy':
    case '365_meds_therapy':
      subscriptionType = 'Meds + Therapy Sub Purchase';
      break;
    case 'nutrition-management':
      subscriptionType = 'Nutrition Sub Purchase';
      break;
    case 'nutrition-medication-management':
      subscriptionType = 'Meds + Nutrition Subs Purchase';
      break;
    case 'counseling':
      subscriptionType = 'Counseling Subscription';
      break;
    case 'nutrition-management-therapy':
      subscriptionType = 'Nutrition + Therapy Subscription';
      break;
    case 'nutrition-medication-management-therapy':
      subscriptionType = 'Nutrition + MM + Therapy Subscription';
      break;
    case 'medication-counseling-nutrition-management':
      subscriptionType = 'MM + CC + Nutrition Subscription';
      break;
    case 'nutrition-medication-management-glp1':
      subscriptionType = 'Nutrition + MM Subscription (GLP-1)';
      break;
    default:
      subscriptionType = 'Subscription Signup';
      break;
  }

  if (insurance && insurance.status === 'in_network') {
    subscriptionType = `(INI) ${subscriptionType}`;
  }

  const params: TrackingParams = {
    revenue: formatPrice(price),
    value: formatPrice(price),
    total: formatPrice(price),
    cost: formatPrice(price),
    order_id: orderId,
    quantity: 1,
    campaignId: campaignId || '',
    currency: 'USD',
    eventID
  };
  const paramsOrderCompleted: TrackingParams = {
    email,
    products: [
      {
        id: subscriptionType,
        name: subscriptionType,
        quantity: 1,
        price: formatPrice(price)
      }
    ],
    total: formatPrice(price),
    campaignId: convertCampaignId(campaignId)
  };
  track(subscriptionType, params);
  track('Order Completed', paramsOrderCompleted);
};

const trackUpsell = (oldPlan: string, price: string): void => {
  const params = {
    revenue: formatPrice(price),
    value: formatPrice(price),
    currency: 'USD'
  };

  switch (oldPlan) {
    case 'medication-&-care-counseling':
      track(`meds-counseling-to-meds-therapy-upsell`, params);
      break;
    case 'therapy':
      track(`therapy-to-meds-therapy-upsell`, params);
      break;
    case 'nutrition-medication-management-glp1':
      track(`Upsell from Nutrition+MM (GLP-1) to Nutrition+MM + Therapy`, params);
      break;
    case 'nutrition-medication-management':
      track(`Upsell from Nutrition+MM to Nutrition+MM + Therapy`, params);
      break;
    case 'nutrition-management':
      track(`Upsell from Nutrition to Nutrition + Therapy`, params);
      break;
    case 'therapy + nutrition':
      track(`Upsell Therapy to Therapy + Nutrition`, params);
      break;
    case 'medication + nutrition':
      track(`Upsell MM+CC to MM+CC + Nutrition`, params);
      break;
    case 'medication-therapy + nutrition':
      track(`Upsell MM+Therapy to MM+Therapy + Nutrition`, params);
      break;
    default:
      break;
  }
};

const locationService = (available: boolean): void => {
  if (available) {
    track('Location Service Confirmation');
  } else {
    track('Location Service Not Available');
  }
};

const eventBasedOnPath = (path: string): void => {
  switch (path) {
    case '/patient/question_bank/checkout/payment':
      track('Checkout Initiate');
      break;
    case '/patient/question_bank/assessment_end/results':
      track('Assessment Complete');
      break;
    default:
      break;
  }
};

const identify = (person: Person): void => {
  if (!isProduction()) return;
  window.analytics.identify(person.id, {
    email: person.email,
    firstName: person.first_name,
    lastName: person.last_name,
    patientId: person.patient.patient.id
  });
};

const page = (path: string): void => {
  if (!isProduction()) return;
  track('Page View');
  window.analytics.page();
  eventBasedOnPath(path);
};

const signUp = (person: Person): void => {
  identify(person);
};

const signIn = (person: Person): void => {
  person.patient.patient = { id: person.patient.id };
  identify(person);
};

const reactivateAccount = () => {
  track('Subscription Reactivate');
};

const planNameToTrack = (
  isNutritionUpsell: boolean,
  isNutritionServiceLine: boolean,
  plan: string,
  offeringKey: string
) => {
  let planName = plan.replace(/\s+/g, '-').toLowerCase();
  if (isNutritionUpsell) {
    planName = `${offeringKey} + nutrition`;
  } else if (isNutritionServiceLine) {
    planName = offeringKey || '';
  }
  return planName;
};

export default {
  locationService,
  signIn,
  signUp,
  identify,
  track,
  page,
  trackSubscription,
  trackUpsell,
  reactivateAccount,
  convertCampaignId,
  planNameToTrack
};
