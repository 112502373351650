import React, { useContext, useReducer, useState } from 'react';
import Bowser from 'bowser';
import LandingView from './LandingView';
import MobileView from './MobileView';
import ConfirmPaymentModal from './ConfirmPaymentModal';
import { is_touch_device } from '../../../utils/common';
import { ApiContext } from '../../../utils/api';
import PaymentModal from '../../ChangePlan/PaymentModal';
import usePromise from '../../../utils/usePromise';

import reducer, { initialState } from './reducer';
import { setSelectedInvoice, setCheckoutResult, setPaidInvoice, setCheckoutError } from './actions';
import { Wrapper, PageTitle, TitleBadge } from './styled';

const Invoices = () => {
  const api = useContext(ApiContext);
  const [state, dispatchReact] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);

  const { selectedInvoice, checkoutResult } = state;

  const [invoiceResponse, invoicesLoaded] = usePromise('/api/invoices', [checkoutResult]);
  const invoices = invoiceResponse?.data || [];
  const unpaidCount = invoices.filter(invoice => invoice.status !== 'paid').length;

  const handlePayConfirm = async () => {
    setLoading(true);
    try {
      const { data } = await api.post(`/api/invoices/${selectedInvoice.id}/pay`);
      dispatchReact(setPaidInvoice(data));

      if (data.status === 'paid') {
        setTimeout(() => {
          dispatchReact(setCheckoutResult(null));
        }, 2000);
      }
    } catch (err) {
      dispatchReact(setCheckoutError());
    } finally {
      setLoading(false);
    }
  };

  const handlePayClick = invoice => {
    dispatchReact(setSelectedInvoice(invoice));
  };

  const handleDownload = async invoiceId => {
    const browser = Bowser.parse(window.navigator.userAgent);
    let newTab = null;
    try {
      if (browser.browser.name === 'Safari') {
        newTab = window.open('', '_blank');
      }

      const { data } = await api.get(`/api/invoices/${invoiceId}/pdf`);

      if (browser.browser.name === 'Safari') {
        newTab.location = data;
      } else {
        window.open(data, '_blank');
      }
    } catch (err) {
      dispatchReact(setCheckoutError());
    }
  };

  const errorMessage = () =>
    checkoutResult !== 'paid' ? (
      <>
        Your payment failed. <br />
        Please try again or contact support for assistance.
      </>
    ) : null;

  return (
    <Wrapper>
      <PageTitle>
        INVOICES
        {!!unpaidCount && <TitleBadge>{unpaidCount}</TitleBadge>}
      </PageTitle>

      {!is_touch_device() && (
        <LandingView
          invoices={invoices}
          invoicesLoading={!invoicesLoaded}
          onPay={handlePayClick}
          onDownload={handleDownload}
        />
      )}
      {is_touch_device() && (
        <MobileView
          invoices={invoices}
          invoicesLoading={!invoicesLoaded}
          onPay={handlePayClick}
          unpaidCount={unpaidCount}
          onDownload={handleDownload}
        />
      )}

      <ConfirmPaymentModal
        isOpen={!!selectedInvoice && !loading && !checkoutResult}
        onClose={() => dispatchReact(setSelectedInvoice(null))}
        data={selectedInvoice}
        onPay={handlePayConfirm}
      />

      <PaymentModal
        open={!!checkoutResult || loading}
        loading={loading}
        onClose={() => dispatchReact(setCheckoutResult(null))}
        payAgain={() => dispatchReact(setCheckoutResult(null))}
        textOnSuccess="Payment Successful!"
        error={errorMessage()}
      />
    </Wrapper>
  );
};

export default Invoices;
