import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const UserTyping = () => {
  const typingRef = React.createRef();

  useEffect(() => {
    typingRef.current && typingRef.current.scrollIntoView();
  }, [typingRef]);

  const typingUserName = useSelector(state => state.messenger_reducer && state.messenger_reducer.typing_user_name);
  return (
    <li ref={typingRef} className="mt-auto d-flex justify-content-start message">
      <div className="d-flex flex-column">
        <span className="align-self-start message_name">{typingUserName}</span>
        <span className="received_message loader">
          <span className="first-dot" />
          <span className="second-dot" />
          <span className="third-dot" />
        </span>
      </div>
    </li>
  );
};

export default UserTyping;
