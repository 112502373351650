import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'types/store';
import type { Scores } from 'types/assessments';
import { format } from 'utils/date-fns';
import { get_visits_for_patient } from 'actions/patient_action';
import AssessmentResult from './AssessmentResult';
import NutritionResult from './NutritionResult';

const Assessment: React.FC = () => {
  const dispatch = useDispatch();
  const visits = useSelector((store: Store) => store.patient_reducer.visits);

  useEffect(() => {
    dispatch(get_visits_for_patient());
  }, []);

  const getScoresFromVisits = (): [Scores, number | null] => {
    const scores: Scores = { isi: [], phq: [], gad: [], asrs: [], alcohol: [], smoking: [] };
    const sortedVisits = visits.slice().sort((v1, v2) => v1.id - v2.id);
    let bmiScore = null;
    sortedVisits.forEach(item => {
      // go through each service line
      const {
        service_lines,
        isi_score,
        phq_score,
        gad_score,
        asrs_score,
        alcohol_score,
        created_at,
        smoking_score,
        bmi_score
      } = item;
      const date = format(created_at, {
        month: 'long',
        year: 'numeric'
      });
      service_lines.forEach(line => {
        if (isi_score != null && line.name === 'ins') {
          scores.isi.push({ name: date, uv: isi_score });
        }
        if (phq_score != null && line.name === 'depression') {
          scores.phq.push({ name: date, uv: phq_score });
        }
        if (gad_score != null && line.name === 'anxiety') {
          scores.gad.push({ name: date, uv: gad_score });
        }
        if (asrs_score != null && line.name === 'adhd') {
          scores.asrs.push({ name: date, uv: asrs_score });
        }
        if (smoking_score != null && line.name === 'smoking') {
          scores.smoking.push({ name: date, uv: smoking_score });
        }
        if (alcohol_score != null && line.name === 'alcohol') {
          scores.alcohol.push({ name: date, uv: alcohol_score });
        }
      });
      if (bmi_score) {
        bmiScore = bmi_score;
      }
    });
    return [scores, bmiScore];
  };

  const [scores, bmiScore] = getScoresFromVisits();

  return (
    <div className="d-flex flex-column profile-main-content">
      <div className="text-main-title">Assessment Results</div>
      <div className="d-flex flex-column main-content-row">
        {scores.phq.length > 0 && <AssessmentResult type="phq" title="DEPRESSION" data={scores.phq} />}
        {scores.gad.length > 0 && <AssessmentResult type="gad" title="ANXIETY" data={scores.gad} />}
        {scores.isi.length > 0 && <AssessmentResult type="isi" title="INSOMNIA" data={scores.isi} />}
        {scores.asrs.length > 0 && <AssessmentResult type="asrs" title="ADHD" data={scores.asrs} />}
        {scores.alcohol.length > 0 && <AssessmentResult type="alcohol" title="ALCOHOL" data={scores.alcohol} />}
        {scores.smoking.length > 0 && <AssessmentResult type="smoking" title="Smoking" data={scores.smoking} />}
        {bmiScore && <NutritionResult bmiScore={bmiScore} />}
      </div>
    </div>
  );
};

export default Assessment;
