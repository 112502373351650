import styled from 'styled-components/macro';

export const MedicationName = styled.p``;
export const Refill = styled.p``;
export const Renewal = styled.p``;
export const ShippingInfomation = styled.p``;

export const PrescriptionWrapper = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #250044;
  margin-bottom: 15px;
`;

export const StatusIndicator = styled.div<{ isActive?: boolean; consentNeeded?: boolean }>`
  width: 17px;
  height: 17px;
  margin-right: 19px;
  border-radius: 50%;
  background: ${({ isActive, consentNeeded }) => {
    if (consentNeeded) return '#EB5757';
    if (isActive) return '#65C9AD';
    return '#FF2768';
  }};
`;

export const StatusText = styled.div<{ spaced?: boolean }>`
  line-height: 20px;
  color: #6786ff;
  flex: 1;
  margin: ${({ spaced }) => (spaced ? '30px 0' : '0')};

  span {
    font-weight: 600;
  }
  a {
    text-decoration: underline;
    color: #6786ff;
  }
  p {
    margin-bottom: 11px;
  }
`;

export const StatusHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;
