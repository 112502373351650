import React, { useState } from 'react';
import { BlueHeader, Modal, WhiteExclamation, WhiteTitle } from 'components/UI/Modal';
import TextButton from 'components/UI/TextButton';
import { noop } from 'utils/default-values';
import { ConsentButton } from '../styled';
import { ModalContent } from './styled';

export interface Props {
  show: boolean;
  onHide?: () => void;
  switchToApprove: () => void;
  onDeny: () => void;
}

const ConsentDenyModal: React.FC<Props> = ({ show, onHide = noop, onDeny, switchToApprove }) => {
  const [disabled, setDisabled] = useState(false);
  const sendResponse = async () => {
    setDisabled(true);
    await onDeny();
    setDisabled(false);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <BlueHeader>
        <WhiteExclamation />
        <WhiteTitle>You are about to cancel your current medication order.</WhiteTitle>
      </BlueHeader>
      <ModalContent>
        <p>Warning: there can be potential withdrawal symptoms and health risks if you stop taking your medication.</p>

        <p>
          <strong>By choosing to delay your medication, your current order will be cancelled.</strong>
        </p>

        <p>If there are any issues or concerns with your current prescription, please contact our support team:</p>

        <p>
          <strong>
            support@getcerebral.com
            <br />
            (415) 403 - 2156
          </strong>
        </p>
        <ConsentButton onClick={switchToApprove} disabled={disabled}>
          I changed my mind
        </ConsentButton>
        <TextButton onClick={sendResponse} disabled={disabled}>
          Delay my medication for now
        </TextButton>
      </ModalContent>
    </Modal>
  );
};

export default ConsentDenyModal;
