/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import strings from 'localization';
import * as Yup from 'yup';
import { isPast, isValid, addMinutes, format } from './date-fns';
import error_checker from './error_checker';
import tracking from './tracking';

export const capitalize = word => {
  return word[0].toUpperCase() + word.substring(1);
};

export const common_errors = {
  state_selector: 'Please select the state you live in from the list',
  first_name: 'This field can’t be blank. Please enter your first name here.',
  last_name: 'This field can’t be blank. Please enter your last name here',
  email: 'Oops! Please enter a valid email address. Example: example@email.com',
  password: 'Your password must contain at least 6 characters',
  password_length: 'be at least 8 characters long',
  password_upper_and_lower_case: 'include both upper and lower case letters',
  password_at_least_one_number: 'include at least 1 number (1, 2, 3, 4, 5, 6, 7, 8, 9, 0)',
  password_at_least_one_symbol:
    'include at least 1 special symbol (! ” # $ % & ’ ( ) * + , - / : ; < > = @ { } [ ] ^ _ ~)',
  password_confirm: 'Please make sure that your passwords match.',
  is_consent: 'Oops! The consent box needs to be checked to continue.',
  phone_number:
    'Hmm...the phone number you entered does not seem right. This field can only contain a valid phone number',
  birth_date: 'Oops! Please enter your date of birth in the following format: (Example mm/dd/yyyy)',
  birth_date_uk: 'Oops! Please enter your date of birth in the following format: (Example dd/mm/yyyy)',
  is_adult: 'Unfortunately, you have to be 18 years old or over to be eligible for our service.',
  is_adult_uk: 'Unfortunately, you have to be 18 years old or over to be eligible for our service.',
  weight: 'Oops! Please enter your weight in lbs.',
  height_inch: 'You have to input your height in inches by selecting an option from the dropdown menu',
  height_ft: 'You have to input your height in feet by selecting an option from the dropdown menu',
  selected_checkbox: 'Oops! No answers were checked off. You have to check at least one box.',
  elaboration_box: 'Oops! This field can’t be blank.',
  address_1: 'Oops! This line can’t be empty. Please insert your shipping address.',
  city: 'Oops! This line can’t be empty. Please type in the name of the city you live in.',
  region: 'Oops! Please select the state you live in.',
  postal_code: 'Sorry! Please type in the zipcode you live in.',
  postal_code_uk: 'Sorry! Please type in the postcode you live in.',
  name_on_card: 'Oops! This field can’t be blank. Please enter the name that appears on your credit card.',
  sign_up: 'This email/password combination is invalid. Please try again.',
  have_an_account: 'Email address already in use.',
  therapist_referral_general: 'You have to enter at least one client’s contact information to move forward.',
  referral_first_name: 'Please enter your client’s first name here.',
  referral_last_name: 'Please enter your client’s last name here.',
  referral_email: 'Oops! Please enter a valid email address. Example: example@email.com',
  emergency_contact_first_name: 'This field can’t be blank.',
  emergency_contact_last_name: 'This field can’t be blank.',
  emergency_contact_phone_number:
    'Hmm...the phone number you entered does not seem right. This field can only contain a valid phone number',
  sms_opted_in: 'You must opt into text messaging to create an account.',
  service_line_not_available: 'We are not live in your state yet, but please check back soon!'
};

export const resizeImage = (screenshot, cb) => {
  const img = new Image();

  img.onload = () => {
    const canvas = document.createElement('canvas');
    const max_size = 1200;

    let { width } = img;
    let { height } = img;

    if (width > height && width > max_size) {
      height *= max_size / width;
      width = max_size;
    } else if (height > max_size) {
      width *= max_size / height;
      height = max_size;
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(img, 0, 0, width, height);
    const resizedImage = canvas.toDataURL('image/jpeg');

    cb(resizedImage);
  };

  img.src = screenshot;
};

export const mapPlanToCouponCodeMedicalManagement = plan => {
  switch (plan) {
    case 'medication-management-50':
      return {
        coupon: strings.defaultMedicationManagementTXAndCA,
        price: strings.defaultMedicationManagementTXAndCAPrice,
        offering: plan
      };
    case 'medication-management-85':
      return {
        coupon: strings.defaultMedicationManagementNYAndFL,
        price: strings.defaultMedicationManagementNYAndFLPrice,
        offering: plan
      };
    default:
      return {};
  }
};

export const mapPlanToCouponSelingUpgrade = plan => {
  switch (plan) {
    case 'nicotine-counseling':
      return {
        coupon: strings.defaultNicotineCounselingCode,
        price: strings.defaultNicotineCounselingCodePrice,
        offering: plan
      };
    case 'bupropion-counseling':
      return {
        coupon: strings.defaultBupropionCounselingCode,
        price: strings.defaultBupropionCounselingCodePrice,
        offering: plan
      };
    case 'bupropion-nicotine-counseling':
      return {
        coupon: strings.defaultBupropionNicotineCounselingCode,
        price: strings.defaultBupropionNicotineCounselingCodePrice,
        offering: plan
      };
    case 'nicotine':
      return {
        coupon: strings.defaultNicotineCode,
        price: strings.defaultNicotineCodePrice,
        offering: plan
      };
    case 'bupropion':
      return {
        coupon: strings.defaultBupropionCode,
        price: strings.defaultBupropionCodePrice,
        offering: plan
      };
    case 'bupropion-nicotine':
      return {
        coupon: strings.defaultBupropionNicotineCode,
        price: strings.defaultBupropionNicotineCodePrice,
        offering: plan
      };
    case 'nutrition-medication-management':
      return {
        coupon: strings.defaultNutritionMedicationCode,
        price: strings.defaultNutritionMedicationCodePrice,
        offering: plan
      };
    case 'nutrition-medication-management-glp1':
      return {
        coupon: strings.defaultNutritionMedicationGlp1Code,
        price: strings.defaultNutritionMedicationGlp1CodePrice,
        offering: plan
      };
    case 'nutrition-management':
      return {
        coupon: strings.defaultNutritionCode,
        price: strings.defaultNutritionCodePrice,
        offering: plan
      };
    default:
      return {};
  }
};

export const conselingUpgradePlanOrderedList = [
  'bupropion-nicotine-counseling',
  'bupropion-counseling',
  'nicotine-counseling',
  'bupropion-nicotine',
  'bupropion',
  'nicotine'
];

export const upgradePlanOrderedList = [
  'bupropion-nicotine-counseling',
  'bupropion-counseling',
  'nicotine-counseling',
  'bupropion-nicotine',
  'bupropion',
  'nicotine',
  'nutrition-management',
  'nutrition-medication-management',
  'nutrition-medication-management-glp1',
  'nutrition-medication-management-glp1-therapy',
  'nutrition-management-therapy',
  'nutrition-medication-management-therapy'
];

export const planMedicationManagement = ['medication-management-50', 'medication-management-85'];

export const planOrderedBeforeUpgradeList = [
  'bupropion',
  'nicotine',
  'bupropion-nicotine',
  'nutrition-management',
  'nutrition-medication-management',
  'nutrition-medication-management-glp1'
];

export const getCarePromo = (planId, defaultPromo, priceSensitivityCouponABTest, priceSensitivity) => {
  if (defaultPromo === strings.defaultSMCampaignCode) {
    return defaultPromo;
  }

  if (priceSensitivityCouponABTest && priceSensitivityCouponABTest !== 'Reference') {
    switch (priceSensitivityCouponABTest) {
      case 'Group 2':
        return (
          {
            medication: 'CEREBRAL15',
            'medication-therapy': 'CEREBRAL79',
            therapy: 'CEREBRAL65',
            'nutrition-management': strings.defaultNutritionCode,
            'nutrition-medication-management': strings.defaultNutritionMedicationCode,
            bupropion: strings.defaultBupropionCode,
            nicotine: strings.defaultNicotineCode,
            'bupropion-nicotine': strings.defaultBupropionNicotineCode,
            'medication-adhd-115-management': strings.defaultMedicationAdhd115,
            'medication-adhd-135-management': strings.defaultMedicationAdhd135
          }[planId] || defaultPromo
        );
      case 'Group 3':
        return (
          {
            medication: 'CEREB49',
            'medication-therapy': 'CEREB195',
            therapy: 'CEREB155',
            'nutrition-management': strings.defaultNutritionCode,
            'nutrition-medication-management': strings.defaultNutritionMedicationCode,
            bupropion: strings.defaultBupropionCode,
            nicotine: strings.defaultNicotineCode,
            'bupropion-nicotine': strings.defaultBupropionNicotineCode,
            'medication-adhd-115-management': strings.defaultMedicationAdhd115,
            'medication-adhd-135-management': strings.defaultMedicationAdhd135
          }[planId] || defaultPromo
        );
      case 'Group 4':
        return (
          {
            medication: 'CERE65',
            'medication-therapy': 'CERE245',
            therapy: 'CERE195',
            'nutrition-management': strings.defaultNutritionCode,
            'nutrition-medication-management': strings.defaultNutritionMedicationCode,
            bupropion: strings.defaultBupropionCode,
            nicotine: strings.defaultNicotineCode,
            'bupropion-nicotine': strings.defaultBupropionNicotineCode,
            'medication-adhd-115-management': strings.defaultMedicationAdhd115,
            'medication-adhd-135-management': strings.defaultMedicationAdhd135
          }[planId] || defaultPromo
        );
      case 'Group 5':
        return {
          medication: '',
          'medication-therapy': '',
          therapy: '',
          'nutrition-management': strings.defaultNutritionCode,
          'nutrition-medication-management': strings.defaultNutritionMedicationCode,
          bupropion: strings.defaultBupropionCode,
          nicotine: strings.defaultNicotineCode,
          'bupropion-nicotine': strings.defaultBupropionNicotineCode,
          'medication-adhd-115-management': strings.defaultMedicationAdhd115,
          'medication-adhd-135-management': strings.defaultMedicationAdhd135
        }[planId];
      default:
        break;
    }
  }

  if (priceSensitivity && priceSensitivity === 'PriceS2') {
    return (
      {
        therapy: strings.defaultTherapyCode,
        'medication-therapy': strings.defaultMedsTherapyCode,
        '99_med_management': strings.defaultMed99
      }[planId] || defaultPromo
    );
  }
  return (
    {
      therapy: strings.defaultTherapyCampaignCode,
      'medication-therapy': strings.defaultMedsTherapyCampaignCode,
      'nutrition-management': strings.defaultNutritionCode,
      'nutrition-medication-management': strings.defaultNutritionMedicationCode,
      'nutrition-medication-management-glp1': strings.defaultNutritionMedicationGlp1Code,
      bupropion: strings.defaultBupropionCode,
      nicotine: strings.defaultNicotineCode,
      'bupropion-nicotine': strings.defaultBupropionNicotineCode,
      'medication-adhd-115-management': strings.defaultMedicationAdhd115,
      'medication-adhd-135-management': strings.defaultMedicationAdhd135,
      '99_med_management': strings.defaultMed99,
      '109_med_management': strings.defaultMed109,
      '345_meds_therapy': strings.defaultMedsTherapy345,
      '275_therapy': strings.defaultTherapy275,
      '119_med_management': strings.defaultMed119,
      '365_meds_therapy': strings.defaultMedsTherapy365,
      '290_therapy': strings.defaultTherapy290
    }[planId] || defaultPromo
  );
};

export const smokingPlanList = ['bupropion-nicotine', 'bupropion', 'nicotine'];

export const getSmokingAddonCode = offeringKey => {
  if (smokingPlanList.includes(offeringKey)) {
    return strings.defaultAssessmentFeeAddonCode;
  }
  return '';
};

export const nutritionPlanOrderedList = [
  'nutrition-management',
  'nutrition-medication-management',
  'nutrition-medication-management-glp1'
];

export const testCards = [
  '370000000000002',
  '6011000000000012',
  '3088000000000017',
  '38000000000006',
  '4007000000027',
  '4012888818888',
  '4111111111111111',
  '5424000000000015',
  '2223000010309703',
  '2223000010309711'
];

export const calculateStep = (number1, number2) => {
  if (number2 === 0) {
    return 0;
  }
  return Math.round((number1 / number2) * 100) / 100;
};

// States get discount CARE79 and CARE99
export const allowDiscount = state => {
  return ['AR', 'LA', 'GA', 'OH', 'IL', 'FL', 'TX', 'MN', 'IN', 'AL', 'MA'].includes(state);
};

// only selected states can see new plans
export const directProviderMessagingAndTherapy = state => {
  return ['MO'].includes(state);
};

// ideally fetch it from BE
const insuranceStates = ['CA'];

export const allowInsurance = state => {
  return insuranceStates.includes(state);
};

export const verifyLuhn = num => {
  let digit;
  let j;
  let len;
  let odd;
  let sum;
  odd = true;
  sum = 0;
  const digits = `${num}`.split('').reverse();
  // eslint-disable-next-line no-plusplus
  for (j = 0, len = digits.length; j < len; j++) {
    digit = digits[j];
    digit = parseInt(digit, 10);

    odd = !odd;
    if (odd) {
      digit *= 2;
    }
    if (digit > 9) {
      digit -= 9;
    }
    sum += digit;
  }
  return sum % 10 === 0;
};

export const getCardTypeAndMaxLength = number => {
  const onlyDigits = number.replace(/\D/g, '');

  if (/^3[47]\d{0,13}$/.test(onlyDigits.substring(0, 15))) {
    return ['AmEx', 17, onlyDigits];
  }

  if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(onlyDigits.substring(0, 14))) {
    return ['DinerClub', 16, onlyDigits];
  }

  return ['RegularCC', 19, onlyDigits];
};

export const cvvLengthByType = type => {
  if (type === 'AmEx') {
    return 4;
  }

  return 3;
};

export function formatCardNumber(value) {
  const [type, maxLength, onlyDigits] = getCardTypeAndMaxLength(value);

  let formatted;

  if (type === 'AmEx') {
    // american express, 15 digits
    formatted = onlyDigits.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
  } else if (type === 'DinerClub') {
    // diner's club, 14 digits
    formatted = onlyDigits.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
  } else {
    // regular cc number, 16 digits
    formatted = onlyDigits
      .replace(/(\d{4})/, '$1 ')
      .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
      .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
  }

  if (onlyDigits.length <= maxLength) {
    return formatted;
  }

  return formatted.substring(0, maxLength);
}

export const insuranceQuestionBankNames = {
  'follow-up-assessment': [
    'information_follow-up',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end'
  ],
  therapy: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'progress_checkout',
    'emergency_contact',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  medication: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'visit_type',
    'visit_with_provider'
  ],
  'medication-therapy': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  counseling: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'progress_checkout',
    'emergency_contact'
  ]
};

export const cashQuestionBankNames = {
  'follow-up-assessment': [
    'information_follow-up',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'alcohol_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'assessment_end'
  ],
  therapy: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'alcohol_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'progress_checkout',
    'emergency_contact',
    'communication',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  medication: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'communication',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_counselor_preferences',
    'counselor_preferences',
    'information_counselor',
    'choose_counselor'
  ],
  'medication-therapy': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'communication',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist'
  ],
  counseling: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'assessment_end',
    'plan_selection',
    'progress_checkout',
    'emergency_contact',
    'manic_depression',
    'communication',
    'information_counselor_preferences',
    'counselor_preferences',
    'information_counselor',
    'choose_counselor',
    'oon_insurance'
  ],
  'bupropion-nicotine': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'smoking_update_plan',
    'survey',
    'oon_insurance',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'health_and_history_smoking',
    'information_smoking'
  ],
  bupropion: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'smoking_update_plan',
    'survey',
    'oon_insurance',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'health_and_history_smoking',
    'information_smoking'
  ],
  nicotine: [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'smoking_update_plan',
    'survey',
    'oon_insurance',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'health_and_history_smoking',
    'information_smoking'
  ],
  'bupropion-nicotine-counseling': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'smoking_update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'health_and_history_smoking',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'communication',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_counselor_preferences',
    'counselor_preferences',
    'information_counselor',
    'choose_counselor'
  ],
  'bupropion-counseling': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'smoking_update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'health_and_history_smoking',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'communication',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_counselor_preferences',
    'counselor_preferences',
    'information_counselor',
    'choose_counselor'
  ],
  'nicotine-counseling': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'smoking_update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'health_and_history_smoking',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'communication',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_counselor_preferences',
    'counselor_preferences',
    'information_counselor',
    'choose_counselor'
  ],
  'medication-counseling-nutrition-management': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'communication',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_counselor_preferences',
    'counselor_preferences',
    'information_counselor',
    'choose_counselor',
    'post_checkout_nutrition_screening',
    'nutrition_information_screen_post_checkout',
    'post_checkout_upsell_nutrition_question_list',
    'information_nutritionist_preferences',
    'choose_nutritionist'
  ],
  'medication-therapy-nutrition-management': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'information_assessment',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'progress_assessment',
    'information_identity',
    'identity_verification',
    'progress_identity',
    'communication',
    'visit_type',
    'visit_with_provider',
    'progress_provider_visit',
    'information_therapist_preferences',
    'therapist_preferences',
    'information_therapists',
    'choose_therapist',
    'post_checkout_nutrition_screening',
    'nutrition_information_screen_post_checkout',
    'post_checkout_upsell_nutrition_question_list',
    'information_nutritionist_preferences',
    'choose_nutritionist'
  ],
  'therapy-nutrition-management': [
    'information_screening',
    'insomnia_assessment_full',
    'depression_assessment_full',
    'anxiety_assessment_full',
    'adhd_assessment_full',
    'smoking_assessment_full',
    'alcohol_assessment_full',
    'assessment_end',
    'in_insurance',
    'checkout',
    'update_plan',
    'survey',
    'oon_insurance',
    'second_medicare',
    'shipping_address',
    'choose_pharmacy',
    'progress_checkout',
    'medication_insurance',
    'emergency_contact',
    'health_and_history_part1',
    'no_manic_depression_selected',
    'manic_depression_selected',
    'manic_depression',
    'health_and_history_part2',
    'communication',
    'post_checkout_nutrition_screening',
    'nutrition_information_screen_post_checkout',
    'post_checkout_upsell_nutrition_question_list',
    'information_nutritionist_preferences',
    'choose_nutritionist'
  ]
};

const flows_removed_for_dpm = [
  'update_plan',
  'progress_provider_visit',
  'information_counselor_preferences',
  'counselor_preferences',
  'information_counselor',
  'choose_counselor'
];

const flows_removed_for_dpm_with_therapy = [
  'progress_provider_visit',
  'information_counselor_preferences',
  'counselor_preferences',
  'information_counselor',
  'choose_counselor'
];

export const bankToPath = bankNames => (path, bank) => {
  const idx = bankNames.indexOf(bank.name);
  const pathArr = path;
  if (idx > -1) {
    pathArr[idx] = bank;
  }
  return pathArr;
};

export const customDifference = ({ newBanks, oldBanks, isProviderNeeded = false, isTherapistNeeded = false }) => {
  const providerBanks = ['progress_identity', 'visit_type', 'visit_with_provider', 'progress_provider_visit'];
  const therapistBanks = ['information_therapists', 'choose_therapist'];
  const setB = new Set(oldBanks);

  const requiredBanks = [];
  if (isProviderNeeded) {
    requiredBanks.push(...providerBanks);
  }

  if (isTherapistNeeded) {
    requiredBanks.push(...therapistBanks);
  }

  const customFilter = bank => {
    if (requiredBanks.includes(bank)) {
      return true;
    }
    return !setB.has(bank);
  };

  return newBanks.filter(customFilter);
};

const handlePendingUpdates = (change, isPilot, region) => {
  const previousChangeBanks = change.is_old_plan_insurance ? insuranceQuestionBankNames : cashQuestionBankNames;
  const newChangeBanks = change.is_new_plan_insurance ? insuranceQuestionBankNames : cashQuestionBankNames;

  const diff = customDifference({
    newBanks: newChangeBanks[change.new_offering_key],
    oldBanks: previousChangeBanks[change.old_offering_key],
    isProviderNeeded: change.needs_insurance_provider,
    isTherapistNeeded: change.needs_insurance_therapist
  });

  if (diff[diff.length - 1] === 'progress_provider_visit') {
    diff.pop();
  }

  // cut all counselor_preferences screens
  if (isPilot && directProviderMessagingAndTherapy(region)) {
    return diff.filter(name => !flows_removed_for_dpm_with_therapy.includes(name));
  }
  if (isPilot) {
    return diff.filter(name => !flows_removed_for_dpm.includes(name));
  }

  return diff;
};

export const handleInitialPlanChange = (flowType = 'medication', isInsurance, isPilot, region) => {
  const banks = isInsurance ? insuranceQuestionBankNames : cashQuestionBankNames;
  const planFlows = banks[flowType] || banks['follow-up-assessment'];

  // cut all counselor_preferences screens
  if (isPilot && directProviderMessagingAndTherapy(region)) {
    return planFlows.filter(name => !flows_removed_for_dpm_with_therapy.includes(name));
  }
  if (isPilot) {
    return planFlows.filter(name => !flows_removed_for_dpm.includes(name));
  }

  return planFlows;
};

export const toBankNames = (pending, change, flowType, isInsurance, isPilot, region) => {
  if (pending) {
    return handlePendingUpdates(change, isPilot, region);
  }
  return handleInitialPlanChange(flowType, isInsurance, isPilot, region);
};

export const getNumberOfQuestionsBefore = (banks, before, isPaymentSubmitted) => {
  let sum = 0;

  if (isPaymentSubmitted) {
    let idx = banks.findIndex(bank => bank.name === 'checkout');
    if (idx < 0) {
      idx = 0;
    }
    // eslint-disable-next-line no-plusplus
    for (let i = idx; i < before; i++) {
      sum += banks[i].num_questions;
      if (banks[i].name === 'checkout') {
        sum -= 1; // -1 is payment page
      }
    }
    return sum;
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < before; i++) {
    sum += banks[i].num_questions;
  }
  return sum;
};

export const getNumberOfQuestions = (banks, isPaymentSubmitted, isFollowUp) => {
  if (isFollowUp) {
    return banks.reduce((acc, bank) => acc + bank.num_questions, 0) - 1; // -1 removed one(last question from assessment_end)
  }

  let idx = banks.findIndex(bank => bank.name === 'checkout');
  if (isPaymentSubmitted) {
    let extra = -1; // -1 is for payment page if exists
    if (idx < 0) {
      idx = 0;
      extra = 0;
    }
    return banks.slice(idx).reduce((acc, bank) => acc + bank.num_questions, 0) + extra;
  }
  return banks.slice(0, idx).reduce((acc, bank) => acc + bank.num_questions, 0);
};

export const isBeforePayment = status => {
  return ['lead', 'canceled'].includes(status);
};

// latest visit is the first visit
export const latestVisit = arr => {
  if (arr.length > 0) {
    return arr[0];
  }

  return null;
};

export const latestInitialVisit = visits => {
  const initials = visits.filter(visit => visit.is_initial === true);
  return latestVisit(initials);
};

export const isVisitCompleted = visit => {
  const { complete, pending_updates } = visit;

  return complete && !pending_updates;
};

export const isRequiredQuestion = questionName => {
  return [
    'sex',
    'allergy',
    'emergency_contact',
    'current_medications',
    'choose_in_insurance',
    'choose_oon_insurance',
    'prv_med',
    'hh_med',
    'specific_meds'
  ].includes(questionName);
};

export const formatDate = date => {
  if (!date) {
    return null;
  }

  const [year, month, day] = date.split('-').map(Number);
  return [month, day, year].join('/');
};

export const removeDuplicates = (arrOfObj, prop) => {
  const arr = arrOfObj.map(obj => obj[prop]);
  return [...new Set(arr)];
};

export const mapCodeToError = err => {
  if (err.code) {
    return { message: 'Please confirm that your payment details are correct.' };
  }

  if (err.data) {
    return err.data;
  }

  return { message: '' };
};

export const valid_states = [
  ['california', 'ca'],
  ['ohio', 'oh'],
  ['florida', 'fl'],
  ['georgia', 'ga'],
  ['maryland', 'md'],
  ['pennsylvania', 'pa'],
  ['texas', 'tx'],
  ['michigan', 'mi'],
  ['illinois', 'il'],
  ['new york', 'ny'],
  ['washington', 'wa'],
  ['virginia', 'va'],
  ['connecticut', 'ct'],
  ['colorado', 'co'],
  ['oregon', 'or'],
  ['indiana', 'in'],
  ['minnesota', 'mn'],
  ['utah', 'ut'],
  ['wisconsin', 'wi'],
  ['iowa', 'ia'],
  ['district of columbia', 'dc'],
  ['kentucky', 'ky'],
  ['tennessee', 'tn'],
  ['new mexico', 'nm'],
  ['north carolina', 'nc'],
  ['arizona', 'az'],
  ['new jersey', 'nj'],
  ['massachusetts', 'ma'],
  ['idaho', 'id'],
  ['south dakota', 'sd'],
  ['maine', 'me'],
  ['alabama', 'al'],
  ['nevada', 'nv'],
  ['alaska', 'ak'],
  ['north dakota', 'nd'],
  ['new hampshire', 'nh'],
  ['rhode island', 'ri'],
  ['delaware', 'de'],
  ['west virginia', 'wv'],
  ['wyoming', 'wy'],
  ['montana', 'mt'],
  ['south carolina', 'sc'],
  ['nebraska', 'ne'],
  ['kansas', 'ks'],
  ['mississippi', 'ms'],
  ['missouri', 'mo'],
  ['oklahoma', 'ok'],
  ['arkansas', 'ar'],
  ['hawaii', 'hi'],
  ['vermont', 'vt'],
  ['louisiana', 'la'],
  ['united kingdom', 'uk']
];

export const isRegionValid = region => {
  return valid_states.some(valid_state => valid_state.includes(region.toLowerCase()));
};

export const email_validation = email => {
  const emailFormat = /^\w+([.(-|+)]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return !!email.match(emailFormat);
};

export const dobValidation = date => {
  if (!date) return false;

  const d = new Date(date).setHours(12);
  const before = new Date('01/01/1900');

  if (isValid(d)) {
    const formatted = format(d, {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });

    return d > before && date === formatted;
  }

  return false;
};

export const formatDob = (dob, type) => {
  if (!dob) return '';

  if (type === 'dashes') {
    if (dob.indexOf('/') === -1) return dob;
    const [mm, dd, yyyy] = dob.split('/');
    return `${yyyy}-${mm}-${dd}`;
  }

  if (dob.indexOf('/') >= 0) return dob;
  if (dob.indexOf('-') === -1) return dob;
  const [yyyy, mm, dd] = dob.split('-');
  return `${mm}/${dd}/${yyyy}`;
};

export const stateToErrorReducer = (state, prefix = '') =>
  Object.keys(state).reduce((err, type) => {
    const errObj = err;
    if (error_checker[type]) {
      if (type === 'password_confirm') {
        if (!error_checker[type](state[type], state.password)) {
          errObj[type] = common_errors[prefix + type];
        }
        return errObj;
      }

      if (!error_checker[type](state[type])) {
        errObj[type] = common_errors[prefix + type];
      }
    }
    return errObj;
  }, {});

export const imgtoBlob = (img, contentType = '', sliceSize = 512) => {
  const b64Data = img.replace(/^data:.+;base64,/, '');

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
};

export const findUrlsInText = text => {
  const parts = text.split(/(https?:\/\/[^\s]+)/g);
  for (let i = 1; i < parts.length; i += 2) {
    parts[i] = (
      <a key={`link${i}`} href={parts[i]} target="_blank" rel="noopener noreferrer">
        {parts[i]}
      </a>
    );
  }

  return parts;
};

export const isManicDepression = answer => {
  if (Array.isArray(answer)) {
    const found = answer.find(option => option.name === 'Manic depression (Bipolar disorder)');
    return !!found;
  }

  if (typeof answer === 'object') {
    return answer.answer === 'No';
  }

  return false;
};

export const getStartDate = date => {
  if (date) {
    return new Date(formatDate(date));
  }

  return new Date();
};

export const isUserActive = status => {
  return status && !['canceled', 'paused'].includes(status);
};

export const isUserCanceled = status => {
  return status === 'canceled';
};

export const getNextBankOffset = (questionName, answer) => {
  switch (questionName) {
    case 'mental_health_conditions':
      return isManicDepression(answer) + 1;
    case 'elated_mood':
      return isManicDepression(answer) + 2;
    default:
      return 0;
  }
};

export const is_touch_device = () => {
  return ('ontouchstart' in window || window.DocumentTouch) === true;
};

export const sleep = timeout => new Promise(resolve => setTimeout(resolve, timeout));
export const fail = timeout => new Promise((resolve, reject) => setTimeout(reject, timeout));

export const defaultError = 'Hmmm… It seems like something went wrong. Please try again.';

export const mapResponseToError = error => {
  if (!error) {
    return defaultError;
  }

  const { data = {} } = error;
  const key = Object.keys(data).find(item => typeof data[item] !== 'boolean');

  if (Array.isArray(data[key]) && data[key].length > 0) {
    return data[key][0];
  }

  if (typeof data[key] === 'string' && data[key].length > 0) {
    return data[key];
  }

  return defaultError;
};

export const useDelay = () => {
  const timeoutRef = useRef(0);
  const targetRef = useRef();
  const callbackRef = useRef();

  const check = () => {
    if (isPast(targetRef.current)) {
      callbackRef.current();
    } else {
      timeoutRef.current = setTimeout(check, 1000);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (time, fn) => {
    targetRef.current = time;
    callbackRef.current = fn;

    check();
  };
};

export const mapNameToType = type =>
  ({
    care_manager: 'Care Counselor',
    provider: 'prescriber',
    therapist: 'therapist'
  }[type] || type);

export const medicationName = prescription => {
  if (!prescription) return '';
  const name = prescription.medication_name_generic || prescription.medication_name_for_local_pharmcy;

  return name ? capitalize(name) : '';
};

export const scheduleLink = ({ appointment }) => {
  switch (appointment.appointment_type) {
    case 'therapist':
      return '/patient/schedule/therapist/schedule-th/45';
    case 'nutritionist':
      return '/patient/schedule/nutritionist/schedule-nt/30';
    case 'care_manager':
      return '/patient/schedule/care_manager/schedule-cm/30';
    default:
      return '/dashboard';
  }
};

export const appointmentStatusBarTime = ({ appointment }) => {
  if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    return addMinutes(new Date(), 1);
  }

  let delay = 0;

  switch (appointment.appointment_type) {
    case 'therapist':
      delay = 38;
      break;
    case 'care_manager':
      delay = 28;
      break;
    case 'nutritionist':
      delay = 28;
      break;
    default:
      throw new Error('This appointment type doesnt use an iFrame bar delay');
  }

  return addMinutes(appointment.starts_at, delay);
};

export const getAbtests = () => {
  const params = new URLSearchParams(window.location.search);
  let param = params.get('cabt') || Cookies.get('cabt');

  if (param) {
    try {
      try {
        param = window.atob(param);
        // eslint-disable-next-line no-empty
      } catch (e) {}

      const pairs = param.split(',');

      const abtests = pairs.reduce((accumulator, pair) => {
        const [key, value] = pair.split(':');
        accumulator[key] = value;
        return accumulator;
      }, {});

      if (params.has('cabt')) {
        Cookies.set('cabt', param, { expires: 30, domain: process.env.REACT_APP_COOKIE_DOMAIN });
      }

      return abtests;
    } catch (e) {
      tracking.error({ e, message: 'Error parsing abtest params' });
    }
  }

  return {};
};

const fetchPrismicMasterRef = async () => {
  const res = await (await fetch('https://cerebral.cdn.prismic.io/api/v2')).json();
  const { ref } = res.refs.find(r => r.isMasterRef);

  return ref;
};

const prismicLanguage = country => {
  if (country === 'uk') {
    return 'en-gb';
  }
  return 'en-us';
};

export const fetchPrismicPromo = async ({ promo }, country) => {
  try {
    const ref = await fetchPrismicMasterRef();
    const uid = promo.toLowerCase();
    const response = await fetch(
      `https://cerebral.cdn.prismic.io/api/v2/documents/search?ref=${ref}&q=%5B%5Bat(document.type%2C+%22promo%22)%5D%5D&q=%5B%5Bany(my.promo.uid%2C+%5B%22${uid}%22%5D)%5D%5D&format=json&lang=${prismicLanguage(
        country
      )}`
    );
    const { results } = await response.json();

    if (results.length === 0) {
      return {};
    }

    const { data } = results.find(r => r.uid === uid);
    const {
      plan_price: {
        0: { text: plan_price }
      },
      plan_under_price_text: {
        0: { text: plan_under_price_text }
      },
      plan_price_strikethrough,
      payment_green_banner
    } = data;

    return { plan_price, plan_price_strikethrough, plan_under_price_text, payment_green_banner };
  } catch (e) {
    // TODO: we should do something better here, but at least this will prevent the app from crashing
    tracking.error({ e, message: 'Error fetching promo text from prismic' });

    return {};
  }
};

export const formatCurrency = value => {
  const instance = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  return instance.format(value);
};

/**
 * Checks if the user-agent is via iOS, Android, or something else.
 *
 * @returns 'iOS', 'Android', undefined
 */
export const getMobileType = () => {
  const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
  const isIos = Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  if (isIos) return 'iOS';

  const isAndroid = Boolean(userAgent.match(/Android/i));
  if (isAndroid) return 'Android';

  return undefined;
};

export const getCBTExerciseTitle = message => {
  const arr = [...message.matchAll(/http\+exercise\/([a-zA-Z0-9_\-()]*)$/g)];

  return !arr.length ? '' : arr[0][1];
};

export const valueFromStorage = key => (typeof window !== 'undefined' ? localStorage.getItem(key) : null);

export const countryFromStorage = () => valueFromStorage('country') || 'us';

export const countryFromUrl = () => {
  const { host } = window.location;
  const regex = /[^.]+$/gm;
  const domainExtensionArray = host.match(regex);
  let domainExtension = '';

  if (domainExtensionArray != null) {
    domainExtension = domainExtensionArray[0];
  }

  if (domainExtension === 'uk') {
    localStorage.setItem('country', 'uk');
    return 'uk';
  }
  localStorage.setItem('country', 'us');
  return 'us';
};

const b2bEligibleCountries = ['us'];

export const isB2bEligibleCountry = country => b2bEligibleCountries.includes(country);

export const convertIdToPlanName = (id, name) => {
  switch (id) {
    case 'medication-therapy-smoking-cessation-treatment':
      return 'Medication + Therapy + Smoking Cessation Treatment';
    case 'medication-smoking-cessation-treatment':
      return 'Medication + Smoking Cessation Treatment';
    case 'nutrition-management-therapy':
      return 'Nutrition + Therapy';
    case 'nutrition-medication-management-therapy':
      return 'Nutrition + Medication + Therapy';
    case 'medication-counseling-nutrition-management':
      return 'Medication + Nutrition';
    case 'medication-therapy-nutrition-management':
      return 'Medication + Therapy + Nutrition';
    default:
      return `${name} Plan`;
  }
};

export const isAddictionServiceLine = visits => {
  return visits?.some(({ service_lines }) =>
    service_lines?.some(({ name }) => name?.includes('smoking') || name?.includes('alcohol'))
  );
};

export const insuranceModalMessages = ServiceLine => {
  switch (ServiceLine) {
    case 'nutrition':
      return 'Adding photos of your insurance or pharmacy benefits card is required in order to have your medications covered by insurance. By skipping this step, you consent to paying full price for your medication when prescribed.';
    default:
      return `Adding photos of your pharmacy benefits card is required in order to have your medications covered by insurance. By skipping this step, you consent to paying full price for your medication when prescribed.`;
  }
};

export const isNutritionOffering = offeringKey => {
  return [
    'nutrition-management',
    'nutrition-medication-management',
    'nutrition-management-therapy',
    'nutrition-medication-management-therapy',
    'nutrition-medication-management-glp1',
    'nutrition-medication-management-glp1-therapy'
  ].includes(offeringKey);
};

const rxbinRegExp = /^[0-9]*$/;

export const validationSchema = Yup.object().shape({
  policy_id: Yup.string().required('Required'),
  rx_bin: Yup.string()
    .matches(rxbinRegExp, 'Your Rx Bin number has to be 6 digits long')
    .min(6, 'Your Rx Bin number has to be 6 digits long')
    .max(6, 'Your Rx Bin number has to be 6 digits long')
    .required('Required')
});

export const formatLabel = (label, value) => {
  if (!value) {
    return label;
  }
  return label.split(value).reduce((prev, current, i) => {
    if (!i) {
      return [current];
    }
    return prev.concat(<b key={value + current}>{value}</b>, current);
  }, []);
};
