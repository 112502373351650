import React, { useEffect } from 'react';
import { Container, Text } from '../NotCompletedFlow/styled';
import tracking from '../../../utils/tracking';

const NotExistingPage = ({ location }) => {
  const isDashboard = location.pathname === '/patient/dashboard';
  const errorMessage = `Oops,the page you are looking for ${location.pathname + location.search} does not exist`;

  useEffect(() => {
    if (!isDashboard) {
      tracking.error({ message: errorMessage });
    }
  }, [isDashboard]);

  if (isDashboard) {
    return null;
  }

  return (
    <Container>
      <Text>
        Oops, the page you are looking for
        <br />
        <b>{location.pathname + location.search}</b>
        <br />
        does not exist
      </Text>
    </Container>
  );
};

export default NotExistingPage;
