import React from 'react';
import Plans, { Plan as PlanType } from 'utils/plans';
import ImportantInformation from '../../ImportantInformation';
import Footnote from '../../ImportantInformation/Footnote';
import { formatCurrency } from '../../../utils/currency.util';
import strings from '../../../localization';

interface ImportantInformationContentProps {
  offeringKey: string;
  isInsurance: boolean;
  isNutritionServiceLine: boolean;
  isOnlySmoking: boolean;
  copayRange: (amount1: number, amount2?: number) => number;
  insurance: number;
  plan: PlanType;
  consultationFee: number;
  plans: typeof Plans;
}

const ImportantInformationContent: React.FC<ImportantInformationContentProps> = ({
  offeringKey,
  isInsurance,
  isNutritionServiceLine,
  isOnlySmoking,
  copayRange,
  insurance,
  plan,
  consultationFee,
  plans
}) => {
  return (
    <ImportantInformation removeTopFootnote={false}>
      {offeringKey.includes('medication') && !isInsurance && !isNutritionServiceLine ? (
        <Footnote>
          *Medication billed separately. Your medication costs will depend on which medication(s) your prescriber
          prescribes. Even without insurance, most medications cost only {formatCurrency(10)}/month.
        </Footnote>
      ) : null}
      {!isNutritionServiceLine && !isOnlySmoking && (
        <Footnote>
          {isInsurance &&
            `*Visit and medication copays are charged separately from your membership fee.  Your visit copay is estimated to be ${copayRange(
              insurance
            )}/visit. Your medication copay will depend on your insurance. Even without insurance, most medications cost only ${formatCurrency(
              10
            )}/month.`}
          {!isInsurance &&
            strings.getLanguage() === 'us' &&
            `*${offeringKey.includes('medication') ? '*' : ''}The $${
              plan.price
            }/month subscription includes $${consultationFee} for consultation fees and $${
              plans.membershipFee
            } for membership services.`}
        </Footnote>
      )}
    </ImportantInformation>
  );
};

export default ImportantInformationContent;
