import React, { createContext, useContext, useState } from 'react';
import { useApi } from 'utils/api';
import { useSelector } from 'react-redux';
import { Store } from 'types/store';

interface FlagData {
  [key: string]: boolean;
}

interface RolloutProps {
  loadFlags(): Promise<void>;
  flags: FlagData;
}

export const RolloutContext = createContext<RolloutProps>({
  loadFlags: () => new Promise<void>(resolve => resolve()),
  flags: {}
});

export const RolloutProvider: React.FC = ({ children }) => {
  const api = useApi();
  const [flags, setFlags] = useState<FlagData>({});
  const patient_id = useSelector((store: Store) => store.global_reducer.current_user.attributes.patient?.id);

  const loadFlags = () =>
    api
      .get<FlagData>('/api/v1/feature_flags', {
        params: {
          patient_id
        }
      })
      .then(res => setFlags(res.data))
      .catch(() => setFlags({}));

  return (
    <RolloutContext.Provider
      value={{
        loadFlags,
        flags
      }}
    >
      {children}
    </RolloutContext.Provider>
  );
};

export const useRollout = (): RolloutProps => useContext(RolloutContext);
