import React, { useState, useMemo, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import { prescriberVariant } from 'utils/care-person-helpers';
import SwitchCareTeamModal from '../../OutstandingTasksModal/SwitchCareTeamModal';
import Estimate from './Estimate';
import ImportantInformation from '../../ImportantInformation';
import Footnote from '../../ImportantInformation/Footnote';
import BackButton from '../../BackButton';
import Spinner from '../../Spinner';
import Error from '../../Error';
import { BlueHeader, WhiteIsotype, WhiteTitle } from '../../UI/Modal';
import { ReactComponent as GirlImage } from '../assets/girl.svg';
import {
  updateInsuranceStatus,
  get_patient_details,
  updateMedicationsInsurance,
  createVisitsInsurance
} from '../../../actions/patient_action';
import { useApi } from '../../../utils/api';
import { mapResponseToError } from '../../../utils/common';
import { Title } from '../../../styles/styled';
import tracking from '../../../utils/tracking';
import { formatCurrency } from '../../../utils/currency.util';

export const UpdateButton = styled.button`
  width: 232px;
  height: 35px;
  background: #6786ff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 28.5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.314286px;
  color: #ffffff;
  outline: none;
`;

export const CashPayButton = styled.button`
  width: 232px;
  height: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #6786ff;
  border: none;
  background: transparent;
  margin-top: 10px;
`;

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Girl = styled(GirlImage)`
  position: absolute;
  right: 0;
  bottom: 0;
`;

const Box = styled.div`
  width: 355px;
  background: #ffffff;
  border: 1px solid #f1f4ff;
  box-sizing: border-box;
  box-shadow: 0 0 12px 4px #f6f6f9;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;

  ${BlueHeader} {
    position: relative;
    border-radius: 10px 10px 0 0;
    height: 237px;
    width: 100%;

    ${WhiteTitle} {
      font-style: italic;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.1px;
      text-align: left;
      margin-top: 30px;
      width: 228px;
    }
  }
`;

const Estimates = styled.div`
  width: 100%;
  padding: 30px 30px 0 25px;
`;

const getAllCopays = ({ insurance }) => {
  const { medication_copay_amounts, therapy_copay_amounts } = insurance;

  return medication_copay_amounts.concat(therapy_copay_amounts).map(Number);
};

const formatCopay = copays => {
  return copays.map(copay => `$${copay}`).join(' - ');
};

const filterProps = insurance => {
  const newInsurance = { ...insurance };

  if (insurance.offering_key === 'medication') {
    newInsurance.therapy_copay_amounts = [];
  }

  if (insurance.offering_key === 'therapy') {
    newInsurance.medication_copay_amounts = [];
  }

  return newInsurance;
};

const getCopaysByType = ({ currentInsurance, newInsurance }) => {
  const insurance = filterProps(newInsurance);

  let { medication_copay_amounts, therapy_copay_amounts } = insurance;

  if (medication_copay_amounts.length === 0) {
    medication_copay_amounts = currentInsurance.medication_copay_amounts || [];
  }

  if (therapy_copay_amounts.length === 0) {
    therapy_copay_amounts = currentInsurance.therapy_copay_amounts || [];
  }

  return { medication_copay_amounts, therapy_copay_amounts };
};

const InsuranceEstimates = ({ updateView, currentPlan, switchToCashPlan, insurance, onSwitchedPlan, rawInsurance }) => {
  const hasProviderIni = useSelector(({ patient_reducer: { provider_ini } }) => provider_ini);
  const hasCareTeam = hasProviderIni?.has_prescriber && hasProviderIni?.has_therapist;
  const [openPopupCareTeam, setPopupCareTeam] = useState(null);
  useEffect(() => {
    setPopupCareTeam(!hasCareTeam);
  }, []);
  const abTestValues = useSelector(({ patient_reducer: { abTest } }) => abTest);
  const ABTestValues = {
    ENABLE_NEW_FLOW_CONVERT_OONI_TO_INI: 'enable_new_flow_convert_ooni_to_ini'
  };
  const currentInsurance = useSelector(({ patient_reducer: { insurance_visits_policy } }) =>
    cloneDeep(insurance_visits_policy)
  );
  const pendingUpdates = useSelector(
    ({
      patient_reducer: {
        visit_object: { pending_updates }
      }
    }) => pending_updates
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatchRedux = useDispatch();
  const refPendingUpdates = useRef(null);
  const api = useApi();

  const { offering_key: newPlan } = insurance;
  const isSamePlan = currentPlan.offering_key === newPlan && currentPlan.is_insurance;
  const copays = getAllCopays({ insurance });
  const [min, max] = [Math.min(...copays), Math.max(...copays)];

  const next = data => {
    if (isSamePlan || !data?.first_visit?.pending_updates) {
      return updateView('profile');
    }

    return onSwitchedPlan();
  };
  const prevInsurance = useSelector(({ patient_reducer: { insurance_visits_policy } }) => insurance_visits_policy);
  const checkPreviousPlan = status => {
    if (status === 'in_network') {
      return 'insurance';
    }
    return 'cash';
  };
  const newRawInsurance = { ...rawInsurance, previous_plan: checkPreviousPlan(prevInsurance.status) };
  const changePlan = () => {
    const { insurance_medications_policy } = insurance;

    const promises = [
      dispatchRedux(createVisitsInsurance(newRawInsurance)),
      dispatchRedux(updateMedicationsInsurance({ insurance: insurance_medications_policy, updates: { active: true } })),
      dispatchRedux(updateInsuranceStatus('active'))
    ];

    if (!isSamePlan) {
      promises.push(api.put(`/api/patients/switch_plan?new_offering_key=${newPlan}&is_insurance=true`));
    }

    return promises;
  };

  const updateInsurancePlan = async () => {
    const saveVisit = true;
    setLoading(true);

    try {
      // change plan
      await Promise.all(changePlan());

      // get updated patient's details
      const data = await dispatchRedux(get_patient_details(saveVisit));

      next(data);
    } catch (err) {
      const errorMessage = mapResponseToError(err);
      tracking.error({ message: errorMessage, e: err });
      setError(errorMessage);
      setLoading(false);
    }
  };

  const switchToCash = () => {
    switchToCashPlan({
      action: 'deactivate-current-insurance'
    });
  };

  const toInsurance = () => {
    updateView('insurance');
  };

  useEffect(() => {
    refPendingUpdates.current = pendingUpdates;
  }, [pendingUpdates]);

  const { mapData: estimatesData } = useMemo(() => {
    const { medication_copay_amounts, therapy_copay_amounts } = getCopaysByType({
      currentInsurance,
      newInsurance: insurance
    });
    const mapData = [
      {
        name: 'Membership fee:',
        price: `${formatCurrency(29)}/mo`,
        check: true,
        small: false,
        isValid: true
      },
      {
        name: `${prescriberVariant.singularCapitalize} Visits:`,
        price: isEmpty(insurance.medication_copay_amounts)
          ? 'Not covered under\nyour new plan'
          : formatCopay(insurance.medication_copay_amounts),
        check: !isEmpty(insurance.medication_copay_amounts),
        small: isEmpty(insurance.medication_copay_amounts),
        isValid: medication_copay_amounts.length > 0
      },
      {
        name: 'Therapy Visits:',
        price: isEmpty(insurance.therapy_copay_amounts)
          ? 'Not covered under\nyour new plan'
          : formatCopay(insurance.therapy_copay_amounts),
        check: !isEmpty(insurance.therapy_copay_amounts),
        small: isEmpty(insurance.therapy_copay_amounts),
        isValid: therapy_copay_amounts.length > 0
      },
      {
        name: 'Medication:',
        price: isEmpty(insurance.medication_copay_amounts)
          ? 'Not available under\nyour new plan'
          : 'Copays vary per\nmedication',
        check: !isEmpty(insurance.medication_copay_amounts),
        small: true,
        isValid: medication_copay_amounts.length > 0
      }
    ]
      .filter(item => item.isValid)
      .sort((x, y) => (x.check === y.check ? 0 : x.check ? -1 : 1));

    return { mapData };
  }, [insurance, currentInsurance]);

  return (
    <React.Fragment>
      <BackButton onClick={toInsurance}>&larr; Edit Eligibility Information</BackButton>
      <Holder>
        <Title>Updated Insurance Eligibility Estimates</Title>
        <Box>
          <BlueHeader>
            <WhiteIsotype />
            <WhiteTitle>
              Please note that your card will be charged the copay or coinsurance amount for each service you utilize
              automatically.
            </WhiteTitle>
            <Girl />
          </BlueHeader>
          <Estimates>
            {estimatesData.map(item => (
              <Estimate key={item.name} name={item.name} price={item.price} check={item.check} small={item.small} />
            ))}
          </Estimates>
          <UpdateButton onClick={updateInsurancePlan}>
            {loading ? <Spinner size="1.6em" /> : `Update insurance ${prescriberVariant.singularNormal}`}
          </UpdateButton>
          <CashPayButton onClick={switchToCash}>Switch to cash-pay</CashPayButton>
        </Box>
        <Error error={error} />
        <ImportantInformation buttonText={`Update insurance ${prescriberVariant.singularNormal}`}>
          <Footnote>*Your {formatCurrency(29)}/month membership fee includes:</Footnote>
          <ul>
            <li>Unlimited access to messaging with your care team and easy online scheduling platform</li>
            <li>Feel better every day with guided meditations, breathing exercises and tools to track your progress</li>
          </ul>
          <Footnote>{`**Visits and medication co-pays are charged separately and dependent on your insurance plan. You will not be billed for your visits or medications, if prescribed, until after your meeting with your ${
            prescriberVariant.singularNormal
          }/therapist and your treatment plan is determined. Co-pays for online visits start at ${formatCurrency(
            0
          )}, with 90% of our clients paying ${
            min === max ? `$${min}/visit` : `between $${min}-$${max}/visit`
          } when on a ${newPlan} plan. Co-pays for medication start at ${formatCurrency(
            0
          )}, with 90% of clients paying less than ${formatCurrency(
            15
          )} per prescription. Your medication cost will be determined after you meet with your ${
            prescriberVariant.singularNormal
          } and agree on a treatment plan.`}</Footnote>
        </ImportantInformation>
        {!error &&
          (abTestValues?.ab_test_convert_ooni_to_ini === ABTestValues.ENABLE_NEW_FLOW_CONVERT_OONI_TO_INI ||
            hasCareTeam) &&
          openPopupCareTeam && (
            <SwitchCareTeamModal
              onClose={() => setPopupCareTeam(false)}
              onUpdate={() => updateInsurancePlan()}
              loading={loading}
            />
          )}
      </Holder>
    </React.Fragment>
  );
};

export default InsuranceEstimates;
