import styled from 'styled-components/macro';
import isotypeImage from '../../../img/iso.svg';
import pillsImage from '../../../img/pills.svg';

export const Isotype = styled.div`
  background-image: url(${isotypeImage});
  height: 38px;
  width: 56px;
  margin: 0 auto;
  background-position: center top;
`;

export const Header = styled.div`
  background: #eff1fa;
  padding: 30px;
`;

export const Title = styled.div`
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #6786ff;
  margin: 7px auto 0;
`;

export const Heading = styled.h2`
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #6786ff;
`;

export const Paragraph = styled.div`
  font-size: 14px;
  line-height: 27px;
  text-align: center;
  margin: ${props => (props.noMargin ? '0' : '34')}px auto 0;
  color: #250044;
  p.blue {
    color: #6786ff;
  }
`;

export const Contents = styled.div`
  padding: 50px 10px;
`;

export const Pills = styled.div`
  background-image: url(${pillsImage});
  height: 100px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin: 24px auto 0;
`;

export const Wrapper = styled.div`
  max-width: 360px;
  margin: 0 auto;
`;

export const Controls = styled.div`
  margin: 48px auto 0;
  text-align: center;
`;

export const Button = styled.button`
  background: #6786ff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 28.5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  padding: 10px;
  width: 240px;
  margin: 18px auto 0;
  border: none;
  outline: none;
  &:first-child {
    margin-top: 0;
  }
`;

export const Step = styled.div``;

export const CancellationList = styled.ul`
  position: relative;
  padding: 0;
  margin: 59px auto 0;
  width: 85%;

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 8.4px;
    width: 5px;
    background: linear-gradient(180deg, #85abee 70%, rgba(133, 171, 238, 0) 100%);
    height: 100%;
    z-index: 0;
  }
`;

export const CancellationItem = styled.li`
  display: flex;
  align-items: flex-start;
  text-align: left;
  padding: 0;
  z-index: 1;
  position: relative;
  margin: 49px auto 0;
  &:first-child {
    margin-top: 0;
  }
`;

export const ItemIcon = styled.div`
  background-color: #aabaf9;
  border: 5px solid #6786ff;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin: 0 2px;
`;
export const ItemContent = styled.div`
  flex: 1;
  margin-left: 28px;
`;

export const ItemTitle = styled.h4`
  font-weight: 600;
  font-size: 25px;
  line-height: 22px;
  color: #85abee;
`;

export const ItemDescription = styled.p`
  margin: 19px 0 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #250044;
`;

export const Form = styled.form`
  width: 88%;
  margin: 0 auto;
  opacity: ${({ saving }) => (saving ? '.5' : '1')};
`;

export const Option = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px auto 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #250044;
  /* TODO: the CustomRadioButton component should be styled components based */
  .radio-button-container {
    margin: 0 14px 0 0;
    display: inline-block;
  }
  &:first-child {
    margin: 0 auto;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  margin: 30px auto 0;
  padding: 10px 8px;
  border: 1px solid #d3dbf8;
  box-shadow: 0px 0px 12px 4px #f6f6f9;
  border-radius: 4px;
  font-style: italic;
  font-size: 14px;
  line-height: 20px;
  outline: none;
  &::placeholder {
    color: #250044;
  }
`;

export const ConfirmControls = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 22px auto 0;
  border-top: 1px solid;
  padding: 30px 0 0;
`;

export const TextButton = styled.button`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.43px;
  color: #6786ff;
  border: none;
  background: none;
  outline: none;
  &:disabled {
    color: #c4c4c4;
  }
`;
