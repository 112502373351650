export const SET_VIEW = '@profile_info/SET_VIEW';
export const SET_CANCELLATION_MODAL = '@profile_info/SET_CANCELLATION_MODAL';
export const SET_INSURANCE_VISITS_POLICY = '@profile_info/SET_INSURANCE_VISITS_POLICY';
export const SET_CANCEL_FINISHED = '@profile_info/SET_CANCEL_FINISHED';
export const SET_SUBSCRIPTION = '@profile_info/SET_SUBSCRIPTION';
export const SET_SUBSCRIPTION_ERROR = '@profile_info/SET_SUBSCRIPTION_ERROR';

export const setView = view => ({
  type: SET_VIEW,
  payload: view
});

export const setCancellationModal = value => ({
  type: SET_CANCELLATION_MODAL,
  payload: value
});

export const setInsuranceVisitsPolicy = (insurance, allowedInsurance, rawInsurance) => ({
  type: SET_INSURANCE_VISITS_POLICY,
  insurance,
  allowedInsurance,
  rawInsurance
});

export const setCancelFinished = () => ({
  type: SET_CANCEL_FINISHED
});

export const setSubscription = subscription => ({
  type: SET_SUBSCRIPTION,
  payload: subscription
});

export const setSubscriptionError = error => ({
  type: SET_SUBSCRIPTION_ERROR,
  payload: error
});
