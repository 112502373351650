export const basePayerUrl = (search, page, showAllPayers) => {
  const apiUrl = '/api/insurance_payers';
  const params = {};
  if (showAllPayers) params.in_and_out_of_network = true;
  if (search) params.query = search;
  if (page) params.page = page;
  return `${apiUrl}?${new URLSearchParams(params).toString()}`;
};
export const baseURLINI = () => {
  const apiUrl = '/api/insurance_payers';
  const params = {
    in_and_out_of_network: false,
    page: 1
  };
  return `${apiUrl}?${new URLSearchParams(params).toString()}`;
};

export const baseSearchPayerUrl = (search, page, showAllPayers) => {
  const apiUrl = '/api/insurance_payers';
  const params = {};
  if (showAllPayers) params.in_and_out_of_network = true;
  if (search) params.query = search;
  if (page) params.page = page;

  return `${apiUrl}?${new URLSearchParams(params).toString()}`;
};
