import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import chevronImage from '../../../img/chevron-select.png';

const DropdownIndicator = ({ innerRef, innerProps }) => (
  <img alt="select-dropdown" ref={innerRef} {...innerProps} src={chevronImage} width="9px" />
);

const customStyles = {
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: isSelected || isFocused ? '#DAFFF1' : provided.backgroundColor,
    color: isSelected ? 'rgba(37, 0, 68)' : provided.color,
    '&:hover': {
      backgroundColor: '#DAFFF1'
    }
  }),
  control: provided => ({
    ...provided,
    width: '100%',
    background: '#FFFFFF',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 12px 4px #F6F6F9',
    border: '1px solid #D3DBF8',
    borderRadius: '4px',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '-0.43px',
    color: 'rgba(37, 0, 68)',
    padding: '15px'
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0'
  }),
  placeholder: provided => ({
    ...provided,
    padding: '0',
    color: '#7E668F'
  }),
  indicatorsContainer: provided => {
    return { ...provided, paddingRight: '10px' };
  },
  singleValue: provided => {
    return { ...provided, color: '#250044' };
  },
  indicatorSeparator: provided => {
    return { ...provided, display: 'none' };
  },
  clearIndicator: provided => {
    return { ...provided, color: 'rgb(42,5,72)', padding: '0', paddingRight: '8px' };
  }
};

export default props => <AsyncPaginate styles={customStyles} {...props} components={{ DropdownIndicator }} />;
