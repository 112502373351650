import styled from 'styled-components/macro';
import { Button } from '../../question_components/components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.div`
  color: #240055;
  width: 290px;
  text-align: center;
  margin-bottom: 30px;
`;

export const ContinueButton = styled(Button)`
  padding: 8px 40px;
  background: #f9fafd;
  border: 2px solid #250044;
  box-shadow: 0 0 5px #3b36e1;
  border-radius: 30px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.314286px;
  color: #250044;
`;
