import React, { useState, useCallback, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { allowDiscount, getCarePromo } from 'utils/common';
import { mapPlanToCouponCode } from 'utils/plans';
import { ApiContext } from 'utils/api';
import { Store } from 'types/store';
import { AxiosResponse } from 'axios';
import { VariationContext } from '../Variations/VariationContext';
import { ReactComponent as ValidSVG } from '../../img/valid.svg';
import { verifyCoupon } from '../../actions/patient_action';
import strings from '../../localization';
import { testPriceSensitivity, checkPriceSensitivity, checkChangePriceMed } from '../../utils/kameleoon';

const Form = styled.div``;

const CouponInput = styled.input`
  border: 1px solid #d3dbf8;
  box-shadow: 0 0 12px 4px #f6f6f9;
  border-radius: 4px;
  font-size: 14px;
  color: #250044;
  flex: 1;
  margin-right: 8px;
  padding: 0 12px;
`;

const ApplyButton = styled.button`
  background: #6786ff;
  border: 1px solid #6786ff;
  box-sizing: border-box;
  border-radius: 4px;
  color: #fff;
  padding: 9px 13px;
  &:disabled {
    opacity: 0.5;
  }
`;

const Status = styled.div`
  font-size: 14px;
  color: #6786ff;
  text-align: left;
  margin: 12px auto 0;
`;

const Error = styled.div`
  font-size: 14px;
  color: #eb5757;
  text-align: left;
  margin: 12px auto 0;
`;

export const Controls = styled.div`
  display: flex;
  margin: 12px auto 0;
`;

const ValidImage = styled(ValidSVG)`
  margin-left: 19px;
`;

interface PatientState {
  region: string;
  plan: Plan;
}

interface Plan {
  offering_key: string;
}

const selectRegion = ({ patient_reducer }: { patient_reducer: PatientState }) => patient_reducer.region;
const selectPlan = ({ patient_reducer: { plan } }: { patient_reducer: { plan: Plan } }) => plan.offering_key;

interface PromoFormProps {
  onApplyCode: ({ id, price }: { id: string; price: number }) => void;
  className?: string;
  offering: string;
  isChange?: boolean;
  promoCode?: string;
  termsToCharge?: number;
}

interface ResponseType {
  data: CouponData;
}

interface CouponData {
  success: boolean;
  price: number;
  plans: string[];
}

const PromoForm: React.FC<PromoFormProps> = ({
  promoCode,
  termsToCharge,
  onApplyCode,
  className,
  offering,
  isChange = false
}) => {
  const api = useContext(ApiContext);
  const { variation, promo } = useContext(VariationContext);
  const priceSensitivityCouponABTest = testPriceSensitivity();
  const priceSensitivity = checkPriceSensitivity();
  const checkChangePriceAdhdABTest = checkChangePriceMed();

  const region = useSelector(selectRegion);
  const planId: string = useSelector(selectPlan);
  const patientId = useSelector((store: Store) => store.global_reducer.current_user.attributes.patient?.id);

  const isCareCampaign = allowDiscount(region);
  const carePromo = getCarePromo(planId, promo, priceSensitivityCouponABTest, priceSensitivity);
  const promoPerPlan = mapPlanToCouponCode[planId] || promo;
  const defaultPromo =
    isCareCampaign ||
    ['Group 2', 'Group 3', 'Group 4', 'Group 5'].includes(priceSensitivityCouponABTest) ||
    ['PriceS2', 'PriceS3', 'PriceS4'].includes(priceSensitivity) ||
    ['option 1', 'option 2'].includes(checkChangePriceAdhdABTest)
      ? carePromo
      : promoPerPlan;

  let initialCode = promoCode || (variation !== 'default' && variation !== 'EMPTY' ? defaultPromo : '');

  if (initialCode !== undefined) {
    initialCode = initialCode.toString();
  } else {
    initialCode = '';
  }
  const [code, setCode] = useState<string>(initialCode);

  const [activePromo, setActivePromo] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const [validating, setValidating] = useState(false);
  const setFailInputPromo = () => {
    setValidating(false);
    setError('Invalid Code. Please use a valid promo code.');
  };
  const applyCode = async () => {
    setValidating(true);
    setError(null);
    setActivePromo(null);

    // This is a sanity check for new campaign
    // it should be in server but so far
    // our coupon validation service is not validating the code
    // which doesn't belong to Chargebee plan
    if (!isChange && isCareCampaign) {
      if (planId !== 'medication-therapy' && code === strings.defaultMedsTherapyCampaignCode) {
        setFailInputPromo();
        return;
      }
    }

    // Check to prevent user can not input promo code from another variations.
    if (
      !isChange &&
      ['medication', 'medication-therapy', 'therapy'].includes(planId) &&
      ['Group 2', 'Group 3', 'Group 4', 'Group 5'].includes(priceSensitivityCouponABTest)
    ) {
      switch (priceSensitivityCouponABTest) {
        case 'Reference':
          if (!['CARE30', 'START139', 'START99'].find(promoC => promoC === code)) {
            setFailInputPromo();
            return;
          }
          break;
        case 'Group 2':
          if (!['CEREBRAL15', 'CEREBRAL79', 'CEREBRAL65'].find(promoC => promoC === code)) {
            setFailInputPromo();
            return;
          }
          break;
        case 'Group 3':
          if (!['CEREB49', 'CEREB195', 'CEREB155'].find(promoC => promoC === code)) {
            setFailInputPromo();
            return;
          }
          break;
        case 'Group 4':
          if (!['CERE65', 'CERE245', 'CERE195'].find(promoC => promoC === code)) {
            setFailInputPromo();
            return;
          }
          break;
        case 'Group 5':
          if (code !== '') {
            setFailInputPromo();
            return;
          }
          break;
        default:
          break;
      }
    }

    let response = null;

    if (!isChange) {
      response = await dispatch(verifyCoupon({ coupon_id: code, terms_to_charge: termsToCharge }));
    } else {
      response = (
        await api.put<AxiosResponse<ResponseType>>(`/api/patients/${patientId}/coupon_valid`, {
          coupon_id: code,
          offering,
          is_insurance: false,
          terms_to_charge: termsToCharge
        })
      ).data;
    }

    const { success, price, plans } = response as unknown as CouponData;

    setValidating(false);
    if (success && price >= 0 && (plans === null || plans?.includes(offering))) {
      setError(null);
      setActivePromo(code);
      onApplyCode({ id: code, price });
    } else {
      setError('Invalid Code. Please use a valid promo code.');
    }
  };

  const onInputChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setCode(e.target.value);
  };

  const onApplyClick = useCallback(async () => applyCode(), [code]);

  useEffect(() => {
    if (code && code !== 'EMPTY' && !isChange) {
      (async () => applyCode())();
    }
  }, []);

  const applyIsDisabled = useCallback(() => {
    return validating || activePromo === code || code === '';
  }, [activePromo, validating, code]);

  return (
    <Form className={className}>
      <Controls>
        <CouponInput value={code} onChange={onInputChange} />
        <ApplyButton type="button" onClick={onApplyClick} disabled={applyIsDisabled()}>
          Add
        </ApplyButton>
      </Controls>

      {error !== null && <Error>{error}</Error>}

      {activePromo !== null && (
        <Status>
          Promo code applied <ValidImage />
        </Status>
      )}

      {validating && <Status>Validating...</Status>}
    </Form>
  );
};

export default styled(PromoForm)``;
